import { Divider, FormControlLabel } from '@material-ui/core';
import React from 'react';
import InputMouseAction, {
	InputMouseActionEventProps,
} from '../../../../../../../../../reusable/InputMouseAction/InputMouseAction';

interface PositionProps extends InputMouseActionEventProps {
	x: number;
	y: number;
	w: number;
	h: number;
	rotation: number;
}

const Position = ({
	x,
	y,
	w,
	h,
	rotation,
	handleLocationAttributeChange,
	handleMouseActionSelectInput,
	handleOnMouseMove,
	handleOnMouseUp,
}: PositionProps) => {
	return (
		<>
			<Divider className="divider-extra-space" />

			<FormControlLabel
				className={'scenario-chart__control'}
				control={
					<div className="scene-widgets__sort-input-control">
						<InputMouseAction
							value={x}
							styleProperty="coordinate"
							valueProperty="x"
							handleLocationAttributeChange={handleLocationAttributeChange}
							handleMouseActionSelectInput={handleMouseActionSelectInput}
							handleOnMouseMove={handleOnMouseMove}
							handleOnMouseUp={handleOnMouseUp}
						/>
						<InputMouseAction
							value={y}
							styleProperty="coordinate"
							valueProperty="y"
							handleLocationAttributeChange={handleLocationAttributeChange}
							handleMouseActionSelectInput={handleMouseActionSelectInput}
							handleOnMouseMove={handleOnMouseMove}
							handleOnMouseUp={handleOnMouseUp}
						/>
					</div>
				}
				label={'Position'}
				labelPlacement="start"
			/>
			<FormControlLabel
				className={'scenario-chart__control'}
				control={
					<div className="scene-widgets__sort-input-control">
						<InputMouseAction
							value={w}
							styleProperty="dimension"
							valueProperty="w"
							handleLocationAttributeChange={handleLocationAttributeChange}
							handleMouseActionSelectInput={handleMouseActionSelectInput}
							handleOnMouseMove={handleOnMouseMove}
							handleOnMouseUp={handleOnMouseUp}
						/>
						<InputMouseAction
							value={h}
							styleProperty="dimension"
							valueProperty="h"
							handleLocationAttributeChange={handleLocationAttributeChange}
							handleMouseActionSelectInput={handleMouseActionSelectInput}
							handleOnMouseMove={handleOnMouseMove}
							handleOnMouseUp={handleOnMouseUp}
						/>
					</div>
				}
				label={'Size'}
				labelPlacement="start"
			/>
			<FormControlLabel
				className={'scenario-chart__control'}
				control={
					<div className="scene-widgets__sort-input-control">
						<InputMouseAction
							value={rotation}
							styleProperty="rotation"
							handleLocationAttributeChange={handleLocationAttributeChange}
							handleMouseActionSelectInput={handleMouseActionSelectInput}
							handleOnMouseMove={handleOnMouseMove}
							handleOnMouseUp={handleOnMouseUp}
						/>
					</div>
				}
				label={'Rotation'}
				labelPlacement="start"
			/>
		</>
	);
};

export default Position;
