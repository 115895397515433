import { useRef } from 'react';
import { FLOWS, UPLOAD_FAILED, messageTypes } from '../constants/mediaConstants';
import { generateId } from '../services/stringHelperService';
import { useDispatch } from 'react-redux';
import { preventUserFromCloseWindow, uploadMediaBegins } from '../actions/publishActions';
import { showAlert, showMessage } from '../actions/globalActions';
import { useTranslation } from 'react-i18next';

interface UseUploadFileProps {
	defaultAccountId: string;
	onUploadBegin?: (_uploadedFileId: string) => void;
}

const useUploadFile = ({ defaultAccountId, onUploadBegin }: UseUploadFileProps) => {
	const inputFileRef = useRef<HTMLInputElement | null>(null);
	const dispatch = useDispatch<any>();
	const { t: translator } = useTranslation();

	const openFileSelector = () => {
		inputFileRef.current?.click();
	};

	const startUploadFile = (file: any) => {
		return new Promise((resolve) => {
			dispatch(preventUserFromCloseWindow(true));
			dispatch(showMessage(translator('LABEL_YOUR_MEDIA_BEING_UPLOADED'), messageTypes.info));
			const mediaId = generateId();
			const blob = file.slice(0, file.size, file.type, file.path);
			let blobFile = new File([blob], `${mediaId}.${file.name.split('.').pop()}`, { type: file.type });
			(blobFile as any).title = file.name;
			(blobFile as any).id = mediaId;
			(blobFile as any).filename = mediaId;
			const flowId = FLOWS.qbrickStandard;

			resolve({ id: mediaId, type: 'uploadNew', file: blobFile });
			dispatch(uploadMediaBegins(defaultAccountId, flowId, '', null, blobFile, '', file.name)).then(
				(data: any) => {
					dispatch(preventUserFromCloseWindow(false));

					if (data === UPLOAD_FAILED) {
						dispatch(showAlert(`${UPLOAD_FAILED}`, messageTypes.error));
						return;
					}
					onUploadBegin?.(mediaId);
					dispatch(showMessage(translator('LABEL_YOUR_MEDIA_BEING_ENCODED'), messageTypes.info));
				}
			);
		});
	};

	return {
		inputFileRef,
		openFileSelector,
		startUploadFile,
	};
};

export default useUploadFile;
