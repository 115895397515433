export const languageSelector = [
    { value: "ar", name: "Arabic" },
    { value: "bg", name: "Bulgarian" },
    { value: "yue", name: "Cantonese" },
    { value: "ca", name: "Catalan" },
    { value: "hr", name: "Croatian" },
    { value: "cs", name: "Czech" },
    { value: "da", name: "Danish" },
    { value: "nl", name: "Dutch" },
    { value: "en", name: "English" },
    { value: "fi", name: "Finnish" },
    { value: "fr", name: "French" },
    { value: "de", name: "German" },
    { value: "el", name: "Greek" },
    { value: "hi", name: "Hindi" },
    { value: "hu", name: "Hungarian" },
    { value: "id", name: "Indonesian" },
    { value: "it", name: "Italian" },
    { value: "ja", name: "Japanese" },
    { value: "ko", name: "Korean" },
    { value: "lv", name: "Latvian" },
    { value: "lt", name: "Lithuanian" },
    { value: "ms", name: "Malay" },
    { value: "cmn", name: "Mandarin" },
    { value: "no", name: "Norwegian" },
    { value: "pl", name: "Polish" },
    { value: "pt", name: "Portuguese" },
    { value: "ro", name: "Romanian" },
    { value: "ru", name: "Russian" },
    { value: "sk", name: "Slovakian" },
    { value: "sl", name: "Slovenian" },
    { value: "es", name: "Spanish" },
    { value: "sv", name: "Swedish" },
    { value: "tr", name: "Turkish" },
];