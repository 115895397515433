import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Timeline from '../../../components/Timeline/Timeline';
import {
	isDateBetweenTwoDates,
	getTimelineStartDate,
	getFormattedTimeWithMilliseconds,
	getTimeDifferenceInSeconds,
	getSecondsFromTimelineTime,
	getTimelineTimeFromSeconds,
	returnAddedTime,
	returnDecreasedTime,
} from '../../../services/timeStampService';
import {
	setInteractScreenModified,
	setInteractScreenUnModified,
	createInteractTimelineItem,
	createInteractTimelineGroup,
	updateInteractTimelineItem,
	updateInteractTimelineGroup,
	setSelectedInteractTimelineItem,
	showTrimTool,
	removeInteractTimelineGroup,
	stopInteractMediaLoop,
	disableVideoApplyButton,
} from './../../../actions/interactTimelineActions';
import {
	setInteractItemsToDelete,
	showInteractDeleteDialog,
	dropItemOnInteractQueue,
} from '../../../actions/interactElementsActions';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import './InteractEditorBottomPanel.scss';
import delete_icon from '../../../assets/images/delete_icon.svg';
import {
	interactTimelineItemTypes,
	interactTimelineGroupTypes,
	interactTimelineIcons,
	interactMimeType,
	sequenceTypes,
} from '../../../constants/interactConstant';
import { getThumbnailFromRenditionsBasedOnWidth } from '../../../services/mediaDisplayService';
import {
	checkIfItemIsOverlapping,
	isTimeBeyondRange,
	getGroupItemsSortedByStartTime,
	getIteracItemEndDate,
	getItemObjectByDefinition,
	isTimelineItemInteract,
} from '../../../services/interactScreenHelperService';
import { generateId } from '../../../services/stringHelperService';
import { showNotification } from './../../../actions/notificationActions';

class InteractEditorBottomPanel extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			options: this.getOptions(),
			customTimes: {
				interactPointer: getTimelineStartDate(),
			},
			isPlayerPlaying: false,
			isTimelineFocused: false,
			duration: 0,
		};
		this.$pointerTimeDisplay = document.createElement('label');
		this.$pointerTimeDisplay.setAttribute('id', 'pointerTimeDisplay');
		this.$interactEditorScreen = document.getElementById('interactEditorScreen');
	}

	UNSAFE_componentWillMount = () => {
		if (!this.props.isOnLiveMode) {
			this.addMediaItemAndGroup();
		}
		window.addEventListener('click', this._handleClickEvent, true);
	};

	componentWillUnmount() {
		this.removePlayerEventHandlers();
		window.removeEventListener('click', this._handleClickEvent, true);
		if (this.$pointerTimeDisplay) {
			this.$pointerTimeDisplay.remove();
		}
	}

	componentDidMount = () => {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
		this._initPlayer();
		//this.loadBrokenTimelineUI()
	};

	componentDidUpdate(prevProps) {
		/*if (this.props.selectedLeftMenuKey !== prevProps.selectedLeftMenuKey && this.props.selectedLeftMenuKey === menuKeys.interactiveManager) {
      this.loadBrokenTimelineUI()
    }*/
		/* if (this.props.interactElementsWidget && this.props.interactElementsWidget !== prevProps.interactElementsWidget) {
       this.fetchExistingInteractItems()
     }*/
		if (
			prevProps.interacMediaLoopStarted !== this.props.interacMediaLoopStarted &&
			this.props.interacMediaLoopStarted &&
			this.player &&
			this.player.play
		) {
			this.player.play();
		}
		if (
			prevProps.interacMediaLoopStarted !== this.props.interacMediaLoopStarted &&
			!this.props.interacMediaLoopStarted &&
			this.player &&
			this.player.pause
		) {
			this.player.pause();
		}

		if (prevProps.trimTool !== this.props.trimTool) {
			if (!this.$interactEditorScreen) {
				this.$interactEditorScreen = document.getElementById('interactEditorScreen');
			}
			if (this.props.trimTool) {
				this.$interactEditorScreen.setAttribute('trimtool', 'true');
			} else {
				this.$interactEditorScreen.removeAttribute('trimtool');
			}
		}
		if (prevProps.refreshPlayer !== this.refreshPlayer) {
			this.removePlayerEventHandlers();
			this._initPlayer();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.itemDroppedOnOverlay != nextProps.itemDroppedOnOverlay) {
			this.onItemDroppedInGroup(nextProps.itemDroppedOnOverlay);
		}
		if (
			nextProps.lastSetPlayerPositionTime &&
			nextProps.lastSetPlayerPositionTime !== this.props.lastSetPlayerPositionTime
		) {
			this.movePlayerToPosition(nextProps.playerPositionToSet);
		}
	}

	_initPlayer = () => {
		this.player = window.GoBrain.widgets()['interactPlayer-od'];
		this.addPlayerEventHandlers();
	};

	addPlayerEventHandlers = () => {
		if (this.player) {
			this.player.on('position', this.handlePlayerPositionEvent);
			this.player.on('duration', this.handlePlayerDurationEvent);
			this.player.on('play', this.handlePlayerPlayEvent);
			this.player.on('pause', this.handlePlayerPauseEvent);
		}
	};

	removePlayerEventHandlers = () => {
		if (this.player) {
			this.player.off('position', this.handlePlayerPositionEvent);
			this.player.off('duration', this.handlePlayerDurationEvent);
			this.player.off('play', this.handlePlayerPlayEvent);
			this.player.off('pause', this.handlePlayerPauseEvent);
		}
	};

	_handleClickEvent = () => {
		this.resetTimeLineFocus();
	};

	setTimeLineFocus = () => {
		this.setState({
			isTimelineFocused: true,
		});
	};

	resetTimeLineFocus = () => {
		this.setState({
			isTimelineFocused: false,
		});
	};

	handlePlayerPlayEvent = () => {
		this.setState({
			isPlayerPlaying: true,
		});
	};

	handlePlayerPauseEvent = () => {
		this.setState({
			isPlayerPlaying: false,
		});
	};

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return {};
	};

	//Move cursor on player position
	handlePlayerPositionEvent = (position) => {
		const { interacMediaLoopStarted, selectedInteractItems, mediaDetails, interactItems } = this.props;
		const { duration } = this.state;
		let newTime = getTimelineTimeFromSeconds(position);
		if (duration && mediaDetails.duration && Math.round(duration) < Math.round(mediaDetails.duration)) {
			let videoItem = _.find(interactItems, (item) => {
				return item.itemType == sequenceTypes.video;
			});
			if (videoItem) {
				let videoItemStartPoint = getSecondsFromTimelineTime(videoItem.start);
				videoItemStartPoint = videoItemStartPoint + position;
				let videoItemEndPoint = getSecondsFromTimelineTime(videoItem.end);
				if (videoItemStartPoint > videoItemEndPoint) {
					videoItemStartPoint = videoItemEndPoint;
				}
				newTime = getTimelineTimeFromSeconds(videoItemStartPoint);
			}
		}
		if (interacMediaLoopStarted) {
			let item = this.getCurrentlyEditedItem();
			if (item.start && newTime > item.end) {
				this.movePlayerToPosition(item.start);
				return;
			}
		}
		this.updateTimeOnPointer(newTime);
		if (selectedInteractItems.length == 1 && selectedInteractItems[0].itemType == sequenceTypes.video) {
			return;
		}
		this.displayItemsOnPosition(newTime);
	};

	handlePlayerDurationEvent = (duration) => {
		this.setState({
			//added to work after trimming of video
			duration: duration,
		});
	};

	displayItemsOnPosition = (time) => {
		const { interactItems, selectedInteractItems, mediaId, interacMediaLoopStarted, trimTool } = this.props;
		let seletedItemId = selectedInteractItems.length > 0 ? selectedInteractItems[0] : '';
		if (interactItems.length > 0 && !interacMediaLoopStarted && !trimTool) {
			let item = this.getInteractItemWithSpecifiedTime(time);
			if (item && item.id !== mediaId && item.id !== seletedItemId && item.itemType !== sequenceTypes.video) {
				this.props.setSelectedInteractTimelineItem([item.id]);
			} else if (item && item.itemType === sequenceTypes.video) {
				this.props.setSelectedInteractTimelineItem([]);
			} else if (!item && selectedInteractItems.length > 0) {
				let videoItemSelected = _.find(interactItems, (item) => {
					return item.itemType == sequenceTypes.video && selectedInteractItems[0] == item.id;
				});
				if (!videoItemSelected && !trimTool) {
					this.props.setSelectedInteractTimelineItem([]); //to do discuss if we need to remove selection
				}
			}
		}
	};

	getInteractItemWithSpecifiedTime = (time) => {
		const { interactItems, mediaId } = this.props;
		let foundItem = '';
		interactItems.map((item) => {
			if (isDateBetweenTwoDates(time, item.start, item.end) && item.id !== mediaId) {
				foundItem = item;
			}
		});
		return foundItem;
	};

	loadBrokenTimelineUI = () => {
		setTimeout(() => {
			let newOptions = {
				...this.state.options,
			};
			this.setState({
				options: newOptions,
			});
			this.updateTimeOnPointer(this.state.customTimes.interactPointer || getTimelineStartDate());
		}, 2000); // to know when timeline gets loaded
	};

	handleEditTrim = () => {
		const { interactItems } = this.props;
		this.props.showTrimTool();
		this.props.disableVideoApplyButton();
		let trimItem = _.find(interactItems, (item) => {
			return item.itemType === sequenceTypes.video;
		});
		if (trimItem) {
			this.props.setSelectedInteractTimelineItem([trimItem.id]);
		}
	};

	handleInpointClick = () => {
		const { interactItems, t } = this.props;
		this.props.showTrimTool();
		let trimItem = _.find(interactItems, (item) => {
			return item.itemType === sequenceTypes.video;
		});
		if (trimItem) {
			trimItem.start = this.getCustomTimeState(); //setting to cursor position
			if (trimItem.start > trimItem.end) {
				this.props.showNotification(t('START_TIME_GREATER_END_TIME'));
			}
			this.props.updateInteractTimelineItem(trimItem, true);
		} else {
			this.addTrimtoolItemAndGroup();
		}
	};

	handleOutpointClick = () => {
		const { interactItems, t } = this.props;
		let trimItem = _.find(interactItems, (item) => {
			return item.itemType === sequenceTypes.video;
		});
		if (trimItem) {
			this.props.showTrimTool();
			if (trimItem.start < this.getCustomTimeState()) {
				trimItem.end = this.getCustomTimeState(); //setting to cursor position
				this.props.updateInteractTimelineItem(trimItem, true);
			} else {
				this.props.showNotification(t('END_TIME_GREATER_THAN_START'));
			}
		}
	};

	addTrimtoolItemAndGroup = () => {
		const { mediaId, mediaDetails, interactGroups } = this.props;
		let group = 'group-' + mediaId;
		if (interactGroups.length > 0) {
			group = interactGroups[0].id;
		}
		let item = {
			id: generateId(),
			className: interactTimelineItemTypes.video,
			content: '',
			editable: true,
			start: this.getCustomTimeState(), //setting to cursor position
			end: getTimelineTimeFromSeconds(mediaDetails.duration),
			group: group,
			itemType: sequenceTypes.video,
		};
		item.settings = {};
		this.props.createInteractTimelineItem(item, 'setScreenUnchanged');
		this.props.setSelectedInteractTimelineItem([item.id]);
	};

	addMediaItemAndGroup = () => {
		const { mediaId, mediaDetails } = this.props;
		const index = 0;
		let thumbnail = mediaDetails.thumbnail;
		if (thumbnail instanceof Array) {
			thumbnail = getThumbnailFromRenditionsBasedOnWidth(thumbnail, 40);
		}

		let group = {
			id: 'group-' + mediaId,
			className: interactTimelineGroupTypes.mediaGroup,
			content: `<img src=${interactTimelineIcons.mediaIcon} ></img>`,
			order: index + 1,
			groupEditable: false,
		};

		let item = {
			id: mediaId,
			className: interactTimelineItemTypes.mediaItem,
			content: ` <img src=${thumbnail}></img> <div className='visItemLabel'> ${mediaDetails.title} </div>`,
			editable: false,
			start: getTimelineStartDate(), //setting to cursor position
			end: getTimelineTimeFromSeconds(mediaDetails.duration),
			group: group.id,
		};
		this.props.createInteractTimelineGroup(group);
		this.props.createInteractTimelineItem(item, 'setScreenUnchanged');
	};

	onItemDroppedInGroup = (objectData) => {
		const { interactGroups, interactItems, isOnLiveMode } = this.props;
		//if (!timelineItemObj) { return; }
		const sortedGroups = interactGroups.sort((a, b) => b.order - a.order);
		const index = interactGroups.length > 0 ? sortedGroups[0].order + 1 : 1;
		let group = _.find(interactGroups, (group) => {
			return group.id === objectData.templateGroup;
		});
		if (!group) {
			group = {
				id: objectData.templateGroup,
				className: objectData.templateGroup,
				content: `<img src=${
					objectData.templateId === sequenceTypes.chapter
						? interactTimelineIcons.chaptersIcon
						: interactTimelineIcons.buttonsIcon
				} ></img>`,
				order: index + 1,
				groupEditable: true,
			};
			this.props.createInteractTimelineGroup(group);
		}
		let itemStartDate = this.getCustomTimeState();
		let itemEndDate = getIteracItemEndDate(itemStartDate, this);
		if (objectData.definitions) {
			objectData = getItemObjectByDefinition(objectData);
		}

		const itemIndex =
			interactItems.length > 0
				? interactItems.sort((a, b) => a.index - b.index)[interactItems.length - 1].index + 1
				: 0;
		let item = {
			...objectData,
			id: generateId(),
			className:
				objectData.templateId === interactTimelineItemTypes.chapter
					? interactTimelineItemTypes.chapter
					: interactTimelineItemTypes.interactItem,
			content: objectData.templateName,
			editable: isOnLiveMode ? false : true,
			start: isOnLiveMode ? null : itemStartDate, //setting to cursor position
			end: isOnLiveMode ? null : itemEndDate,
			group: group.id,
			mimeType: objectData.templateId === sequenceTypes.chapter ? '' : interactMimeType,
			itemType: objectData.templateId === sequenceTypes.chapter ? sequenceTypes.chapter : sequenceTypes.custom, //added only for timeline as could not add type
			index: itemIndex,
		};

		if (!this.props.isOnLiveMode) {
			item = this.getModifiedItemStartAndEndTime(item, group);
		}

		if (!this.props.isOnLiveMode) {
			if (checkIfItemIsOverlapping(this, item) || isTimeBeyondRange(this, item)) {
				return;
			}
		}
		this.props.createInteractTimelineItem(item);
		this.props.setSelectedInteractTimelineItem([item.id]);
		// this.props.setInteractScreenModified() no need to set as already handled while creating interact item
	};

	getModifiedItemStartAndEndTime = (item, group) => {
		const { interactItems, mediaDetails, mediaId } = this.props;
		if (checkIfItemIsOverlapping(this, item, 'noMessage')) {
			let sortedItems = getGroupItemsSortedByStartTime(interactItems, group.id);
			let prevItemEndDate = '';
			let nextItemStartDate = '';
			for (var i = 0; i < sortedItems.length; i++) {
				if (sortedItems[i].id !== mediaId) {
					if (isDateBetweenTwoDates(this.getCustomTimeState(), sortedItems[i].start, sortedItems[i].end)) {
						item.start = new Date(sortedItems[i].end);
						item.start = returnAddedTime(item.start, 1, 'ms');
						//item.start.setMilliseconds(item.start.getMilliseconds() + 1)
						item.end = getIteracItemEndDate(item.start, this);
						if (!checkIfItemIsOverlapping(this, item)) {
							break;
						}
					}
					if (sortedItems[i].start > this.getCustomTimeState()) {
						if (i > 0) {
							if (this.getCustomTimeState() < sortedItems[i - 1].end) {
								prevItemEndDate = new Date(sortedItems[i - 1].end);
								prevItemEndDate = returnAddedTime(prevItemEndDate, 1, 'ms');
								//prevItemEndDate.setMilliseconds(prevItemEndDate.getMilliseconds() + 1)
							} else {
								prevItemEndDate = this.getCustomTimeState();
							}
						}
						nextItemStartDate = new Date(sortedItems[i].start);
						nextItemStartDate = returnDecreasedTime(nextItemStartDate, 1, 'ms');
						//nextItemStartDate.setMilliseconds(nextItemStartDate.getMilliseconds() - 1)
						break;
					}
				}
			}
			if (prevItemEndDate && nextItemStartDate) {
				item.start = prevItemEndDate;
				item.end = nextItemStartDate;
			}
		} else if (isTimeBeyondRange(this, item, 'noMessage')) {
			let positionToMove = getSecondsFromTimelineTime(item.end);
			if (positionToMove > mediaDetails.duration) {
				let startPos = getSecondsFromTimelineTime(item.start);
				if (positionToMove > startPos) {
					item.end = getTimelineTimeFromSeconds(positionToMove);
					item.end = returnDecreasedTime(item.end, 1, 'ms');
				}
			}
		}
		return item;
	};

	updateCustomTimeState = (time) => {
		let customTime = { interactPointer: time };
		this.setState({
			customTimes: customTime,
		});
	};

	getCustomTimeState = () => {
		return this.state.customTimes.interactPointer;
	};

	updateCustomTimeDisplay = (time) => {
		this.$pointerTimeDisplay.textContent = getFormattedTimeWithMilliseconds(time, 'HH:mm:ss.SSS');
	};

	setPointerDisplay = () => {
		if (!this.$pointerDiv) {
			this.$pointerDiv = document.getElementsByClassName('interactPointer')[0];
		}
		if (
			this.$pointerDiv &&
			this.$pointerDiv.firstElementChild &&
			this.$pointerDiv.firstElementChild.children.length === 0
		) {
			this.$pointerDiv.firstElementChild.append(this.$pointerTimeDisplay);
		}
	};

	timelineClickEventHandler = (e) => {
		const { interacMediaLoopStarted, selectedInteractItems, trimTool, mediaDetails } = this.props;
		const { duration } = this.state;

		this.setTimeLineFocus();
		if (e && e.event) {
			e.event.stopPropagation();
			e.event.preventDefault();
		}
		if (e.what && e.what == 'axis') {
			// added to move cursor at selected time
			this.updateTimeOnPointer(e.time);
			if (!trimTool && Math.round(duration) == Math.round(mediaDetails.duration)) {
				this.movePlayerToPosition(e.time);
			}
			if (interacMediaLoopStarted && selectedInteractItems) {
				let item = this.getCurrentlyEditedItem();
				if (e.time > item.end || e.time < item.start) {
					this.props.stopInteractMediaLoop();
				}
				return; // to check this condition
			}
		}
	};

	selectEventHandler = (e) => {
		const { mediaId, interactItems } = this.props;
		const { isPlayerPlaying } = this.state;
		if (e && e.event) {
			e.event.stopPropagation();
			e.event.preventDefault();
		}
		var selectedItems = _.filter(e.items, function (item) {
			return item !== mediaId;
		});
		if (isPlayerPlaying) {
			this.player.pause(); //pausing the player if an item is selected
			setTimeout(() => {
				this.props.setSelectedInteractTimelineItem(selectedItems);
			}, 500);
		} else {
			let foundItemsList = [];
			if (selectedItems.length > 1) {
				selectedItems.map((id) => {
					let _item = _.find(interactItems, (lastItem) => {
						return lastItem.id === id;
					});
					if (_item) {
						foundItemsList.push(_item);
					}
				});
				let sortedItems = getGroupItemsSortedByStartTime(foundItemsList, foundItemsList[0].group);
				let sortedItemsId = [];
				sortedItems.map((item) => {
					sortedItemsId.push(item.id);
				});
				this.props.setSelectedInteractTimelineItem(sortedItemsId);
				return;
			}
			if (selectedItems.length > 0) {
				if (!this.$interactEditorScreen) {
					this.$interactEditorScreen = document.getElementById('interactEditorScreen');
				}
				let item = _.find(interactItems, (lastItem) => {
					return lastItem.itemType === interactTimelineItemTypes.video && selectedItems[0] === lastItem.id;
				});
				if (item) {
					this.$interactEditorScreen.setAttribute('trimtool', 'true');
				} else {
					this.$interactEditorScreen.removeAttribute('trimtool');
				}
				this.props.setSelectedInteractTimelineItem(selectedItems);
			}
		}
	};

	movePlayerToPosition = (time) => {
		if (this.player) {
			let positionToMove = getSecondsFromTimelineTime(time);
			if (this.player && this.player.position) {
				this.player.position(positionToMove);
			}
		}
	};

	updateTimeOnPointer = (time) => {
		this.setPointerDisplay();
		this.updateCustomTimeDisplay(time);
		this.updateCustomTimeState(time);
	};

	timelineTimechangeEventHandler = (properties) => {
		const { mediaDetails } = this.props;
		//this.updateTimeOnPointer(properties.time)
		let time = properties.time;
		let max = getTimelineTimeFromSeconds(mediaDetails.duration);
		if (mediaDetails.duration && time >= getTimelineStartDate() && time <= max) {
			this.movePlayerToPosition(time);
		} else {
			this.setState({
				customTimes: {
					interactPointer: getTimelineStartDate(),
				},
			});
		}
	};

	debounce = (func, delay) => {
		let debounceTimer;
		return function () {
			const context = this;
			const args = arguments;
			clearTimeout(debounceTimer);
			debounceTimer = setTimeout(() => func.apply(context, args), delay);
		};
	};

	//trying to move multiple items TOdo- testing

	tryMovingSelectedItems = (newItem, callback) => {
		const { interactItems, mediaId, selectedInteractItems } = this.props;
		// let _item = _.find(interactItems, (lastItem) => { return lastItem.id === item.id && lastItem.group === item.group })
		let oldItem = '';
		let copyOfNonSelectedItems = [];
		let copyOfSelectedItems = [];
		interactItems.map((item) => {
			if (item.id !== mediaId) {
				if (selectedInteractItems.indexOf(item.id) === -1) {
					copyOfNonSelectedItems.push({ ...item });
				} else if (selectedInteractItems.indexOf(item.id) !== -1) {
					copyOfSelectedItems.push({ ...item });
				}
				if (item.id === newItem.id) {
					oldItem = {
						...item,
					};
				}
			}
		});
		let secondsToIncrease = '';
		let secondsToDecrease = '';
		if (oldItem && newItem) {
			if (oldItem.start < newItem.start) {
				secondsToIncrease = getTimeDifferenceInSeconds(newItem.start, oldItem.start);
			} else if (oldItem.start > newItem.start) {
				secondsToDecrease = getTimeDifferenceInSeconds(oldItem.start, newItem.start);
			}
		}

		if (secondsToIncrease) {
			copyOfSelectedItems.map((item) => {
				item.end = returnAddedTime(item.end, secondsToIncrease, 'seconds');
				item.start = returnAddedTime(item.start, secondsToIncrease, 'seconds');
			});
		} else if (secondsToDecrease) {
			copyOfSelectedItems.map((item) => {
				item.end = returnDecreasedTime(item.end, secondsToDecrease, 'seconds');
				item.start = returnDecreasedTime(item.start, secondsToDecrease, 'seconds');
			});
		}

		let finalItems = copyOfNonSelectedItems.concat(copyOfSelectedItems);
		let canMove = true;
		finalItems.map((item) => {
			if (this.checkIfItemIsOverlappingNewItems(finalItems, item) || this.isTimeBeyondRange(item)) {
				canMove = false;
			}
		});
		if (canMove) {
			copyOfSelectedItems.map((item) => {
				this.props.updateInteractTimelineItem(item);
			});
		} else {
			callback(null);
		}
	};

	checkIfItemIsOverlappingNewItems = (itemList, item) => {
		let overlap = false;
		itemList.map((eachItem) => {
			if (item.id !== eachItem.id && item.group === eachItem.group) {
				if (
					isDateBetweenTwoDates(item.start, eachItem.start, eachItem.end) ||
					isDateBetweenTwoDates(item.end, eachItem.start, eachItem.end)
				) {
					overlap = true;
				}
			}
		});
		return overlap;
	};

	isTimeBeyondRange = (item) => {
		const { t, mediaDetails, showNotification } = this.props;
		let positionToMove = getSecondsFromTimelineTime(item.end);
		if (
			!isDateBetweenTwoDates(
				item.start,
				getTimelineStartDate(),
				getTimelineTimeFromSeconds(mediaDetails.duration)
			) ||
			positionToMove > mediaDetails.duration
		) {
			showNotification(t('TIME_IS_OUT_OF_RANGE'));
			return true;
		}
		return false;
	};

	//trying to move multiple items end

	onMoveItem = (item, callback) => {
		const { interactItems, selectedInteractItems, mediaDetails } = this.props;

		if (item.itemType == interactTimelineItemTypes.video) {
			if (item.start < getTimelineStartDate()) {
				item.start = getTimelineStartDate();
			}
			let positionToMove = getSecondsFromTimelineTime(item.end);
			if (positionToMove > mediaDetails.duration) {
				item.end = getTimelineTimeFromSeconds(mediaDetails.duration);
			}
			this.props.updateInteractTimelineItem(item, true);
			callback(item);
			return;
		}
		if (item.editable) {
			let _item = _.find(interactItems, (lastItem) => {
				return lastItem.id === item.id && lastItem.group === item.group;
			});
			if (_item) {
				if (selectedInteractItems.length > 1) {
					this.tryMovingSelectedItems(item, callback);
					return;
				}
				if (!checkIfItemIsOverlapping(this, item) && !isTimeBeyondRange(this, item)) {
					this.props.updateInteractTimelineItem(item);
					callback(item);
				} else {
					callback(null);
					return;
				}
			} else {
				callback(null);
				return;
			}
		} else {
			callback(null);
			return;
		}
	};

	onRemoveItem = (item, callback) => {
		if (item.editable || isTimelineItemInteract(item)) {
			this.props.setInteractItemsToDelete([item]);
			this.props.showInteractDeleteDialog();
			/*  this.props.removeInteractTimelineItem(item);
        this.props.removeSelectedInteractTimelineItem(item.id)*/

			/* let group = _.find(this.props.timelineGroups, (group) => { return group.id === item.group });
       removeTimelineGroup(group);*/
		} else {
			if (callback && typeof callback == 'function') {
				callback(null);
			}
		}
	};

	getOptions = () => {
		const { mediaDetails } = this.props;
		const min = getTimelineStartDate();
		let max = getTimelineTimeFromSeconds(mediaDetails.duration || 1);
		let end = max;
		const sortGroups = (a, b) => {
			return b.order - a.order;
		};

		//multiselect: true, //code for multiselect

		// http://visjs.org/docs/timeline/#Configuration_Options
		const options = {
			stack: false,
			editable: {
				remove: true,
				updateTime: true,
			},
			longSelectPressTime: 500,
			moveable: true,
			//groupOrder:'title',// function or string
			start: min,
			end: end, //good to give to reduce initial load time // check for zoom
			selectable: true,
			multiselect: true,
			// multiselectPerGroup: true,
			groupEditable: {
				add: true,
				remove: true,
				order: true,
			},
			groupOrder: sortGroups,
			min: min,
			max: max,
			zoomable: true,
			zoomMin: 1000 * 2, // one hour in milliseconds
			zoomMax: 1000 * 60 * 60 * 24, // one day in milliseconds
			orientation: 'top',
			format: {
				minorLabels: {
					//showing timeline with only seconds and single digit hours
					second: 'HH:mm:ss',
					minute: 'HH:mm:ss',
					hour: 'HH:mm:ss',
				},
			},
			snap: function (date) {
				var milliseconds = 100;
				return Math.round(date / milliseconds) * milliseconds;
			},
			onDropObjectOnItem: this.onItemDroppedInGroup,
			margin: {
				axis: 0,
				item: 0,
			},
			showCurrentTime: false,
			showMajorLabels: false,
			stackSubgroups: true,
			itemsAlwaysDraggable: false,
			onRemove: this.onRemoveItem,
			onMove: this.onMoveItem,
			// onMove: this.debounce(this.onMoveItem, 500)
		};
		return options;
	};

	objectWithoutKey = (object, key) => {
		const { [key]: _deletedKey, ...otherKeys } = object;
		return otherKeys;
	};

	renderLiveAssetsContainer(interactItems) {
		const { liveQueueItem } = this.props;
		let itemArray = interactItems.filter((item) => {
			return (
				(item.templateId && !(item.start && item.end)) ||
				(item.start && !item.end) ||
				(liveQueueItem && liveQueueItem.id == item.id)
			);
		});

		return (
			<div
				className="interactQueueContainer"
				onDragOver={(event) => this.allowDrop(event)}
				onDrop={(event) => this.onDrop(event)}
			>
				{itemArray.map((item) => {
					let subElementsArray = [];

					Object.values(item.settings).map((subElement) => {
						if (subElement.hasOwnProperty('imageUrl')) {
							subElementsArray.push(subElement);
						}
					});

					return (
						<>
							<div
								className="interactQueueItem"
								liveQueue={liveQueueItem && liveQueueItem.id === item.id ? 'true' : ''}
								onClick={() => {
									this.props.setSelectedInteractTimelineItem([item.id]);
								}}
							>
								<div className="interactQueueItemBox">
									<img
										className="interactElementImage"
										src={subElementsArray.length > 0 ? subElementsArray[0].imageUrl.default : ''}
									/>
								</div>
								<img className="deleteIcon" src={delete_icon} onClick={() => this.onRemoveItem(item)} />
								<label>
									{item.templateName} - {item.content}
								</label>
								{liveQueueItem && liveQueueItem.id === item.id ? (
									<>
										<label> In live queue</label>
									</>
								) : null}
							</div>
						</>
					);
				})}
			</div>
		);
	}

	onDrop = (event) => {
		event.stopPropagation();
		var data = JSON.parse(event.dataTransfer.getData('text'));
		this.props.dropItemOnInteractQueue(data);
	};

	allowDrop = (event) => {
		event.preventDefault();
	};

	render() {
		const { interactItems, interactGroups, selectedInteractItems } = this.props;
		const { options, customTimes } = this.state;
		interactItems.sort((a, b) => a.index - b.index);

		return (
			<div
				id="interactEditorBottomPanelWrapper"
				className="interactEditorBottomPanelWrapper"
				attr={'groupLength' + interactGroups.length}
			>
				<div className="interactBottomPanelTopLayer">
					{/*this.props.isOnLiveMode ? <div className='interactElementsTitleContainer'>

            <label className='interactElementsTitle'> {t('INTERACT_QUEUE')}</label>
            <div className='openInNewWindowIcon'>
              <OpenInNewIcon />
            </div>
    </div> : <></>*/}
				</div>
				{this.props.isOnLiveMode ? (
					<>{this.renderLiveAssetsContainer(interactItems)}</>
				) : (
					<div id="interact-vis-timeline-container" className="interactTimeLine">
						<Timeline
							ref="timelineRef"
							options={options}
							items={interactItems}
							groups={interactGroups}
							customTimes={customTimes}
							clickEventHandler={this.timelineClickEventHandler}
							timechangeEventHandler={this.timelineTimechangeEventHandler}
							selectEventHandler={this.selectEventHandler}
							// mouseOverEventHandler={this.timelineMouseOverEventHandler}
							selectedItems={selectedInteractItems}
						/>
					</div>
				)}
			</div>
		);
	}
}

InteractEditorBottomPanel.propTypes = {
	mediaId: PropTypes.string.isRequired,
	isVisible: PropTypes.bool.isRequired,
};

InteractEditorBottomPanel.defaultProps = {};

const mapStateToProps = ({ session, interactTimelineReducer, inspectScreenReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	selectedLeftMenuKey: inspectScreenReducer.selectedLeftMenuKey,
	itemDroppedOnOverlay: interactElementsReducer.itemDroppedOnOverlay,
	deletionFlagForDroppedItemOnOverlay: interactElementsReducer.deletionFlagForDroppedItemOnOverlay,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
	liveQueueItem: interactTimelineReducer.liveQueueItem,
	lastSetPlayerPositionTime: interactTimelineReducer.lastSetPlayerPositionTime,
	playerPositionToSet: interactTimelineReducer.playerPositionToSet,
	interacMediaLoopStarted: interactTimelineReducer.interacMediaLoopStarted,
	trimTool: interactTimelineReducer.trimTool,
});

const mapDispatchToProps = (dispatch) => ({
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	setInteractScreenModified: () => dispatch(setInteractScreenModified()),
	setInteractScreenUnModified: () => dispatch(setInteractScreenUnModified()),
	createInteractTimelineItem: (item, setScreenUnchanged) =>
		dispatch(createInteractTimelineItem(item, setScreenUnchanged)),
	updateInteractTimelineItem: (item, setScreenUnchanged) =>
		dispatch(updateInteractTimelineItem(item, setScreenUnchanged)),
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
	updateInteractTimelineGroup: (group) => dispatch(updateInteractTimelineGroup(group)),
	createInteractTimelineGroup: (item) => dispatch(createInteractTimelineGroup(item)),
	removeInteractTimelineGroup: (group) => dispatch(removeInteractTimelineGroup(group)),
	showNotification: (message) => dispatch(showNotification(message)),
	setInteractItemsToDelete: (items) => dispatch(setInteractItemsToDelete(items)),
	showInteractDeleteDialog: (_) => dispatch(showInteractDeleteDialog()),
	dropItemOnInteractQueue: (item) => dispatch(dropItemOnInteractQueue(item)),
	stopInteractMediaLoop: (_) => dispatch(stopInteractMediaLoop()),
	showTrimTool: () => dispatch(showTrimTool()),
	disableVideoApplyButton: () => dispatch(disableVideoApplyButton()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractEditorBottomPanel);
