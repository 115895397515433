import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dialogSizes } from './../../../../../constants/dialogSizesConstant';
import IconsStore from './../../../../../reusable/IconsStore/IconsStore';
import { IconsList } from './../../../../../reusable/IconsStore/IconsList';

export const FileDeleteDialog = ({ t, state, deleteSelection, hideDeleteDialog }) => (
	<Dialog
		open={state.deleteItemDialogStatus}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		fullWidth
		maxWidth={dialogSizes.SMALL}
		className="confirmationPopup"
	>
		<DialogTitle className="dialogTitle" disableTypography>
			<Typography variant="h6">{t("DELETE_FILE")}</Typography>
			<IconButton onClick={hideDeleteDialog}>
				<Close/>
			</IconButton>
		</DialogTitle>

		<DialogContent>
			<DialogContentText>
				<div className="dialogContentWrapper">
					<label> {t('DELETE_CONFIRMATION_SINGLE')}</label>
				</div>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<div className="dialogActionsWrapper">
				<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
					<Button variant="contained" className="defaultActionBtn neutralBtn" onClick={hideDeleteDialog}>
						{t('BACK')}
					</Button>
					<Button
						variant="contained"
						className="defaultActionBtn deleteButtonWithIcon"
						onClick={() => deleteSelection()}
					>
						<IconsStore iconName={IconsList.TRASHCAN} />
						{t('DELETE')}
					</Button>
				</div>
			</div>
		</DialogActions>
	</Dialog>
);
