import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';

import { getFormattedDate } from './../../../../../services/timeStampService';
import { mediaTypes } from '../../../../../constants/mediaConstants';
import { showNotification } from './../../../../../actions/notificationActions';
import { getLiveAssetInfo, fetchPlayerEmbedConfiguration } from './../../../../../actions/publishActions';
import StreamInfo from '../../../../../reusable/StreamInfo/StreamInfo';
import info from './../../../../../assets/images/info.svg';
import Tooltip from '../../../../../reusable/Tooltip/Tooltip';
import copy_text from './../../../../../assets/images/copy_text.svg';
import { getIPonlyChannels } from './../../../../../actions/iponlyChannelActions';
import { loadChannels } from './../../../../../actions/channelActions';

import './InformationSection.scss';
import { IconButton } from '@material-ui/core';
import { FileCopyOutlined, Visibility, VisibilityOff } from '@material-ui/icons';

class InformationSection extends Component {
	state = {
		liveAssetInfo: '',
		channelArray: [],
		currentChannelName: '',
		showStreamInfoIndex: -1,
	};

	UNSAFE_componentWillMount() {
		this.getLiveAssetInfo();
		this.initialize();
		this.props.fetchPlayerEmbedConfiguration(this.props.defaultAccountId).then((data) => {
			this.setState({
				themeOptions: data,
				themeOptionsDefault: data && data.length > 0 ? data[0].id : '',
			});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.channelArray !== this.state.channelArray) {
			let channel =
				this.state.channelArray != undefined
					? this.state.channelArray.find((item) => item.id === this.props.liveMediaChannelId)
					: '';

			if (channel !== undefined) {
				this.setState({ currentChannelName: channel.metadata ? channel.metadata.title : '' });
			}
		}
	}

	getLiveAssetInfo = () => {
		const { mediaDetails, defaultAccountId } = this.props;
		if (
			mediaDetails.asset &&
			mediaDetails.asset.id &&
			(mediaDetails.type === mediaTypes.livevideo || mediaDetails.type === mediaTypes.liveaudio)
		) {
			let assetId = mediaDetails.asset.id;
			this.props.getLiveAssetInfo(defaultAccountId, assetId).then((data) => {
				if (data) {
					this.setState({
						liveAssetInfo: data,
					});
				}
			});
		}
	};

	initialize = () => {
		const { defaultAccountId, loadChannels, getIPonlyChannels } = this.props;
		loadChannels(defaultAccountId).then((channels) => {
			this.setState({ channelArray: channels });
		});
		getIPonlyChannels(defaultAccountId, 'liveorigin1');
	};

	renderLegacyLiveInfo = () => {
		const { mediaDetails, liveMediaChannelId } = this.props;
		const channel = (this.state.channelArray ?? [])?.find((c) => c?.id === liveMediaChannelId);

		if (!liveMediaChannelId || !liveMediaChannelId.includes('legacy') || !channel) {
			return null;
		}

		const ingestURL = channel?.ingestPoint?.ingestURL + channel?.ingestPoint?.authentication?.password;
		const renditions = (
			mediaDetails?.asset?.resources?.find((r) => r.type === 'livevideo')?.renditions ?? []
		).filter((r) => r?.channel?.id === liveMediaChannelId);

		return (
			renditions.length > 0 && (
				<div className="legacyLive__wrapper">
					<label className="infoHeading" style={{ marginTop: '12px' }}>
						Resolution and Stream URLs
					</label>
					<table>
						<tr>
							<th>
								<i>Resolution</i>
							</th>
							<th>
								<i>Stream URL (paste in encoder)</i>
							</th>
							<th>
								<i>Stream key (paste in encoder)</i>
							</th>
						</tr>
						{renditions.map((rendition, index) => {
							const href = rendition?.links?.[0]?.href;
							const urlParam = href && href.includes('/') ? href.split('/') : [href];
							const streamName = urlParam[urlParam.length - 1];

							return (
								<tr key={index}>
									<td style={{ textAlign: 'center' }}>
										{`${rendition?.videos?.[0]?.height ?? '0'}p`}
									</td>
									<td>
										<div className="url-container">
											<div
												className={[
													'text',
													this.state.showStreamInfoIndex === index ? 'text--show' : '',
												]
													.join(' ')
													.trim()}
											>
												{this.state.showStreamInfoIndex === index
													? ingestURL
													: '--------------------------------'}
											</div>
											<div className="actions">
												<IconButton onClick={() => this.showStreamInfoIndex(index)}>
													{this.state.showStreamInfoIndex === index ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
												<CopyToClipboard text={ingestURL}>
													<IconButton onClick={() => this.copyToClipboard()}>
														<FileCopyOutlined />{' '}
													</IconButton>
												</CopyToClipboard>
											</div>
										</div>
									</td>

									<td>
										<div className="url-container">
											<div
												className={[
													'text',
													this.state.showStreamInfoIndex === index ? 'text--show' : '',
												]
													.join(' ')
													.trim()}
											>
												{this.state.showStreamInfoIndex === index
													? streamName
													: '---------------'}
											</div>
											<div className="actions">
												<IconButton onClick={() => this.showStreamInfoIndex(index)}>
													{this.state.showStreamInfoIndex === index ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
												<CopyToClipboard text={streamName}>
													<IconButton onClick={() => this.copyToClipboard()}>
														<FileCopyOutlined />{' '}
													</IconButton>
												</CopyToClipboard>
											</div>
										</div>
									</td>
								</tr>
							);
						})}
					</table>
				</div>
			)
		);
	};

	showStreamInfoIndex = (index) => {
		this.setState((prevState) => {
			if (index === prevState.showStreamInfoIndex) {
				return { showStreamInfoIndex: -1 };
			}
			return { showStreamInfoIndex: index };
		});
	};

	copyToClipboard = () => {
		this.props.showNotification('Text is copied!');
	};

	renderStreamInfo = () => {
		const { liveAssetInfo } = this.state;
		const { t } = this.props;
		if (
			liveAssetInfo &&
			liveAssetInfo.streams &&
			liveAssetInfo.streams.length > 0 &&
			liveAssetInfo.streams[0] &&
			liveAssetInfo.streams[0].map
		) {
			return (
				<div className="streamList">
					<label className="infoHeading"> {t('BITRATE_AND_STREAM_URL')} </label>
					{liveAssetInfo.streams[0].map((stream, index) => this.renderStreamList(stream, index))}
				</div>
			);
		} else {
			return null;
		}
	};

	renderStreamList = (stream, index) => {
		const { liveAssetInfo } = this.state;
		return (
			<div className="streamBlock" key={index}>
				<label className="streamBitrate"> {stream.totalBitRate / 1000 + 'kbps' + '*:'} </label>
				<label className="streamName"> {liveAssetInfo.primaryURL + stream.streamName} </label>
				{
					<Tooltip message={this.getTooltipInfo(stream)} position={'left'} clickEvent={true}>
						<div className="infoSection">
							<img className="infoIcon" src={info} />
						</div>
					</Tooltip>
				}
			</div>
		);
	};

	getTooltipInfo = (stream) => {
		const { t } = this.props;
		return <StreamInfo t={t} stream={stream} />;
	};

	render() {
		const { t, mediaDetails, liveMediaChannelId, codec, format, resolution } = this.props;

		return (
			<>
				<div
					id="informationSection"
					className={[
						'player-preview__information-section',
						resolution || codec || format ? 'player-preview__information-section--new' : '',
					]
						.join(' ')
						.trim()}
				>
					<div className="details">
						<div className="info-section">
							<div style={{ display: 'inline-flex' }}>
								<label className="infoHeading">
									{t(mediaDetails.type === mediaTypes.catalog ? 'CATALOG_ID' : 'MEDIA_ID')}
								</label>
								{(resolution || codec || format) && (
									<CopyToClipboard
										text={mediaDetails.id}
										onCopy={() => this.props.showNotification(`${t('MEDIA_ID')} is copied!`)}
									>
										<button
											style={{ marginLeft: 5, width: '20px', height: '20px' }}
											title={t('CLICK_TO_COPY')}
											className="player-preview__btn"
										>
											<img src={copy_text} />
										</button>
									</CopyToClipboard>
								)}
							</div>
							<label>{mediaDetails.id} </label>
						</div>

						{liveMediaChannelId && (
							<div className="info-section">
								<label className="infoHeading">
									{!liveMediaChannelId.includes('legacy') ? t('CHANNEL') : ''}
								</label>
								<label>
									{!liveMediaChannelId.includes('legacy') ? this.state.currentChannelName : ''}
								</label>
							</div>
						)}

						{resolution && (
							<div className="info-section">
								<label className="infoHeading">Video dimensions</label>
								<label>{resolution}</label>
							</div>
						)}

						<div className="info-section">
							<label className="infoHeading">{t('DATE_CREATED')}</label>
							<label>{getFormattedDate(mediaDetails.created)}</label>
						</div>

						{codec && (
							<div className="info-section">
								<label className="infoHeading">Codec</label>
								<label>{codec.toUpperCase()}</label>
							</div>
						)}

						<div className="info-section">
							<label className="infoHeading">{t('DATE_UPDATED')}</label>
							<label>{getFormattedDate(mediaDetails.updated)}</label>
						</div>

						{format && (
							<div className="info-section">
								<label className="infoHeading">Audio format</label>
								<label>{format}</label>
							</div>
						)}
					</div>
					{this.renderLegacyLiveInfo()}
					{this.renderStreamInfo()}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, channel }) => ({
	defaultAccountId: session.defaultAccountId,
	channels: channel.channels,
});

const mapDispatchToProps = (dispatch) => ({
	getLiveAssetInfo: (accountId, assetId) => dispatch(getLiveAssetInfo(accountId, assetId)),
	showNotification: (message) => dispatch(showNotification(message)),
	fetchPlayerEmbedConfiguration: (accId) => dispatch(fetchPlayerEmbedConfiguration(accId)),
	getIPonlyChannels: (accountId, resourceId) => dispatch(getIPonlyChannels(accountId, resourceId)),
	loadChannels: (accountId) => dispatch(loadChannels(accountId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(InformationSection);
