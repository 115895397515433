import { makeStyles } from '@material-ui/core';

import { detailsMenuKeys } from '../../../constants/inspectScreenMenu';

export namespace InspectUtils {
	export const PLAYLIST_MENU_ITEMS = [
		{
			id: detailsMenuKeys.playlistMediaDetails,
			text: 'PLAYLIST_MEDIA_DETAILS',
		},
		{
			id: detailsMenuKeys.embedVideo,
			text: 'LABEL_EMBED',
		},
		{
			id: detailsMenuKeys.mediaDetails,
			text: 'GENERAL_DETAILS',
		},
		{
			id: detailsMenuKeys.thumbnailManager,
			text: 'LABEL_THUMBNAIL_MANAGER_TEXT',
		},
	];

	const getListCustomStyle = makeStyles((_) => ({
		parentMenuItem: {
			color: '#fff',
			fontWeight: 600,
		},
		parentMenuIcon: {
			backgroundColor: '#126cfc !important',
			color: '#fff',
			fontWeight: 600,
			width: '36px',
			height: '46px',
			minWidth: '36px',
		},
		customItem: {
			paddingLeft: '12px',
			paddingRight: '4px',
			height: '46px',
			fontSize: '1rem',
			lineHeight: 1.5,
			letterSpacing: '0.00938em',
			margin: '2px 0px',
			borderRadius: '4px',
			width: 'auto',
			backgroundColor: '#f2f5f7',
		},
		selected: {
			// backgroundColor: '#DBE9FF80 !important',
			fontWeight: 600,
			backgroundColor: '#e5e9ec  !important',
		},
		ellipsis: {
			width: 'calc(100% - 20px)',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	}));

	export const useListCustomStyle = () => getListCustomStyle();
}
