import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Close } from '@material-ui/icons';

import { generateId } from '../../services/stringHelperService';
import { getLastIndexCatalogId } from '../../services/locationPathServices';
import { createNewCatalog, refreshCatalogs, updateCatalogAccessLimit } from '../../actions/publishActions';
import { showMessage } from '../../actions/globalActions';
import { messageTypes } from '../../constants/mediaConstants';
import CatalogLimitedAccess, { ACCESS_LEVEL } from './CatalogLimitedAccess';
import {
	findParentCatalog,
	getCatalogAccessLimitHash,
	getUsersCatalogAccessLimitHasBeenChanged,
} from '../MediaLibrary/utils';

interface CreateNewCatalogDialogProps {
	open?: boolean;
	onClose?: () => void;
	onCreateNewCatalog: (_catalogId: string) => void;
	parentCatalogId?: string;
}

const CreateNewCatalogDialog: React.FC<CreateNewCatalogDialogProps> = ({
	open,
	onClose,
	onCreateNewCatalog,
	parentCatalogId,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const { catalogs } = useSelector((state) => (state as any).publish);
	const [catalogName, setCatalogName] = useState('');
	const limitedAccessRef = useRef<any>(null);
	const [isSubmit, setIsSubmit] = useState<boolean>(false);

	const handleCatalogNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCatalogName(e.target.value);
	};

	const handleCreateCatalogSuccess = (catalogId: string) => {
		setTimeout(() => {
			// delay 1s for backend have time to update
			dispatch(showMessage(t('NEW_CATALOG_CREATED'), messageTypes.success));
			onCreateNewCatalog?.(catalogId);
			limitedAccessRef.current?.resetAndCloseModal();
			setIsSubmit(false);
			setCatalogName('');
			dispatch(refreshCatalogs());
		}, 1000);
	};

	const handleCreateNewCatalog = () => {
		setIsSubmit(true);
		let parentId = parentCatalogId === 'allCatalog' ? '' : getLastIndexCatalogId() || '';
		let assetId = generateId();
		let catalogId = generateId();
		const restrictionUsersAfterUpdate = limitedAccessRef.current?.getRestrictionUsers();

		const usersNeedToBeUpdated = getUsersCatalogAccessLimitHasBeenChanged({}, restrictionUsersAfterUpdate);

		const { newUsers } = usersNeedToBeUpdated;

		const oldCatalogAccessLimitHash = getCatalogAccessLimitHash(catalogs);

		const catalogMapNeedToUpdate: { [key: string]: any } = {};

		if (Object.keys(newUsers).length > 0) {
			const catalogsToAddPermission = findParentCatalog(catalogs, parentId);

			Object.keys(newUsers).forEach((userId: any) => {
				catalogsToAddPermission.forEach((catalogId: any) => {
					const oldCatalogAccess = oldCatalogAccessLimitHash[catalogId];

					if (Object.keys(oldCatalogAccess).length === 0) {
						//in case parents catalog is 'everyone' which mean no users in oldCatalogAccess object, then no need to update
						return;
					}

					const oldAccessLevelOfUser = oldCatalogAccess[userId];
					const newCatalogAccess = oldCatalogAccess
						? {
								...oldCatalogAccess,
								[userId]: oldAccessLevelOfUser
									? oldAccessLevelOfUser
									: { id: userId, accessLevel: ACCESS_LEVEL.LIMITED.type },
						  }
						: {
								[userId]: { id: userId, accessLevel: ACCESS_LEVEL.LIMITED.type },
						  };
					const catalogAlreadyInUpdateMap = catalogMapNeedToUpdate[
						catalogId as keyof typeof catalogMapNeedToUpdate
					] as any;

					const newCatalogToUpdate = catalogAlreadyInUpdateMap
						? { ...catalogAlreadyInUpdateMap, ...newCatalogAccess }
						: newCatalogAccess;
					catalogMapNeedToUpdate[catalogId as keyof typeof catalogMapNeedToUpdate] = newCatalogToUpdate;
				});
			});
		}

		if (catalogName) {
			dispatch(
				createNewCatalog(
					defaultAccountId,
					assetId,
					catalogId,
					catalogName,
					parentId,
					restrictionUsersAfterUpdate
				)
			).then((data: any) => {
				if (data && data.length) {
					if (Object.keys(catalogMapNeedToUpdate).length === 0) {
						handleCreateCatalogSuccess(catalogId);
						return;
					}

					// only update other after catalog is created
					const promisseList = Object.entries(catalogMapNeedToUpdate).map(([catalogId, accessLevel]: any) => {
						return dispatch(updateCatalogAccessLimit(defaultAccountId, catalogId, accessLevel));
					});

					Promise.all(promisseList).then((data: any) => {
						if (data && data.length) {
							handleCreateCatalogSuccess(catalogId);
						} else {
							limitedAccessRef.current?.resetAndCloseModal();
							dispatch(showMessage('Update catalog permission failed!'), messageTypes.error);
							setIsSubmit(false);
							setCatalogName('');
						}
					});
				} else {
					limitedAccessRef.current?.resetAndCloseModal();
					dispatch(showMessage(t('NEW_CATALOG_FAILED'), messageTypes.error));
					setIsSubmit(false);
					setCatalogName('');
				}
			});
		}
	};

	return (
		<Dialog
			onClose={() => {
				limitedAccessRef.current?.resetAndCloseModal();
			}}
			aria-labelledby="simple-dialog-title"
			open={!!open}
			fullWidth
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('CREATE_NEW_CATALOG')}</Typography>
				<IconButton
					onClick={() => {
						limitedAccessRef.current?.resetAndCloseModal();
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<div className="dialogContentWrapper">
				<label> {t('TITLE_OF_CATALOG')}</label>
				<div className="fullWidthControl">
					<input
						value={catalogName}
						type="text"
						placeholder="Catalog name"
						onChange={handleCatalogNameChange}
					/>
				</div>
			</div>
			<CatalogLimitedAccess open={open} ref={limitedAccessRef} onClose={onClose} />

			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
						<Button
							variant="contained"
							className="defaultActionBtn"
							onClick={handleCreateNewCatalog}
							disabled={!catalogName || isSubmit}
						>
							{isSubmit ? (
								<>
									<span>Create Catalog</span> <CircularProgress size={10} color="inherit" />{' '}
								</>
							) : (
								<span>Create Catalog</span>
							)}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default CreateNewCatalogDialog;
