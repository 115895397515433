import { interactAssetLibraryEndpointV1, uiBaseUrl } from '../utils/config';
import BackendClient from './backendClient';

export const getAllInteractElements = () =>
	BackendClient.get(`${uiBaseUrl}widgetTemplates.json`, true);

export const getAllAccountPresets = (accId) =>
	BackendClient.get(
		`${interactAssetLibraryEndpointV1}interact/accounts/${accId}/presets`,
		true
	);

export const postInteractAsset = (accId, body) =>
	BackendClient.post(
		`${interactAssetLibraryEndpointV1}interact/accounts/${accId}/presets`,
		body
	);

export const deleteInteractAsset = (accId, assetId) =>
	BackendClient.delete(
		`${interactAssetLibraryEndpointV1}interact/accounts/${accId}/presets/${assetId}`
	);
