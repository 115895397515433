import {formatByte} from './../utils/commonUtil';

// TO Do translations should add to translation.json
export const filterTypeValues = ['Custom', 'Last 30 Days', 'Last Week', 'Today'];

export const topUrlsColumns = [
    { id: 'url', label: 'URL', minWidth: 300, 'sort': true, link: 'url'},
    { id: 'hits', label: 'Hits', minWidth: 150, 'sort': true},
    {
      id: 'size',
      label: 'Distributed volume',
      minWidth: 120,
      align: 'right',
      format: (value) => formatByte(value),
      'sort': true
    }
];

export const osColumns = [
    { id: 'name', label: 'Operating System', minWidth: 300, 'sort': true},
    {
      id: 'y',
      label: 'Volume',
      minWidth: 120,
      align: 'right',
      format: (value) => formatByte(value),
      'sort': true
    }
];

export const locationColumns = [
  { id: 'name', label: 'City', minWidth: 300, 'sort': true},
  {
    id: 'hits',
    label: 'Hits',
    minWidth: 120,
    align: 'right',
    'sort': true
  },
  {
    id: 'value',
    label: 'Volume',
    minWidth: 120,
    align: 'right',
    format: (value) => formatByte(value),
    'sort': true
  }
];

export const socialColumns = [
  { id: 'Title', label: 'Title', minWidth: 300, 'sort': true},
  {
    id: 'Views',
    label: 'Views',
    minWidth: 120,
    align: 'right',
    'sort': true
  },
  {
    id: 'WatchTimeMinutes',
    label: 'Watch Time (min)',
    minWidth: 120,
    align: 'right',
    'sort': true
  },
  {
    id: 'Shares',
    label: 'Shares',
    minWidth: 120,
    align: 'right',
    'sort': true
  },
  {
    id: 'Likes',
    label: 'Likes',
    minWidth: 120,
    align: 'right',
    'sort': true
  },
  {
    id: 'Comments',
    label: 'Comments',
    minWidth: 120,
    align: 'right',
    'sort': true
  }
];