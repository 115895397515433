import { useDispatch, useSelector } from 'react-redux';
import fixWebmDuration from 'fix-webm-duration';

import { preventUserFromCloseWindow, uploadMediaBegins } from '../../../actions/publishActions';
import { FLOWS, UPLOAD_FAILED, messageTypes } from '../../../constants/mediaConstants';
import { showAlert, showMessage } from '../../../actions/globalActions';
import { generateId } from '../../../services/stringHelperService';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { BOTH_MODE, CAMERA_MODE, SCREEN_MODE } from '../../../components/ScreenRecoder/ScreenRecorderConfigModal';

interface UseScreenRecorderProps {
	mode: string;
	isPreviewing: boolean;
	previewVideoRef: any;
	webcamPreviewVideoRef: any;
	previousModeRef: any;
	onUserHitNativeBrowserStopSharingButton: () => void;
	handleModeChange: (_mode: string) => void;
}

const INACTIVE = 'INACTIVE';
const RECORDING = 'RECORDING';
const PAUSED = 'PAUSED';

const AUDIO = 'audioinput';
const VIDEO = 'videoinput';

export const USER_DEVICES_INPUT = {
	AUDIO,
	VIDEO,
};

export const RECORDING_STATUS = {
	INACTIVE,
	RECORDING,
	PAUSED,
};

const useScreenRecorder = ({
	mode,
	isPreviewing,
	previewVideoRef,
	webcamPreviewVideoRef,
	previousModeRef,
	onUserHitNativeBrowserStopSharingButton,
	handleModeChange,
}: UseScreenRecorderProps) => {
	const dispatch = useDispatch<any>();
	const accountId = useSelector((state) => (state as any).session.defaultAccountId);
	const { t: translator } = useTranslation();

	const [previewStream, setPreviewStream] = useState<MediaStream | null>();
	const [mediaRecorder, setMediaRecorder] = useState<any>();
	const [screenShareStreams, setScreenShareStream] = useState<any>({});
	const [recordingStatus, setRecordingStatus] = useState<string>(INACTIVE);
	const [duration, setRecordingDuration] = useState<number>(0);
	const [mediaChunks, setMediaChunks] = useState<any>([]);
	const [availableInputs, setAvailableInputs] = useState({ videoIds: {}, audioIds: {} });
	const [selectedInput, setSelectedInput] = useState({ videoId: '', audioId: '' });
	const [startUploadProcess, setStartUploadProcess] = useState<boolean>(false);
	const countDownIntervalRef = useRef<any>();
	const previousAudioIdRef = useRef<string>('');
	const previousWebcamIdRef = useRef<string>('');

	const afterJobRef = useRef<any>();
	const [canvasStream, setCanvasStream] = useState<MediaStream | null>();

	const canvasElementRef = useRef<any>(document.createElement('canvas'));
	const canvasContextRef = useRef<any>(canvasElementRef.current.getContext('2d', { willReadFrequently: true }));
	const requestFramRef = useRef<any>();

	const debounceTimer = useRef<any>();

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	const initCameraMode = async () => {
		try {
			const userStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					// echoCancellation: false,
					noiseSuppression: true,
					sampleRate: 44100,
					deviceId: selectedInput.audioId,
				},
				video: { width: { ideal: 4096 }, height: { ideal: 2160 }, deviceId: selectedInput.videoId },
			});

			const mediaRecorder = getMediaRecorder(userStream);

			if (userStream) {
				setPreviewStream(userStream);
			}

			if (mediaRecorder) {
				setMediaRecorder(mediaRecorder);
			}
		} catch (e: any) {
			console.error(e);
			dispatch(
				showMessage(
					`We weren't able to access your camera. Make sure that your browser has the correct permission settings and that you grant permissions when selecting a window.`
				)
			);

			resetRecoder();
			handleModeChange(CAMERA_MODE);
		}
	};

	const initScreenShareMode = async (isNeedToReplaceScreenStream?: boolean) => {
		try {
			const { screenStream: initStream } = screenShareStreams;
			let screenStream = initStream;

			if (!screenStream || isNeedToReplaceScreenStream) {
				screenStream = await navigator.mediaDevices.getDisplayMedia({
					video: {
						width: { ideal: 4096 },
						height: { ideal: 2160 },
					},
					audio: true,
					// @ts-ignore: Unreachable code error
					// this bellow property should be available,. Somehow ts keep complain
					selfBrowserSurface: 'include',
				});
			}
			// screen stream contain screen & audio output., audioStream contain microphone input
			// need to use audioContext to merge these two stream
			const audioContext = new AudioContext();

			const audioStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					// echoCancellation: false,
					noiseSuppression: true,
					sampleRate: 44100,
					deviceId: selectedInput.audioId,
				},
			});

			const audioSource = audioContext.createMediaStreamSource(audioStream);
			let displaySource;
			try {
				// incase user not allow to capture sound. Then just ignore
				displaySource = audioContext.createMediaStreamSource(screenStream);
			} catch (e) {
				console.error(e);
			}

			const dest = audioContext.createMediaStreamDestination();
			audioSource.connect(dest);
			displaySource && displaySource.connect(dest);

			const mixedStream = new MediaStream();
			mixedStream.addTrack(screenStream.getVideoTracks()[0]);
			mixedStream.addTrack(dest.stream.getAudioTracks()[0]);

			const mediaRecorder = getMediaRecorder(mixedStream);

			setScreenShareStream({ screenStream, audioStream });

			screenStream.getTracks().forEach((track: any) =>
				track.addEventListener('ended', () => {
					audioStream.getTracks().forEach((track: any) => track.stop());
					if (mediaRecorder) {
						onUserHitNativeBrowserStopSharingButton?.();
						mediaRecorder.stop();
						setStartUploadProcess(true);
					}
				})
			);

			if (mixedStream) {
				setPreviewStream(mixedStream);
			}

			if (mediaRecorder) {
				setMediaRecorder(mediaRecorder);
			}
		} catch (e: any) {
			if (e.message === 'Permission denied') {
				dispatch(
					showMessage(
						`We weren't able to access your screen. Make sure that your browser has the correct permission settings and that you grant permissions when selecting a window.`,
						messageTypes.warning
					)
				);
			}

			resetRecoder();
			handleModeChange(CAMERA_MODE);
		}
	};

	const initBothMode = async (isNeedToReplaceScreenStream?: boolean) => {
		try {
			const { screenStream: initStream } = screenShareStreams;
			let screenStream = initStream;

			if (!screenStream || isNeedToReplaceScreenStream) {
				screenStream = await navigator.mediaDevices.getDisplayMedia({
					video: {
						width: { ideal: 1920, max: 1920 },
						height: { ideal: 1080, max: 1080 },
					},
					audio: true,
					// @ts-ignore: Unreachable code error
					// this bellow property should be available,. Somehow ts keep complain
					selfBrowserSurface: 'include',
				});
			}

			const webcamStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					// echoCancellation: false,
					noiseSuppression: true,
					sampleRate: 44100,
					deviceId: selectedInput.audioId,
				},
				video: { width: { ideal: 4096 }, height: { ideal: 2160 }, deviceId: selectedInput.videoId },
			});

			setScreenShareStream({ screenStream, webcamStream });

			screenStream.getTracks().forEach((track: any) =>
				track.addEventListener('ended', () => {
					webcamStream.getTracks().forEach((track: any) => track.stop());
					if (mediaRecorder) {
						onUserHitNativeBrowserStopSharingButton?.();
						mediaRecorder.stop();
						setStartUploadProcess(true);
					}
				})
			);

			if (screenStream) {
				setPreviewStream(screenStream);
			}

			await drawing();
			let videoStream = canvasElementRef.current.captureStream(60);

			const audioContext = new AudioContext();
			const audioSource = audioContext.createMediaStreamSource(webcamStream);
			let audioStreamSource;
			try {
				// incase user not allow to capture sound. Then just ignore
				audioStreamSource = audioContext.createMediaStreamSource(screenStream);
			} catch (e) {
				console.error(e);
			}

			const dest = audioContext.createMediaStreamDestination();
			audioSource.connect(dest);
			audioStreamSource && audioStreamSource.connect(dest);

			const mixedStream = new MediaStream();
			mixedStream.addTrack(videoStream.getVideoTracks()[0]);
			mixedStream.addTrack(dest.stream.getAudioTracks()[0]);

			const mediaRecorder = getMediaRecorder(mixedStream);

			if (mixedStream) {
				setCanvasStream(mixedStream);
			}

			if (mediaRecorder) {
				setMediaRecorder(mediaRecorder);
			}
		} catch (e: any) {
			if (e.message === 'Permission denied') {
				dispatch(
					showMessage(
						`We weren't able to access your screen. Make sure that your browser has the correct permission settings and that you grant permissions when selecting a window.`,
						messageTypes.warning
					)
				);
			}

			resetRecoder();
			handleModeChange(CAMERA_MODE);
		}
	};

	const drawing = async () => {
		if (!previewVideoRef.current || !webcamPreviewVideoRef.current) {
			return;
		}

		const width = previewVideoRef.current.videoWidth || window.innerWidth;
		const height = previewVideoRef.current.videoHeight || window.innerHeight;
		const webcamWidth = webcamPreviewVideoRef.current.videoWidth || 1920;
		const webcamHeight = webcamPreviewVideoRef.current.videoHeight || 1080;

		canvasContextRef.current.save();
		canvasElementRef.current.setAttribute('width', `${width}px`);
		canvasElementRef.current.setAttribute('height', `${height}px`);
		canvasContextRef.current.clearRect(0, 0, width, height);
		canvasContextRef.current.drawImage(previewVideoRef.current, 0, 0, width, height);

		const cameraPadding = 80;
		const x = cameraPadding;
		const y = height - cameraPadding - height / 3;
		const length = height / 3;
		const radius = 70;

		canvasContextRef.current.beginPath();
		canvasContextRef.current.moveTo(x + radius, y);
		canvasContextRef.current.lineTo(x + length - radius, y);
		canvasContextRef.current.quadraticCurveTo(x + length, y, x + length, y + radius);
		canvasContextRef.current.lineTo(x + length, y + length - radius);
		canvasContextRef.current.quadraticCurveTo(x + length, y + length, x + length - radius, y + length);
		canvasContextRef.current.lineTo(x + radius, y + length);
		canvasContextRef.current.quadraticCurveTo(x, y + length, x, y + length - radius);
		canvasContextRef.current.lineTo(x, y + radius);
		canvasContextRef.current.quadraticCurveTo(x, y, x + radius, y);
		canvasContextRef.current.closePath();
		canvasContextRef.current.clip();

		canvasContextRef.current.drawImage(
			webcamPreviewVideoRef.current,
			webcamWidth / 2 - webcamHeight / 2,
			0,
			webcamHeight,
			webcamHeight,
			cameraPadding,
			height - cameraPadding - height / 3,
			Math.floor(height / 3),
			Math.floor(height / 3)
		);
		canvasContextRef.current.restore();
		requestFramRef.current = requestVideoFrame(drawing);
	};

	const requestVideoFrame = function (callback: any) {
		return window.setTimeout(function () {
			callback(Date.now());
		}, 1000 / 60); // 60 fps - just like requestAnimationFrame
	};

	const getMediaRecorder = (inputStream: any) => {
		const mediaRecorder = new MediaRecorder(inputStream, { mimeType: isSafari ? 'video/mp4' : 'video/webm' });
		return mediaRecorder;
	};

	const addCollectedDataToMediaChunk = (e: any) => {
		mediaChunks.push(e.data);
		setMediaChunks([...mediaChunks]);
	};

	const processUploadingMedia = (blobChunks: any) => {
		return new Promise((resolve, reject) => {
			// only trigger when start event has been called

			if (!blobChunks[0]) {
				reject(null);
				setStartUploadProcess(false);
				return;
			}

			// prevent safari called event multiple time for unknown reason
			debouncedUploading(() => {
				let blob = new Blob(blobChunks, {
					type: blobChunks[0].type,
				});

				fixWebmDuration(blob, duration * 1000).then((fixedBlob) => {
					const fileName = `Recording - ${new Date().toDateString()}`;
					const extension = isSafari ? 'mp4' : 'webm';
					const mediaId = generateId();
					const file = new File([fixedBlob], `${mediaId}.${extension}`, {
						type: isSafari ? 'video/mp4' : 'video/webm',
					});

					(file as any).title = `${fileName}.${extension}`;
					(file as any).filename = `${fileName}.${extension}`;

					dispatch(showMessage(translator('LABEL_YOUR_MEDIA_BEING_UPLOADED'), messageTypes.info));
					resolve({ id: mediaId, duration, type: 'addNew' });
					dispatch(preventUserFromCloseWindow(true));
					dispatch(
						uploadMediaBegins(accountId, FLOWS.qbrickStandard, '', null, file, '', `${fileName}.webm`)
					).then((data: any) => {
						if (data && data !== UPLOAD_FAILED) {
							dispatch(showMessage(translator('LABEL_YOUR_MEDIA_BEING_ENCODED'), messageTypes.info));
						} else {
							reject();
							dispatch(showAlert(UPLOAD_FAILED, messageTypes.error));
						}
						dispatch(preventUserFromCloseWindow(false));
					});

					setStartUploadProcess(false);
					resetRecoder();
				});
			}, 500);
		});
	};

	const startDurationTimer = () => {
		countDownIntervalRef.current = setInterval(() => {
			setRecordingDuration((oldDuration) => oldDuration + 1);
		}, 1000);
	};

	const stopDurationTimer = () => {
		clearInterval(countDownIntervalRef.current);
	};

	const startRecording = () => {
		if (!previewStream) {
			return;
		}

		setRecordingStatus(RECORDING);
		mediaRecorder.start(200);
		startDurationTimer();
	};

	const pauseRecording = () => {
		mediaRecorder.pause();
		setRecordingStatus(PAUSED);
		stopDurationTimer();
	};

	const resumeRecording = () => {
		mediaRecorder.resume();
		setRecordingStatus(RECORDING);
		startDurationTimer();
	};

	const stopRecording = ({
		isKeepScreenStream,
		initUploadProcess,
	}: {
		isKeepScreenStream?: boolean;
		initUploadProcess?: boolean;
	}) => {
		setRecordingStatus(INACTIVE);
		stopDurationTimer();
		previewStream &&
			previewStream.getTracks().forEach((track) => {
				if (!isKeepScreenStream || track.kind !== 'video') {
					track.stop();
				}
			});

		mediaRecorder && mediaRecorder.stop();

		const { screenStream, audioStream, webcamStream } = screenShareStreams;

		if (!isKeepScreenStream && screenStream) {
			screenStream.getTracks().forEach((track: any) => track.stop());
		}

		if (audioStream) {
			audioStream.getTracks().forEach((track: any) => track.stop());
		}

		if (webcamStream) {
			webcamStream.getTracks().forEach((track: any) => track.stop());
		}

		setScreenShareStream(isKeepScreenStream ? { screenStream } : {});

		if (initUploadProcess) {
			setStartUploadProcess(true);
		}
	};

	const switchScreen = () => {
		const { screenStream, audioStream, webcamStream } = screenShareStreams;

		previewStream &&
			previewStream.getTracks().forEach((track) => {
				track.stop();
			});

		if (screenStream) {
			screenStream.getTracks().forEach((track: any) => track.stop());
		}

		if (audioStream) {
			audioStream.getTracks().forEach((track: any) => track.stop());
		}

		if (webcamStream) {
			webcamStream.getTracks().forEach((track: any) => track.stop());
		}

		if (mode === SCREEN_MODE) {
			initScreenShareMode(true);
		} else if (mode === BOTH_MODE) {
			initBothMode(true);
		}
	};

	const restartRecording = () => {
		stopDurationTimer();
		mediaRecorder && mediaRecorder.stop();
		setStartUploadProcess(false);
		setRecordingDuration(0);
		setMediaChunks([]);
		setRecordingStatus(RECORDING);
		startDurationTimer();
		let newMediaRecoder;

		if (mode === SCREEN_MODE || mode === CAMERA_MODE) {
			newMediaRecoder = getMediaRecorder(previewStream);
		} else {
			newMediaRecoder = getMediaRecorder(canvasStream);
		}

		newMediaRecoder.start(200);
		setMediaRecorder(newMediaRecoder);
	};

	const updateUserInput = (type: any, value: any) => {
		if (type === USER_DEVICES_INPUT.AUDIO) {
			previousAudioIdRef.current = selectedInput.audioId;
		}

		if (type === USER_DEVICES_INPUT.VIDEO) {
			previousWebcamIdRef.current = selectedInput.videoId;
		}

		const idType = type === USER_DEVICES_INPUT.AUDIO ? 'audioId' : 'videoId';
		setSelectedInput({ ...selectedInput, [idType]: value });
	};

	const resetRecoder = () => {
		// called after error, or finised record
		setPreviewStream(null);
		setMediaRecorder(null);
		setScreenShareStream({});
		setRecordingDuration(0);
		setMediaChunks([]);
		setCanvasStream(null);
		clearTimeout(requestFramRef.current);
	};

	const debouncedUploading = (func: any, timeout = 300) => {
		clearTimeout(debounceTimer.current);
		debounceTimer.current = setTimeout(func, timeout);
	};

	useEffect(() => {
		if (!isPreviewing || recordingStatus === RECORDING || recordingStatus === PAUSED) {
			return;
		}

		const isAudioInputUpdated = previousAudioIdRef.current !== selectedInput.audioId;
		const isVideoInputUpdated = previousWebcamIdRef.current !== selectedInput.videoId;
		const isSwitchBetweenScreenAdnBothMode = !`${previousModeRef.current}${mode}`.includes(CAMERA_MODE);

		const isKeepScreenStream = isAudioInputUpdated || isVideoInputUpdated || isSwitchBetweenScreenAdnBothMode;

		stopRecording({
			isKeepScreenStream,
		});
		switch (mode) {
			case CAMERA_MODE:
				initCameraMode();
				break;
			case SCREEN_MODE:
				initScreenShareMode();
				break;
			case BOTH_MODE:
				initBothMode();
				break;
		}
	}, [mode, isPreviewing, recordingStatus, selectedInput]);

	useEffect(() => {
		if (Object.keys(availableInputs.audioIds).length !== 0 || Object.keys(availableInputs.videoIds).length !== 0) {
			return;
		}

		navigator.mediaDevices.enumerateDevices().then((devices: any) => {
			const availableDevices = devices.reduce(
				(acc: any, { kind, groupId, label, deviceId }: any) => {
					if (!groupId || deviceId === 'default') {
						return acc;
					}

					if (kind !== USER_DEVICES_INPUT.AUDIO && kind !== USER_DEVICES_INPUT.VIDEO) {
						return acc;
					}

					if (kind === USER_DEVICES_INPUT.AUDIO) {
						acc.audioIds = { ...acc.audioIds, [groupId]: { label, deviceId } };
						return acc;
					}

					if (kind === USER_DEVICES_INPUT.VIDEO) {
						acc.videoIds = { ...acc.videoIds, [groupId]: { label, deviceId } };
						return acc;
					}
				},
				{ videoIds: {}, audioIds: {} }
			);

			setAvailableInputs(availableDevices);
		});
	}, [previewStream]);

	useEffect(() => {
		if (startUploadProcess) {
			processUploadingMedia(mediaChunks).then((media) => {
				media && afterJobRef?.current?.(media);
			});
		}
	}, [startUploadProcess]);

	useEffect(() => {
		if (!mediaRecorder) {
			return;
		}

		mediaRecorder.addEventListener('dataavailable', addCollectedDataToMediaChunk);
		return () => {
			mediaRecorder.removeEventListener('dataavailable', addCollectedDataToMediaChunk);
		};
	}, [mediaRecorder]);

	return {
		recordingStatus,
		previewStream,
		duration,
		selectedInput,
		availableInputs,
		screenShareStreams,
		afterJobRef,
		switchScreen,
		startRecording,
		pauseRecording,
		resumeRecording,
		stopRecording,
		restartRecording,
		updateUserInput,
		setScreenShareStream,
		resetRecoder,
	};
};
export default useScreenRecorder;
