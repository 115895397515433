import {
    getAllInteractElements,
    getAllAccountPresets,
    postInteractAsset,
    deleteInteractAsset
} from "../services/interactService";

import { createResource, deleteAudio, _uploadFileUsingSmilWorkflow } from "../services/mediaService";
import { LocalStorageService } from "../services/localStorageService";

export const getAccountId = getState => {
    if (getState) {
        return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
    } else {
        return LocalStorageService.getDefaultAccountId();
    }
};

export const SEND_INTERACT_ELEMENTS_REQUEST = "SEND_INTERACT_ELEMENTS_REQUEST";
export const POST_INTERACT_ASSET_REQUEST = "POST_INTERACT_ASSET_REQUEST";
export const POST_INTERACT_ASSET_REQUEST_SUCCESS = "POST_INTERACT_ASSET_REQUEST_SUCCESS";
export const POST_INTERACT_ASSET_REQUEST_FAILURE = "POST_INTERACT_ASSET_REQUEST_FAILURE";
export const DELETE_INTERACT_ASSET_REQUEST = "DELETE_INTERACT_ASSET_REQUEST";
export const DELETE_INTERACT_ASSET_REQUEST_SUCCESS = "DELETE_INTERACT_ASSET_REQUEST_SUCCESS";
export const DELETE_INTERACT_ASSET_REQUEST_FAILURE = "DELETE_INTERACT_ASSET_REQUEST_FAILURE";
export const SET_COMPONENT_STATIC_TRUE = "SET_COMPONENT_STATIC_TRUE";
export const SET_COMPONENT_STATIC_FALSE = "SET_COMPONENT_STATIC_FALSE";
export const ITEM_DROPPED_ON_OVERLAY = "ITEM_DROPPED_ON_OVERLAY";
export const ITEM_DROPPED_ON_INTERACT_QUEUE = "ITEM_DROPPED_ON_INTERACT_QUEUE";
export const SET_IS_ELEMENT_UPDATING = "SET_IS_ELEMENT_UPDATING";
export const SET_DELETION_FLAG_FOR_DROPPED_ITEM_ON_OVERLAY = "SET_DELETION_FLAG_FOR_DROPPED_ITEM_ON_OVERLAY";
export const ITEM_DROPPED_ON_OVERLAY_CLEARED = "ITEM_DROPPED_ON_OVERLAY_CLEARED";
export const SEND_INTERACT_ELEMENTS_REQUEST_SUCCESS = "SEND_INTERACT_ELEMENTS_REQUEST_SUCCESS";
export const SEND_INTERACT_ELEMENTS_REQUEST_FAILURE = "SEND_INTERACT_ELEMENTS_REQUEST_FAILURE";
export const SEND_OPEN_INTERACT_PREVIEW = "SEND_OPEN_INTERACT_PREVIEW";
export const SEND_CLOSE_INTERACT_PREVIEW = "SEND_CLOSE_INTERACT_PREVIEW";
export const CLEAR_INTERACT_ELEMENTS_STORE = "CLEAR_INTERACT_ELEMENTS_STORE";
export const SHOW_INTERACT_ITEM_DELETE = "SHOW_INTERACT_ITEM_DELETE";
export const HIDE_INTERACT_ITEM_DELETE = "HIDE_INTERACT_ITEM_DELETE";
export const SET_INTERACT_ITEMS_TO_DELETE = "SET_INTERACT_ITEMS_TO_DELETE";
export const CLEAR_INTERACT_ITEMS_TO_DELETE = "CLEAR_INTERACT_ITEMS_TO_DELETE";
export const SET_INTERACT_ELEMENTS_WIDGET = "SET_INTERACT_ELEMENTS_WIDGET";
export const SHOW_SELECTED_SUB_PROPERTY_KEY = "SHOW_SELECTED_SUB_PROPERTY_KEY";
export const HIDE_SELECTED_SUB_PROPERTY_KEY = "HIDE_SELECTED_SUB_PROPERTY_KEY";
export const SEND_PRESETS_REQUEST = "SEND_PRESETS_REQUEST";
export const SEND_PRESETS_REQUEST_SUCCESS = "SEND_PRESETS_REQUEST_SUCCESS";
export const SEND_PRESETS_REQUEST_FAILURE = "SEND_PRESETS_REQUEST_FAILURE";
export const SET_ELEMENT_PROPERTIES_ERROR = "SET_ELEMENT_PROPERTIES_ERROR";
export const RESET_ELEMENT_PROPERTIES_ERROR = "RESET_ELEMENT_PROPERTIES_ERROR";
export const UPDATE_SELECTED_ELEMENT_PROPERTIES = "UPDATE_SELECTED_ELEMENT_PROPERTIES";

export const SEND_TILE_WINDOW_CHANGE = "SEND_TILE_WINDOW_CHANGE";

export const sendTileWindowChange = () => ({
    type: SEND_TILE_WINDOW_CHANGE,
});

export const sendPresetsRequest = () => ({
    type: SEND_PRESETS_REQUEST,
});

export const postInteractAssetRequest = () => ({
    type: POST_INTERACT_ASSET_REQUEST
});

export const postInteractAssetRequestSuccess = () => ({
    type: POST_INTERACT_ASSET_REQUEST_SUCCESS
});

export const postInteractAssetRequestFailure = () => ({
    type: POST_INTERACT_ASSET_REQUEST_FAILURE
});

export const deleteInteractAssetRequest = () => ({
    type: DELETE_INTERACT_ASSET_REQUEST
});

export const deleteInteractAssetRequestSuccess = () => ({
    type: DELETE_INTERACT_ASSET_REQUEST_SUCCESS
});

export const deleteInteractAssetRequestFailure = () => ({
    type: DELETE_INTERACT_ASSET_REQUEST_FAILURE
});

export const sendPresetsRequestSuccess = () => ({
    type: SEND_PRESETS_REQUEST_SUCCESS,
});

export const sendPresetsRequestFailure = () => ({
    type: SEND_PRESETS_REQUEST_FAILURE
});

export const showSelectedSubPropertyKey = (key) => ({
    type: SHOW_SELECTED_SUB_PROPERTY_KEY,
    key
});

export const hideSelectedSubPropertyKey = () => ({
    type: HIDE_SELECTED_SUB_PROPERTY_KEY
});

export const clearInteractElementsStore = () => ({
    type: CLEAR_INTERACT_ELEMENTS_STORE
});

export const showInteractDeleteDialog = (msg) => ({
    type: SHOW_INTERACT_ITEM_DELETE,
    msg
});

export const hideInteractDeleteDialog = () => ({
    type: HIDE_INTERACT_ITEM_DELETE
});

export const setInteractItemsToDelete = (items) => ({
    type: SET_INTERACT_ITEMS_TO_DELETE,
    items
});

export const setIsElementUpdating = (isElementUpdating) => ({
    type: SET_IS_ELEMENT_UPDATING,
    isElementUpdating
});

export const clearInteractItemsToDelete = () => ({
    type: CLEAR_INTERACT_ITEMS_TO_DELETE
});

export const sendOpenInteractPreview = () => ({
    type: SEND_OPEN_INTERACT_PREVIEW
});

export const sendCloseInteractPreview = () => ({
    type: SEND_CLOSE_INTERACT_PREVIEW
});

export const sendInteractElementsRequest = () => ({
    type: SEND_INTERACT_ELEMENTS_REQUEST
});

export const setComponentStaticTrue = () => ({
    type: SET_COMPONENT_STATIC_TRUE
});

export const setComponentStaticFalse = () => ({
    type: SET_COMPONENT_STATIC_FALSE
});

export const sendInteractElementsSuccess = () => ({
    type: SEND_INTERACT_ELEMENTS_REQUEST_SUCCESS
});

export const sendInteractElementsFailure = () => ({
    type: SEND_INTERACT_ELEMENTS_REQUEST_FAILURE
});

export const dropItemOnOverlay = (item) => ({
    type: ITEM_DROPPED_ON_OVERLAY,
    item
});

export const dropItemOnInteractQueue = (item) => ({
    type: ITEM_DROPPED_ON_INTERACT_QUEUE,
    item
});

export const setDeletionFlagDroppedItemOnOverlay = (flag) => ({
    type: SET_DELETION_FLAG_FOR_DROPPED_ITEM_ON_OVERLAY,
    flag
});

export const clearDropItemOnOverlay = () => ({
    type: ITEM_DROPPED_ON_OVERLAY_CLEARED
});

export const setInteractElementsWidget = (data) => ({
    type: SET_INTERACT_ELEMENTS_WIDGET,
    data
});

export const setElementPropertiesError = () => ({
    type: SET_ELEMENT_PROPERTIES_ERROR
});

export const resetElementPropertiesError = () => ({
    type: RESET_ELEMENT_PROPERTIES_ERROR
});

export const updateSelectedElementProperties = () => ({
    type: UPDATE_SELECTED_ELEMENT_PROPERTIES
});


export const getInteractElements = accountId => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    dispatch(sendInteractElementsRequest());
    return getAllInteractElements(defaultAccountId)
        .then(data => {
            dispatch(sendInteractElementsSuccess());
            if (data && data.map) {
                dispatch(setInteractElementsWidget(JSON.stringify(data)));
            }
            return data;
        })
        .catch(error => {
            dispatch(sendInteractElementsFailure());
            dispatch(setInteractElementsWidget(''));
            return error;
        });
};

export const getAccountPresets = accountId => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    dispatch(sendPresetsRequest());
    return getAllAccountPresets(defaultAccountId)
        .then(data => {
            dispatch(sendPresetsRequestSuccess());
            return data;
        })
        .catch(error => {
            dispatch(sendPresetsRequestFailure());
            return error;
        });
};


export const addElementToAssetLibrary = (accountId, body) => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    dispatch(postInteractAssetRequest());
    return postInteractAsset(defaultAccountId, body)
        .then(data => {
            dispatch(postInteractAssetRequestSuccess());
            return data;
        })
        .catch(error => {
            dispatch(postInteractAssetRequestFailure());
            return error;
        });
};

export const deleteElemenentFromAssetLibrary = (accountId, assetId) => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    dispatch(deleteInteractAssetRequest());
    return deleteInteractAsset(defaultAccountId, assetId)
        .then(data => {
            dispatch(deleteInteractAssetRequestSuccess());
            return data;
        })
        .catch(error => {
            dispatch(deleteInteractAssetRequestFailure());
            return error;
        });
};

export const createNewResource = (accountId, assetId, resource) => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    return createResource(defaultAccountId, assetId, resource).then(data => {
        return data;
    });
};

export const deleteExistingResource = (accountId, assetId, resourceId) => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    return deleteAudio(defaultAccountId, assetId, resourceId).then(data => {
        return data;
    });
};

export const uploadFileUsingSmilWorkflow = (accountId, assetId, data) => (dispatch, getState) => {
    const defaultAccountId = accountId || getAccountId(getState);
    return _uploadFileUsingSmilWorkflow(defaultAccountId, assetId, data).then(data => {
        return data;
    });
};







