import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as H from 'history';

import { joinClassNames } from '../../services/elementHelperService';
import { AdobeExpressUtils } from '../PublishScreen/AdobeExpress/utils';
import ScenarioCreationDialog from '../Scenario/utils/ScenarioCreationDialog';
import CreateLiveEventDialog from '../PublishScreen/CreateLiveEventDialog';
import { FLOWS, customScreenType, mediaActionKeys, mediaNames, messageTypes } from '../../constants/mediaConstants';
import { _getChannels } from '../../services/channelService';
import { screenRoutes } from '../../constants/routesPath';
import { showMessage } from '../../actions/globalActions';
import { getMediaQueryDetails, setPermissionInformationDialog } from '../../actions/publishActions';
import useAEEditor from '../../hooks/useAEEditor';
import { setShowUploadMediasDialog } from '../../actions/uploadingActions';
import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import { setScreenRecorderModalStatus, updateAdobeExpressEditorModal } from '../../actions/sessionActions';

import RecentMedias from './RecentMedias';
import IntroducingSlider from './IntroducingSlider';
import { DashboardUtils } from './DashboardUtils';
import './Dashboard.scss';

const { RecentMediaTypes, LINKS, Footer, ShortcutButton, DASHBOARD_BASE_CLASSNAME } = DashboardUtils;

interface DashboardProps {
	history: H.History;
}

const Dashboard = ({ history }: DashboardProps) => {
	const dispatch = useDispatch() as any;

	const { defaultAccountId, userPermissions } = useSelector((state) => (state as any).session);
	const { ccEverywhere, screenType, qbrickStandardFlows } = useSelector((state) => (state as any).publish);

	const [showActionDialog, setShowActionDialog] = useState<string>('');
	const [liveChannels, setLiveChannels] = useState<Array<object> | null>(null);

	const isLargeScreen = useMemo(
		() => screenType === customScreenType.medium || screenType === customScreenType.large,
		[screenType]
	);

	const isPegacoderEnable = useMemo(() => {
		return qbrickStandardFlows.find((f: any) => f.id === FLOWS.qbrickStandard);
	}, [qbrickStandardFlows]);

	const permissions = useMemo(() => {
		return {
			[RecentMediaTypes.interact]: checkPermissionGrantedFor(userPermissions, 'scenarios'),
			[RecentMediaTypes.edits]: checkPermissionGrantedFor(userPermissions, 'adobeExpress'),
			[RecentMediaTypes.record]: !!isPegacoderEnable,
			[RecentMediaTypes.live]:
				checkPermissionGrantedFor(userPermissions, 'channels') ||
				checkPermissionGrantedFor(userPermissions, 'legacyLive'),
			[RecentMediaTypes.video]: true,
			[RecentMediaTypes.speechToText]: checkPermissionGrantedFor(userPermissions, 'speech2Text'),
		};
	}, [userPermissions, isPegacoderEnable]);

	const openMediaDetailPage = useCallback(
		(mediaId: string, mediaName: string) => {
			setTimeout(() => {
				if (!mediaId) {
					return;
				}

				let redirectRoute = `${screenRoutes.PUBLISH}/medias/${mediaId}/detail`;
				if (mediaName === mediaNames.scenarios) {
					redirectRoute = `${screenRoutes.PUBLISH_SCENARIOS}/${mediaId}/detail`;
					mediaName = mediaNames.scenarios;
				}

				dispatch(getMediaQueryDetails(defaultAccountId, mediaId, mediaName)).then((data: any) => {
					if (!data || (data && data.message)) {
						dispatch(showMessage('Go to Media Library to view created content', messageTypes.info));
						return;
					}
					redirectRoute && history.push(redirectRoute);
				});
			}, 2000);
		},
		[defaultAccountId, dispatch, history]
	);

	const onCreateScenario = useCallback(
		(scenarioId: string) => {
			setShowActionDialog('');
			openMediaDetailPage(scenarioId, mediaNames.scenarios);
		},
		[openMediaDetailPage]
	);

	const createLiveMedia = useCallback(
		(mediaId: string) => {
			setShowActionDialog('');
			openMediaDetailPage(mediaId, mediaNames.medias);
		},
		[openMediaDetailPage]
	);

	const { uploadToPlatformCallback } = useAEEditor({
		afterJobFunc: () => {
			dispatch(updateAdobeExpressEditorModal(false));
		},
	});

	const createAdobeExpressDesign = useCallback(
		(dataUrl?: string, mediaName?: string) => {
			dispatch(updateAdobeExpressEditorModal(true));
			AdobeExpressUtils.createNewProject({
				ccEverywhere,
				actionCallback: uploadToPlatformCallback,
				dataUrl,
				mediaName,
			});
		},
		[ccEverywhere, dispatch, uploadToPlatformCallback]
	);

	const onMediaActionMenuClick = useCallback(
		(mediaDetail: any, action: string) => {
			if (action === mediaActionKeys.openAEProject) {
				const projectId = AdobeExpressUtils.getAdobeExpressProjectIdFromMediaId(mediaDetail.id);
				projectId &&
					AdobeExpressUtils.editDesign({
						ccEverywhere,
						projectId,
						actionCallback: uploadToPlatformCallback,
						mediaName: mediaDetail.metadata?.title,
					});
				dispatch(updateAdobeExpressEditorModal(true));
			}
		},
		[ccEverywhere, dispatch, uploadToPlatformCallback]
	);

	const handleOnShortcutClick = useCallback(
		(feature: string) => {
			if (permissions[feature]) {
				switch (feature) {
					case RecentMediaTypes.edits:
						createAdobeExpressDesign();
						break;
					case RecentMediaTypes.interact:
						setShowActionDialog(mediaActionKeys.createInteractProject);
						break;
					case RecentMediaTypes.record:
						dispatch(setScreenRecorderModalStatus(true));
						break;
					case RecentMediaTypes.live:
						setShowActionDialog(mediaActionKeys.createLiveEvent);
						break;
					case RecentMediaTypes.video:
						dispatch(setShowUploadMediasDialog(true));
						break;
				}
			} else {
				dispatch(setPermissionInformationDialog(feature));
			}
		},
		[createAdobeExpressDesign, dispatch, permissions]
	);

	useEffect(() => {
		const fetchChannels = async () => {
			let _liveChannels: any[] | undefined = [];
			_liveChannels = await _getChannels(defaultAccountId);

			if (!_liveChannels) {
				throw new Error('Channels not found', { cause: 404 });
			}

			setLiveChannels(_liveChannels ?? []);
		};

		fetchChannels().catch((error) => {
			const { cause: code } = error;
			if (Number(code) === 404) {
				setLiveChannels([]);
			}
		});
	}, [defaultAccountId]);

	if (screenType === customScreenType.mobile || screenType === customScreenType.small) {
		return (
			<div className={joinClassNames(DASHBOARD_BASE_CLASSNAME, `${DASHBOARD_BASE_CLASSNAME}--mobile`)}>
				<div className={`${DASHBOARD_BASE_CLASSNAME}__container-wrapper`}>
					<div className={joinClassNames(`${DASHBOARD_BASE_CLASSNAME}__container`)}>
						<div className={`${DASHBOARD_BASE_CLASSNAME}__left`}>
							<div className={`${DASHBOARD_BASE_CLASSNAME}__actions`}>
								<ShortcutButton
									type={RecentMediaTypes.video}
									onClick={() => handleOnShortcutClick(RecentMediaTypes.video)}
								/>
							</div>
						</div>
						<div className={`${DASHBOARD_BASE_CLASSNAME}__right`}>
							<iframe src={LINKS.news} className={`${DASHBOARD_BASE_CLASSNAME}--shadow`} />
						</div>
					</div>

					<RecentMedias history={history} hidePagination={screenType === customScreenType.mobile} />
					{screenType === customScreenType.small && permissions[RecentMediaTypes.interact] && (
						<RecentMedias
							mediaType={RecentMediaTypes.interact}
							history={history}
							onShortcutActionClick={() => handleOnShortcutClick(RecentMediaTypes.interact)}
						/>
					)}
				</div>

				<Footer isMobile={screenType === customScreenType.mobile} />
			</div>
		);
	}

	return (
		<div className={DASHBOARD_BASE_CLASSNAME}>
			<div className={`${DASHBOARD_BASE_CLASSNAME}__container-wrapper`}>
				<div className={`${DASHBOARD_BASE_CLASSNAME}__container`}>
					<div className={`${DASHBOARD_BASE_CLASSNAME}__left`}>
						<iframe
							src={'https://www.qbrick.com/whats-new/'}
							className={`${DASHBOARD_BASE_CLASSNAME}--shadow`}
						/>
					</div>
					<div className={`${DASHBOARD_BASE_CLASSNAME}__right`}>
						<div
							className={joinClassNames(
								`${DASHBOARD_BASE_CLASSNAME}__actions`,
								`${DASHBOARD_BASE_CLASSNAME}__container-grid`
							)}
						>
							<ShortcutButton
								type={RecentMediaTypes.video}
								onClick={() => handleOnShortcutClick(RecentMediaTypes.video)}
							/>
							<ShortcutButton
								type={RecentMediaTypes.edits}
								onClick={() => handleOnShortcutClick(RecentMediaTypes.edits)}
								noPermission={!permissions[RecentMediaTypes.edits]}
							/>
							<ShortcutButton
								type={RecentMediaTypes.interact}
								onClick={() => handleOnShortcutClick(RecentMediaTypes.interact)}
								noPermission={!permissions[RecentMediaTypes.interact]}
							/>
							{isLargeScreen && (
								<ShortcutButton
									type={RecentMediaTypes.record}
									onClick={() => handleOnShortcutClick(RecentMediaTypes.record)}
									noPermission={!permissions[RecentMediaTypes.record]}
								/>
							)}
							{isLargeScreen && (
								<ShortcutButton
									type={RecentMediaTypes.live}
									onClick={() => handleOnShortcutClick(RecentMediaTypes.live)}
									noPermission={!permissions[RecentMediaTypes.live]}
								/>
							)}
						</div>
						<IntroducingSlider onActionClick={handleOnShortcutClick} permissions={permissions} />
					</div>
				</div>

				<RecentMedias
					history={history}
					onShortcutActionClick={() => handleOnShortcutClick(RecentMediaTypes.video)}
				/>
				{permissions[RecentMediaTypes.interact] && (
					<RecentMedias
						mediaType={RecentMediaTypes.interact}
						history={history}
						onShortcutActionClick={() => handleOnShortcutClick(RecentMediaTypes.interact)}
					/>
				)}
				{permissions[RecentMediaTypes.edits] && (
					<RecentMedias
						mediaType={RecentMediaTypes.edits}
						history={history}
						onMediaActionMenuClick={onMediaActionMenuClick}
						onShortcutActionClick={() => handleOnShortcutClick(RecentMediaTypes.edits)}
					/>
				)}
				{permissions[RecentMediaTypes.live] && (
					<RecentMedias
						mediaType={RecentMediaTypes.live}
						history={history}
						onShortcutActionClick={() => handleOnShortcutClick(RecentMediaTypes.live)}
					/>
				)}
			</div>

			<Footer />

			{showActionDialog === mediaActionKeys.createInteractProject && (
				<ScenarioCreationDialog
					open={showActionDialog === mediaActionKeys.createInteractProject}
					onClose={() => setShowActionDialog('')}
					onCreateScenario={onCreateScenario}
				/>
			)}

			{showActionDialog === mediaActionKeys.createLiveEvent && (
				<CreateLiveEventDialog
					open={showActionDialog === mediaActionKeys.createLiveEvent}
					hideCreateLiveEventDialogBox={() => setShowActionDialog('')}
					liveChannels={liveChannels}
					createLiveMedia={createLiveMedia}
					createLegacyLiveMedia={createLiveMedia}
				/>
			)}
		</div>
	);
};

export default Dashboard;
