import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { login, LoginFormUpdate, sessionEnd } from '../../actions/sessionActions';
import { OauthService } from './../../services/oauthService';
import { TokenService } from '../../services/tokenService';
import { LocalStorageService } from '../../services/localStorageService';
import { showNavArea, hideNavArea } from '../../services/componentReusableService';
import { screenRoutes } from './../../constants/routesPath';
import { isValidJsonString, isValidTokenResponse } from '../../services/stringHelperService';
import { qbrickUrl, supportUrl } from '../../utils/config';
import globe from '../../assets/images/globe.png';
import headset from '../../assets/images/headset.svg';
import headset_img from '../../assets/images/headset.png';
import globe_img from '../../assets/images/globe_img.png';
import logo from '../../assets/images/logo_white.png';

import IframeLogin from './IframeLogin';

import './LoginScreen.scss';

class LoginScreen extends Component {
	state = {
		message: '',
		username: '',
		password: '',
		callBackUrl: '',
		errorFields: {
			username: false,
			password: false,
		},
		errorsInTheForm: false,
		successInTheForm: false,
		isResetPasswordForm: false,
	};

	UNSAFE_componentWillMount() {
		this.props.sessionEnd();
		const callbackUrl = OauthService.createLoginUrl();
		this.setState({ callBackUrl: callbackUrl });
		this.onframeLoaded = this.onframeLoaded.bind(this);
		window.addEventListener('message', this.handleFrameTasks);
	}

	componentDidMount() {
		hideNavArea();
		this.setState({ message: 'Loading.....' });
	}

	onframeLoaded = (_) => {
		this.setState({ message: '' });
	};

	componentWillUnmount() {
		showNavArea();
		window.removeEventListener('message', this.handleFrameTasks);
	}

	handleFrameTasks = (e) => {
		let expiresIn = 0;
		const iframe = document.getElementById('oauthFrame');
		if (iframe && iframe.remove) {
			iframe.remove();
		}
		if (e.data && isValidJsonString(e.data) && e.origin === window.location.origin) {
			const data = JSON.parse(e.data);

			if (!isValidTokenResponse(data)) {
				return;
			}

			LocalStorageService.updateLocalStorage(data);
			this.setState({ message: 'Please wait while we log in.....' });
			expiresIn = data.expires_in;
			if (expiresIn) {
				TokenService.saveTokenTime(expiresIn);
				this.props
					.submitForm()
					.then(() => this.props.history.push(screenRoutes.DASHBOARD /* PUBLISH_MEDIA_LIBRARY */))
					.catch((error) => {
						console.error('Failed to login ....', error);
					});
			}
		}

		const mess = e.data.toString();
		const isResetPasswordForm = mess && mess.includes('login_resetPassword');
		if (isResetPasswordForm !== this.state.isResetPasswordForm) {
			this.setState({ isResetPasswordForm: isResetPasswordForm });
		}
	};

	redirectToGivenUrl = (url) => window.open(url, '_blank');

	render() {
		const { callBackUrl, isResetPasswordForm } = this.state;
		const { isLoggedIn } = this.props;
		return (
			<>
				{isLoggedIn ? (
					''
				) : (
					<div className="loginScreen">
						<div
							className={['loginScreen__bg', isResetPasswordForm ? 'loginScreen--reset' : '']
								.join(' ')
								.trim()}
						>
							<div className="loginScreen__slot loginScreen__slot--left">
								<div className="loginContainer">
									<IframeLogin src={callBackUrl} frameLoaded={this.onframeLoaded} />
								</div>
							</div>

							<div className="loginScreen__slot loginScreen__slot--right">
								<div className="loginScreen__welcomeText">
									Welcome back <span>Q-bies!</span>
								</div>
							</div>
							<div className="dashboard_copyRight">©Qbrick {new Date().getFullYear()}</div>
						</div>

						<loginHeader className="loginScreen__header">
							<div className="loginScreen__header--slot">
								<img className="loginScreen__header-logo" src={logo} />
							</div>
							<div className="loginScreen__header--slot">
								<button
									className="loginScreen__header-button"
									onClick={() => this.redirectToGivenUrl(supportUrl)}
								>
									<img className="loginScreen__header-button--icon" src={headset} />
									Support
								</button>
								<button
									className="loginScreen__header-button loginScreen__header-button--white"
									onClick={() => this.redirectToGivenUrl(qbrickUrl)}
								>
									<img className="loginScreen__header-button--icon" src={globe} />
									qbrick.com
								</button>
								<button className="loginScreen__header-button--sm">
									<img
										className="loginScreen__header-button--icon"
										src={headset_img}
										onClick={() => this.redirectToGivenUrl(supportUrl)}
									/>
								</button>
								<button
									className="loginScreen__header-button--sm"
									onClick={() => this.redirectToGivenUrl(qbrickUrl)}
								>
									<img className="loginScreen__header-button--icon" src={globe_img} />
								</button>
							</div>
						</loginHeader>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	message: session.message,
	isLoggedIn: session.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
	submitForm: (_) => dispatch(login()),
	sessionEnd: (_) => dispatch(sessionEnd()),
	updateForm: (field, value) => dispatch(LoginFormUpdate({ field, value })),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LoginScreen);
