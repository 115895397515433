import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './../InteractEditorTopPanel/InteractElementProperties/InteractElementProperties.scss';
import Dropzone from 'react-dropzone';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import { updateInteractTimelineItem } from '../../../actions/interactTimelineActions';
import {
	getMediaQueryDetails,
	startThumbImageUploadProcess,
	keepLoadResourcesAfterTheFlowStarted,
	removeSubtitle,
	getAllCurrentJobs,
	setUploadingProcessActive,
} from '../../../actions/publishActions';
import { setInteractiveImageRel } from '../../../services/mediaService';
import { setInteractButtonImageUploaded } from '../../../actions/inspectScreenActions';
import { setIsElementUpdating } from '../../../actions/interactElementsActions';
import { showNotification } from '../../../actions/notificationActions';
import { showAlert, showMessage } from '../../../actions/globalActions';
import { mediaNames, messageTypes, UPLOADING_FILE, UPLOAD_FAILED } from '../../../constants/mediaConstants';
import loading from '../../../assets/images/loading.gif';
import { generateId } from '../../../services/stringHelperService';
import _ from 'underscore';
import upload from '../../../assets/images/upload.svg';

class ButtonFileChoose extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showloading: false,
		};
	}

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				/* let newItem = JSON.parse(JSON.stringify(item))
                 newItem.start = new Date(item.start)
                 newItem.end = new Date(item.end)
                 return newItem*/
				return item;
			}
		}
		return {};
	};

	componentWillUnmount() {}

	componentDidMount = () => {};

	renderDropZone = () => {
		const { t, showMessage } = this.props;
		return (
			<Dropzone
				ref="dropzone"
				multiple={false}
				accept=".jpg, .jpeg, .gif, .png, .bmp"
				parallelChunkUploads={true}
				// onDrop={files => this.loadTheSelectedMediaItems(files)}
				onDrop={(acceptedFiles, fileRejections) => {
					if (fileRejections.length > 0) {
						showMessage(t('LABEL_NOT_SUPPORTED_FILES'), messageTypes.error);
						return;
					}
					this.loadTheSelectedMediaItems(acceptedFiles);
				}}
				// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
				inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Drag Files')}
			>
				{({ getRootProps, getInputProps }) => (
					<>
						<div className="container">
							<div {...getRootProps({ className: 'dropzone' })}>
								<input {...getInputProps()} />
							</div>
						</div>
					</>
				)}
			</Dropzone>
		);
	};
	openDropZoneSelection = () => {
		this.refs.dropzone.open();
	};

	/* setImageUrl = (file) => {
         const { item, eachButton, t, buttonIndex, imageType } = this.props
         const { buttonName, urlType } = this.state
         let newItem = { //added to update item properly
             ...item
         }
         let fileBlob = URL.createObjectURL(file)
         var img = new Image();
         img.onload = () => {
             newItem.settings[buttonName][urlType].default = fileBlob
             this.props.updateInteractTimelineItem(newItem)
             URL.revokeObjectURL(this.src); // clean-up memory
         }
         img.src = fileBlob;
     }*/

	setImageUrl = (filePath) => {
		let item = this.getCurrentlyEditedItem();
		const { parentKey, objKey } = this.props;
		let newItem = {
			//added to update item properly
			...item,
		};
		newItem.settings[parentKey][objKey].default = filePath;
		this.props.updateInteractTimelineItem(newItem);
	};

	resetImageUrl = () => {
		let item = this.getCurrentlyEditedItem();
		const { parentKey, objKey } = this.props;
		let newItem = {
			//added to update item properly
			...item,
		};
		newItem.settings[parentKey][objKey].default = '';
		this.props.updateInteractTimelineItem(newItem);
	};

	setLoading = () => {
		this.setState({
			showloading: true,
		});
	};

	resetLoading = () => {
		this.setState({
			showloading: false,
		});
	};

	getSelectedMedia = (eachFile, callback) => {
		const { defaultAccountId, mediaDetails, t } = this.props;
		this.setLoading();
		let fileFound = false;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias).then((media) => {
			if (!media) {
				this.props.showNotification(t('LABEL_MEDIA_NOT_FOUND'));
				this.resetLoading();
				return;
			}
			let resourceArray = [];
			if (media && media.asset && media.asset.resources && media.asset.resources.map) {
				resourceArray = media.asset.resources.filter(
					(eachResource) => eachResource && eachResource.type === 'image' && eachResource.renditions
				);
				if (resourceArray.length > 0) {
					resourceArray.map((resource) => {
						resource.renditions.map((rendition) => {
							if (rendition.path.match(eachFile.name) && rendition.size === eachFile.size) {
								fileFound = true;

								this.setState(
									{
										currentResouceId: resource.id,
									},
									() => {
										this.setImageUrl(rendition.links[0].href || rendition.path);
										this.props.setIsElementUpdating(false);
										if (!resource.rel.includes('interactiveImage')) {
											setInteractiveImageRel(defaultAccountId, media.asset.id, resource.id);
										}
									}
								);
								this.resetLoading();
							}
						});
					});
				}
			}
			if (!fileFound && callback) {
				callback();
			} else if (!fileFound) {
				this.props.showNotification(t('FILE_NOT_FOUND'));
			}
			this.resetLoading();
		});
	};

	trackUploadStatus = (eachFile, uid) => {
		const { defaultAccountId } = this.props;
		let file = '';
		this.props
			.getAllCurrentJobs(defaultAccountId)
			.then((data) => {
				if (data && data.length > 0) {
					file = _.find(data, (eachData) => {
						return eachData.uid.trim() === uid.trim();
					});
				}
				if (file) {
					setTimeout(() => {
						this.trackUploadStatus(eachFile, uid);
					}, 500);
				} else {
					setTimeout(() => {
						this.getSelectedMedia(eachFile, () => {
							this.tryFetchingMediaAgain(eachFile);
						});
					}, 2000);
					this.resetLoading();
				}
			})
			.catch(() => {
				setTimeout(() => {
					this.getSelectedMedia(eachFile, () => {
						this.tryFetchingMediaAgain(eachFile);
					});
				}, 2000);
				this.resetLoading();
			});
	};

	tryFetchingMediaAgain = (eachFile) => {
		setTimeout(() => {
			this.getSelectedMedia(eachFile, () => {
				this.tryFetchingMediaAgain(eachFile);
			});
		}, 2000);
	};

	getRandomFileName = (filename) => {
		let randomFileName = generateId() + '.' + this.getFileNameExtension(filename);
		return randomFileName;
	};

	getFileNameExtension = (filename) => {
		return filename.split('.').pop();
	};

	loadTheSelectedMediaItems = (files) => {
		const { defaultAccountId, mediaDetails, t, setUploadingProcessActive } = this.props;
		let eachFileTemp = files[0];
		let assetId = '';
		this.props.setIsElementUpdating(true);
		var blob = eachFileTemp.slice(0, eachFileTemp.size, eachFileTemp.type);
		var eachFile = new File([blob], this.getRandomFileName(eachFileTemp.name), { type: eachFileTemp.type });

		if (mediaDetails && mediaDetails.asset && mediaDetails.asset.id) {
			assetId = mediaDetails.asset.id;
			this.setLoading();
			setUploadingProcessActive(UPLOADING_FILE);

			this.props.startThumbImageUploadProcess(defaultAccountId, eachFile, assetId).then((res) => {
				setTimeout(() => setUploadingProcessActive(''), 1000);

				if (!res || res === UPLOAD_FAILED) {
					this.props.showAlert('');
					this.props.showAlert(UPLOAD_FAILED, messageTypes.error);
					this.resetLoading();
					return;
				}

				if (res) {
					if (res && res.data && typeof res.data === 'string') {
						let uid = res.data.split('uid:');
						uid = uid.length > 0 ? uid[1] : '';
						setTimeout(() => {
							this.trackUploadStatus(eachFile, uid);
						}, 500);
					}
					this.props.setInteractButtonImageUploaded(true);
					this.resetLoading();
				}
			});
		} else {
			this.props.showMessage(t('FAILED_PROCESS_NO_ASSET_ID'), messageTypes.error);
		}
	};

	clearTheFilesSelection = (e) => {
		if (e) {
			e.stopPropagation();
		}
		this.resetImageUrl();
	};

	onFileNameChange = (e) => {
		let item = this.getCurrentlyEditedItem();
		const { parentKey, objKey } = this.props;
		let newItem = {
			//added to update item properly
			...item,
		};
		newItem.settings[parentKey][objKey].default = e.target.value;
		this.props.updateInteractTimelineItem(newItem);
	};
	render() {
		const { t } = this.props;
		const { showloading } = this.state;
		const { parentKey, objKey } = this.props;
		let item = this.getCurrentlyEditedItem();
		let fileName = item.settings[parentKey][objKey].default;

		return (
			<>
				{this.renderDropZone()}
				<div className="fileSelection" onClick={() => this.openDropZoneSelection()}>
					{/* <input className='chooseFileInput' type='text' placeholder={t('NO_FILE_SELECTED')} value={fileName} onChange={(e) => { this.onFileNameChange(e) }} /> */}
					<div className="chooseFileInput" id="imgUploadField">
						{!item.settings[parentKey][objKey].default ? (
							<div className="defaultFileInputWrapper">
								<img className="uploadSvg" src={upload} />
								<label>{t('LABEL_UPLOAD_IMAGE_FILE')}</label>
							</div>
						) : (
							<div>
								<img className="uploadedImg" src={fileName}></img>
								<label className="uploadedTitle">
									{fileName.substring(fileName.lastIndexOf('/') + 1)}
								</label>
							</div>
						)}
					</div>

					{!item.settings[parentKey][objKey].default ? (
						/*  <button className="chooseFileButton">
                            {t('CHOOSE_FILE')}
                        </button> */ <></>
					) : showloading ? (
						<>
							<img className="loadingImage" src={loading} />
						</>
					) : (
						<div className="closeButton" onClick={(e) => this.clearTheFilesSelection(e)}>
							<IconsStore color={'#ffffff'} iconName={IconsList.CLOSE} />
						</div>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, inspectScreenReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
	currentThumbnail: inspectScreenReducer.currentThumbnail,
	interactItems: interactTimelineReducer.items,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
	startThumbImageUploadProcess: (accId, file, assetId) =>
		dispatch(startThumbImageUploadProcess(accId, file, assetId)),
	showNotification: (message) => dispatch(showNotification(message)),
	setInteractButtonImageUploaded: (value) => dispatch(setInteractButtonImageUploaded(value)),
	keepLoadResourcesAfterTheFlowStarted: (accId, assetId, status) =>
		dispatch(keepLoadResourcesAfterTheFlowStarted(accId, assetId, status)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	removeSubtitle: (accountId, assetId, resourceId) => dispatch(removeSubtitle(accountId, assetId, resourceId)),
	setIsElementUpdating: (isUpdating) => dispatch(setIsElementUpdating(isUpdating)),
	getAllCurrentJobs: (defaultAccountId) => dispatch(getAllCurrentJobs(defaultAccountId)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	showAlert: (value, type) => dispatch(showAlert(value, type)),
	setUploadingProcessActive: (value) => dispatch(setUploadingProcessActive(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ButtonFileChoose);
