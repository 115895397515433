import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dialogSizes } from '../../../../../../constants/dialogSizesConstant';
import IconsStore from '../../../../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../../../../reusable/IconsStore/IconsList';
import { getRestrictionRules } from '../../../../../../actions/publishActions';
import { accessRestrictionRulesType, messageTypes } from './../../../../../../constants/mediaConstants';
import {
	updateExistingRestrictionRule,
	deleteRestrictionRule,
	createNewRestrictionRule,
} from '../../../../../../actions/publishActions';
import { isRequestFail } from './../../../../../../services/inspectScreenHelperService';
import { RestrictionRuleDeleteDialog } from '../RestrictionDeleteDialog/RestrictionRuleDeleteDialog';

import './../AccessRestriction.scss';
import './RestrictionRuleDialog.scss';

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	formControl: {
		margin: theme.spacing(2),
		marginLeft: 0,
		minWidth: 500,
	},
	select: {
		minWidth: 300,
		position: 'relative',
		backgroundColor: 'transparent',
		fontSize: 16,
		width: 'auto',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 1,
	},
});

class RestrictionRuleDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displaySettings: false,
			newRuleName: '',
			currentSelectedRule: {},
			selectedTab: 0,
			selectedRestrictionDetails: '',
			selectedRulesForMedia: [],
			inheritedRestrictions:
				this.props.mediaDetails && this.props.mediaDetails.inheritedRestrictions
					? this.props.mediaDetails.inheritedRestrictions
					: [],
			appliedRestrictions:
				this.props.mediaDetails && this.props.mediaDetails.appliedRestrictions
					? this.props.mediaDetails.appliedRestrictions
					: [],
			restrictionRulesOfAccId: [],
			listOfIpRulesInRule: [],
			secretTokenOfRule: '',
			disableView: true,
			showDeleteDialogStatus: false,
		};
	}

	UNSAFE_componentWillMount() {
		this.getRestrictionRules();
	}

	getDialogStyle = () => {};

	handleTabChange = (event, newValue) => {
		// setValue(newValue);
		this.setState({
			listOfIpRulesInRule: [],
		});
		this.setState({
			secretTokenOfRule: '',
		});
		this.setState({
			currentSelectedRule: {},
		});
		this.setState({
			selectedTab: newValue,
		});
	};

	onEditToken = (e) => {
		this.secretTokenInput.focus();
		this.setState({
			secretTokenOfRule: e.target.value,
		});
	};

	renderTokenRule = () => {
		const { secretTokenOfRule } = this.state;
		const { t } = this.props;
		return (
			<>
				<div className="tokenRule">
					<label className="headers"> {t('TOKEN')} </label>

					<input
						type="text"
						className="tokenField  fullWidthControl"
						value={secretTokenOfRule}
						onChange={this.onEditToken}
						disabled={this.state.disableView}
						ref={(tokenValue) => {
							this.secretTokenInput = tokenValue;
						}}
					/>
				</div>
			</>
		);
	};

	addIpToRule = () => {
		const { listOfIpRulesInRule } = this.state;
		let fromIp = document.getElementById('fromIp').value;
		let toIp = document.getElementById('toIp').value;
		fromIp = fromIp.trim();
		toIp = toIp.trim();
		document.getElementById('fromIp').value = '';
		document.getElementById('toIp').value = '';
		if (fromIp || toIp) {
			if (fromIp && !this.validateIPaddress(fromIp)) {
				this.props.showMessage('set valid from ip address', messageTypes.error);
				return;
			}
			if (toIp && !this.validateIPaddress(toIp)) {
				this.props.showMessage('set valid to ip address', messageTypes.error);
				return;
			}
			let ip = {
				type: accessRestrictionRulesType.IP,
				from: fromIp,
				to: toIp,
			};
			let listOfIpRulesInRuleArray = listOfIpRulesInRule;
			listOfIpRulesInRuleArray.push(ip);
			this.setState({
				listOfIpRulesInRule: listOfIpRulesInRuleArray,
			});
		} else {
			this.props.showMessage('set ip address', messageTypes.error);
		}
	};

	validateIPaddress = (ipaddress) => {
		// this validates ipv4 and ipv6 but not ipv6 with url and port
		if (
			/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
				ipaddress
			)
		) {
			return true;
		}
		return false;
	};

	renderIPRangeRule = () => {
		const { t } = this.props;
		const { disableView, listOfIpRulesInRule } = this.state;
		return (
			<>
				<div className="ipRangeRule accessActionButtons">
					<div className="ipAddressInputSection">
						<div className="ipAddressFromInput">
							<label className="headers"> {t('IP_RANGE_SETTINGS')} </label>
							<input className="ipaddressField from" type="text" id="fromIp" disabled={disableView} />
						</div>

						<label className="range">-</label>

						<div className="ipAddressToInput">
							<label className="headers">&nbsp; </label>
							<input className="ipaddressField to" type="text" id="toIp" disabled={disableView} />
						</div>

						<div>
							<label> &nbsp; </label>
							{!disableView ? (
								<Button variant="contained" className="ipAdd" onClick={this.addIpToRule}>
									+ Add
								</Button>
							) : null}
						</div>
						<br />
					</div>

					<div className="ipSets">
						{listOfIpRulesInRule &&
							listOfIpRulesInRule.map((eachRule, key) => (
								<>
									<div key={'ip_rule_' + key} className="ruleButton">
										<div className="ruleChip">
											<div className="deleteIcon" onClick={() => this.handleDeleteIP(eachRule)}>
												{this.getCloseIcon()}
											</div>
											<label> {eachRule.from + ' - ' + eachRule.to} </label>
										</div>
									</div>
								</>
							))}
					</div>
				</div>
				<RestrictionRuleDeleteDialog
					t={this.props.t}
					state={this.state}
					deleteSelected={this.deleteSelected}
					handleHideDialog={this.handleHideDeleteDialog}
				/>
			</>
		);
	};

	handleDeleteIP = (rule) => {
		const { listOfIpRulesInRule } = this.state;
		let index = -1;
		let filteredRule = [];
		for (let i = 0; i < listOfIpRulesInRule.length; i++) {
			if (listOfIpRulesInRule[i].from === rule.from && listOfIpRulesInRule[i].to === rule.to) {
				index = i;
				break;
			}
		}
		if (index !== -1) {
			listOfIpRulesInRule.splice(index, 1);
		}
		filteredRule = listOfIpRulesInRule;
		this.setState({
			listOfIpRulesInRule: filteredRule,
		});
	};

	getRestrictionRules = () => {
		const { defaultAccountId } = this.props;

		this.props.getRestrictionRules(defaultAccountId).then((data) => {
			// to check if rules are more than 200. right now backend does not give correct response when set limit to 200- tried with https://video.qbrick.com/api/v1/accounts/123780/restrictionRules?limit=1
			if (data && data.message) {
				this.props.showNotification(data.message);
				return;
			}
			if (data && !data.map) {
				this.props.showNotification(data);
				return;
			}
			if (data && data.map) {
				let rules = [];
				data.map((rule) => {
					if (rule.rules) {
						let found_rule = false;
						rule.rules.map((r) => {
							if (['Ip', 'Token'].indexOf(r.type) >= 0) {
								found_rule = true;
							}
						});

						if (found_rule && rule.hasOwnProperty('name')) {
							//displaying only if rule is having a name // to be checked
							rules.push(rule);
						}
					}
				});
				this.setState({
					restrictionRulesOfAccId: rules,
				});
			}
		});
	};

	resetRules = () => {
		this.setState({
			toRuleDate: null,
			showUnPublishTime: false,
			fromDateRule: null,
			secretTokenOfRule: '',
			listOfIpRulesInRule: '',
		});
		document.getElementById('fromIp').value = '';
		document.getElementById('toIp').value = '';
	};

	getCloseIcon = () => {
		return (
			<svg
				style={{
					fill: 'white',
				}}
				className="closeIcon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 19.5 19.5"
			>
				{' '}
				<defs />
				<title>X</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z"
						/>
						<path
							className="cls-1"
							d="M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	handleRuleClick = (e) => {
		this.setState({
			displaySettings: true,
		});

		let rule_id = e.target.value;
		this.state.restrictionRulesOfAccId.map((rule) => {
			if (rule.id === rule_id) {
				// this.resetRules()
				this.setState({
					currentSelectedRule: rule,
					disableView: false,
				});

				if (rule.rules && rule.rules.length > 0) {
					let listOfIpRulesInRule = [];
					rule.rules.map((eachRule) => {
						if (eachRule && eachRule.type == accessRestrictionRulesType.IP) {
							listOfIpRulesInRule.push(eachRule);
						}
						if (eachRule && eachRule.type == accessRestrictionRulesType.TOKEN) {
							this.setState({
								secretTokenOfRule: eachRule.secret,
							});
						}
					});
					this.setState({
						listOfIpRulesInRule: listOfIpRulesInRule,
					});
				}
			}
		});
	};

	createNewRule = () => {
		const { t } = this.props;

		if (
			((!this.state.listOfIpRulesInRule || Object.keys(this.state.listOfIpRulesInRule).length == 0) &&
				this.state.secretTokenOfRule == '') ||
			this.state.newRuleName == ''
		) {
			this.props.showMessage('Restriction rule is empty', messageTypes.error);
		} else {
			let rules = [];
			let save_obj = {};
			rules = this.state.listOfIpRulesInRule ? Object.assign([], this.state.listOfIpRulesInRule) : [];
			if (this.state.secretTokenOfRule != '') {
				rules.push({ secret: this.state.secretTokenOfRule, type: 'Token' });
			}

			save_obj = {
				name: this.state.newRuleName,
				rules: rules,
			};

			this.props.createNewRestrictionRule(this.props.defaultAccountId, save_obj).then((data) => {
				document.getElementById('new_rule_name').value = '';

				this.setState({
					listOfIpRulesInRule: [],
					secretTokenOfRule: '',
					newRuleName: '',
					//disableView:false
				});

				if (isRequestFail(this, data)) {
					this.props.showMessage(t('FAILED_CREATE_RESTRICTION_RULE'), messageTypes.error);
					return;
				}

				// this.getRestrictionRules();//refresh rule list
				// var th = this
				setTimeout(() => {
					this.getRestrictionRules();
					this.setState({
						disableView: false,
					});

					this.props.showMessage(t('SAVED_DETAILS_SUCCESSFULLY'), messageTypes.info);
				}, 1000);
			});
		}
	};

	deleteRule = () => {
		if (!this.state.currentSelectedRule || Object.keys(this.state.currentSelectedRule).length == 0) {
			this.props.showMessage('Select the rule you want to delete', messageTypes.error);
		} else {
			this.setState({
				showDeleteDialogStatus: true,
			});
			this.resetRules();
		}
	};

	deleteSelected = () => {
		const { t } = this.props;
		this.setState({
			disableView: true,
			showDeleteDialogStatus: false,
		});

		if (!this.state.currentSelectedRule || Object.keys(this.state.currentSelectedRule).length == 0) {
			this.props.showMessage('Select the rule you want to delete', messageTypes.error);
		} else {
			this.props
				.deleteRestrictionRule(this.props.defaultAccountId, this.state.currentSelectedRule.id)
				.then((data) => {
					this.setState({
						disableView: false,
					});
					if (isRequestFail(this, data)) {
						this.props.showMessage(t('FAILED_DELETE_RESTRICTION_RULE'), messageTypes.error);
						return;
					}

					this.getRestrictionRules(); //refresh rule list
					this.props.showMessage(t('DELETE_RESTRICTION_RULE_SUCCESSFULLY'), messageTypes.info);
				});

			document.getElementById('rulelist_dropdown').value = '';
			this.setState({
				currentSelectedRule: {},
			});
		}
	};

	handleHideDeleteDialog = () => {
		this.setState({
			showDeleteDialogStatus: false,
		});
	};

	saveRule = () => {
		const { t } = this.props;

		if (!this.state.currentSelectedRule || !this.state.currentSelectedRule.id) {
			this.props.showMessage('Please select a restriction rule first', messageTypes.error);
		} else {
			let rules = [];
			let save_obj = {};
			rules = this.state.listOfIpRulesInRule ? Object.assign([], this.state.listOfIpRulesInRule) : [];
			if (this.state.secretTokenOfRule != '') {
				rules.push({ secret: this.state.secretTokenOfRule, type: 'Token' });
			}
			this.setState({
				disableView: true,
			});
			save_obj = {
				name: this.state.currentSelectedRule.name,
				rules: rules,
			};

			this.props
				.updateExistingRestrictionRule(this.props.defaultAccountId, this.state.currentSelectedRule.id, save_obj)
				.then((data) => {
					this.setState({
						disableView: false,
					});
					if (isRequestFail(this, data)) {
						this.props.showMessage(t('FAILED_UPDATE_RESTRICTION_RULE'), messageTypes.error);
						return;
					}

					this.getRestrictionRules(); //refresh rule list
					this.props.showMessage(t('UPDATE_RESTRICTION_RULE_SUCCESSFULLY'), messageTypes.info);
				});
		}
	};

	ruleNameChange = (e) => {
		this.setState({
			newRuleName: e.target.value,
			disableView: false,
		});
	};

	a11yProps = (index) => {
		return {
			id: `full-width-tab-${index}`,
			'aria-controls': `full-width-tabpanel-${index}`,
		};
	};

	render() {
		const { t, closeInformationDialog, open } = this.props;
		const { restrictionRulesOfAccId } = this.state;
		return (
			<>
				<Dialog
					id="CaptureThumbnailDialog"
					style={this.getDialogStyle()}
					fullWidth={true}
					maxWidth={dialogSizes.SMALL}
					open={open}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					onClose={closeInformationDialog}
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6"></Typography>
						<IconButton onClick={closeInformationDialog}>
							<Close/>
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div id="accessRestrictionSection">
							<div className="videoPlayerCaptureThumbnail">
								<Tabs
									TabIndicatorProps={{
										style: {
											backgroundColor: '#2EBEDC',
											fontSize: 16,
											color: '#000',
										},
									}}
									value={this.state.selectedTab}
									onChange={this.handleTabChange}
									indicatorColor="primary"
									textColor="primary"
									variant="fullWidth"
									centered
								>
									<Tab
										label={<span className="tabHeader">{t('MANAGE_RESTRICTIONS')}</span>}
										{...this.a11yProps(1)}
									/>
									<Tab
										label={<span className="tabHeader">{t('CREATE_RESTRICTION')}</span>}
										{...this.a11yProps(2)}
									/>
								</Tabs>
								{this.state.selectedTab == 0 && (
									<div className="tabContent">
										<div className="previewAndShareInternalBlock">
											<div className="fullWidthControl panel">
												<label className="headers">{t('RESTRICTION_LIST_TO_SELECT')}</label>
												<select
													onChange={(e) => this.handleRuleClick(e)}
													className="genericDropDown"
													id="rulelist_dropdown"
												>
													<option value="" selected disabled hidden>
														{'- Select -'}
													</option>
													{restrictionRulesOfAccId.map((rule) => (
														<option key={rule.id} value={rule.id}>
															{rule.name}
														</option>
													))}
												</select>
											</div>
										</div>

										{this.state.displaySettings && (
											<>
												<div className="panel">
													{/* <label>{t('IP_RANGE_SETTINGS')}</label> */}
													{this.renderIPRangeRule()}
												</div>

												<div className="panel">{this.renderTokenRule()}</div>
											</>
										)}
										<div className="panel"></div>
										<div className="panel accessActionButtons centerElements">
											<Button
												disabled={this.state.disableView}
												className="deleteButton centerButton fullWidthButton"
												onClick={() => this.deleteRule()}
											>
												<IconsStore iconName={IconsList.TRASHCAN} />
												{t('DELETE')}
											</Button>
											<Button
												disabled={this.state.disableView}
												className="saveButton centerButton fullWidthButton"
												onClick={() => this.saveRule()}
											>
												{t('LABEL_SAVE_BUTTON')}
											</Button>
										</div>
									</div>
								)}

								{this.state.selectedTab == 1 && (
									<div className="tabContent">
										<div className="fullWidthControl panel">
											<label className="headers">{t('RESTRICTION_RULE_NAME')}</label>
											<input
												type="text"
												id={'new_rule_name'}
												disabled={false}
												onChange={(e) => this.ruleNameChange(e)}
											/>
										</div>

										<div className="panel">
											{/* <label>{t('IP_RANGE_SETTINGS')}</label> */}
											{this.renderIPRangeRule()}
										</div>

										<div className="panel">{this.renderTokenRule()}</div>
										<div className="panel"></div>
										<div className="panel accessActionButtons centerElements">
											<Button
												className="saveButton centerButton fullWidthButton"
												onClick={() => {
													this.createNewRule();
												}}
											>
												{t('SAVE_SETTINGS')}
											</Button>
											<Button
												className="cancelButton centerButton fullWidthButton"
												onClick={() => closeInformationDialog()}
											>
												{t('LABEL_CANCEL_BUTTON')}
											</Button>
										</div>
									</div>
								)}
							</div>
						</div>
					</DialogContent>

					<DialogActions>
						<div className="dialogActionsWrapper"></div>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	getRestrictionRules: (accId) => dispatch(getRestrictionRules(accId)),
	deleteRestrictionRule: (accountId, restrictionId) => dispatch(deleteRestrictionRule(accountId, restrictionId)),
	createNewRestrictionRule: (accountId, body) => dispatch(createNewRestrictionRule(accountId, body)),
	updateExistingRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(updateExistingRestrictionRule(accountId, restrictionId, body)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles, { withTheme: true })(RestrictionRuleDialog));
