import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './StreamInfo.scss';

class StreamInfo extends Component {
	render() {
		const { t, stream } = this.props;
		return (
			<>
				<div id="streamInfo">
					<table>
						<tbody>
							<tr>
								<td>{t('VIDEO_BITRATE') + ':'}</td>
								<td> {stream.bitRate} </td>
								<td> {t('AUDIO_BITRATE') + ':'}</td>
								<td> {stream.audioBitRate} </td>
							</tr>
							<tr>
								<td> {t('VIDEO_CODEC') + ':'}</td>
								<td> {stream.codec} </td>
								<td> {t('SAMPLE_RATE') + ':'}</td>
								<td> {stream.sampleRate} </td>
							</tr>
							<tr>
								<td> {t('VIDEO_WIDTH') + ':'}</td>
								<td> {stream.width} </td>
								<td> {t('BIT_DEPTH') + ':'}</td>
								<td> {stream.bitDepth} </td>
							</tr>
							<tr>
								<td> {t('VIDEO_HEIGHT') + ':'}</td>
								<td> {stream.height} </td>
								<td> {t('CHANNELS') + ':'}</td>
								<td> {stream.channels} </td>
							</tr>
							<tr>
								<td> {t('VIDEO_PROFILE') + ':'}</td>
								<td> {stream.videoProfile} </td>
								<td> {t('AUDIO_CODEC') + ':'}</td>
								<td> {stream.audioCodec} </td>{' '}
								{/*to clarify as values is coming as HE-AAC but shown as AAC-LC*/}
							</tr>
						</tbody>
					</table>
				</div>
			</>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(StreamInfo);
