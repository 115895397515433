import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import LinearProgress from '@material-ui/core/LinearProgress';
import _ from 'underscore';

import { showNotification } from '../../../actions/notificationActions';
import { setSaveAsOnDemandOption } from '../../../actions/liveManagerActions';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import {
	mediaNames,
	messageTypes,
	imageRelTagList,
	UPLOAD_FAILED,
	UPLOADING_FILE,
} from '../../../constants/mediaConstants';
import { getDetails } from '../../../services/mediaDisplayService';
import {
	getMediaQueryDetails,
	removeThumbnailImage,
	stopMediaUploadProcess,
	startMediaUploadProcess,
	startThumbImageUploadProcess,
	setTheThumbnailImageAsDefaultAction,
	keepLoadResourcesAfterTheFlowStarted,
	updateResourceOfMedia,
	removeResourceAsThumbnail,
	updateMediaCover,
	removeSubtitle,
	getAllCurrentJobs,
	setUploadingProcessActive,
} from '../../../actions/publishActions';
import { setIsElementUpdating } from '../../../actions/interactElementsActions';
import { showAlert, showMessage } from '../../../actions/globalActions';
import {
	removeTagFromRelOfResource,
	addTagToRelOfResource,
	getResourceOfMediaWithTag,
	getResourceOfMediaById,
} from '../../../services/mediaResourceService';
import { generateId } from '../../../services/stringHelperService';

import './LiveCoversUpload.scss';

class LiveCoversUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showloading: false,
			selectedCoverResource: '',
			onOngoingFilePercent: '',
		};
	}

	UNSAFE_componentWillMount = () => {
		this.fetchAndSetMediaDetails();
	};

	componentDidMount = () => {};

	componentDidUpdate = (nextProps) => {
		if (this.props.isVisible !== nextProps.isVisible) {
			this.fetchAndSetMediaDetails();
		}
	};

	fetchAndSetMediaDetails = (_) => {
		const { defaultAccountId, t, coverName, mediaId } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (!media) {
				this.props.showMessage(t('MEDIA_NOT_FOUND'), messageTypes.error);
				return;
			}
			if (typeof media == 'string') {
				this.props.showMessage(media, messageTypes.error);
				return;
			}
			this.updateMediaDetails(media);
			let selectedCoverResource = '';
			if (
				coverName === imageRelTagList.THUMBNAIL &&
				media.thumbnails &&
				media.thumbnails.length > 0 &&
				media.thumbnails[0].id
			) {
				selectedCoverResource = getResourceOfMediaById(media, media.thumbnails[0].id);
			} else {
				selectedCoverResource = getResourceOfMediaWithTag(media, coverName);
			}
			this.setState({
				selectedCoverResource: selectedCoverResource || '',
			});
		});
	};

	updateMediaDetails = (media) => {
		const { mediaName } = this.props;
		const customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
		this.setState({ mediaDetails: customMediaInfo });
	};

	openDropZoneSelection = () => {
		this.refs.dropzone.open();
	};

	setLoading = () => {
		this.setState({ showloading: true });
		this.props.setIsElementUpdating(true);
	};

	resetLoading = () => {
		this.setState({ showloading: false });
		this.props.setIsElementUpdating(false);
	};

	getPercentage = (expense, total) => {
		return Math.round((expense / total) * 100);
	};

	trackUploadStatus = (eachFile, uid) => {
		const { defaultAccountId } = this.props;
		let file = '';
		this.props
			.getAllCurrentJobs(defaultAccountId)
			.then((data) => {
				if (data && data.length > 0) {
					file = _.find(data, (eachData) => {
						return eachData.uid.trim() === uid.trim();
					});
				}
				if (file) {
					let num = file.distans;
					num = num.split('/');
					let percent = this.getPercentage(num[0], num[1]);
					this.setState({
						onOngoingFilePercent: percent,
					});
					setTimeout(() => {
						this.trackUploadStatus(eachFile, uid);
					}, 500);
				} else {
					this.setState({
						onOngoingFilePercent: '',
					});
					setTimeout(
						(eachFile) => {
							this.getSelectedMedia(eachFile, () => {
								this.tryFetchingMediaAgain(eachFile, 0);
							});
							this.resetLoading();
						},
						1000,
						eachFile
					);
				}
			})
			.catch((error) => {
				setTimeout(
					(eachFile) => {
						this.getSelectedMedia(eachFile, () => {
							this.tryFetchingMediaAgain(eachFile, 0);
						});
						this.resetLoading();
					},
					0,
					eachFile
				);
				return error;
			});
	};

	tryFetchingMediaAgain = (eachFile, count) => {
		let countDown = count;
		if (countDown < 3) {
			setTimeout(
				(eachFile) => {
					this.getSelectedMedia(eachFile, () => {
						countDown = countDown + 1;
						this.tryFetchingMediaAgain(eachFile, countDown);
						this.props.showMessage('Trying to fetch uploaded media', messageTypes.info);
					});
				},
				2000,
				eachFile
			);
		} else {
			this.resetLoading();
		}
	};

	getFileWithRandomName = (file) => {
		const newName = generateId() + '.' + file.name.split('.').pop();
		const options = { type: file.type, lastModified: file.lastModified };
		try {
			// If this call fails, we go for Blob
			return new File([file], newName, options);
		} catch (e) {
			// If we reach this point a new File could not be constructed
			var myBlob = new Blob([file], options || {});
			myBlob.name = newName;
			return myBlob;
		}
	};

	loadTheSelectedMediaItems = (files) => {
		const { defaultAccountId, t, setUploadingProcessActive } = this.props;
		const { mediaDetails } = this.state;
		let eachFile = this.getFileWithRandomName(files[0]);
		let assetId = '';
		if (mediaDetails && mediaDetails.asset && mediaDetails.asset.id) {
			assetId = mediaDetails.asset.id;
			this.setLoading(eachFile);
			setUploadingProcessActive(UPLOADING_FILE);

			this.props.startThumbImageUploadProcess(defaultAccountId, eachFile, assetId).then((res) => {
				setTimeout(() => setUploadingProcessActive(''), 1000);

				if (!res || res === UPLOAD_FAILED) {
					this.props.showAlert('');
					this.props.showAlert(UPLOAD_FAILED, messageTypes.error);
					this.resetLoading();
					return;
				}

				if (res && res.data && typeof res.data === 'string') {
					let uid = res.data.split('uid:');
					uid = uid.length > 0 ? uid[1] : '';
					setTimeout(() => {
						this.trackUploadStatus(eachFile, uid);
					}, 500);
				}
			});
		} else {
			this.props.showMessage(t('FAILED_PROCESS_NO_ASSET_ID'), messageTypes.error);
		}
	};

	getSelectedMedia = (eachFile, callback) => {
		const { defaultAccountId, t, coverName, mediaId } = this.props;
		const { currentResouceId, mediaDetails } = this.state;
		this.setLoading();
		let assetId = mediaDetails.asset.id;
		let fileFound = false;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias).then((media) => {
			if (!media) {
				this.props.showMessage(t('LABEL_MEDIA_NOT_FOUND'), messageTypes.error);
				this.resetLoading();
				return;
			}
			let resourceArray = [];
			if (media && media.asset && media.asset.resources && media.asset.resources.map) {
				resourceArray = media.asset.resources.filter(
					(eachResource) => eachResource && eachResource.type === 'image' && eachResource.renditions
				);
				if (resourceArray.length > 0) {
					if (!fileFound) {
						resourceArray.map((resource) => {
							resource.renditions.map((rendition) => {
								if (
									!fileFound &&
									rendition.path.match(eachFile.name) &&
									rendition.size === eachFile.size
								) {
									if (currentResouceId) {
										// to check if old resource is to be deleted or not
										this.props.removeSubtitle(defaultAccountId, assetId, currentResouceId); //here removeSubtitle is actually removing a resource
									}
									fileFound = true;
									this.setState(
										{
											currentResouceId: resource.id,
										},
										() => {
											if (coverName === imageRelTagList.THUMBNAIL) {
												this.props
													.setTheThumbnailImageAsDefaultAction(
														defaultAccountId,
														mediaId,
														resource.id
													)
													.then((res) => {
														if (res) {
															this.props.showMessage(
																t('THUMBNAIL_UPDATED'),
																messageTypes.info
															);
															this.updateRelArrayWithTag(resource, coverName);
														}
													});
											} else {
												this.updateRelArrayWithTag(resource, coverName);
											}
										}
									);
									this.resetLoading();
								}
							});
						});
					} //end of if not filefound
				}
			}
			if (!fileFound && callback) {
				callback();
			} else if (!fileFound) {
				this.props.showMessage(t('FILE_NOT_FOUND'), messageTypes.error);
			}
			this.resetLoading();
		});
	};

	removeTagFromOtherResources = (currentResourceId, tag, callback) => {
		const { defaultAccountId, t } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showMessage(t('FAILED_PROCESS_NO_ASSET_ID'), messageTypes.error);
			return;
		}
		let assetId = mediaDetails.asset.id;
		let allImageResources =
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.filter(
				(eachImageResource) => eachImageResource.type === 'image' && eachImageResource.renditions
			);

		let resources = allImageResources.filter(
			(eachImageResource) =>
				eachImageResource.id &&
				eachImageResource.id !== currentResourceId &&
				eachImageResource.rel &&
				eachImageResource.rel.length > 0 &&
				eachImageResource.rel.indexOf(tag) !== -1
		);
		if (resources.length > 0) {
			let count = 0;
			let resourcesLength = resources.length;
			resources.map((eachImageResource) => {
				eachImageResource = removeTagFromRelOfResource(eachImageResource, tag);
				this.props.updateResourceOfMedia(defaultAccountId, assetId, eachImageResource).then((_) => {
					count = count + 1;
					if (resourcesLength === count) {
						if (callback) {
							callback();
						}
					}
				});
			});
		} else {
			if (callback) {
				callback();
			}
		}
	};

	updateRelArrayWithTag = (resource, tag) => {
		const { defaultAccountId, t } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showMessage(t('FAILED_PROCESS_NO_ASSET_ID'), messageTypes.error);
			return;
		}
		resource = addTagToRelOfResource(resource, tag);
		let assetId = mediaDetails.asset.id;
		this.removeTagFromOtherResources(resource.id, tag, () => {
			this.props.updateResourceOfMedia(defaultAccountId, assetId, resource).then((res) => {
				if (res && (!res.message || !res.Message)) {
					if (tag === imageRelTagList.PRECOVER) {
						this.props.showMessage(t('PRECOVER_UPDATED'), messageTypes.info);
						this.updatePrecoverInTracks(resource, 'add');
					} else if (tag === imageRelTagList.POSTCOVER) {
						this.props.showMessage(t('POSTCOVER_UPDATED'), messageTypes.info);
					}
					setTimeout(() => {
						this.fetchAndSetMediaDetails();
					}, 3000);
				} else {
					this.props.showMessage(t('FAILED_TO_UPDATE'), messageTypes.error);
				}
			});
		});
	};

	removeTagFromResourceAndUpdate = (resource) => {
		const { t, defaultAccountId, mediaId, coverName } = this.props;
		const { mediaDetails } = this.state;
		if (mediaDetails && !mediaDetails.asset) {
			this.props.showMessage(t('FAILED_PROCESS_NO_ASSET_ID'), messageTypes.error);
			return;
		}
		if (!resource || (resource && !resource.id)) {
			this.props.showMessage(t('RESOURCE_ID_NOT_FOUND'), messageTypes.error);
			return;
		}
		const assetId = mediaDetails.asset.id;
		if (coverName === imageRelTagList.THUMBNAIL) {
			this.props.removeResourceAsThumbnail(defaultAccountId, mediaId, resource.id).then((res) => {
				if (res) {
				} else {
					this.props.showMessage(t('FAILED_TO_PROCESS'), messageTypes.error);
				}
			});
		}
		resource = removeTagFromRelOfResource(resource, coverName);
		this.props.updateResourceOfMedia(defaultAccountId, assetId, resource).then((res) => {
			if (res) {
				this.props.showMessage(coverName + ' removed', messageTypes.info);
				if (coverName === imageRelTagList.PRECOVER) {
					this.updatePrecoverInTracks(resource, 'delete');
				}
			}
			setTimeout(() => {
				this.fetchAndSetMediaDetails();
			}, 2000);
		});
	};

	updatePrecoverInTracks = (resource, operation) => {
		const { mediaDetails } = this.state;
		let tracks = mediaDetails.tracks || [];
		if (!tracks) {
			return;
		}
		let gotTypeCover = false;
		tracks.map((track) => {
			let trackId = track.id;
			if (track && track.sequences && track.sequences[0].type === 'cover' && !gotTypeCover) {
				gotTypeCover = true;
				let bodyObj = track.sequences[0];
				let coverId = bodyObj.id;
				let cover = bodyObj;
				if (cover && !cover.resource) {
					cover['resource'] = {};
				}
				if (operation === 'add' && resource.id) {
					cover.resource.id = resource.id;
					this.updateMediaCover(trackId, coverId, cover);
				} else if (operation === 'delete') {
					cover.resource.id = '';
					this.updateMediaCover(trackId, coverId, cover);
				}
			}
		});
	};

	updateMediaCover = (trackId, coverId, cover) => {
		const { t, defaultAccountId, mediaId } = this.props;
		this.props.updateMediaCover(defaultAccountId, mediaId, trackId, coverId, cover).then((res) => {
			if (!res) {
				this.props.showMessage(t('PRE_COVER_UPDATION_IN_TRACKS_FAILED'), messageTypes.error);
			}
		});
	};

	previewImage = () => {
		const { selectedCoverResource } = this.state;
		window.open(selectedCoverResource.renditions[0].links[0].href, '_blank');
	};

	getDropZone = (selectedfile) => {
		const { t, showMessage } = this.props;
		return (
			<>
				<Dropzone
					ref="dropzone"
					multiple={false}
					accept=".jpg, .jpeg, .gif, .png, .bmp"
					parallelChunkUploads={true}
					onDrop={(acceptedFiles, fileRejections) => {
						if (fileRejections.length > 0) {
							showMessage(t('LABEL_NOT_SUPPORTED_FILES'), messageTypes.error);
							return;
						}
						this.loadTheSelectedMediaItems(acceptedFiles);
					}}
					onFileDialogCancel={this.clearTheFilesSelection}
					inputContent={(files, extra) => (extra.reject ? 'Image files only' : 'Drag Files')}
				>
					{({ getRootProps, getInputProps }) => (
						<>
							<div className="dropzoneContainer">
								<div {...getRootProps({ className: 'dropzone' })}>
									<input {...getInputProps()} />
									{selectedfile ? (
										<>
											<Button className="defaultActionBtn"> {t('REPLACE')}</Button>
										</>
									) : (
										<>
											<Button className="defaultActionBtn">
												<IconsStore
													className="closeIcon"
													iconName={IconsList.CARD_IMAGE}
													color={'#ffffff'}
												/>
												{t('CHOOSE_FILE')}
											</Button>
										</>
									)}
								</div>
							</div>
						</>
					)}
				</Dropzone>
			</>
		);
	};

	render() {
		const { selectedCoverResource, showloading, onOngoingFilePercent } = this.state;
		let fileName = '';
		if (selectedCoverResource && selectedCoverResource.renditions[0].path) {
			let path = selectedCoverResource.renditions[0].path;
			path = path.split('/');
			fileName = path[path.length - 1];
		}
		return (
			<>
				<div id="coverUploads">
					{showloading && (
						<LinearProgress
							className={'loadingProgressBar'}
							style={{
								background: 'transparent',
							}}
							variant="determinate"
							value={onOngoingFilePercent}
						/>
					)}

					{selectedCoverResource && selectedCoverResource.renditions && (
						<div className="coverImage">
							<div className="loadedImageContainer" onClick={this.previewImage}>
								<img className="loadedImage" src={selectedCoverResource.renditions[0].links[0].href} />
								{fileName ? <label> {fileName} </label> : null}
							</div>
							<div className="replaceButton">{this.getDropZone(selectedCoverResource)}</div>
							<div
								className="removeResource"
								onClick={() => this.removeTagFromResourceAndUpdate(selectedCoverResource)}
							>
								<IconsStore className="closeIcon" iconName={IconsList.CLOSE} color={'#ffffff'} />
							</div>
						</div>
					)}
					{!selectedCoverResource && !selectedCoverResource.renditions && (
						<div className="newButton">{this.getDropZone()}</div>
					)}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, notification, liveManager }) => ({
	defaultAccountId: session.defaultAccountId,
	message: notification.message,
	open: notification.showNotification,
	liveEventStatus: liveManager.liveEventStatus,
	SaveAsOnDemand: liveManager.SaveAsOnDemand,
});

const mapDispatchToProps = (dispatch) => ({
	stopMediaUploadProcess: (_) => dispatch(stopMediaUploadProcess()),
	setSaveAsOnDemandOption: (boolStatus) => dispatch(setSaveAsOnDemandOption(boolStatus)),
	showNotification: (message) => dispatch(showNotification(message)),
	removeThumbnailImage: (accId, id, resourceId) => dispatch(removeThumbnailImage(accId, id, resourceId)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	startThumbImageUploadProcess: (accId, file, assetId) =>
		dispatch(startThumbImageUploadProcess(accId, file, assetId)),
	startMediaUploadProcess: (onGoingfiles, selectedFiles) =>
		dispatch(startMediaUploadProcess(onGoingfiles, selectedFiles)),
	keepLoadResourcesAfterTheFlowStarted: (accId, assetId, status) =>
		dispatch(keepLoadResourcesAfterTheFlowStarted(accId, assetId, status)),
	updateResourceOfMedia: (accountId, assetId, resource) =>
		dispatch(updateResourceOfMedia(accountId, assetId, resource)),
	removeSubtitle: (accountId, assetId, resourceId) => dispatch(removeSubtitle(accountId, assetId, resourceId)),
	setTheThumbnailImageAsDefaultAction: (accId, mediaId, resourceId) =>
		dispatch(setTheThumbnailImageAsDefaultAction(accId, mediaId, resourceId)),
	removeResourceAsThumbnail: (accId, mediaId, resourceId) =>
		dispatch(removeResourceAsThumbnail(accId, mediaId, resourceId)),
	getAllCurrentJobs: (accountId) => dispatch(getAllCurrentJobs(accountId)),
	updateMediaCover: (accId, mediaId, trackId, coverId, cover) =>
		dispatch(updateMediaCover(accId, mediaId, trackId, coverId, cover)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	setIsElementUpdating: (isUpdating) => dispatch(setIsElementUpdating(isUpdating)),
	showAlert: (value, type) => dispatch(showAlert(value, type)),
	setUploadingProcessActive: (value) => dispatch(setUploadingProcessActive(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveCoversUpload);
