import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import image_nothumbnail from '../../../../assets/images/playlists-nothumbnail.svg';
import { mediaNames, messageTypes } from '../../../../constants/mediaConstants';
import { useFetchLibraryData } from '../../../../hooks/screens/library/useFetchLibraryData';
import { assignMediaItemToTheSelectedPlaylist } from '../../../../actions/publishActions';
import { showMessage } from '../../../../actions/globalActions';

import './AddToPlaylistDialog.scss';

interface AddToPlaylistDialogProps {
	open: boolean;
	mediasToAssign?: any[];
	onClose?: () => void;
	showCreateNewPlaylist?: () => void;
	onAssignMediaToPlaylist?: () => void;
}

export const AddToPlaylistDialog: React.FC<AddToPlaylistDialogProps> = ({
	open,
	showCreateNewPlaylist,
	onClose,
	mediasToAssign,
	onAssignMediaToPlaylist,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const [selectedPlaylist, setSelectedPlaylist] = useState('');

	const { items } = useFetchLibraryData({
		mediaType: mediaNames.playlists,
	});

	const assignMediasToPlaylist = () => {
		onClose?.();
		if (!mediasToAssign || mediasToAssign.length === 0) {
			return;
		}

		mediasToAssign.map((media: any) => {
			dispatch(assignMediaItemToTheSelectedPlaylist(defaultAccountId, selectedPlaylist, media.id)).then(
				(data: any) => {
					if (!data) {
						dispatch(showMessage(t('FAILED_ASSIGN_TO_PLAYLIST'), messageTypes.error));
						return;
					}
					dispatch(showMessage(t('LABEL_ASSIGN_TO_PLAYLIST_SUCCESS'), messageTypes.success));
					onAssignMediaToPlaylist?.();
				}
			);
		});
	};

	return (
		<Dialog
			fullWidth
			maxWidth={'sm'}
			id="addToPlaylistDialog"
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			open={open}
		>
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">{t('LABEL_ADD_TO_PLAYLIST')}</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</DialogTitle>
				<div className="dialogContentWrapper fullWidth emptyMarginTop">
					<div className="dialogBoxInternalBlock">
						<div className="labelWrapperPlaylist">
							<h4>{t('LABEL_CHOOSE_PLAYLIST')}</h4>
						</div>
						<div className="allPlaylistsContainer">
							{items.length &&
								items.map((eachPlaylist, i) => (
									<div
										key={i}
										onClick={() => setSelectedPlaylist(eachPlaylist.id)}
										className={`singleListViewContainer ${
											selectedPlaylist === eachPlaylist.id
												? 'selectedSingleListViewContainer'
												: ''
										}`}
									>
										{eachPlaylist.asset &&
										eachPlaylist.asset.resources &&
										eachPlaylist.asset.resources[0] &&
										eachPlaylist.asset.resources[0].renditions &&
										eachPlaylist.asset.resources[0].renditions[0] ? (
											<img
												src={
													eachPlaylist.asset &&
													eachPlaylist.asset.resources[0] &&
													eachPlaylist.asset.resources[0].renditions[0].links[0].href &&
													eachPlaylist.asset.resources[0].renditions[0].links[0].href
												}
											/>
										) : (
											<img src={image_nothumbnail} />
										)}
										<h6>
											{eachPlaylist.metadata &&
												eachPlaylist.metadata.title !== '' &&
												eachPlaylist.metadata.title}
										</h6>
									</div>
								))}
						</div>
					</div>
					<a onClick={showCreateNewPlaylist}>{t('LABEL_NEW_PLAYLIST_HEADER')}</a>
				</div>
			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
						<Button
							variant="contained"
							className="defaultActionBtn"
							disabled={selectedPlaylist !== '' ? false : true}
							onClick={assignMediasToPlaylist}
						>
							{t('LABEL_ADD_TO_PLAYLIST')}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};
