import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Helmet } from 'react-helmet';
import { Button } from '@material-ui/core';

import {
	clearBreadCrumbLocationArray,
	updateScreenName,
	updateBreadCrumbLocationArray,
} from '../../actions/breadCrumbActions';
import { showMessage } from '../../actions/globalActions';
import {
	sides,
	showLeftPart,
	setLastRoute,
	showRightZone,
	checkForZones,
	handleMenuChange,
	getNewWindowUrl,
	defaultVideoWidth,
	defaultVideoHeight,
	handleStayOnScreen,
	handleSaveAndRoute,
	checkForRouteParam,
	handleCancelAndRoute,
	fetchAndSetMediaDetails,
	updateStateAsMobileViewOnResize,
	isRequestFail,
} from '../../services/inspectScreenHelperService';
import {
	getMediaQueryDetails,
	loadMediasByQuery,
	loadMediasForPagination,
	sendMediaScreenChanged,
	sendMediaScreenUnChanged,
	changeRouteAfterInspectScreen,
	getRestrictionRules,
} from '../../actions/publishActions';
import { updateCurentComponentKey } from '../../actions/navActions';
import { addMediaDetails, clearInspectScreenStorage } from '../../actions/inspectScreenActions';
import { getDetails } from './../../services/mediaDisplayService';
import { NOCATALOG, mediaNames, messageTypes } from '../../constants/mediaConstants';
import { screenRoutes } from '../../constants/routesPath';
import { KEYS } from '../../layout/nav/navigationItems';
import { menuKeys, detailsMenuKeys } from './../../constants/inspectScreenMenu';
import BreadCrumb from './../../layout/header/breadCrumb';
import ScreenParentContainer from '../../reusable/ScreenParentContainer/ScreenParentContainer';
import MediaDetails from '../PublishScreen/InspectScreen/InspectSections/MediaDetails';
import MediaAddSection from '../PublishScreen/InspectScreen/InspectSections/MediaAddSection/MediaAddSection';
import InspectPlayerPreviewArea from '../PublishScreen/InspectScreen/InspectSections/InspectPlayerPreviewArea';
import { InspectScreenExitConfirmationDialog } from '../PublishScreen/InspectScreen/InspectExitConfirmationDialog';

import menu_setting from '../../assets/images/menu_settings.svg';
import { appPlacementTypes } from '../../constants/appPlacementTypes';
import pencil_square_white from '../../assets/images/pencil-square-white.svg';
import pencil_square from '../../assets/images/pencil-square.svg';
import { appsHelperService } from '../../services/appsHelperService';

import './InspectScenarioScreen.scss';
import ScenarioGraph from './utils/ScenarioGraph';
import {
	addScenesMetadata,
	getScenario,
	setOriginScenarioAction,
	setSceneLoading,
	updateScenarioAction,
	updateScenesAction,
	updateScenario,
} from '../../actions/scenarioActions';
import { joinClassNames } from '../../services/elementHelperService';

class InspectScenarioScreen extends Component {
	constructor(props) {
		super(props);

		this.mediaDetailsSection = null;
		this.defaultMenuKey = menuKeys.details;
		this.defaultDetailsMenuKey = 'scenesGraph';
		(this.listDetails = this.getViewListOfDetailsMenu()),
			(this.inspectedItemId =
				this.props.match && this.props.match.params && this.props.match.params.scenarioId
					? this.props.match.params.scenarioId
					: this.props.scenarioId);

		this.state = {
			mobileView: false,
			mediaDetails: null,
			routeAfterSave: '',
			mediaName: mediaNames.scenarios,
			currentView: this.defaultDetailsMenuKey,
			expandedItem: this.defaultMenuKey,
			mobileViewZone: sides.left,
			lastRoute: '',
			videoWidth: defaultVideoWidth,
			videoHeight: defaultVideoHeight,
			playerConfigurationId: 'default',
			accountRestrictions: '',
			shouldReinitPlayer: false,
		};

		this.mediaDetailsRef = createRef(null);
	}

	componentDidMount() {
		this.loadScenario();
		setLastRoute(this);
		checkForRouteParam(this);
		updateStateAsMobileViewOnResize(this);
		this.props.updateCurentComponentKey('INSPECT_SCENARIO_SCREEN');

		window.addEventListener('resize', () => updateStateAsMobileViewOnResize(this));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.defaultAccountId !== this.props.defaultAccountId) {
			this.props.history.push(screenRoutes.PUBLISH_MEDIA_LIBRARY);
		}
		if (prevProps.location && prevProps.location.pathname != this.props.location.pathname) {
			checkForRouteParam(this, this.props.location.pathname);
		}
		if (this.props.routeAfterSave && prevProps.routeAfterSave !== this.props.routeAfterSave) {
			this.setState({ routeAfterSave: this.props.routeAfterSave });
			let isMediaDetailsSectionModified = false;
			if (
				this.mediaDetailsSection &&
				this.mediaDetailsSection.state &&
				this.mediaDetailsSection.state.mediaDetailsModified
			) {
				isMediaDetailsSectionModified = true;
			}
			if (isMediaDetailsSectionModified) {
				this.setState({ inspectScreenExitConfirmationStatus: true });
			}
		}
		if (prevProps.playerConfigId !== this.props.playerConfigId) {
			this.setState({ playerConfigurationId: this.props.playerConfigId });
		}

		if (prevProps.playerConfigId !== this.props.playerConfigId) {
			updateStateAsMobileViewOnResize(this);
		}

		if (this.props.refreshMediasIds !== prevProps.refreshMediasIds) {
			this.loadScenario(true);
		}
	}

	componentWillUnmount() {
		const { dialogMode } = this.props;
		if (!dialogMode) {
			this.props.clearBreadCrumbLocationArray();
		}
		this.props.sendMediaScreenUnChanged();
		this.props.clearInspectScreenStorage();
		window.removeEventListener('resize', () => updateStateAsMobileViewOnResize(this));
	}

	_getAppMenu = (type) => {
		const { apps } = this.props;
		return appsHelperService.getApps(apps, type);
	};

	loadScenario = (shouldReinitPlayer) => {
		this.props.getRestrictionRules(this.props.defaultAccountId);
		this.props.getScenario(this.props.defaultAccountId, this.inspectedItemId).then((scenario) => {
			if (!scenario) {
				this.props.showMessage("Can't get the scenario", messageTypes.error);
				return;
			}
			this.props.updateScenarioAction(scenario);
			this.props.updateScenesAction(scenario.scenes ?? []);
			this.props.setOriginScenarioAction(scenario);
			fetchAndSetMediaDetails(this.inspectedItemId, this);
			this.getSceneWithMetadata(scenario, scenario.scenes, shouldReinitPlayer);
		});
	};

	getSceneWithMetadata = (updatedScenario, scenes, shouldReinitPlayer) => {
		if (!scenes || scenes.length === 0) {
			return;
		}

		const sceneWithBG = new Set();
		const sceneIds = new Set();
		scenes.forEach((scene) => {
			const widgetTemplates = scene.widgetTemplates ?? [];
			if (widgetTemplates.length === 0) {
				return;
			}

			const currentLayout =
				(scene?.layouts ?? []).find((l) => l.type === (updatedScenario?.defaults?.type ?? 'mobile')) ?? {};
			// backGroundBox: SHOULD BE THIS ONE WHEN OFFICIAL
			// const backGroundBox = (((updatedScenario?.layoutTemplates ?? []).find(l => l.id === currentLayout?.layoutTemplateId) ?? {})
			// .boxes ?? []).find(b => b.isBackground) ?? {};
			const backGroundBox =
				(((updatedScenario?.layoutTemplates ?? [])[0] ?? {}).boxes ?? []).find((b) => b.isBackground) ?? {};
			const backgroundWidgets =
				(currentLayout?.boxes ?? []).find((box) => box.boxTemplateId === backGroundBox?.id)?.widgets ?? [];

			widgetTemplates.forEach((template) => {
				if (backgroundWidgets.find((bg) => bg.widgetTemplateId === template.id)) {
					if (template.type === 'video') {
						sceneIds.add(template?.settings?.mediaId);
						sceneWithBG.add({ sceneId: scene.id, mediaId: template?.settings?.mediaId });
					}
				}
			});
		});
		let idString = [];
		for (const id of sceneIds) {
			idString.push('"' + id + '"');
		}

		const sceneWithBGFinal = new Set();
		this.props
			.loadMediasByQuery(
				this.props.defaultAccountId,
				mediaNames.medias,
				'?q=id:(' + encodeURIComponent(idString.join(',')) + ')'
			)
			.then((data) => {
				if (!data || data.length === 0) {
					return;
				}

				for (const item of sceneWithBG) {
					if (item.mediaId) {
						const media = data.find((d) => d.id === item.mediaId);
						const mediaDetails = getDetails(media);
						sceneWithBGFinal.add({ sceneId: item.sceneId, mediaId: item.mediaId, metadata: mediaDetails });
					} else {
						sceneWithBGFinal.add(item);
					}
				}
				this.setState(
					(prevState) => {
						if (prevState.shouldReinitPlayer !== shouldReinitPlayer) {
							return { ...prevState, shouldReinitPlayer: shouldReinitPlayer };
						}
						return null;
					},
					() => {
						setTimeout(this.setState({ shouldReinitPlayer: false }), 3000);
					}
				);
				this.props.addScenesMetadata(Array.from(sceneWithBGFinal));
			});
	};

	getViewListOfDetailsMenu = (_) => {
		const { t } = this.props;
		return [
			{
				key: 'details',
				order: 0,
				icon: menu_setting,
				param: {},
				name: 'Details',
				children: [
					{
						id: detailsMenuKeys.scenes,
						text: 'Scenes',
					},
					{
						id: detailsMenuKeys.mediaDetails,
						text: t('GENERAL_DETAILS'),
					},
				],
			},
		];
	};

	updateScreenTitle = (title) => {
		const { dialogMode } = this.props;
		if (!dialogMode) {
			this.props.updateScreenName(title);
		}
	};

	updateBreadCrumb = (mediaDetails) => {
		const { dialogMode } = this.props;
		const listDetails = this.listDetails;
		if (dialogMode) {
			return;
		}
		let appMenu = this._getAppMenu(appPlacementTypes.LIBRARY_MEDIAS_MEDIA_DETAIL, 0);
		let listDetailsConcat = listDetails.concat(appMenu);

		const appNameFormatting = (appName) => {
			listDetailsConcat.map((item) =>
				item.key == appName
					? (appName = item.name)
					: item.key == 'apps_' + appName
					? (appName = item.text)
					: appName
			);
			return appName;
		};

		this.props.clearBreadCrumbLocationArray();
		let mediaHref = screenRoutes.PUBLISH_MEDIA_LIBRARY;
		let breadCrumbLocationArray = [];
		if (mediaDetails) {
			breadCrumbLocationArray.push({ href: mediaHref + '/scenario/details', text: mediaDetails.title });
			if (
				this.props.match.params.menuKey &&
				(typeof this.props.match.params.subMenuKey === 'undefined' ||
					this.props.match.params.subMenuKey.length < 2)
			) {
				breadCrumbLocationArray.push({
					href: mediaHref + '/scenario/details' + this.props.match.params.menuKey,
					text: appNameFormatting(this.props.match.params.menuKey),
				});
			}
			this.props.updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray);
			if (mediaDetails.parentId) {
				let customhref = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/catalogs/';
				this.getDetailsForBreadCrumb(mediaDetails.parentId, customhref, breadCrumbLocationArray);
			}
		}
	};

	reloadBreadCrumb = () => {
		const { mediaName, mediaDetails } = this.state;
		const { defaultAccountId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaName).then((media) => {
			if (isRequestFail(this, media)) {
				this.showMessage(t('FAILED_FETCH_MEDIA_DETAILS'), messageTypes.error);
				return;
			}
			let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
			this.setState({ mediaDetails: customMediaInfo });
			this.updateBreadCrumb(customMediaInfo);
		});
	};

	getDetailsForBreadCrumb = (itemId, customhref, breadCrumbLocationArray) => {
		if (itemId === NOCATALOG) {
			return;
		}

		this.getCatalogDetails(itemId, (mediaDetails) => {
			if (typeof mediaDetails == 'string') {
				this.showMessage(mediaDetails + 'for breadcrumb', messageTypes.error);
				return;
			}
			breadCrumbLocationArray.unshift({ href: customhref + itemId, text: mediaDetails.title });
			this.props.updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray);
			if (mediaDetails && mediaDetails.parentId) {
				this.getDetailsForBreadCrumb(mediaDetails.parentId, customhref, breadCrumbLocationArray);
			}
		});
	};

	getCatalogDetails = (itemId, callback) => {
		const { defaultAccountId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, itemId, mediaNames.catalogs).then((media) => {
			if (isRequestFail(this, media)) {
				this.showMessage(t('FAILED_FETCH_CATALOG_DETAILS_FOR_BREADCRUMB'), messageTypes.error);
				return;
			} else {
				callback(getDetails(media));
			}
		});
	};

	showMessage = (message, type) => {
		this.props.showMessage(message, type);
	};

	onMediaDetailsSectionLoaded = (mediaDetailsSection) => {
		const { mobileView } = this.state;
		this.mediaDetailsSection = mediaDetailsSection;
		checkForZones(this);
		if (mobileView) {
			this.setState({ currentView: '' });
		}
	};

	showInspectScrenExitConfirmationStatus = (_) => {
		this.setState({ inspectScreenExitConfirmationStatus: true });
	};

	hideInspectScrenExitConfirmationStatus = (_) => this.setState({ inspectScreenExitConfirmationStatus: false });

	renderExitConfirmationDialog = (_) => {
		const { inspectScreenExitConfirmationStatus, routeAfterSave } = this.state;
		const { t } = this.props;

		let isMediaDetailsSectionModified = false;
		if (
			this.mediaDetailsSection &&
			this.mediaDetailsSection.state &&
			this.mediaDetailsSection.state.mediaDetailsModified
		) {
			isMediaDetailsSectionModified = true;
		}
		if (
			inspectScreenExitConfirmationStatus &&
			isMediaDetailsSectionModified &&
			routeAfterSave !== this.props.location.pathname
		) {
			return (
				<InspectScreenExitConfirmationDialog
					t={t}
					handleSaveAndRoute={this.handleSaveAndRoute}
					inspectScrenExitConfirmationStatus={inspectScreenExitConfirmationStatus}
					handleCancelAndRoute={this.handleCancelAndRoute}
					handleStayOnScreen={this.handleStayOnScreen}
				/>
			);
		} else {
			return null;
		}
	};

	handleSave = () => {
		const { updateScenario, showMessage, defaultAccountId, t } = this.props;
		const { stateData, tags } = this.mediaDetailsRef.current;
		const { inputNameValue, inputDescriptionValue, custom } = stateData;

		showMessage(t('WAIT_TO_SAVE_CHANGE'), messageTypes.info);

		if (this.mediaDetailsSection && this.mediaDetailsSection.state.mediaDetailsModified) {
			this.mediaDetailsSection.setState({ mediaDetailsModified: false });
		}

		const scenarioBody = {
			metadata: {
				title: inputNameValue,
				description: inputDescriptionValue,
			},
			tags: tags,
			custom,
		};

		updateScenario(defaultAccountId, this.inspectedItemId, scenarioBody).then((resolve) => {
			if (isRequestFail(this.props, resolve)) {
				showMessage(t('FAILED_TO_SAVE_MEDIA_DETAILS'), messageTypes.error);
				return;
			}
			showMessage(t('SAVED_DETAILS_SUCCESSFULLY'), messageTypes.info);
		});
		this.hideBottomActionButtons();
	};

	handleCancel = (_) => {
		if (this.mediaDetailsSection) {
			this.mediaDetailsSection.handleCancelForMediaDetails();
		}
		this.hideBottomActionButtons();
	};

	handleSaveAndRoute = (_) => {
		handleSaveAndRoute(this);
	};

	handleCancelAndRoute = (_) => {
		handleCancelAndRoute(this);
	};

	handleStayOnScreen = (_) => handleStayOnScreen(this);

	onDetailsChange = (_) => {
		this.displayBottomActionButtons();
	};

	displayBottomActionButtons = (_) => {
		const { isInspectScreenModified } = this.props;
		if (!isInspectScreenModified) {
			this.props.sendMediaScreenChanged();
		}
	};

	hideBottomActionButtons = (_) => {
		const { isInspectScreenModified } = this.props;
		if (isInspectScreenModified) {
			this.props.sendMediaScreenUnChanged();
		}
	};

	renderGeneralDetails = (_) => {
		const { t, history } = this.props;
		const { mediaDetails, mediaName, lastRoute } = this.state;

		return (
			<MediaDetails
				t={t}
				mediaName={mediaName}
				mediaDetails={mediaDetails}
				handleSave={this.handleSave}
				showMessage={this.showMessage}
				handleCancel={this.handleCancel}
				onDetailsChange={this.onDetailsChange}
				updateScreenTitle={this.updateScreenTitle}
				onMediaDetailsSectionLoaded={this.onMediaDetailsSectionLoaded}
				history={history}
				lastRoute={lastRoute}
				mediaDetailsRef={this.mediaDetailsRef}
			/>
		);
	};

	renderNewLivePlayer = (_) => {
		const { videoWidth, videoHeight, playerConfigurationId, shouldReinitPlayer } = this.state;
		const { scenario } = this.props;

		return (
			<InspectPlayerPreviewArea
				playerConfigurationId={playerConfigurationId}
				videoHeight={videoHeight}
				videoWidth={videoWidth}
				mediaDetails={{ ...scenario }}
				placementType={'scenarios'}
				scenario
				reinitPlayer={shouldReinitPlayer}
			/>
		);
	};

	showMobileBackNav = (text, icon) => {
		const { mobileView } = this.state;
		if (mobileView) {
			return (
				<div
					className="backToList"
					onClick={() => {
						showLeftPart();
						this.setState({ mobileViewZone: sides.left, currentView: '' });
					}}
				>
					<label>{text} </label>
					{icon}
					{this.getLeftArrow()}
				</div>
			);
		} else {
			return null;
		}
	};

	getMediaAddSection = () => {
		const { mediaDetails, mobileView } = this.state;
		return (
			<MediaAddSection
				mediaDetails={mediaDetails}
				mobileView={mobileView}
				showMessage={this.showMessage}
				reloadBreadCrumb={this.reloadBreadCrumb}
			/>
		);
	};

	openSceneEditScreen = () => {
		this.props.setSceneLoading(true);
		this.props.history.push(`${screenRoutes.PUBLISH_SCENARIOS}/${this.props.scenario.id}/chart`);
	};

	renderScenesAction = () => {
		return (
			<div>
				<Button variant="contained" className={`defaultActionBtn squireBtn`} onClick={this.openSceneEditScreen}>
					<img src={pencil_square_white} />
					Edit video
				</Button>
			</div>
		);
	};

	renderAllDetails = (_) => {
		const { currentView, mobileView, mediaDetails } = this.state;
		const { t } = this.props;
		return (
			<>
				<div id="middleZone">
					<div
						id={detailsMenuKeys.mediaDetails}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.mediaDetails ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('GENERAL_DETAILS'))}
						{!mobileView ? (
							<>
								<h3>
									{t('GENERAL_DETAILS')} {this.getMediaAddSection()}{' '}
								</h3>
							</>
						) : null}
						{mobileView ? (
							<>
								<div className="mediaAddSection">
									<h4>
										{' '}
										{t('MEDIA_DETAILS')} {this.getMediaAddSection()}{' '}
									</h4>
								</div>
							</>
						) : null}
						{this.renderGeneralDetails()}
					</div>

					<div
						id={detailsMenuKeys.scenes}
						className={joinClassNames(
							'inspectViewMenuListItems',
							mobileView ? 'inspectViewMenuListItems__mobile' : ''
						)}
						style={{ display: currentView === detailsMenuKeys.scenes ? 'block' : 'none' }}
					>
						{this.showMobileBackNav('Scenes', () => (
							<img src={pencil_square} />
						))}

						{!mobileView && <h3>{mediaDetails.title}</h3>}
						{mobileView && (
							<div className="mediaAddSection">
								<h4>
									Scenario <img src={pencil_square} />
								</h4>
							</div>
						)}

						<ScenarioGraph t={t} summaryScenes actions={this.renderScenesAction()} flowInstanceRef={{}} />
					</div>
				</div>
			</>
		);
	};

	getLeftArrow = (_) => {
		return (
			<svg
				viewBox="0 0 21.86 15.51"
				onClick={() => {
					showLeftPart();
					this.setState({
						mobileViewZone: sides.left,
						currentView: '',
					});
				}}
			>
				<defs />
				<title>Arrow left</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M20.36,6.25H5L8.56,2.54A1.5,1.5,0,0,0,8.51.42a1.48,1.48,0,0,0-1-.42A1.54,1.54,0,0,0,6.39.46l-6,6.26a1.49,1.49,0,0,0,0,2.07l6,6.25A1.5,1.5,0,1,0,8.56,13L5,9.25H20.36a1.5,1.5,0,0,0,0-3Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	onMainNavClick = (evt, item, selected) => {
		if (!selected) {
			evt.preventDefault();
			handleMenuChange(this, item.key, item.param);
		}
	};

	onSubNavClick = (evt, item, param, selected) => {
		if (!selected) {
			evt.preventDefault();
			showRightZone(item, this);
		}
	};

	isChildSelected = (currentView, childArray) => {
		let isSubMenu = false;
		for (let index = 0; index < childArray.length; ++index) {
			let subMenu = childArray[index];
			if (subMenu.id === currentView) {
				isSubMenu = true;
				break;
			}
		}
		return isSubMenu;
	};

	renderItemNameCase = (item) => {
		const { currentView, expandedItem } = this.state;

		return (
			<div className="mediaMenuRow">
				<ListItem
					key={item.key}
					selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
					classes={{
						root: 'listItem',
						selected: 'selectedMainMenu',
					}}
					onClick={
						item.children.length > 0
							? (e) => {
									this.onSubNavClick(
										e,
										item.children[0],
										item.children[0].param,
										currentView === item.children[0].key
									);
							  }
							: (e) => this.onMainNavClick(e, item, currentView === item.key)
					}
				>
					{item.name ? <ListItemText primary={item.name} /> : <ListItemText primary={item.text} />}
				</ListItem>
				<div
					className={
						currentView === item.key || this.isChildSelected(currentView, item.children)
							? 'mediaIconWrapper selectedMainMenu'
							: 'mediaIconWrapper'
					}
					onClick={
						item.children.length > 0
							? () => {
									this.onExpandSubMenu(item.key);
							  }
							: () => this.openInNewWindow(item)
					}
				>
					{item.children.length > 0 ? (
						expandedItem === item.key || expandedItem === menuKeys.details ? (
							<div className={'mainNavIcon'}>
								<ExpandMore />
							</div>
						) : (
							<ChevronRight />
						)
					) : (
						<OpenInNewIcon />
					)}
				</div>
				<div style={{ clear: 'both' }}></div>
			</div>
		);
	};

	onExpandSubMenu = (expand_id) => {
		if (this.state.expandedItem === expand_id) {
			this.setState({
				expandedItem: 0,
			});
		} else {
			this.setState({
				expandedItem: expand_id,
			});
		}
	};

	openInNewWindow = (item) => {
		let deviceWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
		//let windowWidth = deviceWidth - 400;
		let windowWidth = 1300;
		let windowHeight = 600;
		let leftMargin = (deviceWidth - windowWidth) / 2;
		let params =
			'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=' +
			windowWidth +
			',height=' +
			windowHeight +
			',left=' +
			leftMargin +
			',top=100,rel=noreferrer';
		let newPath = getNewWindowUrl(this, item.key, item.param);
		let protocol = window.location.protocol;
		let domain = window.location.hostname;
		let port = window.location.port;
		let baseUrl = protocol + '//' + domain + (port ? ':' + port : '') + '/#';
		let completeUrl = baseUrl + newPath + '?selectedMenu=' + item.key;
		window.open(completeUrl, '_blank', params);
	};

	renderMediaMenu() {
		const { mediaName } = this.state;
		return (
			<div className="mediaMenuWrapper">
				{mediaName === mediaNames.scenarios &&
					this.listDetails.map((item) => (
						<>
							{this.renderItemNameCase(item)}
							{this.listNestedItems(item)}
						</>
					))}
			</div>
		);
	}

	listNestedItems(listItem) {
		const { open } = this.props;
		const { currentView, expandedItem } = this.state;
		if ((expandedItem === listItem.key || expandedItem === menuKeys.details) && listItem.children.length > 0) {
			return (
				<div in={open} timeout="auto" unmountOnExit className="mediaSubMenuWrapper" id={'sub' + listItem.id}>
					{listItem.children.map((item, index) => {
						return (
							<List key={index} component="div" className="mediaSubMenuElement" disablePadding>
								<ListItem
									button
									key={item.id + index}
									selected={currentView === item.id}
									classes={{ root: 'subMenuDiv', selected: 'selectedSubMenu' }}
									onClick={(e) => {
										this.onSubNavClick(e, item, item.param, currentView === item.id);
									}}
								>
									<ListItemText primary={item.text} />
								</ListItem>
							</List>
						);
					})}
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	render() {
		const { history } = this.props;
		const { mediaDetails } = this.state;

		return (
			<>
				<Helmet>
					<title>{'Inspect - ' + mediaDetails?.title}</title>
				</Helmet>
				<BreadCrumb history={history} hasBorder />
				<ScreenParentContainer>
					<div id="inspectView" className="inspectView__scenes-project">
						{mediaDetails && (
							<>
								<div id="breadCrumbMobileView">
									<BreadCrumb history={history} isMobile={true} />
								</div>
								<div className={'mediaFullDetails'}>
									<div id="rightZone">{this.renderNewLivePlayer()}</div>
									<div id="leftZone">{this.renderMediaMenu()}</div>
									{this.renderAllDetails()}
								</div>
								{this.renderExitConfirmationDialog()}
							</>
						)}
					</div>
				</ScreenParentContainer>
			</>
		);
	}
}

InspectScenarioScreen.propTypes = {
	dialogMode: PropTypes.bool,
};

const mapStateToProps = ({ apps, session, publish, navReducer, inspectScreenReducer, scenarioReducer }) => ({
	apps: apps.apps,
	open: navReducer.open,
	routeAfterSave: publish.routeAfterSave,
	defaultAccountId: session.defaultAccountId,
	isInspectScreenModified: publish.isInspectScreenModified,
	playerConfigId: inspectScreenReducer.playerConfigId,
	scenario: scenarioReducer.scenario,
	scenes: scenarioReducer.scenes,
	refreshMediasIds: publish.refreshMediasIds,
});

const mapDispatchToProps = (dispatch) => ({
	loadMediasForPagination: (accountId, mediaSet, offset, mediaName, sortType) =>
		dispatch(loadMediasForPagination(accountId, mediaSet, offset, mediaName, sortType)),
	updateScreenName: (name) => dispatch(updateScreenName(name)),
	sendMediaScreenChanged: (_) => dispatch(sendMediaScreenChanged()),
	sendMediaScreenUnChanged: (_) => dispatch(sendMediaScreenUnChanged()),
	clearBreadCrumbLocationArray: (_) => dispatch(clearBreadCrumbLocationArray()),
	updateBreadCrumbLocationArray: (key, location) => dispatch(updateBreadCrumbLocationArray(key, location)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	clearInspectScreenStorage: () => dispatch(clearInspectScreenStorage()),
	updateCurentComponentKey: (key) => dispatch(updateCurentComponentKey(key)),
	addMediaDetails: (details) => dispatch(addMediaDetails(details)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	updateScenarioAction: (scenario) => dispatch(updateScenarioAction(scenario)),
	updateScenesAction: (scenes) => dispatch(updateScenesAction(scenes)),
	getScenario: (accountId, scenarioId) => dispatch(getScenario(accountId, scenarioId)),
	loadMediasByQuery: (accountId, mediaName, query) => dispatch(loadMediasByQuery(accountId, mediaName, query)),
	setSceneLoading: (loading) => dispatch(setSceneLoading(loading)),
	addScenesMetadata: (sceneMetadata) => dispatch(addScenesMetadata(sceneMetadata)),
	setOriginScenarioAction: (scenario) => dispatch(setOriginScenarioAction(scenario)),
	updateScenario: (accountId, scenarioId, body) => dispatch(updateScenario(accountId, scenarioId, body)),
	changeRouteAfterInspectScreen: (route) => dispatch(changeRouteAfterInspectScreen(route)),
	getRestrictionRules: (accountId) => dispatch(getRestrictionRules(accountId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(InspectScenarioScreen);
