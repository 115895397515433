import { useDispatch, useSelector } from 'react-redux';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import { showMessage } from '../../../actions/globalActions';
import { useTranslation } from 'react-i18next';
import { mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { getDetails } from '../../../services/mediaDisplayService';
import { useCallback, useEffect, useState } from 'react';
import { getAccountSettings } from '../../../actions/profileActions';

interface FetchMediaProps {
	mediaId: string;
	mediaName?: string;
	refreshData: boolean;
	resetRefreshData?: () => void;
}
interface FetchMediaResult {
	mediaDetails: any | undefined;
	embedVersion: string;
}

const useFetchMediaDetails = ({
	mediaId,
	mediaName,
	refreshData,
	resetRefreshData,
}: FetchMediaProps): FetchMediaResult => {
	const dispatch = useDispatch<any>();
	const { t } = useTranslation();

	const { defaultAccountId } = useSelector((state: any) => state.session);

	const [mediaDetails, setMediaDetails] = useState<any | undefined>(undefined);
	const [embedVersion, setEmbedVersion] = useState('1');

	const fetchMedia = useCallback(() => {
		dispatch(getMediaQueryDetails(defaultAccountId, mediaId, mediaName)).then((media: any) => {
			if (!media) {
				dispatch(showMessage(t('MEDIA_NOT_FOUND'), messageTypes.error));
				return;
			}
			if (typeof media == 'string') {
				dispatch(showMessage(media, messageTypes.error));
				return;
			}

			const mediaInfo = getDetails(media, mediaName === mediaNames.playlists);
			setMediaDetails(mediaInfo);
		});
	}, [defaultAccountId, dispatch, mediaId, mediaName, t]);

	const loadEmbedSetting = useCallback(() => {
		dispatch(getAccountSettings(defaultAccountId)).then((settings: any) => {
			if (!(settings && Object.keys(settings).length > 0)) {
				return;
			}

			let embed = { version: '1' };
			if (
				settings.hasOwnProperty('qbrickPlayer') &&
				settings['qbrickPlayer'] &&
				settings['qbrickPlayer'].length > 0
			) {
				embed = settings['qbrickPlayer'].find((v: any) => !!v.enabled);
			}

			setEmbedVersion((prevEmbedVersion) =>
				embed && prevEmbedVersion !== embed.version ? embed.version : prevEmbedVersion
			);
		});
	}, [defaultAccountId, dispatch]);

	useEffect(() => {
		if (!refreshData) {
			return;
		}
		fetchMedia();
		loadEmbedSetting();
		resetRefreshData?.();
	}, [fetchMedia, loadEmbedSetting, refreshData, resetRefreshData]);

	return { mediaDetails, embedVersion };
};

export default useFetchMediaDetails;
