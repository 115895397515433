import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Box } from '@material-ui/core';
import StockMenu from './treeMenu/StockMenu';

import './TabContent.scss';
import Pagination from '../../Pagination/Pagination';
import MediaItem from './mediaItem/MediaItem';
import { useTranslation } from 'react-i18next';

interface StockLibraryProps {
	provider: string;
	mediaType: string;
	medias: { id: string; thumbnail: string; description: string }[];
	totalCount: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (_e: object, _page: number) => void;
	onRowPerPageChange: (_e: any) => void;
	onProviderChange: (_e: any) => void;
	setAllowToSubmit: (_e: boolean) => void;
}

const StockLibrary = forwardRef(
	(
		{
			provider,
			mediaType,
			medias,
			totalCount,
			page,
			rowsPerPage,
			onPageChange,
			onRowPerPageChange,
			onProviderChange,
			setAllowToSubmit,
		}: StockLibraryProps,
		ref
	) => {
		const { t: translator } = useTranslation();
		const [selectedMedia, setSelectedMedia] = useState<any>();

		const submitSelectedMedia = useCallback(() => {
			return [selectedMedia];
		}, [selectedMedia]);

		useImperativeHandle(ref, () => {
			return { submitSelectedMedia };
		});

		return (
			<Box className="media-library-modal-wrapper">
				<Box className="structure-navigator">
					<StockMenu mediaType={mediaType} provider={provider} onProviderChange={onProviderChange} />
				</Box>
				<Box className="content-wrapper">
					<Box className="media-list">
						{medias.map((media: any) => {
							return (
								<MediaItem
									currentMediaType={mediaType}
									translator={translator}
									key={media.id}
									id={media.id}
									media={media}
									selected={selectedMedia?.id === media.id}
									onMediaSelectionChange={(media) => {
										setSelectedMedia(media);
										setAllowToSubmit(true);
									}}
								/>
							);
						})}
					</Box>
					<Pagination
						count={totalCount}
						page={page}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage={'Medias per page:'}
						onPageChange={onPageChange}
						onRowsPerPageChange={onRowPerPageChange}
					/>
				</Box>
			</Box>
		);
	}
);

export default StockLibrary;
