import React, { useState } from 'react';

import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import StarIcon from '@material-ui/icons/Star';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';

import './WidgetTemplateItem.scss';
import { CropLandscape, Image } from '@material-ui/icons';
import { WIDGET_TYPES } from '../../../../../../../constants/scenarioConstant';

interface WidgetTemplateItemProps {
	name: string;
	widgetTemplates: any[];
	duplicateWidget: (_widget: object, _templateToCopy?: object) => {};
}

interface TemplateProps {
	id: string;
	name: string;
	type: string;
}

const getWidgetIcon = (type: string) => {
	switch (type) {
		case WIDGET_TYPES.WIDGET_TYPE_BUTTON:
			return <CropLandscape />;
		case WIDGET_TYPES.WIDGET_TYPE_IMAGE:
			return <Image />;
		case WIDGET_TYPES.WIDGET_TYPE_ICON:
			return <StarIcon />;
		case WIDGET_TYPES.WIDGET_TYPE_TEXT:
			return <FormatShapesIcon />;
		default:
			return <CropLandscape />;
	}
};

const WidgetTemplateItem = ({ name, widgetTemplates, duplicateWidget, ...rest}: WidgetTemplateItemProps) => {
	const [isExpanded, setIsExpanded] = useState(true);

	return (
		<div className="template-group-wrapper" {...rest}>
			<div className="template-group-header">
				<IconButton
					onClick={() => {
						setIsExpanded(!isExpanded);
					}}
					size="small"
					disableRipple
					disableFocusRipple
				>
					{isExpanded ? <ExpandMoreIcon /> : <KeyboardArrowUpIcon />}
				</IconButton>
				<span className="group-title">
					<b>{`Scene: "${name}"`}</b>
				</span>
			</div>
			{isExpanded &&
				widgetTemplates.map((template: TemplateProps) => (
					<div key={template.id} className="template-item">
						<div className="item-title">
							{getWidgetIcon(template.type)}
							<span>{template.name}</span>
						</div>
						<div className="item-action">
							<IconButton
								size="small"
								disableRipple
								disableFocusRipple
								onClick={() => {
									duplicateWidget({ widgetTemplateId: template.id }, template);
								}}
							>
								<FileCopyIcon />
							</IconButton>
						</div>
					</div>
				))}
		</div>
	);
};

export default WidgetTemplateItem;
