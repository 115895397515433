import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { NOCATALOG, mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { screenRoutes } from '../../../constants/routesPath';
import { KEYS } from '../../../layout/nav/navigationItems';
import MediaDetails from './InspectSections/MediaDetails';
import AccessRestriction from './InspectSections/AccessRestriction/AccessRestriction';
import InformationSection from './InspectSections/InformationSection/InformationSection';

import { showNotification } from '../../../actions/notificationActions';
import {
	clearBreadCrumbLocationArray,
	updateScreenName,
	updateBreadCrumbLocationArray,
} from '../../../actions/breadCrumbActions';
import {
	getMediaQueryDetails,
	stopMediaUploadProcess,
	saveInspectScreenDetails,
	createNewRestrictionRule,
	applyRestrictionRuleOnMedia,
	deleteRestrictionRuleOnMedia,
	updateExistingRestrictionRule,
	deleteRestrictionRule,
	sendMediaScreenChanged,
	sendMediaScreenUnChanged,
	changeRouteAfterInspectScreen,
} from '../../../actions/publishActions';
import { clearInspectScreenStorage, updateMenuKeys, addMediaDetails } from '../../../actions/inspectScreenActions';

import { getDetails } from './../../../services/mediaDisplayService';
import {
	sides,
	showLeftPart,
	showRightZone,
	updateStateAsMobileViewOnResize,
	fetchAndSetMediaDetails,
	handleSaveAndRoute,
	handleCancelAndRoute,
	handleStayOnScreen,
	setAccessDetails,
	checkForRouteParam,
	handleMenuChange,
	checkForZones,
	setLastRoute,
	isRequestFail,
} from './../../../services/inspectScreenHelperService';
import { showMessage } from '../../../actions/globalActions';
import { menuKeys, detailsMenuKeys } from './../../../constants/inspectScreenMenu';
import BreadCrumb from './../../../layout/header/breadCrumb';
import './InspectScreen.scss';
import { InspectScreenExitConfirmationDialog } from './InspectExitConfirmationDialog';
import MediaAddSection from './InspectSections/MediaAddSection/MediaAddSection';
import ScreenParentContainer from '../../../reusable/ScreenParentContainer/ScreenParentContainer';
import menu_setting from '../../../assets/images/menu_settings.svg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

class InspectCatalogScreen extends Component {
	constructor(props) {
		super(props);
		this.interval = null;
		this.mediaDetailsSection = null;
		this.accessDetailsSection = null;
		this.inspectedItemId = this.props.match.params.catalogId;
		this.defaultMenuKey = menuKeys.details;
		this.defaultDetailsMenuKey = detailsMenuKeys.mediaDetails;
		this.state = {
			mediaDetails: null,
			selectedMenu: this.defaultMenuKey,
			mobileView: false,
			listDetails: this.getViewListOfDetailsMenu(),
			mediaName: mediaNames.catalogs,
			isSaveInProgress: false,
			inspectScrenExitConfirmationStatus: false,
			routeAfterSave: '',
			currentView: this.defaultDetailsMenuKey,
			expandadItem: this.defaultMenuKey,
			mobileViewZone: sides.left,
			lastRoute: '',
		};
	}

	getViewListOfDetailsMenu = (_) => {
		const { t } = this.props;
		const listDetails = [
			/*{
            id: detailsMenuKeys.mediaDetails,
            text: t('CATALOG_DETAILS')
            },
            {
                id: detailsMenuKeys.accessRestrictions,
                text: t('ACCESS_RESTRICTIONS')
            }*/
			{
				key: 'details',
				order: 0,
				icon: menu_setting,
				param: {},
				name: 'Details',
				children: [
					{
						id: detailsMenuKeys.mediaDetails,
						text: t('CATALOG_DETAILS'),
					},
					{
						id: detailsMenuKeys.accessRestrictions,
						text: t('ACCESS_RESTRICTIONS'),
					},
				],
			},
		];
		return listDetails;
	};

	UNSAFE_componentWillMount() {
		checkForRouteParam(this);
		clearInterval(this.interval);
		fetchAndSetMediaDetails(this.inspectedItemId, this); //don't set predefined data's
		window.addEventListener('resize', () => updateStateAsMobileViewOnResize(this));
		setLastRoute(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.defaultAccountId !== this.props.defaultAccountId) {
			this.props.history.push(screenRoutes.PUBLISH_MEDIA_LIBRARY);
		}
		if (nextProps.location.pathname != this.props.location.pathname) {
			checkForRouteParam(this, nextProps.location.pathname);
		}
		if (nextProps.routeAfterSave && nextProps.routeAfterSave !== this.props.routeAfterSave) {
			this.setState({
				routeAfterSave: nextProps.routeAfterSave,
				inspectScrenExitConfirmationStatus: true,
			});
		}
	}

	componentWillUnmount() {
		this.props.sendMediaScreenUnChanged();
		this.props.clearBreadCrumbLocationArray();
		this.props.clearInspectScreenStorage();
		window.removeEventListener('resize', () => updateStateAsMobileViewOnResize(this));
	}

	componentDidMount() {
		updateStateAsMobileViewOnResize(this);
	}

	updateScreenTitle = (title) => {
		this.props.updateScreenName(title);
	};

	updateBreadCrumb = (mediaDetails) => {
		this.props.clearBreadCrumbLocationArray();
		let customhref = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/catalogs/';
		let breadCrumbLocationArray = [];
		if (mediaDetails) {
			this.props.updateScreenName(mediaDetails.title);
			breadCrumbLocationArray.push({
				href: customhref + mediaDetails.id + '/' + 'detail',
				text: mediaDetails.title,
				level: 1001,
			});
			this.props.updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray);
			if (mediaDetails.parentId) {
				this.getDetailsForBreadCrumb(mediaDetails.parentId, customhref, breadCrumbLocationArray, 1000);
			}
		}
	};

	reloadBreadCrumb = () => {
		const { mediaName, mediaDetails } = this.state;
		const { defaultAccountId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaName).then((media) => {
			if (isRequestFail(this, media)) {
				this.showMessage(t('FAILED_FETCH_MEDIA_DETAILS'), messageTypes.error);
				return;
			}
			let customMediaInfo = getDetails(media, mediaName === mediaNames.playlists);
			this.setState({ mediaDetails: customMediaInfo });
			this.updateBreadCrumb(customMediaInfo);
		});
	};

	getDetailsForBreadCrumb = (itemId, customhref, breadCrumbLocationArray, level) => {
		if (itemId === NOCATALOG) {
			return;
		}

		this.getCatalogDetails(itemId, (mediaDetails) => {
			if (typeof mediaDetails == 'string') {
				this.showMessage(mediaDetails + 'for breadcrumb', messageTypes.error);
				return;
			}
			breadCrumbLocationArray.unshift({ href: customhref + itemId, text: mediaDetails.title, level: level });
			this.props.updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray);
			if (mediaDetails && mediaDetails.parentId) {
				this.getDetailsForBreadCrumb(mediaDetails.parentId, customhref, breadCrumbLocationArray, level - 1);
			}
		});
	};

	getCatalogDetails = (itemId, callback) => {
		const { defaultAccountId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, itemId, mediaNames.catalogs).then((media) => {
			if (isRequestFail(this, media)) {
				this.showMessage(t('FAILED_FETCH_CATALOG_DETAILS_FOR_BREADCRUMB'), messageTypes.error);
				return;
			} else {
				callback(getDetails(media));
			}
		});
	};

	loadTheSelectedMediaItems = (files) => this.setState({ selectedMediaItems: files });

	redirectToLocation = (path) => this.props.history.push(`/${path}`);

	showMessage = (message, type) => {
		this.props.showMessage(message, type);
	};

	onMediaDetailsSectionLoaded = (mediaDetailsSection) => {
		const { mobileView } = this.state;
		this.mediaDetailsSection = mediaDetailsSection;
		checkForZones(this);
		if (mobileView) {
			this.setState({
				currentView: '',
			});
		}
	};

	onAccessComponentLoaded = (accessDetailsSection) => {
		this.accessDetailsSection = accessDetailsSection;
		this.onCheckForTabViews();
	};

	setAccessDetails = (type, value) => setAccessDetails(type, value, this);

	showInspectScrenExitConfirmationStatus = () => {
		this.setState({
			inspectScrenExitConfirmationStatus: true,
		});
	};

	hideInspectScrenExitConfirmationStatus = () => {
		this.setState({
			inspectScrenExitConfirmationStatus: false,
		});
	};

	renderExitConfirmationDialog = () => {
		const { inspectScrenExitConfirmationStatus, routeAfterSave } = this.state;
		const { isInspectScreenModified } = this.props;
		const { t } = this.props;
		if (
			inspectScrenExitConfirmationStatus &&
			isInspectScreenModified &&
			routeAfterSave !== this.props.location.pathname
		) {
			return (
				<InspectScreenExitConfirmationDialog
					t={t}
					handleSaveAndRoute={this.handleSaveAndRoute}
					inspectScrenExitConfirmationStatus={inspectScrenExitConfirmationStatus}
					handleCancelAndRoute={this.handleCancelAndRoute}
					handleStayOnScreen={this.handleStayOnScreen}
				/>
			);
		} else {
			return null;
		}
	};

	handleSave = (_) => {
		// handleSave(this)
		if (this.mediaDetailsSection) {
			this.mediaDetailsSection.handleSaveForMediaDetails();
		}
		this.hideBottomActionButtons();
	};

	handleCancel = (_) => {
		//handleCancel(this)
		if (this.mediaDetailsSection) {
			this.mediaDetailsSection.handleCancelForMediaDetails();
		}
		this.hideBottomActionButtons();
	};

	handleSaveAndRoute = (_) => {
		handleSaveAndRoute(this);
	};

	handleCancelAndRoute = (_) => {
		handleCancelAndRoute(this);
	};

	handleStayOnScreen = () => {
		handleStayOnScreen(this);
	};

	onDetailsChange = (_) => {
		this.displayBottomActionButtons();
	};

	displayBottomActionButtons = () => {
		const { isInspectScreenModified } = this.props;
		if (!isInspectScreenModified) {
			this.props.sendMediaScreenChanged();
		}
	};

	hideBottomActionButtons = (_) => {
		const { isInspectScreenModified } = this.props;
		if (isInspectScreenModified) {
			this.props.sendMediaScreenUnChanged();
		}
	};

	showSaveInProgress = (_) => this.setState({ isSaveInProgress: true });

	showSaveInDone = (_) => this.setState({ isSaveInProgress: false });

	onCheckForTabViews = (_) => {};

	onTabCollapsibleMenu = () => {};

	renderGeneralDetails = () => {
		const { t, history } = this.props;
		const { mediaDetails, mediaName, lastRoute } = this.state;
		return (
			<>
				<MediaDetails
					t={t}
					mediaDetails={mediaDetails}
					onDetailsChange={this.onDetailsChange}
					updateScreenTitle={this.updateScreenTitle}
					onMediaDetailsSectionLoaded={this.onMediaDetailsSectionLoaded}
					handleSave={this.handleSave}
					handleCancel={this.handleCancel}
					showMessage={this.showMessage}
					mediaName={mediaName}
					history={history}
					lastRoute={lastRoute}
				/>
			</>
		);
	};

	renderAccessDetails = () => {
		const { t } = this.props;
		const { mediaDetails } = this.state;
		return (
			<>
				<AccessRestriction
					parentState={this.state}
					setAccessDetails={this.setAccessDetails}
					t={t}
					mediaDetails={mediaDetails}
					onDetailsChange={this.onDetailsChange}
					onAccessComponentLoaded={this.onAccessComponentLoaded}
					showMessage={this.showMessage}
					mediaNames={mediaNames.catalogs}
				/>
			</>
		);
	};

	renderInformationSection = () => {
		const { t } = this.props;
		const { mediaDetails } = this.state;
		return (
			<>
				<InformationSection t={t} mediaDetails={mediaDetails} />
			</>
		);
	};

	showMobileBackNav = (text) => {
		const { mobileView } = this.state;
		if (mobileView) {
			return (
				<div
					className="backToList"
					onClick={() => {
						showLeftPart();
						this.setState({
							mobileViewZone: sides.left,
						});
					}}
				>
					<label> {text} </label>
					{this.getLeftArrow()}
				</div>
			);
		} else {
			return null;
		}
	};

	getMediaAddSection = () => {
		const { mediaDetails, mobileView } = this.state;
		return (
			<MediaAddSection
				mediaDetails={mediaDetails}
				mobileView={mobileView}
				showMessage={this.showMessage}
				reloadBreadCrumb={this.reloadBreadCrumb}
			/>
		);
	};

	renderAllDetails = () => {
		const { currentView, mobileView } = this.state;
		const { t } = this.props;
		return (
			<>
				<div id="middleZone">
					<div
						id={detailsMenuKeys.mediaDetails}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.mediaDetails ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('GENERAL_DETAILS'))}
						{!mobileView ? (
							<>
								{' '}
								<h3>
									{t('GENERAL_DETAILS')} {this.getMediaAddSection()}{' '}
								</h3>
							</>
						) : null}
						{mobileView ? (
							<>
								<div className="mediaAddSection">
									<h4>
										{' '}
										{t('CATALOG_DETAILS')} {this.getMediaAddSection()}{' '}
									</h4>
								</div>
							</>
						) : null}
						{this.renderGeneralDetails()}
					</div>
					<div
						id={detailsMenuKeys.accessRestrictions}
						className="inspectViewMenuListItems"
						style={{ display: currentView === detailsMenuKeys.accessRestrictions ? 'block' : 'none' }}
					>
						{this.showMobileBackNav(t('ACCESS_RESTRICTIONS'))}
						{!mobileView ? <h3>{t('ACCESS_RESTRICTIONS')}</h3> : null}
						{this.renderAccessDetails()}
					</div>
				</div>
			</>
		);
	};

	getLeftArrow = (_) => {
		return (
			<svg
				viewBox="0 0 21.86 15.51"
				onClick={() => {
					showLeftPart();
					this.setState({
						mobileViewZone: sides.left,
					});
				}}
			>
				<defs />
				<title>Arrow left</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M20.36,6.25H5L8.56,2.54A1.5,1.5,0,0,0,8.51.42a1.48,1.48,0,0,0-1-.42A1.54,1.54,0,0,0,6.39.46l-6,6.26a1.49,1.49,0,0,0,0,2.07l6,6.25A1.5,1.5,0,1,0,8.56,13L5,9.25H20.36a1.5,1.5,0,0,0,0-3Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	getRightArrow = () => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.8 10.18">
				<defs />
				<title>Right</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M.5,10.18A.47.47,0,0,1,.15,10a.48.48,0,0,1,0-.7L4.38,5.09.15.85a.48.48,0,0,1,0-.7.48.48,0,0,1,.7,0l5,4.94L.85,10A.47.47,0,0,1,.5,10.18Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	renderMediaDetails = (_) => {
		return (
			<>
				<div id="rightZone">
					<div id="inspectViewLivePlayer">{this.renderInformationSection()}</div>
				</div>
				<div id="leftZone">{this.renderMediaMenu()}</div>
				{this.renderAllDetails()}
			</>
		);
	};

	renderMediaMenu() {
		const { listDetails, mediaName } = this.state;
		let mediaMenuSorted = listDetails.sort((a, b) => {
			return a.order - b.order;
		});

		return (
			<div className="mediaMenuWrapper">
				{mediaName === mediaNames.catalogs &&
					mediaMenuSorted.map((item) => (
						<>
							{this.renderItemNameCase(item)}
							{this.listNestedItems(item)}
						</>
					))}
			</div>
		);
	}

	listNestedItems(listItem) {
		const { open } = this.props;
		const { currentView, expandadItem } = this.state;

		if ((expandadItem === listItem.key || expandadItem === menuKeys.details) && listItem.children.length > 0) {
			return (
				<div in={open} timeout="auto" className="mediaSubMenuWrapper" id={'sub' + listItem.id}>
					{listItem.children.map((item, index) => (
						<List key={index} component="div" className={'mediaSubMenuElement'} disablePadding>
							<ListItem
								button
								key={item.id + index}
								selected={currentView === item.id}
								classes={{
									root: 'subMenuDiv',
									selected: 'selectedSubMenu',
								}}
								onClick={(e) => {
									this.onSubNavClick(e, item, item.param, currentView === item.id);
								}}
							>
								<ListItemText primary={item.text} />
							</ListItem>
						</List>
					))}
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	onMainNavClick = (evt, item, selected) => {
		if (!selected) {
			evt.preventDefault();
			handleMenuChange(this, item.key, item.param);
		}
	};

	onSubNavClick = (evt, item, param, selected) => {
		if (!selected) {
			evt.preventDefault();
			showRightZone(item, this);
		}
	};

	isChildSelected = (currentView, childArray) => {
		let isSubMenu = false;
		for (let index = 0; index < childArray.length; ++index) {
			let subMenu = childArray[index];
			if (subMenu.id === currentView) {
				isSubMenu = true;
				break;
			}
		}
		return isSubMenu;
	};

	renderItemNameCase = (item) => {
		const { currentView, expandadItem } = this.state;

		return (
			<div className="mediaMenuRow">
				<ListItem
					key={item.key}
					selected={currentView === item.key || this.isChildSelected(currentView, item.children)}
					classes={{
						root: 'listItem',
						selected: 'selectedMainMenu',
					}}
					onClick={
						item.children.length > 0
							? (e) => {
									this.onSubNavClick(
										e,
										item.children[0],
										item.children[0].param,
										currentView === item.children[0].key
									);
							  }
							: (e) => this.onMainNavClick(e, item, currentView === item.key)
					}
				>
					{item.name ? <ListItemText primary={item.name} /> : <ListItemText primary={item.text} />}
				</ListItem>
				<div
					className={
						currentView === item.key || this.isChildSelected(currentView, item.children)
							? 'mediaIconWrapper selectedMainMenu'
							: 'mediaIconWrapper'
					}
					onClick={
						item.children.length > 0
							? () => {
									this.onExpandSubMenu(item.key);
							  }
							: () => this.openInNewWindow(item)
					}
				>
					{item.children.length > 0 ? (
						expandadItem === item.key || expandadItem === menuKeys.details ? (
							<div className={'mainNavIcon'}>
								<ExpandMore />
							</div>
						) : (
							<ChevronRight />
						)
					) : (
						<OpenInNewIcon />
					)}
				</div>
				<div style={{ clear: 'both' }}></div>
			</div>
		);
	};

	onExpandSubMenu = (expand_id) => {
		if (this.state.expandadItem === expand_id) {
			this.setState({
				expandadItem: 0,
			});
		} else {
			this.setState({
				expandadItem: expand_id,
			});
		}
	};

	render() {
		const { history } = this.props;
		const { mediaDetails, selectedMenu } = this.state;

		return (
			<>
				<Helmet>
					<title>
						{mediaDetails && mediaDetails.title
							? 'Inspect - ' + mediaDetails.title
							: 'Qbrick Video Platform'}
					</title>
				</Helmet>
				<BreadCrumb history={history} hasBorder />
				<ScreenParentContainer>
					<div id="inspectView">
						{mediaDetails && (
							<>
								<div id="breadCrumbMobileView">
									<BreadCrumb history={history} isMobile />
								</div>
								<div className={selectedMenu === menuKeys.details ? 'mediaFullDetails' : 'hide'}>
									{this.renderMediaDetails()}
								</div>
								<div className={selectedMenu === menuKeys.close ? 'show' : 'hide'}>
									This is back button
								</div>
								{this.renderExitConfirmationDialog()}
							</>
						)}
					</div>
				</ScreenParentContainer>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish, breadCrumbReducer, navReducer }) => ({
	open: navReducer.open,
	loading: publish.loading,
	username: session.username,
	accounts: session.accounts,
	isLoggedIn: session.isLoggedIn,
	routeAfterSave: publish.routeAfterSave,
	defaultAccountId: session.defaultAccountId,
	locationArray: breadCrumbReducer.locationArray,
	isInspectScreenModified: publish.isInspectScreenModified,
});

const mapDispatchToProps = (dispatch) => ({
	updateScreenName: (name) => dispatch(updateScreenName(name)),
	sendMediaScreenChanged: (_) => dispatch(sendMediaScreenChanged()),
	stopMediaUploadProcess: (_) => dispatch(stopMediaUploadProcess()),
	showNotification: (message) => dispatch(showNotification(message)),
	sendMediaScreenUnChanged: (_) => dispatch(sendMediaScreenUnChanged()),
	clearBreadCrumbLocationArray: (_) => dispatch(clearBreadCrumbLocationArray()),
	changeRouteAfterInspectScreen: (route) => dispatch(changeRouteAfterInspectScreen(route)),
	updateBreadCrumbLocationArray: (key, location) => dispatch(updateBreadCrumbLocationArray(key, location)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	deleteRestrictionRule: (accountId, restrictionId) => dispatch(deleteRestrictionRule(accountId, restrictionId)),
	createNewRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(createNewRestrictionRule(accountId, restrictionId, body)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	updateExistingRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(updateExistingRestrictionRule(accountId, restrictionId, body)),
	applyRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(applyRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
	deleteRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(deleteRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
	clearInspectScreenStorage: () => dispatch(clearInspectScreenStorage()),
	updateMenuKeys: (key) => dispatch(updateMenuKeys(key)),
	addMediaDetails: (details) => dispatch(addMediaDetails(details)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(InspectCatalogScreen);
