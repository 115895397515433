import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';

import no_medias_icon from '../../assets/images/no_medias.svg';
import { AddBox } from '@material-ui/icons';

export interface NoMediaDisplayProps {
	showUploadMediaDialogBox?: () => void;
	showCreateScenarioBox?: () => void;
	searchQuery?: string;
}

export const NoMediaDisplay: React.FC<NoMediaDisplayProps> = ({
	showUploadMediaDialogBox,
	showCreateScenarioBox,
	searchQuery,
}) => {
	const { t } = useTranslation();
	const isAtScenario = window.location.toString().includes('interact');

	const initPlayer = () => {
		(window as any).QplayerLoader.killInteractivePlayer?.();
		if (
			(window as any).QplayerLoader.initiateInteractivePlayer &&
			typeof (window as any).QplayerLoader.initiateInteractivePlayer === 'function'
		) {
			(window as any).QplayerLoader.initiateInteractivePlayer();
		}
	};

	useEffect(() => {
		if (isAtScenario) {
			initPlayer();
		}

		return () => (window as any).killInteractivePlayer?.();
	}, [isAtScenario]);

	const scenarioEmptyView = (
		<div className="scenario-empty-view">
			<div className="video-wrapper">
				<div style={{ position: 'relative', paddingBottom: '56.25%' }}>
					<div
						id="player-preview"
						style={{ position: 'absolute', top: 0, left: 0 }}
						data-qplayer-thumbnail-width="100%"
						data-qplayer-thumbnail-height="100%"
						data-qplayer-id={'047226d5-00090291-19993686'}
						data-qplayer-media-id={'047226d5-00090291-19993686'}
						data-qplayer-account-id={'AccPn4oB8ooNEqDOBpvqxDBIA'}
						data-qplayer-preset-id={'8e67c866-370f-4508-8126-629385f14a49'}
						data-qplayer-inline={'on'}
						data-qplayer-analytics={'off'}
					/>
				</div>
			</div>
			<div className="content-wrapper">
				<Typography className="title" variant="h2">
					Create an interactive video.
				</Typography>
				<Typography className="subtitle" variant="body1">
					Build an interactive video experience to boost engagement, boost conversions & impress your
					audience.
				</Typography>
				<Button className="button" variant="contained" color="primary" onClick={showCreateScenarioBox}>
					Create your first interactive video
				</Button>
			</div>
		</div>
	);

	return isAtScenario && (!searchQuery || searchQuery === 'q=*') ? (
		scenarioEmptyView
	) : (
		<div className="noMediaMessage">
			<img src={no_medias_icon} />
			<label> {t('THERE_IS_NOTHING_HERE')}</label>

			{window.location.toString().includes('catalogs') && (
				<Button variant="contained" color="primary" onClick={showUploadMediaDialogBox} startIcon={<AddBox />}>
					{t('UPLOAD_VIDEO')}
				</Button>
			)}
		</div>
	);
};
