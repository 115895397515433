import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import LiveDetailScreen from './LiveDetailScreen/LiveDetailScreen';
import ScreenParentContainer from '../../reusable/ScreenParentContainer/ScreenParentContainer';

import './LiveScreen.scss';

class LiveScreen extends Component {
	redirectToLocation = (path) => this.props.history.push(`/${path}`);

	render() {
		const { t } = this.props;

		return (
			<>
				<ScreenParentContainer>
					<h1 className="comingSoon">{t('COMING_SOON')}</h1>
					<LiveDetailScreen> </LiveDetailScreen>
				</ScreenParentContainer>
			</>
		);
	}
}

const mapStateToProps = ({ session, navReducer }) => ({
	loading: session.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	open: navReducer.open,
});

export default compose(connect(mapStateToProps, null), withTranslation())(LiveScreen);
