export const liveMessageActionMenuKey = {
    reply: 'reply',
    pinMessage: 'pinMessage',
    unpinMessage: 'unpinMessage',
    blockUser: 'blockUser',
    unBlockUser: 'unBlockUser',
    blockMessage: 'blockMessage'
};

export const liveWebSocketEvents = {
    textCreated: "Subscribe.On.Chat.Events.Media.Text.Created",
    textUpdated: "Subscribe.On.Chat.Events.Media.Text.Updated",
    textPinned: "Subscribe.On.Chat.Events.Media.Text.Pinned",
    textUnPinned: "Subscribe.On.Chat.Events.Media.Text.UnPinned",
    reactionCreated: "Subscribe.On.Chat.Events.Media.Reaction.Created",
    reactionUpdated: "Subscribe.On.Chat.Events.Media.Reaction.Updated",
    blockMessage: "Subscribe.On.Chat.Events.Media.Block.Messages",
    blockUser: "Subscribe.On.Chat.Events.Media.Block.Users",
    unblockMessage: "Subscribe.On.Chat.Events.Media.Unblock.Messages",
    unblockUser: "Subscribe.On.Chat.Events.Media.Unblock.Users"
};