import React, { forwardRef, HTMLAttributes } from 'react';
import Handle from './Handle';
import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import '../WidgetList.scss';
export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'id'> {
	childCount?: number;
	clone?: boolean;
	collapsed?: boolean;
	depth: number;
	disableInteraction?: boolean;
	disableSelection?: boolean;
	ghost?: boolean;
	handleProps?: any;
	indicator?: boolean;
	indentationWidth: number;
	value?: string;
	index?: number;
	widgetData: { [key: string]: any };
	editingWidget: any;
	onCollapse?(): void;
	onWidgetClick: (_e: any, _widget: any) => {};
	onDuplicateWidget: (_e: any, _widget: any, _isEditable: boolean) => {};
	onRemoveWidget: (_e: any, _widget: any, _isEditable: boolean) => {};
	wrapperRef?(_node: HTMLDivElement): void;
}

const TreeItem = forwardRef<HTMLDivElement, Props>(
	(
		{
			handleProps,
			collapsed,
			onCollapse,
			style,
			wrapperRef,
			widgetData,
			onDuplicateWidget,
			onWidgetClick,
			onRemoveWidget,
			index,
			editingWidget,
			...props
		},
		ref
	) => {
		const { id, name, parentId, type, groupZIndex } = widgetData;
		const { id: activeWidgetId } = editingWidget ?? { id: '' };
		const isVideoItem = type === 'video';
		return (
			<div className={`tree-item ${!parentId ? 'layer-wrapper' : 'item-wrapper'}`} ref={wrapperRef} {...props}>
				<div
					className={`item-content ${activeWidgetId === id ? 'active' : ''}`}
					ref={ref}
					style={style}
					onClick={(e) => {
						parentId && onWidgetClick(e, widgetData);
					}}
				>
					<div className="item-title">
						{parentId && !isVideoItem ? <Handle {...handleProps} /> : <></>}
						{onCollapse && (
							<IconButton onClick={onCollapse} size="small" disableRipple disableFocusRipple>
								{collapsed ? <ExpandMoreIcon /> : <KeyboardArrowUpIcon />}
							</IconButton>
						)}
						<span className="title-span">
							{parentId ? name : `Layer ${index}`}{' '}
							{groupZIndex === 'video' && <span className="italic-text">(Cannot be moved)</span>}
						</span>
					</div>

					{!isVideoItem && (
						<>
							<div className="item-action">
								{parentId && (
									<IconButton
										size="small"
										disableRipple
										disableFocusRipple
										onClick={(e) => {
											onDuplicateWidget(e, widgetData, !isVideoItem);
										}}
									>
										<FileCopyIcon />
									</IconButton>
								)}
								{parentId && (
									<IconButton
										size="small"
										disableRipple
										disableFocusRipple
										onClick={(e) => {
											onRemoveWidget(e, widgetData, !isVideoItem);
										}}
									>
										<DeleteIcon />
									</IconButton>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
);

export default TreeItem;
