import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getMediaQueryDetails } from '../../../actions/publishActions';
import { showNotification } from '../../../actions/notificationActions';
import { mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { interactMimeType, tracksSequencesTypes } from '../../../constants/interactConstant';
import { mapTimeDifferenceToDefaultTime } from '../../../services/timeStampService';
import {
	getTrackOfInteractItem,
	getBodyOfSequence,
	getBodyOfTrack,
	getInteractGroupItemFromPreset,
	getNewCopyOfItem,
	getCustomInteractTrack,
} from '../../../services/interactScreenHelperService';
import { createTrack, createSequence, updateSequence } from '../../../actions/tracksActions';
import {
	createInteractTimelineItem,
	createInteractTimelineGroup,
	updateInteractTimelineItem,
	setLiveQueueItem,
	removeInteractTimelineItem,
	resetQueueOperation,
} from '../../../actions/interactTimelineActions';
import { updateTimelineItem } from '../../../actions/liveManagerActions';
import { liveEventStatusList } from '../../../constants/liveEventStatus';
import { isRequestFail } from '../../../services/inspectScreenHelperService';
import _ from 'underscore';

class LiveManagerInteractSave extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	UNSAFE_componentWillMount = () => {
		const { interactItems } = this.props;
		if (interactItems.length > 0) {
			interactItems.map((eachItem) => {
				if (eachItem.start && !eachItem.end) {
					this.props.setLiveQueueItem(eachItem);
				}
			});
		}
	};

	componentDidMount = () => {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
	};

	componentDidUpdate = (prevProps) => {
		const { liveQueueItem, liveEventStatus } = this.props;
		if (liveEventStatus != prevProps.liveEventStatus && liveEventStatus === liveEventStatusList.STOPPED) {
			if (liveQueueItem) {
				let time = this.getLiveEventEndTimeAsTimeline();
				if (time) {
					this.modifyTimeOfQueueElement(liveQueueItem, liveQueueItem.start, time, () => {
						this.pushItemBackToQueue(liveQueueItem);
						this.props.setLiveQueueItem('');
					});
				}
			}
		}
	};

	pushItemBackToQueue = (item) => {
		const { defaultAccountId, mediaId, t } = this.props;
		this.props.removeInteractTimelineItem(item, 'setScreenUnModified');
		let newItem = getNewCopyOfItem(item);
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.resetQueueOperation();
				this.props.showMessage(t('FAILED_FETCH_MEDIA_DETAILS'), messageTypes.error);
				return;
			}
			let trackId = getCustomInteractTrack(media);
			this.createSequence(newItem, trackId);
		});
	};

	getLiveEventEndTimeAsTimeline = () => {
		const { liveEventStartTime, liveEventStopTime } = this.props;
		if (liveEventStartTime && liveEventStopTime) {
			let startTime = liveEventStartTime;
			let endTime = liveEventStopTime;
			endTime = mapTimeDifferenceToDefaultTime(endTime, startTime);
			return endTime;
		}
		return null;
	};

	addInteractItemToTracks = (_item) => {
		const { mediaId, t, defaultAccountId, interactElementsWidget, interactGroups, interactItems } = this.props;
		if (!interactElementsWidget) {
			this.props.showMessage(t('TEMPLATE_NOT_FOUND'), messageTypes.error);
			return;
		}
		let objectData = _.find(JSON.parse(interactElementsWidget), (widget) => {
			return widget.templateId === _item.templateId;
		});
		if (!objectData) {
			this.props.showMessage(t('TEMPLATE_NOT_FOUND'), messageTypes.error);
			return;
		}
		let itemAndGroup = getInteractGroupItemFromPreset(objectData, _item, null, null); //start and end are passed as null
		let item = itemAndGroup.item;
		if (item.itemType === 'custom') {
			const itemIndex =
				interactItems.length > 0
					? interactItems.sort((a, b) => a.index - b.index)[interactItems.length - 1].index + 1
					: 0;
			item = { ...item, index: itemAndGroup.item.index ?? itemIndex };
		}
		let group = _.find(interactGroups, (group) => {
			return group.id === objectData.templateGroup;
		});
		//return;
		if (!group) {
			this.props.createInteractTimelineGroup(itemAndGroup.group);
		}

		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (!media || typeof media == 'string') {
				return;
			}
			let tracks = media.tracks;
			if (!tracks || tracks.length === 0) {
				//create a track with a sequence if no track exists in server and interact element is found
				this.createTracksWithSequence(item);
				return;
			}
			let serverInteractTrack = [];
			tracks.map((track) => {
				if (
					track &&
					track.sequences &&
					track.sequences.length > 0 &&
					track.sequences[0].type === 'custom' &&
					track.sequences[0].custom &&
					track.sequences[0].custom.mimeType === interactMimeType &&
					track.sequences[0].custom.interactiveWidget
				) {
					serverInteractTrack = [track];
				}
			});
			if (serverInteractTrack.length === 0) {
				//create a track with a sequence if no interact track exists in server and interact element is found (applies if there are other tracks)
				this.createTracksWithSequence(item);
			} else if (serverInteractTrack.length > 0) {
				this.createSequence(item, serverInteractTrack[0].id);
			}
		});
	};

	createSequence = (item, trackId) => {
		const { t, defaultAccountId, mediaId } = this.props;
		let customBody = getBodyOfSequence(item);

		if (!customBody) {
			this.props.resetQueueOperation();
			this.props.showMessage(t('FAILED_TO_SAVE_INVALID_VALUES'), messageTypes.error);
			return;
		}
		this.props
			.createSequence(defaultAccountId, mediaId, trackId, tracksSequencesTypes.customs, customBody)
			.then((res) => {
				if (typeof res === 'string') {
					this.props.showMessage(t('CREATED_INTERACT_ITEMS'), messageTypes.info);
					this.props.createInteractTimelineItem(item, 'setScreenUnModified');
				} else {
					this.props.showMessage(
						t('FAILED_TO_SAVE') + ' ' + (item.templateName || item.name),
						messageTypes.error
					);
				}
			});
	};

	createTracksWithSequence = (item) => {
		const { defaultAccountId, mediaId, t } = this.props;
		let trackBody = getBodyOfTrack();
		let trackId = trackBody.id;
		this.props.showMessage(t('WAIT_TO_SAVE_CHANGE'), messageTypes.info);
		this.props.createTrack(defaultAccountId, mediaId, trackBody).then((res) => {
			if (typeof res === 'string') {
				this.createSequence(item, trackId);
			}
		});
	};

	modifyTimeOfQueueElement = (item, startTime, endTime, callback) => {
		const { defaultAccountId, mediaId, t, liveStreamPositionTime } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (!media) {
				this.props.resetQueueOperation();
				this.props.showNotification(t('MEDIA_NOT_FOUND'));
				return;
			}
			let track = getTrackOfInteractItem(media, item.id);
			if (track) {
				item.start = startTime ? new Date(startTime) : null;
				item.end = endTime ? new Date(endTime) : null;
				if (item.start > liveStreamPositionTime) {
					this.props.showMessage(t('TIME_GREATER_THAN_LIVESTREAM'), messageTypes.error);
					this.props.resetQueueOperation();
					return;
				}
				let customBody = getBodyOfSequence(item);
				if (!customBody) {
					this.props.resetQueueOperation();
					this.props.showMessage(t('FAILED_TO_SAVE_INVALID_VALUES'), messageTypes.error);
					return;
				}
				this.props
					.updateSequence(
						defaultAccountId,
						mediaId,
						track.id,
						tracksSequencesTypes.customs,
						item.id,
						customBody
					)
					.then((res) => {
						if (typeof res === 'string') {
							this.props.updateInteractTimelineItem(item, 'setScreenUnModified');
							this.props.updateTimelineItem(item);
							callback();
						} else {
							callback('error');
						}
					});
			}
		});
	};

	render() {
		return <></>;
	}
}

const mapStateToProps = ({ session, liveManager, interactTimelineReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
	interactGroups: interactTimelineReducer.groups,
	liveEventStatus: liveManager.liveEventStatus,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	liveEventStartTime: liveManager.liveEventStartTime,
	liveEventStopTime: liveManager.liveEventStopTime,
	liveQueueItem: interactTimelineReducer.liveQueueItem,
	queueOperationInProgress: interactTimelineReducer.queueOperationInProgress,
});

const mapDispatchToProps = (dispatch) => ({
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	showNotification: (message) => dispatch(showNotification(message)),
	createTrack: (accId, mediaId, body) => dispatch(createTrack(accId, mediaId, body)),
	createSequence: (accId, mediaId, trackId, sequenceType, body) =>
		dispatch(createSequence(accId, mediaId, trackId, sequenceType, body)),
	createInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(createInteractTimelineItem(item, setScreenUnModified)),
	createInteractTimelineGroup: (group) => dispatch(createInteractTimelineGroup(group)),
	updateSequence: (accId, mediaId, trackId, sequenceType, sequenceId, body) =>
		dispatch(updateSequence(accId, mediaId, trackId, sequenceType, sequenceId, body)),
	updateTimelineItem: (item) => dispatch(updateTimelineItem(item)),
	updateInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(updateInteractTimelineItem(item, setScreenUnModified)),
	setLiveQueueItem: (item) => dispatch(setLiveQueueItem(item)),
	removeInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(removeInteractTimelineItem(item, setScreenUnModified)),
	resetQueueOperation: (_) => dispatch(resetQueueOperation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveManagerInteractSave);
