import React from 'react';
import SideBar from '../../utils/SideBar';
import TabBar from '../../utils/TabBar';
import { Settings, WidgetsOutlined } from '@material-ui/icons';
import WidgetsTab from './tabsContent/widgetTab/WidgetsTab';
import EditTab from './tabsContent/editTab/EditTab';

const SELECTED_TAB = {
	WIDGETS: 'widgets',
	EDIT: 'edit',
};

type SidebarProps = SidebarContentProps & {
	selectedTab: 'widgets' | 'edit';
	onTabItemClick: () => {};
};

type SidebarContentProps = {
	selectedTab: 'widgets' | 'edit';

	//common props
	baseClassName: string;

	//widget tab props
	isOnModal: boolean;
	selectedWidgetType: string;
	onSelectWidgetType: () => {};
	handleDragStart: (_event: {}) => {};

	// Edit tab props
	breadcrumbLevel: string;
	editingScene: { id: string; widgetTemplates: Array<{ id: string; name: string }> };
	editingWidget: { widgetTemplateId: string };
	activeLayout: string;
	editingBox: {};
	scenarioContainerRef: any;
	widgetOverlayRef: any;
	addHoverImageRef: {};
	widgetSettingRef: any;
	setBreadcrumbLevel: () => {};
	onUploadMediaClick: () => {};
	onAddWidgetClick: () => {};
	onWidgetClick: () => {};
	duplicateWidget: () => {};
	removeWidgetInBox: () => {};
	movePositionHandler: () => {};
	onShowIconLibraryPickerModal: () => {};
	handleReturnedMedia: () => {};
};

const SidebarContent = ({ selectedTab, ...others }: SidebarContentProps) => {
	const {
		//common
		baseClassName,

		//widgets tab
		duplicateWidget,
		handleDragStart,

		// edit tab
		breadcrumbLevel,
		editingScene,
		editingWidget,
		activeLayout,
		editingBox,
		scenarioContainerRef,
		widgetOverlayRef,
		addHoverImageRef,
		widgetSettingRef,
		setBreadcrumbLevel,
		onAddWidgetClick,
		onWidgetClick,
		removeWidgetInBox,
		onUploadMediaClick,
		movePositionHandler,
		onShowIconLibraryPickerModal,
		handleReturnedMedia,
	} = others;

	switch (selectedTab) {
		case SELECTED_TAB.WIDGETS:
			return (
				<WidgetsTab
					baseClassName={baseClassName}
					handleDragStart={handleDragStart}
					duplicateWidget={duplicateWidget}
					editingScene={editingScene}
				/>
			);
		case SELECTED_TAB.EDIT:
			return (
				<EditTab
					baseClassName={baseClassName}
					breadcrumbLevel={breadcrumbLevel}
					editingScene={editingScene}
					editingWidget={editingWidget}
					activeLayout={activeLayout}
					editingBox={editingBox}
					scenarioContainerRef={scenarioContainerRef}
					widgetOverlayRef={widgetOverlayRef}
					widgetSettingRef={widgetSettingRef}
					addHoverImageRef={addHoverImageRef}
					setBreadcrumbLevel={setBreadcrumbLevel}
					onAddWidgetClick={onAddWidgetClick}
					onWidgetClick={onWidgetClick}
					duplicateWidget={duplicateWidget}
					removeWidgetInBox={removeWidgetInBox}
					onUploadMediaClick={onUploadMediaClick}
					movePositionHandler={movePositionHandler}
					onShowIconLibraryPickerModal={onShowIconLibraryPickerModal}
					handleReturnedMedia={handleReturnedMedia}
				/>
			);
		default:
			return <>Empty</>;
	}
};

const buildHeader = (selectedTab: string, onTabItemClick: () => {}) => {
	return (
		<TabBar
			size="big"
			items={[
				{
					label: (
						<>
							<WidgetsOutlined />
							Widgets
						</>
					),
					id: 'widgets',
					selected: selectedTab === 'widgets',
				},
				{
					label: (
						<>
							<Settings />
							Edit
						</>
					),
					id: 'edit',
					selected: selectedTab === 'edit',
				},
			]}
			onItemClick={onTabItemClick}
		/>
	);
};

const SceneEditorSidebar = ({ selectedTab, onTabItemClick, ...others }: SidebarProps) => {
	return (
		<SideBar header={buildHeader(selectedTab, onTabItemClick)}>
			<SidebarContent selectedTab={selectedTab} {...others} />
		</SideBar>
	);
};

export default SceneEditorSidebar;
