import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
	Button,
	FormControlLabel,
	IconButton,
	InputAdornment,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import { Close } from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { listAllUsers } from '../../actions/profileActions';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { standarlizeRestrictionUser } from '../MediaLibrary/utils';

const EVERYONE = {
	type: 'EVERYONE',
	label: 'LIMIT_ACCESS_CATALOG_EVERYONE',
};

const SELECTED_USERS = {
	type: 'SELECTED_USERS',
	label: 'LIMIT_ACCESS_CATALOG_SELECTED_USERS',
};

export const LIMITED_ACCESS_TYPE = {
	EVERYONE,
	SELECTED_USERS,
};

const FULL = {
	type: 'FULL',
	label: 'Full',
	description: 'View & upload content, manage user access',
};

const LIMITED = {
	type: 'LIMITED',
	label: 'Limited',
	description: 'View & upload content',
};

export const ACCESS_LEVEL = {
	FULL,
	LIMITED,
};

const REMOVE_ALL_USERS = { id: 'REMOVE_ALL_USERS', label: 'Remove all users' };

const CATALOG_ACCESS_MENU = [REMOVE_ALL_USERS];

interface CatalogLimitedAcessProps {
	open?: boolean;
	preDefineCatalog?: any;
	onClose?: () => void;
}

const CatalogLimitedAccess = forwardRef(({ open, preDefineCatalog, onClose }: CatalogLimitedAcessProps, ref: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const { defaultAccountId, username, userId } = useSelector((state) => (state as any).session);

	const [limitedAccessType, setLimitedAccessType] = useState<string>(EVERYONE.type);
	const [userEmailSearchText, setUserEmailSearchText] = useState<string>('');
	const [availableUsers, setAvailableUsers] = useState<any>({});
	const [restrictionUsers, setRestrictionUsers] = useState<any>({});
	const [accessLevelMenuAnchorEl, setAccessLevelMenuAnchorEl] = useState<any>(null);
	const [removeAccessMenuAnchorEl, setRemoveAccessMenuAnchorEl] = useState<any>(null);
	const loading = open && Object.keys(availableUsers).length === 0;

	const handleUpdateUserAccessLevel = (accessLevel: string, userId: string) => {
		setRestrictionUsers({ ...restrictionUsers, [userId]: { id: userId, accessLevel } });
	};

	const handleRemoveRestrictedUser = (id: string) => {
		delete restrictionUsers[id];
		setRestrictionUsers({ ...restrictionUsers });
	};

	const resetAndCloseModal = () => {
		setLimitedAccessType(EVERYONE.type);
		setUserEmailSearchText('');
		setAvailableUsers({});
		setRestrictionUsers({});
		setAccessLevelMenuAnchorEl(null);
		onClose?.();
	};

	useImperativeHandle(ref, () => {
		return {
			resetAndCloseModal,
			getRestrictionUsers: () => {
				return limitedAccessType === EVERYONE.type ? {} : restrictionUsers;
			},
		};
	});

	useEffect(() => {
		if (limitedAccessType === EVERYONE.type || Object.keys(availableUsers).length !== 0) {
			return;
		}

		//editing user should be available in case of LIMITED_ACCESS_TYPE
		setRestrictionUsers({
			...restrictionUsers,
			[userId]: { id: userId, email: username, accessLevel: FULL.type },
		});

		dispatch(listAllUsers(defaultAccountId)).then((data: any) => {
			if (!data) {
				return;
			}
			const availableUsersMap = data.reduce((all: any, { login, userId }: any) => {
				if (!login || !userId) {
					return all;
				}

				return { ...all, [userId]: { id: userId, email: login } };
			}, {});

			setAvailableUsers(availableUsersMap);
		});
	}, [limitedAccessType, availableUsers]);

	useEffect(() => {
		if (!preDefineCatalog) {
			return;
		}

		const { access } = preDefineCatalog;

		const presetRestrictionUsers = standarlizeRestrictionUser(access);

		setLimitedAccessType(Object.keys(access).length === 0 ? EVERYONE.type : SELECTED_USERS.type);
		setRestrictionUsers(presetRestrictionUsers);
	}, [preDefineCatalog]);

	return (
		<div className="dialogContentWrapper">
			<label> {t('LIMIT_ACCESS_CATALOG')}</label>
			<div className="fullWidthControl">
				<RadioGroup
					row
					value={limitedAccessType}
					onChange={(event: any) => {
						setLimitedAccessType(event.currentTarget.value);
					}}
				>
					{Object.values(LIMITED_ACCESS_TYPE).map(({ type, label }) => {
						return (
							<FormControlLabel
								key={type}
								value={type}
								control={<Radio color="primary" />}
								label={t(label)}
							/>
						);
					})}
				</RadioGroup>
			</div>
			{limitedAccessType === SELECTED_USERS.type && (
				<>
					<Autocomplete
						value={''}
						disableClearable
						onChange={(_event, newValue: any) => {
							if (!newValue) {
								return;
							}
							const { id } = newValue;
							setUserEmailSearchText('');

							if (id === 'allUsers') {
								const allUsersToAdd = Object.values(availableUsers).filter(({ id }: any) => {
									return !restrictionUsers[id];
								});
								const usersHash = allUsersToAdd.reduce((all: any, { id }: any) => {
									return { ...all, [id]: { id, accessLevel: LIMITED.type } };
								}, {});

								setRestrictionUsers({ ...restrictionUsers, ...(usersHash as any) });
							} else {
								setRestrictionUsers({
									...restrictionUsers,
									[id]: { id, accessLevel: LIMITED.type },
								});
							}
						}}
						options={
							Object.keys(availableUsers).length !== Object.keys(restrictionUsers).length
								? [
										{ id: 'allUsers' },
										...Object.values(availableUsers).filter(({ id }: any) => {
											return !restrictionUsers[id];
										}),
								  ]
								: Object.values(availableUsers).filter(({ id }: any) => {
										return !restrictionUsers[id];
								  })
						}
						getOptionLabel={(test: any) => {
							if (test === '') {
								return '';
							}
							const { id, email } = test;
							return id === 'allUsers' ? 'Select all users' : email;
						}}
						getOptionSelected={(option, value) => option === value}
						loading={loading}
						inputValue={userEmailSearchText}
						onInputChange={(_event, newInputValue) => {
							setUserEmailSearchText(newInputValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								className="user-email-search-box"
								type="text"
								placeholder={t('LIMIT_ACCESS_CATALOG_EMAIL_SEARCH_PLACE_HOLDER')}
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon htmlColor="#aab0b4" />
										</InputAdornment>
									),
								}}
							/>
						)}
					/>
					<div className="fullWidthControl">
						<TableContainer style={{ maxHeight: 230, marginTop: 10 }}>
							<Table aria-label="restriction user table" stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell width="70%">User</TableCell>
										<TableCell width="20%">Access Level</TableCell>
										<TableCell align="right" width="10%">
											<IconButton
												size="small"
												onClick={(e) => {
													setRemoveAccessMenuAnchorEl(e.currentTarget);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.values(restrictionUsers)
										.sort(({ id: idA }: any, { id: idB }: any) => {
											if (availableUsers[idA] >= availableUsers[idB]) {
												return 1;
											}
											return -1;
										})
										.map(({ id, email, accessLevel }: any) => (
											<TableRow key={id} hover>
												<TableCell width="70%" component="th" scope="row">
													<Typography>{email || availableUsers[id]?.email}</Typography>
												</TableCell>
												<TableCell width="20%">
													{
														<Button
															endIcon={<ExpandMoreIcon />}
															size="small"
															data-userid={id}
															fullWidth
															style={{ justifyContent: 'space-between' }}
															disabled={userId === id}
															onClick={(event: any) => {
																setAccessLevelMenuAnchorEl(event.currentTarget);
															}}
														>
															<Typography>
																{
																	ACCESS_LEVEL[
																		accessLevel as keyof typeof ACCESS_LEVEL
																	].label
																}
															</Typography>
														</Button>
													}
												</TableCell>
												<TableCell align="right" width="10%">
													<IconButton
														size="small"
														disabled={userId === id}
														onClick={() => {
															handleRemoveRestrictedUser(id);
														}}
													>
														<Close />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					<Menu
						id="access-level-menu"
						anchorEl={accessLevelMenuAnchorEl}
						keepMounted
						open={Boolean(accessLevelMenuAnchorEl)}
						onClose={() => {
							setAccessLevelMenuAnchorEl(null);
						}}
					>
						{Object.values(ACCESS_LEVEL).map(({ type, label, description }: any) => {
							return (
								<MenuItem
									key={type}
									onClick={() => {
										const userId = accessLevelMenuAnchorEl.dataset.userid;
										setAccessLevelMenuAnchorEl(null);
										handleUpdateUserAccessLevel(type, userId);
									}}
									style={{ flexDirection: 'column', alignItems: 'start' }}
								>
									<Typography variant="body1">{label}</Typography>
									<Typography variant="body2" color="textSecondary">
										{description}
									</Typography>
								</MenuItem>
							);
						})}
					</Menu>
					<Menu
						id="table-option-menu"
						anchorEl={removeAccessMenuAnchorEl}
						keepMounted
						open={Boolean(removeAccessMenuAnchorEl)}
						onClose={() => {
							setRemoveAccessMenuAnchorEl(null);
						}}
					>
						{CATALOG_ACCESS_MENU.map(({ id, label }: any) => {
							return (
								<MenuItem
									key={id}
									disabled={Object.keys(restrictionUsers).length === 1 && !!restrictionUsers[userId]}
									onClick={() => {
										setRemoveAccessMenuAnchorEl(null);
										setRestrictionUsers({
											[userId]: { id: userId, email: username, accessLevel: FULL.type },
										});
									}}
								>
									{label}
								</MenuItem>
							);
						})}
					</Menu>
				</>
			)}
		</div>
	);
});

export default CatalogLimitedAccess;
