import React, { useState, memo, useEffect } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { _optionsWithByteFormat } from './../../../utils/commonUtil';

const VideoPerformance = (props) => {
	const { histoReportsSummaryData, reportsMilestonesData } = props;
	// TO Do translations should add
	const chartTypeValues = ['column', 'bar', 'line', 'area'];
	const [aciveTab, setAciveTab] = useState(0);
	const [chartType, setChartType] = useState(chartTypeValues[3]);
	const [viewsData, setViewsData] = useState([]);
	const [engagementData, setEngagementData] = useState([]);
	const [qoeData, setQoeData] = useState([]);
	const [concurrentViewsData, setConcurrentViewsData] = useState([]);

	function a11yProps(index) {
		return {
			id: `tab-${index}`,
			'aria-controls': `tabpanel-${index}`,
		};
	}
	const handleChange = (event, newValue) => {
		setAciveTab(newValue);
	};

	const _options = (
		data,
		cType,
		xAxisType = 'datetime',
		xAxisText = `GMT ${moment(new Date())
			.format()
			.substr(moment(new Date()).format().length - 6)}`,
		yAxisText = 'Number of Viewers'
	) => {
		return {
			time: {
				useUTC: false,
			},
			title: { text: undefined },
			credits: { enabled: false },
			exporting: { enabled: false },
			chart: { type: cType, zoomType: 'xy' },
			series: data,
			xAxis: {
				type: xAxisType,
				title: { text: xAxisText },
				// tickInterval: 48 * 3600 * 1000 // 1 day //1000 * 3600 * 24 *30 // 1 month
			},
			yAxis: [{ title: { text: yAxisText } }],

			tooltip: {
				pointFormat: '<span style="color:{series.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
				shared: true,
				// valueSuffix: '°C',
				// xDateFormat: '%A, %b %e',
				// formatter: function () {
				//   return this.points.reduce(function (s, point) {
				//     return s + '<br/> <span style="color:' + point.series.color + '">●</span> ' +
				//     point.series.name + ': ' + point.y;
				//   }, '<b>' +  Highcharts.dateFormat('%e - %b - %Y',
				//   new Date(this.x)) + '</b>');
				// },
				// shared: true
			},
		};
	};

	const _updateModifiedValues = () => {
		let arrTotal = [];
		let arrUnique = [];
		let arrCompletes = [];
		let arrTotalTimeWatched = [];
		let arrAverageTimeWatched = [];
		let arrBitrate = [];
		let arrConcurrentViews = [];

		let totalData = [
			{
				name: 'Total',
				color: 'rgb(177, 199, 0)',
				data: arrTotal,
			},
			{
				name: 'Unique',
				color: 'rgb(20, 82, 20)',
				data: arrUnique,
			},
			{
				name: 'Completes',
				color: 'rgb(5, 173, 211)',
				data: arrCompletes,
			},
		];
		let timeWatchedData = [
			{
				name: 'Total time watched',
				color: 'rgb(0, 204, 153)',
				data: arrTotalTimeWatched,
			},
			{
				name: 'Average time watched',
				color: 'rgb(253, 184, 19)',
				data: arrAverageTimeWatched,
			},
		];
		let bitrateData = [
			{
				name: 'Bitrate',
				color: 'rgb(12, 156, 238)',
				data: arrBitrate,
			},
		];
		let concurrentViewsData = [
			{
				name: 'Concurrent views',
				color: 'rgb(177, 199, 0)',
				data: arrConcurrentViews,
			},
		];

		histoReportsSummaryData &&
			Object.keys(histoReportsSummaryData).length > 0 &&
			histoReportsSummaryData.items.map((item) => {
				const total = {
					x: Date.parse(item.key),
					y: item.item?.views?.sum?.value | 0,
				};
				const unique = {
					x: Date.parse(item.key),
					y: item.item?.uniqueViews?.sum?.value | 0,
				};
				const completes = {
					x: Date.parse(item.key),
					y: item.item?.completes?.sum?.value | 0,
				};
				const timeWatchedSum = {
					x: Date.parse(item.key),
					y: parseFloat((item.item?.timeWatched?.sum?.value / 3600 || 0).toFixed(4)),
				};
				const timeWatchedAverage = {
					x: Date.parse(item.key),
					y: parseFloat((item.item?.timeWatched?.average?.value / 3600 || 0).toFixed(4)),
				};
				const bitrate = {
					x: Date.parse(item.key),
					y: item.item?.bitrate?.average?.value,
				};

				arrTotal.push(total);
				arrUnique.push(unique);
				arrCompletes.push(completes);
				arrTotalTimeWatched.push(timeWatchedSum);
				arrAverageTimeWatched.push(timeWatchedAverage);
				arrBitrate.push(bitrate);
			});

		reportsMilestonesData &&
			Object.keys(reportsMilestonesData).length > 0 &&
			reportsMilestonesData.items.map((item) => {
				arrConcurrentViews.push(item.item?.passed?.sum?.value);
			});
		setViewsData(totalData);
		setEngagementData(timeWatchedData);
		setQoeData(bitrateData);
		setConcurrentViewsData(concurrentViewsData);
	};

	useEffect(() => {
		_updateModifiedValues();
	}, [histoReportsSummaryData, reportsMilestonesData]);

	return (
		<Grid item xs={12}>
			<Box mb={2} display="flex" justifyContent="flex-end">
				<FormControl className="formControlAnalytics">
					<InputLabel id="chart-type">View as</InputLabel>
					<Select
						native
						className="capitalize"
						inputProps={{
							name: 'chart',
							id: 'chart-type',
						}}
						value={chartType}
						label="View as"
						onChange={(event) => setChartType(event.target.value)}
					>
						{chartTypeValues.map((name) => (
							<option key={name} value={name}>
								{name}
							</option>
						))}
					</Select>
				</FormControl>
			</Box>
			<AppBar position="static" className="analyticsTab">
				<Tabs value={aciveTab} onChange={handleChange} aria-label="tabs">
					<Tab label="Views" {...a11yProps(0)} />
					<Tab label="Engagement" {...a11yProps(1)} />
					<Tab label="QoE" {...a11yProps(2)} />
				</Tabs>
			</AppBar>
			<TabPanel value={aciveTab} index={0}>
				<HighchartsReact
					constructorType={'chart'}
					highcharts={Highcharts}
					options={_options(viewsData, chartType)}
				/>
			</TabPanel>
			<TabPanel value={aciveTab} index={1}>
				<HighchartsReact
					constructorType={'chart'}
					highcharts={Highcharts}
					options={_options(engagementData, chartType, undefined, undefined, 'Number of hours')}
				/>
				<HighchartsReact
					constructorType={'chart'}
					highcharts={Highcharts}
					options={_options(concurrentViewsData, chartType, '', '')}
				/>
			</TabPanel>
			<TabPanel value={aciveTab} index={2}>
				<HighchartsReact
					constructorType={'chart'}
					highcharts={Highcharts}
					options={_optionsWithByteFormat(qoeData, chartType, undefined, undefined, 'Average bitrate')}
				/>
			</TabPanel>
		</Grid>
	);
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography component="span">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default memo(VideoPerformance);
