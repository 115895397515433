import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { withTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { getBreadCrumbTopLevelsText, KEYS, PUBLISH_SUBKEY } from './../nav/navigationItems';
import { updateBreadCrumbLocationArray } from '../../actions/breadCrumbActions';
import { showNotification } from '../../actions/notificationActions';
import { changeRouteAfterInspectScreen, setSelectedCatalogId } from '../../actions/publishActions';
import { screenRoutes } from './../../constants/routesPath';
import { updateSelectedKey, updateSubSelectedKey } from '../../actions/navActions';
import { detailsMenuKeys } from './../../constants/inspectScreenMenu';
import { IconsList } from '../../reusable/IconsStore/IconsList';
import IconsStore from '../../reusable/IconsStore/IconsStore';
import { joinClassNames } from '../../services/elementHelperService';
import { generateId } from '../../services/stringHelperService';

import './breadCrumb.scss';

class BreadCrumb extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbAnchor: null,
			numberBreadCrumbItemDisplay: 1,
		};

		this.breadCrumbSectionRef = null;
		this.dummyId = generateId() + '-dummy';
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResizeWindow);
		this.updateNavKeysBasedOnLocation(window.location);
		this.renderDummyBreadCrumb();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.locationArray !== this.props.locationArray) {
			this.removeDummyBreadCrumb();
			this.renderDummyBreadCrumb();
		}
	}

	componentWillUnmount() {
		this.removeDummyBreadCrumb();
		window.removeEventListener('resize', this.handleResizeWindow);
	}

	removeDummyBreadCrumb = () => {
		const dummyBreadCrumb = document.getElementById(this.dummyId);
		if (dummyBreadCrumb) {
			document.getElementById('root').removeChild(dummyBreadCrumb);
		}
	};

	handleResizeWindow = () => {
		const dummyBreadCrumb = document.getElementById(this.dummyId);
		if (dummyBreadCrumb) {
			this.calculateBreadCrumbToShow();
		} else {
			this.renderDummyBreadCrumb();
		}
	};

	calculateBreadCrumbToShow = () => {
		if (this.props.locationArray.length <= 1) {
			this.setState({ numberBreadCrumbItemDisplay: locationLength });
			return;
		}

		const locationLength = this.props.locationArray ? this.props.locationArray.length : 0;
		if (!document.getElementById(this.dummyId) || !this.breadCrumbSectionRef) {
			this.setState({ numberBreadCrumbItemDisplay: locationLength });
			return;
		}

		const dummyBreadCrumb = document.getElementById(this.dummyId);
		const parentWidth = this.breadCrumbSectionRef.getBoundingClientRect().width - 24; // Minus padding
		const items = dummyBreadCrumb?.getElementsByClassName('MuiBreadcrumbs-li');
		const separatorWidth = 30;

		let itemCount = 0;
		let tempWidth = 0;
		while (
			itemCount < items.length &&
			tempWidth + items[itemCount].getBoundingClientRect().width + (itemCount ? separatorWidth : 0) < parentWidth
		) {
			tempWidth = tempWidth + items[itemCount].getBoundingClientRect().width + (itemCount ? separatorWidth : 0);
			itemCount++;
		}

		itemCount = itemCount > 0 && tempWidth + 40 + separatorWidth <= parentWidth ? itemCount : itemCount - 1;
		itemCount--; // Minus one since when render, always render levelTexts

		if (itemCount !== this.state.numberBreadCrumbItemDisplay) {
			this.setState({ numberBreadCrumbItemDisplay: itemCount });
		}
	};

	renderDummyBreadCrumb = () => {
		const { locationArray, selectedItemKey, screenName, t, selectedSubItemList } = this.props;

		const locations = [...locationArray];
		const levelTexts = getBreadCrumbTopLevelsText(selectedItemKey, selectedSubItemList, t);
		const container = document.createElement('div');

		const breadCrumb = () => (
			<Paper style={{ visibility: 'hidden', display: 'inline-block' }}>
				<Breadcrumbs
					separator={this.renderSeparator()}
					arial-label="Breadcrumb"
					className="hidden-breadcrumb"
					maxItems={50}
				>
					{locations.reverse().map((item) => (
						<Link color="inherit" key={item.href} href="">
							{item.text}
						</Link>
					))}
					{levelTexts.length > 1 || selectedItemKey === KEYS.publish ? (
						<Link
							className="breadCrumbList"
							color="inherit"
							key={selectedItemKey}
							onClick={(event) =>
								this.handleBreadCrumbItemClick(event, {
									href:
										selectedItemKey === KEYS.publish
											? screenRoutes.PUBLISH_ALL_MEDIA
											: firstBreadCrumbHref,
								})
							}
						>
							{selectedItemKey === KEYS.publish ? t('ALL_MEDIA') : levelTexts[0].text}
						</Link>
					) : (
						<Link className="breadCrumbList singleBreadcrumb" color="inherit" key={selectedItemKey}>
							{screenName ||
								levelTexts[0].text ||
								(selectedItemKey === KEYS.publish ? t('ALL_MEDIA') : '')}
						</Link>
					)}
				</Breadcrumbs>
			</Paper>
		);

		container.setAttribute('id', this.dummyId);
		container.style.height = '0px';
		ReactDOM.render(breadCrumb(), container, this.calculateBreadCrumbToShow);
		document.getElementById('root').insertBefore(container, document.getElementById('root').firstChild);
	};

	handleBreadCrumbItemClick = (event, item) => {
		const { href } = item;
		const {
			isInspectScreenModified,
			onLibraryViewChange,
			changeRouteAfterInspectScreen,
			history,
			locationArray,
			updateBreadCrumbLocationArray,
			setSelectedCatalogId,
		} = this.props;
		event.preventDefault();
		this.hideBreadcrumbMenu();

		if (isInspectScreenModified) {
			changeRouteAfterInspectScreen(href);
		} else {
			history.push(href);

			// Special for Catalogs
			if (href.includes(screenRoutes.PUBLISH_CATALOGS)) {
				const params = href.split('/');
				const catalogSubId = href === screenRoutes.PUBLISH_CATALOGS ? '' : params[params.length - 1];
				onLibraryViewChange?.('catalogs', catalogSubId);
				setSelectedCatalogId(catalogSubId === '' ? 'allCatalog' : catalogSubId);

				const newLocationArray = locationArray.filter((l) =>
					l.level !== undefined ? l.level <= item.level : l.href !== href
				);
				updateBreadCrumbLocationArray(KEYS.publish, newLocationArray);
			}
		}
	};

	updateNavKeysBasedOnLocation = (location) => {
		const { updateSelectedKey, updateSubSelectedKey } = this.props;
		const currentLocation = location.hash;

		if (currentLocation) {
			if (currentLocation.match(screenRoutes.DASHBOARD)) {
				updateSelectedKey(KEYS.dashboard);
			} else if (currentLocation.match(screenRoutes.ANALYTICS)) {
				updateSelectedKey(KEYS.analytics);
			} else if (currentLocation.match(screenRoutes.PUBLISH_MEDIA_LIBRARY)) {
				updateSelectedKey(KEYS.publish);
				updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.allMedia);
			} else if (currentLocation.match(screenRoutes.PUBLISH_PLAYLISTS)) {
				updateSelectedKey(KEYS.publish);
				updateSubSelectedKey(KEYS.publish, PUBLISH_SUBKEY.playlists);
			} else if (currentLocation.match(screenRoutes.CREATE)) {
				updateSelectedKey(KEYS.create);
			} else if (currentLocation.match(screenRoutes.LIVE)) {
				updateSelectedKey(KEYS.live);
			} else if (currentLocation.match(screenRoutes.APPS_EDITOR)) {
				updateSelectedKey(KEYS.editorApp);
			} else if (currentLocation.match(screenRoutes.APPS)) {
				updateSelectedKey(KEYS.apps);
			}
		}
	};

	showBreadcrumbMenu = (e) => this.setState({ breadcrumbAnchor: e.currentTarget });

	hideBreadcrumbMenu = () => this.setState({ breadcrumbAnchor: null });

	renderSeparator = (isFolderSeparator = false) => {
		return (
			<span className={isFolderSeparator ? 'folderSeparator' : 'breadcrumb-separator'}>
				<svg xmlns="http://www.w3.org/2000/svg" width="3.774" height="5.85" viewBox="0 0 3.774 5.85">
					<path
						id="caret-down-fill"
						d="M4.747,7.6,2.327,4.836A.5.5,0,0,1,2.707,4H7.546a.5.5,0,0,1,.38.837L5.507,7.6a.5.5,0,0,1-.76,0Z"
						transform="translate(-4 8.051) rotate(-90)"
						fill="#9ca1ac"
					/>
				</svg>
			</span>
		);
	};

	render() {
		const { locationArray, selectedItemKey, screenName, t, className, hasBorder, selectedSubItemList, isMobile } =
			this.props;
		const { breadcrumbAnchor, numberBreadCrumbItemDisplay } = this.state;

		const firstBreadCrumbHref = this.props.lastLibraryView?.includes('playlist')
			? screenRoutes.PUBLISH_PLAYLISTS
			: this.props.lastLibraryView?.includes('catalogs')
			? screenRoutes.PUBLISH_CATALOGS
			: screenRoutes.PUBLISH_ALL_MEDIA;
		const startingPoint = localStorage.getItem('startingPoint') ?? 'All media';

		const levelTexts = getBreadCrumbTopLevelsText(selectedItemKey, selectedSubItemList, t);
		const pathArray = window.location.hash.split('/');
		const pathArrayLastElement = pathArray[pathArray.length - 1];
		const isInspectPage = pathArray.includes('detail');

		const checkForApps = (lastElement) => {
			switch (lastElement) {
				case 'detail':
				case detailsMenuKeys.mediaDetails:
				case detailsMenuKeys.embedVideo:
				case detailsMenuKeys.socialSharing:
				case detailsMenuKeys.thumbnailManager:
				case detailsMenuKeys.accessRestrictions:
				case detailsMenuKeys.files:
				case detailsMenuKeys.playlistMediaDetails:
				case detailsMenuKeys.playerConfigurations:
					return false;
			}
			return true;
		};

		let breadCrumbItems = locationArray;
		let menuItems = [];
		if (locationArray && numberBreadCrumbItemDisplay !== locationArray.length) {
			breadCrumbItems = locationArray.slice(0 - numberBreadCrumbItemDisplay);
			menuItems = locationArray.slice(0, locationArray.length - numberBreadCrumbItemDisplay);
		}
		if (isMobile && locationArray.length > 0) {
			// On mobile view just show the last level
			breadCrumbItems = [locationArray[locationArray.length - 1]];
			menuItems = locationArray.slice(0, locationArray.length - 1);
		}

		return (
			<div
				id="breadCrumbSection"
				className={joinClassNames(
					isMobile ? 'breadcrumb--mobile' : 'breadcrumb--desktop',
					hasBorder ? 'breadcrumb--bordered' : ''
				)}
				ref={(ref) => {
					this.props.breadCrumbRef?.(ref);
					this.breadCrumbSectionRef = ref;
				}}
			>
				<div className="breadCrumbMenuBar">
					{menuItems.length > 0 && (
						<>
							<div className="breadcrumbMenuIcon" onClick={(event) => this.showBreadcrumbMenu(event)}>
								<IconsStore iconName={IconsList.BREADCRUMB_FOLDER} color={'#126cfc'} />
							</div>
							<Menu
								id="topMenuSystem"
								className="breadcrumbMenuList"
								anchorEl={breadcrumbAnchor}
								open={Boolean(breadcrumbAnchor)}
								onClose={() => this.hideBreadcrumbMenu()}
								getContentAnchorEl={null}
								anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
							>
								{menuItems.reverse().map((item, index) => (
									<MenuItem
										key={item.key}
										attr={item.key}
										onClick={(event) => this.handleBreadCrumbItemClick(event, item)}
									>
										{isInspectPage && checkForApps(pathArrayLastElement) && index === 0 ? (
											<IconsStore iconName={IconsList.BREADCRUMB_MENU_MEDIA} color={'#444a58'} />
										) : (
											<IconsStore iconName={IconsList.BREADCRUMB_MENU_FOLDER} color={'#3b3b3b'} />
										)}
										{item.text}
									</MenuItem>
								))}
								<MenuItem
									key={levelTexts[1]?.key}
									attr={levelTexts[1]?.key}
									onClick={(event) =>
										this.handleBreadCrumbItemClick(event, { href: firstBreadCrumbHref })
									}
								>
									<IconsStore iconName={IconsList.BREADCRUMB_MENU_MAIN} color={'#3b3b3b'} />
									{levelTexts[1]?.text}
								</MenuItem>
							</Menu>
							{this.renderSeparator(true)}
						</>
					)}

					<Paper id="breadCrumbPaper">
						<Breadcrumbs
							id="breadCrumbGroup"
							separator={this.renderSeparator()}
							arial-label="Breadcrumb"
							maxItems={50}
							className={className}
						>
							{menuItems.length === 0 &&
							(levelTexts.length > 1 ||
								selectedItemKey === KEYS.publish ||
								selectedItemKey === KEYS.interact) ? (
								<Link
									className="breadCrumbList"
									color="inherit"
									key={selectedItemKey}
									onClick={(event) =>
										this.handleBreadCrumbItemClick(event, {
											href:
												startingPoint === 'ALL_MEDIA'
													? screenRoutes.PUBLISH_ALL_MEDIA
													: startingPoint === 'PLAYLISTS'
													? screenRoutes.PUBLISH_PLAYLISTS
													: startingPoint === 'INTERACT'
													? screenRoutes.PUBLISH_SCENARIOS
													: startingPoint === 'CATALOGS'
													? screenRoutes.PUBLISH_CATALOGS
													: startingPoint === 'EDIT'
													? screenRoutes.PUBLISH_EDITED_MEDIA
													: startingPoint === 'RECYCLE_BIN'
													? screenRoutes.PUBLISH_RECYCLE_BIN
													: firstBreadCrumbHref,
										})
									}
								>
									{t(startingPoint)}
								</Link>
							) : (
								<Link className="breadCrumbList singleBreadcrumb" color="inherit" key={selectedItemKey}>
									{screenName || t(startingPoint)}
								</Link>
							)}

							{breadCrumbItems.length > 0 &&
								breadCrumbItems.map((item) => (
									<Link
										color="inherit"
										key={item.href}
										href=""
										onClick={(event) => this.handleBreadCrumbItemClick(event, item)}
									>
										{item.text}
									</Link>
								))}
						</Breadcrumbs>
					</Paper>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ navReducer, breadCrumbReducer, publish }) => ({
	open: navReducer.open,
	screenName: breadCrumbReducer.screenName,
	selectedItemKey: navReducer.selectedItemKey,
	locationArray: breadCrumbReducer.locationArray,
	selectedSubItemList: navReducer.selectedSubItemList,
	isInspectScreenModified: publish.isInspectScreenModified,
	lastLibraryView: publish.lastLibraryView,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	changeRouteAfterInspectScreen: (route) => dispatch(changeRouteAfterInspectScreen(route)),
	updateSelectedKey: (key) => dispatch(updateSelectedKey(key)),
	updateSubSelectedKey: (parent, key) => dispatch(updateSubSelectedKey(parent, key)),
	updateBreadCrumbLocationArray: (key, newArray) => dispatch(updateBreadCrumbLocationArray(key, newArray)),
	setSelectedCatalogId: (id) => dispatch(setSelectedCatalogId(id)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(BreadCrumb);
