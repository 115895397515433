import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showNotification } from './../../../../../actions/notificationActions';
import { fetchPlayerEmbedConfiguration } from './../../../../../actions/publishActions';
import './CopyAndPreview.scss';
import copy_link from '../../../../../assets/images/copy_link_n.svg';
import preview from '../../../../../assets/images/eye_preview.svg';
import download_media from '../../../../../assets/images/download-media.svg';
import { renditionTypes } from '../../../../../constants/mediaConstants';
import { updateDownloadLink } from '../../../../../actions/inspectScreenActions';

class CopyAndPreview extends Component {
	state = {
		previewLinkCopied: false,
		themeOptionsDefault: '',
		autoStartCheck: true,
		replayCheck: true,
		socialSharingCheck: true,
		videoData: [],
	};

	UNSAFE_componentWillMount() {
		this.getData();
		this.props.fetchPlayerEmbedConfiguration(this.props.defaultAccountId).then((data) => {
			this.setState({
				themeOptions: data,
				themeOptionsDefault: data && data.length > 0 ? this.props.playerConfigId : '',
			});
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.playerConfigId !== this.props.playerConfigId) {
			this.setState({ themeOptionsDefault: nextProps.playerConfigId });
		}
	}

	copyPreviewLink = (_) => {
		this.setState({ previewLinkCopied: true });
		this.props.showNotification(this.props.t('LABEL_PREVIEW_LINK_COPIED'));
	};

	getSortedData = (list, property) => {
		let sortedArray = list.sort((a, b) => (a[property] <= b[property] ? 1 : -1));
		return sortedArray;
	};

	getData = () => {
		const { mediaDetails, setDownloadLink } = this.props;
		let videos = [];
		mediaDetails &&
			mediaDetails.asset &&
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.map((resource) => {
				if (resource && resource.renditions) {
					resource.renditions.map((rendition) => {
						let link = rendition.links ? rendition.links[0].href : '';
						let arr = link ? link.split('/') : rendition.path ? rendition.path.split('/') : [];
						let lastName = arr.length > 0 ? arr[arr.length - 1] : '';
						let defaultName = '...' + (link ? link.slice(-17) : '');
						let name = lastName || defaultName;
						let genericObj = {
							resourceId: resource.id,
							renditionId: rendition.id,
							name: name,
							link: link,
						};

						switch (rendition.type) {
							case renditionTypes.VIDEO: {
								let tempVideoObj = {};
								if (rendition.videos && rendition.videos.length > 0) {
									tempVideoObj = {
										...rendition.videos[0],
									};
								}
								let videoObj = {
									...genericObj,
									...rendition,
									...tempVideoObj,
								};
								videos.push(videoObj);
								break;
							}
							default:
								break;
						}
					});
				}
			});
		videos = this.getSortedData(videos, 'bitrate');
		setDownloadLink(videos.length > 0 ? videos[0].link + '?download=true' : '#');
		this.setState({
			videoData: videos,
		});
	};

	render() {
		const { videoWidth, previewLink } = this.props;
		const { videoData } = this.state;
		const downloadLink = videoData.length > 0 ? videoData[0].link + '?download=true' : '#';

		return (
			<>
				<div id="linksSection">
					<div className="previewAndShareBlock linksBlock" style={{ width: videoWidth + 'px' }}>
						<CopyToClipboard text={previewLink} onCopy={this.copyPreviewLink}>
							<button className="borderWrapperCopy">
								<img src={copy_link} /> <span>Copy link</span>
							</button>
						</CopyToClipboard>
						<button className="borderWrapperPreview">
							<a target="_blank" href={previewLink}>
								<img src={preview} /> <span>Preview</span>
							</a>
						</button>
						<>
							{videoData.length > 0 ? (
								<a className="borderWrapperDownload" target="_blank" href={downloadLink} download>
									<img src={download_media} />
								</a>
							) : (
								''
							)}
						</>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, inspectScreenReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	playerConfigId: inspectScreenReducer.playerConfigId,
	videoPlayerMuted: inspectScreenReducer.videoPlayerMuted,
	previewLink: inspectScreenReducer.previewLink,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	fetchPlayerEmbedConfiguration: (accId) => dispatch(fetchPlayerEmbedConfiguration(accId)),
	setDownloadLink: (downloadLink) => {
		dispatch(updateDownloadLink(downloadLink));
	},
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CopyAndPreview);
