import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// import { getPermission } from '../../../../actions/permissionActions';
import { featurePermissionsList } from '../../../../constants/featurePermissionsConstant';

class UserPermissionsList extends Component {
	getFeatureName = (featureId) => {
		const feature = (this.props.featurePermissions ?? []).find((f) => f.featureId === featureId);

		if (!feature) {
			return '-';
		}

		if (feature.featureId === featurePermissionsList.scenarios.featureId) {
			return featurePermissionsList.scenarios.name;
		}

		if (feature.featureId === featurePermissionsList.interact.featureId) {
			return featurePermissionsList.interact.name;
		}

		return feature.name;
	};

	render() {
		const { userPermissions } = this.props;
		const list = userPermissions && Array.isArray(userPermissions) && userPermissions.slice(0, 6); // Max six items
		return (
			<>
				{list &&
					list.map(
						(permission, i) =>
							this.getFeatureName(permission.featureId) + (list.length === i + 1 ? '...' : ', ')
					)}
			</>
		);
	}
}

UserPermissionsList.propTypes = {
	userId: PropTypes.string.isRequired,
};

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(UserPermissionsList);
