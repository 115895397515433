import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { showNotification } from '../../../../../actions/notificationActions';
import { getRestrictionRules } from '../../../../../actions/publishActions';
import PropTypes from 'prop-types';
import './AccessRestriction.scss';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import 'date-fns';

import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';

import { mediaTypes, messageTypes } from './../../../../../constants/mediaConstants';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Chip from '@material-ui/core/Chip';
import {
	updateExistingRestrictionRule,
	deleteRestrictionRule,
	createNewRestrictionRule,
	applyRestrictionRuleOnMedia,
	deleteRestrictionRuleOnMedia,
} from '../../../../../actions/publishActions';
import _ from 'underscore';
import { isRequestFail } from './../../../../../services/inspectScreenHelperService';
import RestrictionRuleDialog from './RestrictionRuleDialog/RestrictionRuleDialog';
import { generateRestrictionId } from '../../../../../services/stringHelperService';
import { joinClassNames } from '../../../../../services/elementHelperService';

const styles = (theme) => ({
	root: {
		width: '100%',
	},
	formControl: {
		margin: theme.spacing(2),
		marginLeft: 0,
		minWidth: 500,
	},
	select: {
		minWidth: 300,
		position: 'relative',
		backgroundColor: 'transparent',
		fontSize: 16,
		width: 'auto',
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 1,
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class AccessRestriction extends Component {
	state = {
		openDialog: false,
		newSelectedRuleId: '',
		currentSelectedRule: {},
		showUnPublishTime: false,
		restrictionRulesOfAccId: [],
		fromDateRule: null,
		toRuleDate: null,
		selectedRestrictionDetails: '',
		appliedDateRuleId: '',
		selectedRulesForMedia: [],
		inheritedRestrictions:
			this.props.mediaDetails && this.props.mediaDetails.inheritedRestrictions
				? this.props.mediaDetails.inheritedRestrictions
				: [],
		appliedRestrictions:
			this.props.mediaDetails && this.props.mediaDetails.appliedRestrictions
				? this.props.mediaDetails.appliedRestrictions
				: [],
		listOfIpRulesInRule: [],
		secretTokenOfRule: '',
		disableView: false,
		allRules: [],
	};

	constructor(props) {
		super(props);
		this.secretTokenInput = null;
		this.calendarRef = React.createRef();
		this.timepickerRef = React.createRef();
		this.toCalendarRef = React.createRef();
		this.toTimepickerRef = React.createRef();
	}

	UNSAFE_componentWillMount() {
		this.getRestrictionRules();
	}

	componentDidUpdate() {
		this.validatePublishDates();
	}

	componentDidMount() {
		if (this.props.onAccessComponentLoaded) {
			this.props.onAccessComponentLoaded(this);
		}
	}

	onRevertChanges = (changes) => {
		this.setState({
			fromDateRule: changes.fromDateRule,
			toRuleDate: changes.toRuleDate,
			selectedRestrictionDetails: changes.selectedRestrictionDetails,
			showUnPublishTime: changes.toRuleDate ? true : false,
		});
	};

	clearAllRules = () => {
		this.setState({
			allRules: [],
		});
	};

	getRestrictionRules = () => {
		const { defaultAccountId, mediaDetails } = this.props;
		this.clearAllRules();
		this.props.getRestrictionRules(defaultAccountId).then((data) => {
			// to check if rules are more than 200. right now backend does not give correct response when set limit to 200- tried with https://video.qbrick.com/api/v1/accounts/123780/restrictionRules?limit=1
			if (data && data.message) {
				this.props.showNotification(data.message);
				return;
			}
			if (data && !data.map) {
				this.props.showNotification(data);
				return;
			}
			if (data && data.map) {
				let rules = [];
				data.map((rule) => {
					if (rule.rules) {
						let found_rule = false;
						rule.rules.map((r) => {
							if (['Ip', 'Token'].indexOf(r.type) >= 0) {
								found_rule = true;
							}
						});

						if (found_rule && rule.hasOwnProperty('name')) {
							//displaying only if rule is having a name // to be checked
							rules.push(rule);
						}
					}

					// if(mediaDetails.appliedRestrictions == rule.id)
					if (
						mediaDetails.appliedRestrictions &&
						mediaDetails.appliedRestrictions.length > 0 &&
						mediaDetails.appliedRestrictions.indexOf(rule.id) >= 0
					) {
						this.setState({ allRules: [...this.state.allRules, rule] });
						if (rule.hasOwnProperty('rules')) {
							rule.rules.map((r) => {
								if (r.type == 'Date') {
									this.setState({
										appliedDateRuleId: rule.id,
									});
									this.setState({
										fromDateRule: r.from,
									});

									if (this.state.toRuleDate != '' && this.isIsoDate(r.to + 'Z')) {
										this.setState({
											toRuleDate: r.to,
										});
										this.setState({
											showUnPublishTime: true,
										});
									} else {
										this.setState({
											toRuleDate: '',
										});
										this.setState({
											showUnPublishTime: false,
										});
									}
								} else {
									//set selected rule with ip/token restriction
									this.setState({
										currentSelectedRule: rule,
										newSelectedRuleId: rule.id,
									});
									if (r.type == 'Ip') {
										this.state.listOfIpRulesInRule.push(r);
									} else if (r.type == 'Token') {
										this.setState({
											secretTokenOfRule: r.secret,
										});
									}
								}
							});
							//rules for publis from and to dates
						}
					}
					// rules.push(rule)
				});
				this.setState({
					restrictionRulesOfAccId: rules,
				});
			}
		});
	};

	enableOrDisableUnpublishTime = () => {
		this.setState(
			(prevState) => ({
				showUnPublishTime: !prevState.showUnPublishTime,
			}),
			() => {
				/*  this.state.allRules.map((rule) => {
                 if (rule.rules.some(e => e.type === 'Date')) {
                     this.setState({ appliedDateRuleId: rule.id })
                 }
             }) */
				if (this.state.showUnPublishTime === false) {
					this.setToDate(null);
				}
			}
		);
		// this.sendChangeDetection()
	};

	sendChangeDetection = () => {
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange();
		}
	};

	handleFromDateChange = (date) => {
		if (date === null) {
			return;
		}

		this.setState({
			fromDateRule: date.toISOString(),
		});
		// this.sendChangeDetection()
	};

	handleToDateChange = (date) => {
		if (date === null) {
			return;
		}

		this.setState({
			toRuleDate: date.toISOString(),
		});
		//  this.sendChangeDetection()
	};

	getCalendarClockIcon = () => {
		return (
			<svg
				style={{
					width: '25px',
					height: '25px',
					fill: '#878cac',
				}}
				className="dateTimeIcon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 60.24 57.93"
			>
				<defs />
				<title>Calendar_clock</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="M48.5,36.7c-1.2,1.17-2.37,2.37-3.55,3.55l-.53.53-.54-.54c-1.15-1.15-2.29-2.32-3.46-3.45a2,2,0,0,0-2.8-.11,1.87,1.87,0,0,0-.09,2.64c1.84,1.9,3.71,3.78,5.62,5.61a1.78,1.78,0,0,0,2.62-.09c1.82-1.8,3.64-3.61,5.44-5.44a1.85,1.85,0,0,0,0-2.67A1.88,1.88,0,0,0,48.5,36.7Z" />
						<path d="M9.69,11.56a1.91,1.91,0,0,0,1.9-1.91V1.91a1.91,1.91,0,1,0-3.81,0V9.65A1.91,1.91,0,0,0,9.69,11.56Z" />
						<path d="M40.42,11.56a1.92,1.92,0,0,0,1.91-1.91V1.91a1.91,1.91,0,1,0-3.82,0V9.65A1.92,1.92,0,0,0,40.42,11.56Z" />
						<rect x="21.38" y="20.36" width="7.08" height="7.22" />
						<rect x="8.66" y="20.36" width="7.08" height="7.22" />
						<rect x="8.66" y="31.63" width="7.08" height="7.22" />
						<path d="M24,42.1H3.62a.36.36,0,0,1-.36-.36V17.28a.36.36,0,0,1,.36-.36H46.49a.36.36,0,0,1,.36.36v4.87a17.75,17.75,0,0,1,3.26.75V10.61a3.21,3.21,0,0,0-3.21-3.2H44.33V9.65a3.91,3.91,0,1,1-7.82,0V7.41H13.59V9.65a3.91,3.91,0,1,1-7.81,0V7.41H3.2A3.2,3.2,0,0,0,0,10.61V42.73a3.21,3.21,0,0,0,3.2,3.21H24.46A17.38,17.38,0,0,1,24,42.1Z" />
						<path d="M44.41,26.27A15.83,15.83,0,1,0,60.24,42.1,15.85,15.85,0,0,0,44.41,26.27Zm0,27.66A11.83,11.83,0,1,1,56.24,42.1,11.84,11.84,0,0,1,44.41,53.93Z" />
						<path d="M21.38,38.86h3.06a20.1,20.1,0,0,1,2.66-7.23H21.38Z" />
						<path d="M41.21,22.13V20.36H34.13v4.32A19.89,19.89,0,0,1,41.21,22.13Z" />
					</g>
				</g>
			</svg>
		);
	};

	getCalendarIcon = () => {
		return (
			<svg
				style={{
					width: '25px',
					height: '25px',
					fill: '#878cac',
				}}
				className="dateTimeIcon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 30.24 35.93"
			>
				<defs />
				<title>Calendar</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							d="M27.96,1.375h-0.682V1c0-0.552-0.447-1-1-1c-0.552,0-1,0.448-1,1v0.375h-1.837V1c0-0.552-0.448-1-1-1s-1,0.448-1,1v0.375
                        h-1.837V1c0-0.552-0.447-1-1-1c-0.552,0-1,0.448-1,1v0.375h-1.837V1c0-0.552-0.448-1-1-1c-0.552,0-1,0.448-1,1v0.375h-1.837V1
                        c0-0.552-0.448-1-1-1c-0.552,0-1,0.448-1,1v0.375H8.094V1c0-0.552-0.448-1-1-1c-0.552,0-1,0.448-1,1v0.375H5.412
                        c-2.605,0-4.725,2.12-4.725,4.726v22.547c0,2.605,2.12,4.727,4.727,4.727H27.96c2.606,0,4.728-2.12,4.728-4.727V6.101
                        C32.687,3.495,30.567,1.375,27.96,1.375z M25.78,1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6.482c0,0.276-0.224,0.5-0.5,0.5
                        s-0.5-0.224-0.5-0.5V1z M21.943,1c0-0.276,0.225-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v6.482c0,0.276-0.224,0.5-0.5,0.5
                        c-0.275,0-0.5-0.224-0.5-0.5V1z M18.106,1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6.482c0,0.276-0.224,0.5-0.5,0.5
                        s-0.5-0.224-0.5-0.5V1z M14.269,1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6.482c0,0.276-0.224,0.5-0.5,0.5
                        s-0.5-0.224-0.5-0.5V1z M10.432,1c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v6.482c0,0.276-0.224,0.5-0.5,0.5
                        s-0.5-0.224-0.5-0.5V1z M6.595,1c0-0.276,0.224-0.5,0.5-0.5c0.276,0,0.5,0.224,0.5,0.5v6.482c0,0.276-0.224,0.5-0.5,0.5
                        c-0.276,0-0.5-0.224-0.5-0.5V1z M30.279,28.648c0,1.277-1.04,2.317-2.318,2.317H5.414c-1.278,0-2.318-1.04-2.318-2.317V6.101
                        c0-1.278,1.04-2.318,2.318-2.318h0.682v2.513c-0.339,0.286-0.56,0.709-0.56,1.187c0,0.86,0.699,1.56,1.56,1.56
                        c0.861,0,1.56-0.699,1.56-1.56c0-0.478-0.221-0.9-0.56-1.187V3.783h1.837v2.513c-0.339,0.286-0.56,0.709-0.56,1.187
                        c0,0.86,0.699,1.56,1.56,1.56c0.861,0,1.56-0.699,1.56-1.56c0-0.478-0.221-0.9-0.56-1.187V3.783h1.837v2.513
                        c-0.339,0.286-0.56,0.709-0.56,1.187c0,0.86,0.699,1.56,1.56,1.56s1.56-0.699,1.56-1.56c0-0.478-0.221-0.9-0.56-1.187V3.783h1.837
                        v2.513c-0.339,0.286-0.56,0.709-0.56,1.187c0,0.86,0.699,1.56,1.56,1.56s1.561-0.699,1.561-1.56c0-0.478-0.222-0.9-0.561-1.187
                        V3.783h1.837v2.513c-0.339,0.286-0.56,0.709-0.56,1.187c0,0.86,0.699,1.56,1.56,1.56c0.861,0,1.56-0.699,1.56-1.56
                        c0-0.478-0.221-0.9-0.56-1.187V3.783h1.837v2.513c-0.339,0.286-0.56,0.709-0.56,1.187c0,0.86,0.698,1.56,1.56,1.56
                        s1.561-0.699,1.561-1.56c0-0.478-0.221-0.9-0.561-1.187V3.783h0.682c1.277,0,2.317,1.04,2.317,2.318L30.279,28.648L30.279,28.648z
                        "
						/>
						<rect x="11.322" y="13.089" width="4.596" height="4.089" />
						<rect x="17.458" y="13.089" width="4.595" height="4.089" />
						<rect x="23.591" y="13.089" width="4.596" height="4.089" />
						<rect x="5.187" y="18.547" width="4.596" height="4.086" />
						<rect x="11.322" y="18.547" width="4.596" height="4.086" />
						<rect x="17.458" y="18.547" width="4.595" height="4.086" />
						<rect x="23.591" y="18.547" width="4.596" height="4.086" />
						<rect x="5.187" y="24.003" width="4.596" height="4.086" />
						<rect x="11.322" y="24.003" width="4.596" height="4.086" />
						<rect x="17.458" y="24.003" width="4.595" height="4.086" />
					</g>
				</g>
			</svg>
		);
	};

	getClockIcon = () => {
		return (
			<svg
				style={{
					width: '20px',
					height: '20px',
					fill: '#878cac',
				}}
				enableBackground="new 0 0 443.294 443.294"
				viewBox="-50 -60 443.294 443.294"
				xmlns="http://www.w3.org/2000/svg"
			>
				<defs />
				<title>Calendar</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="m343.59375 101.039062c-7.953125 3.847657-11.28125 13.417969-7.433594 21.367188 10.511719 21.714844 15.839844 45.121094 15.839844 69.59375 0 88.222656-71.777344 160-160 160s-160-71.777344-160-160 71.777344-160 160-160c36.558594 0 70.902344 11.9375 99.328125 34.519531 6.894531 5.503907 16.976563 4.351563 22.480469-2.566406 5.503906-6.914063 4.351562-16.984375-2.570313-22.480469-33.652343-26.746094-76-41.472656-119.238281-41.472656-105.863281 0-192 86.136719-192 192s86.136719 192 192 192 192-86.136719 192-192c0-29.335938-6.40625-57.449219-19.039062-83.527344-3.839844-7.96875-13.441407-11.289062-21.367188-7.433594zm0 0" />
						<path d="m192 64c-8.832031 0-16 7.167969-16 16v112c0 8.832031 7.167969 16 16 16h80c8.832031 0 16-7.167969 16-16s-7.167969-16-16-16h-64v-96c0-8.832031-7.167969-16-16-16zm0 0" />
					</g>
				</g>
			</svg>
		);
	};

	handleChange = (event) => {
		this.setState({ selectedRulesForMedia: event.target.value });
		// this.sendChangeDetection()
	};

	checkIfRuleExistsInExistingRules = (id) => {
		const { selectedRulesForMedia } = this.state;
		for (let i = 0; i < selectedRulesForMedia.length; i++) {
			if (id == selectedRulesForMedia[i].id) {
				return true;
			}
		}
		return false;
	};

	handleRemoveRule = (e, rule) => {
		const { restrictionRulesOfAccId, inheritedRestrictions } = this.state;
		if (inheritedRestrictions.indexOf && inheritedRestrictions.indexOf(rule.id) !== -1) {
			return;
		}
		if (rule && rule.id) {
			let allRules = {
				...restrictionRulesOfAccId,
			};
			let filteredRules = _.filter(allRules, function (eachRule) {
				return eachRule.id !== rule.id;
			});
			this.setState({
				restrictionRulesOfAccId: filteredRules,
			});
			//this.sendChangeDetection()
		}
	};

	isIsoDate = (str) => {
		return moment(str, 'YYYY-MM-DDTHH:mm:00+00:00Z', true).isValid();
	};

	validateIPaddress = (ipaddress) => {
		// this validates ipv4 and ipv6 but not ipv6 with url and port
		if (
			/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
				ipaddress
			)
		) {
			return true;
		}
		return false;
	};

	getCloseIcon = () => {
		return (
			<svg
				style={{
					fill: 'white',
				}}
				className="closeIcon"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 19.5 19.5"
			>
				{' '}
				<defs />
				<title>X</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z"
						/>
						<path
							className="cls-1"
							d="M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z"
						/>
					</g>
				</g>
			</svg>
		);
	};
	getLockIcon = () => {
		return (
			<svg
				style={{
					fill: 'white',
					height: '12px',
					width: '12px',
				}}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 9.7 12.14"
			>
				<defs />
				<title>Lock</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							id="Path_8952"
							data-name="Path 8952"
							className="cls-1"
							d="M7.77,4.44h0V3a2.92,2.92,0,1,0-5.84-.1V4.44h0A1.92,1.92,0,0,0,0,6.37v3.84a1.93,1.93,0,0,0,1.93,1.93H7.77A1.93,1.93,0,0,0,9.7,10.21V6.37A1.92,1.92,0,0,0,7.77,4.44ZM2.89,3A2,2,0,1,1,6.8,2.89V4.44H2.9Zm2,7.17a1,1,0,0,1-1-1,1.07,1.07,0,0,1,.54-.92V6.94a.49.49,0,0,1,.56-.43.5.5,0,0,1,.43.43V8.17a1,1,0,0,1-.48,2Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	getExpandIcon = () => {
		return (
			<svg
				style={{
					fill: '#878cac',
					height: '12px',
					width: '12px',
				}}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 15.51 9.66"
			>
				<defs />
				<title>Arrow in case you need it</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							className="cls-1"
							d="M0,1.88A1.89,1.89,0,0,1,3.13.47L7.81,4.6,12.36.49a1.88,1.88,0,1,1,2.53,2.79l-7,6.38L.64,3.3A1.89,1.89,0,0,1,0,1.88Z"
						/>
					</g>
				</g>
			</svg>
		);
	};

	renderListOfRules = () => {
		const { classes } = this.props;
		const { restrictionRulesOfAccId, selectedRulesForMedia } = this.state;

		return (
			<>
				<label> Attach new restriction rule </label>
				<FormControl variant="outlined" className={classes.formControl}>
					<InputLabel htmlFor="select-multiple-checkbox">Click to see options</InputLabel>
					<Select
						className={classes.select}
						value={selectedRulesForMedia}
						onChange={this.handleChange}
						input={<OutlinedInput id="select-multiple-checkbox" labelWidth={0} />}
						renderValue={(selected) => (
							<div className={classes.chips}>
								{selected.map((value) => (
									<Chip key={value.id} label={value.name} className={classes.chip} />
								))}
							</div>
						)}
						MenuProps={MenuProps}
					>
						{restrictionRulesOfAccId.map((rule) => (
							<MenuItem key={rule.name} value={rule}>
								<Checkbox checked={this.checkIfRuleExistsInExistingRules(rule.id)} />
								<ListItemText primary={rule.name} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</>
		);
	};

	setStartDate = (date) => {
		if (date !== null && date != '') {
			if (date.getYear() < 200 && date.getYear() > 0) {
				this.setState({
					fromDateRule: date.toISOString(),
				});
			} else {
				return '';
			}
		} else {
			this.setState({
				fromDateRule: '',
			});
		}
	};

	setStartTime = (date) => {
		if (date === null || date.getYear() > 200 || date.getYear() < 0) {
			return;
		}

		this.setState({
			fromDateRule: date.toISOString(),
		});
		this.state.allRules.some((rule) => {
			if (rule.rules.some((e) => e.type === 'Date')) {
				this.setState({ appliedDateRuleId: rule.id });
			}
		});
	};

	setToDate = (date) => {
		if (date !== null && date != '') {
			if (date.getYear() < 200 && date.getYear() > 0) {
				this.setState({
					toRuleDate: date.toISOString(),
				});
			} else {
				return '';
			}
		} else {
			this.setState({
				toRuleDate: '',
			});
		}
		this.state.allRules.some((rule) => {
			if (rule.rules.some((e) => e.type === 'Date')) {
				this.setState({ appliedDateRuleId: rule.id });
			}
		});
	};

	setToTime = (date) => {
		if (date === null || date.getYear() > 200 || date.getYear() < 0) {
			return;
		}

		this.setState({
			toRuleDate: date.toISOString(),
		});
	};

	renderDateRule = () => {
		const { t } = this.props;
		const { showUnPublishTime, fromDateRule, toRuleDate, disableView } = this.state;
		return (
			<>
				<div className="unPublishTimeSection" id="date_picker_from">
					<label className="headerRegular"> {t('PUBLISH_FROM')} </label>
					<div className="date_time_container">
						<div className="dateFieldContainer">
							<DatePicker
								ref={this.calendarRef}
								dateFormat="dd/MM/yyyy"
								type={'date'}
								id="outlined-helperText"
								className="dateField"
								selected={fromDateRule ? new Date(fromDateRule) : ''}
								//value={fromDateRule}
								disabled={disableView}
								//variant="outlined"
								onChange={(date) => this.setStartDate(date)}
							/>
							<IconButton
								className="calender_icon"
								onClick={() => {
									this.calendarRef.current.setOpen(true);
								}}
							>
								{this.getCalendarIcon()}
							</IconButton>
						</div>
						<div className="timeFieldContainer">
							<DatePicker
								selected={fromDateRule ? new Date(fromDateRule) : ''}
								ref={this.timepickerRef}
								className="timeField"
								showTimeSelect
								showTimeSelectOnly
								dateFormat="HH:mm"
								timeIntervals={30}
								disabled={disableView}
								onChange={(date) => this.setStartTime(date)}
							/>
							<IconButton
								className="calender_icon"
								onClick={() => this.timepickerRef.current.setOpen(true)}
							>
								{this.getClockIcon()}
							</IconButton>
						</div>
					</div>

					{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>

                        <DateTimePicker id='datepicker_from' value={fromDateRule} disabled={disableView}
                            InputProps={{ className: 'datepicker_from' }}
                            // format="dd-MM-yyyy HH:mm"
                            format="MMMM d, yyyy h:mm aa"
                            margin="normal"
                            label={t('PUBLISH_FROM')}
                            onChange={this.handleFromDateChange}
                            clearable
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            {this.getCalendarClockIcon()}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </MuiPickersUtilsProvider> */}
				</div>{' '}
				<br />
				<div className="unPublishTimeSection">
					<div className="round" id="enable_unpublished_section">
						<input
							type="checkbox"
							disabled={disableView}
							id="unpublishTime"
							onChange={this.enableOrDisableUnpublishTime}
							checked={showUnPublishTime}
						/>
						<label htmlFor="unpublishTime" className="unpublishTime_checkbox"></label>
						<span> {t('ENABLE_UNPUBLISH_TIME')} </span>
					</div>
					<div
						className={showUnPublishTime ? 'unPublishDateTimeSelection' : 'unPublishDateTimeSelection hide'}
					>
						{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker value={toRuleDate} disabled={disableView}
                                format="MMMM d, yyyy h:mm aa"
                                margin="normal"
                                label={t('PUBLISH_TO')}
                                onChange={this.handleToDateChange}
                                clearable
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                {this.getCalendarClockIcon()}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider> */}

						<label className="headerRegular"> {t('PUBLISH_TO')} </label>
						<div className="date_time_container">
							<div className="dateFieldContainer">
								<DatePicker
									//format={'DD/MM/YYYY'}
									dateFormat="dd/MM/yyyy"
									type={'date'}
									ref={this.toCalendarRef}
									id="outlined-helperText"
									className="dateField"
									selected={toRuleDate ? new Date(toRuleDate) : ''}
									//value={fromDateRule}
									disabled={disableView}
									//variant="outlined"
									onChange={(date) => this.setToDate(date)}
								/>
								<IconButton
									className="calender_icon"
									onClick={() => this.toCalendarRef.current.setOpen(true)}
								>
									{this.getCalendarIcon()}
								</IconButton>
							</div>
							<div className="timeFieldContainer">
								<DatePicker
									selected={this.state.toRuleDate ? new Date(this.state.toRuleDate) : ''}
									className="timeField"
									disabled={disableView}
									ref={this.toTimepickerRef}
									showTimeSelect
									showTimeSelectOnly
									dateFormat="HH:mm"
									timeIntervals={30}
									onChange={(date) => this.setToTime(date)}
								/>
								<IconButton
									className="calender_icon"
									onClick={() => this.toTimepickerRef.current.setOpen(true)}
								>
									{this.getClockIcon()}
								</IconButton>
							</div>
							<div style={{ clear: 'both' }} />
						</div>
					</div>
					{!this.validatePublishDates() && (
						<div className="notAllowedInputText">{t('OVERLAPPING_DATES')}</div>
					)}
					<br />
					<label className="headerRegular"> {t('TIMEZONE')} </label>
					<label className="timezone"> {'UTC ' + moment().format('Z')} </label>

					<a
						variant="contained"
						className="saveButton"
						onClick={() => {
							this.clearDateValues();
						}}
						style={{ color: 'red' }}
					>
						{t('CLEAR_DATES')}
					</a>
				</div>
			</>
		);
	};

	clearDateValues = () => {
		this.setState({
			fromDateRule: null,
			toRuleDate: null,
		});
	};

	renderActionButtons = () => {
		const { t, mediaType, showNotification } = this.props;

		const isTrashedMedia = mediaType === mediaTypes.trashedMedias;

		return (
			<>
				<div className="accessActionButtons">
					{
						<>
							{this.checkIfDateRuleExists() && (
								<Button
									disabled={
										isTrashedMedia ||
										(this.state.showUnPublishTime && this.state.toRuleDate === null)
									}
									variant="contained"
									className="saveButton fullWidthButton"
									onClick={() => {
										if (isTrashedMedia) {
											return showNotification(t('RESTORE_TO_CHANGE'));
										}
										this.updateRule();
									}}
								>
									{t('SAVE_SETTINGS')}
								</Button>
							)}

							{!this.checkIfDateRuleExists() && (
								<Button
									disabled={
										isTrashedMedia ||
										(this.state.showUnPublishTime && this.state.toRuleDate === null)
									}
									variant="contained"
									className="saveButton fullWidthButton"
									onClick={() => {
										if (isTrashedMedia) {
											return showNotification(t('RESTORE_TO_CHANGE'));
										}
										this.createNewRule();
									}}
								>
									{t('SAVE_SETTINGS')}
								</Button>
							)}
						</>
					}
				</div>
			</>
		);
	};

	createNewRule = () => {
		const { t, mediaDetails, mediaNames } = this.props;

		this.setState({
			disableView: true,
		});

		//rule assign for pre saved rules from dropdown
		if (
			!this.state.currentSelectedRule ||
			(this.state.currentSelectedRule && this.state.currentSelectedRule.id != this.state.newSelectedRuleId)
		) {
			if (this.state.currentSelectedRule) {
				this.props
					.deleteRestrictionRuleOnMedia(
						this.props.defaultAccountId,
						mediaNames,
						mediaDetails.id,
						this.state.currentSelectedRule.id
					)
					.then((data) => {
						this.setState({
							disableView: false,
						});

						if (isRequestFail(this, data)) {
							this.props.showMessage(t('FAILED_REMOVE_RESTRICTION_RULE'), messageTypes.error);
							return;
						}
						if (!this.state.newSelectedRuleId) {
							this.props.showMessage(t('REMOVED_RESTRICTION_RULE_SUCCESSFULLY'), messageTypes.info);
							return;
						}
					});
			}

			if (this.state.newSelectedRuleId != '') {
				this.props
					.applyRestrictionRuleOnMedia(this.props.defaultAccountId, mediaNames, mediaDetails.id, {
						id: this.state.newSelectedRuleId,
					})
					.then((data) => {
						this.setState({
							disableView: false,
						});

						if (isRequestFail(this, data)) {
							this.props.showMessage(t('FAILED_ASSIGN_RESTRICTION_RULE'), messageTypes.error);
							return;
						}

						this.props.showMessage(t('ASSIGN_RESTRICTION_RULE_SUCCESSFULLY'), messageTypes.info);
					});
			}
		}

		var body = {};
		var newGenaratedRuleId = generateRestrictionId();
		var newGenaratedRuleName = generateRestrictionId();
		if (this.state.showUnPublishTime) {
			body = {
				name: newGenaratedRuleName,
				id: newGenaratedRuleId,
				rules: [{ from: this.state.fromDateRule, to: this.state.toRuleDate, type: 'Date' }],
			};
		} else {
			body = {
				name: newGenaratedRuleName,
				id: newGenaratedRuleId,
				rules: [{ from: this.state.fromDateRule, type: 'Date' }],
			};
		}

		//rule assign for date rules
		if (this.state.fromDateRule || this.state.toRuleDate) {
			this.props.createNewRestrictionRule(this.props.defaultAccountId, body).then((data) => {
				this.setState({
					disableView: false,
				});

				if (isRequestFail(this, data)) {
					this.props.showMessage(t('FAILED_CREATE_RESTRICTION_RULE'), messageTypes.error);
					return;
				}

				this.props.showMessage(t('SAVED_DETAILS_SUCCESSFULLY'), messageTypes.info);
				this.setState({
					allRules: [...this.state.allRules, body],
				});
				if (newGenaratedRuleId != '') {
					this.props
						.applyRestrictionRuleOnMedia(this.props.defaultAccountId, mediaNames, mediaDetails.id, {
							id: newGenaratedRuleId,
						})
						.then((data) => {
							this.setState({
								disableView: false,
							});

							if (isRequestFail(this, data)) {
								this.props.showMessage(t('FAILED_ASSIGN_RESTRICTION_RULE'), messageTypes.error);
								return;
							}

							this.props.showMessage(t('ASSIGN_RESTRICTION_RULE_SUCCESSFULLY'), messageTypes.info);
						});
				}
			});
		} else {
			this.setState({
				disableView: false,
			});
		}
	};

	openNewRulePopup = () => {
		this.setState({
			openDialog: true,
		});
	};

	validatePublishDates = () => {
		let start = this.state.fromDateRule;
		let end = this.state.toRuleDate;

		var diff = new Date(end) - new Date(start);
		if (diff <= 0 && this.state.showUnPublishTime && this.state.toRuleDate) {
			return false;
		}

		return true;
	};

	updateRule = () => {
		const { t, mediaDetails, mediaNames } = this.props;

		this.setState({
			disableView: true,
		});

		let body = {};
		let delete_date_rule = false;
		if (this.state.showUnPublishTime) {
			if (this.state.fromDateRule === null || this.state.fromDateRule === '') {
				body = { rules: [{ to: this.state.toRuleDate, type: 'Date' }] };
				if (this.state.toRuleDate === null || this.state.toRuleDate === '') {
					delete_date_rule = true;
				}
			} else {
				if (this.state.toRuleDate != null && this.state.toRuleDate != '') {
					body = { rules: [{ from: this.state.fromDateRule, to: this.state.toRuleDate, type: 'Date' }] };
				} else {
					body = { rules: [{ from: this.state.fromDateRule, type: 'Date' }] };
				}
			}
		} else if (this.state.fromDateRule != null && this.state.fromDateRule != '') {
			body = { rules: [{ from: this.state.fromDateRule, type: 'Date' }] };
		} else {
			delete_date_rule = true;
		}

		if (delete_date_rule) {
			//delete existing date rule if necessarry

			let ruleIdDelete;
			if (this.state.appliedDateRuleId === '') {
				ruleIdDelete = this.state.allRules[0].id;
			} else {
				ruleIdDelete = this.state.appliedDateRuleId;
			}
			this.props
				.deleteRestrictionRuleOnMedia(this.props.defaultAccountId, mediaNames, mediaDetails.id, ruleIdDelete)
				.then((data) => {
					if (isRequestFail(this, data)) {
						this.props.showMessage(t('FAILED_ASSIGN_RESTRICTION_RULE'), messageTypes.error);
						return;
					} else {
						this.setState({
							allRules: [],
						});
					}
				});
			this.setState({
				disableView: false,
			});
			this.props.showMessage(t('SAVED_DETAILS_SUCCESSFULLY'), messageTypes.info);

			if (
				(this.state.fromDateRule === null || this.state.fromDateRule === '') &&
				(this.state.toDateRule === null || this.state.toDateRule === '')
			) {
				this.state.allRules.map((rule) => {
					if (rule.rules.some((e) => e.type === 'Date')) {
						let newArray = this.state.allRules.filter((item) => item.id !== rule.id);
						this.setState({
							allRules: newArray,
						});
					}
				});
			}
		} else {
			//update date rule

			let ruleId;
			if (this.state.appliedDateRuleId === '') {
				ruleId = this.state.allRules[0].id;
			} else {
				ruleId = this.state.appliedDateRuleId;
			}
			this.props.updateExistingRestrictionRule(this.props.defaultAccountId, ruleId, body).then((data) => {
				this.setState({
					disableView: false,
				});

				if (isRequestFail(this, data)) {
					this.props.showMessage(t('FAILED_UPDATE_RESTRICTION_RULE'), messageTypes.error);
					return;
				}

				this.props.showMessage(t('SAVED_DETAILS_SUCCESSFULLY'), messageTypes.info);
			});
		}

		if (this.state.currentSelectedRule && this.state.currentSelectedRule.id != this.state.newSelectedRuleId) {
			this.props
				.deleteRestrictionRuleOnMedia(
					this.props.defaultAccountId,
					mediaNames,
					mediaDetails.id,
					this.state.currentSelectedRule.id
				)
				.then((data) => {
					this.setState({
						disableView: false,
					});

					if (isRequestFail(this, data)) {
						this.props.showMessage(t('FAILED_ASSIGN_RESTRICTION_RULE'), messageTypes.error);
						return;
					}
				});

			if (this.state.newSelectedRuleId != '') {
				this.props
					.applyRestrictionRuleOnMedia(this.props.defaultAccountId, mediaNames, mediaDetails.id, {
						id: this.state.newSelectedRuleId,
					})
					.then((data) => {
						this.setState({
							disableView: false,
						});

						if (isRequestFail(this, data)) {
							this.props.showMessage(t('FAILED_ASSIGN_RESTRICTION_RULE'), messageTypes.error);
							return;
						}

						this.props.showMessage(t('ASSIGN_RESTRICTION_RULE_SUCCESSFULLY'), messageTypes.info);
					});
			}
		}
	};

	handleChangeRule = (e) => {
		let rule_id = e.target.value;
		this.setState({
			newSelectedRuleId: '',
		});
		this.state.restrictionRulesOfAccId &&
			this.state.restrictionRulesOfAccId.map((rule) => {
				if (rule.id === rule_id) {
					this.setState({
						newSelectedRuleId: rule.id,
					});
				}
			});
	};

	onExpansionPanelClick = (e) => {
		e.target.classList.toggle('active');
		/* Toggle between hiding and showing the active panel */
		var panel = e.target.nextElementSibling;
		if (panel.style.display === 'block' || !panel.style.display) {
			panel.style.display = 'none';
		} else {
			panel.style.display = 'block';
		}
	};

	closeInformationDialog = () => {
		this.setState({
			openDialog: false,
		});
		this.getRestrictionRules(); //refresh rule list
	};

	checkIfDateRuleExists = () => {
		let result = false;
		this.state.allRules.map((rule) => {
			if (rule.rules && rule.rules.some((e) => e.type === 'Date')) {
				result = true;
			}
		});
		return result;
	};

	render() {
		const { t, classes, mediaType, showNotification } = this.props;
		const { newSelectedRuleId } = this.state;

		return (
			<>
				<div id="accessRestrictionSection">
					<RestrictionRuleDialog
						t={t}
						open={this.state.openDialog}
						closeInformationDialog={this.closeInformationDialog}
						defaultAccountId={this.props.defaultAccountId}
						mediaDetails={this.props.mediaDetails}
						showMessage={this.props.showMessage}
					/>

					<div className="rulesListSection"></div>

					<div className="ruleDisplaySection">
						<div className={classes.root}>
							<div className="panel">{this.renderDateRule()}</div>
							<hr />
							<div id="themeSelectionBox" className="previewAndShareInternalBlock">
								<div className="fullWidthControl panel">
									<label className="headerRegular">{t('RESTRICTION_LIST_TO_SELECT')}</label>
									<select
										value={newSelectedRuleId}
										onChange={(e) => this.handleChangeRule(e)}
										className="genericDropDown"
									>
										<option key={''} value="" selected>
											{'- Select -'}
										</option>
										{this.state.restrictionRulesOfAccId &&
											this.state.restrictionRulesOfAccId.map((rule) => (
												<option key={rule.id} value={rule.id}>
													{rule.name}
												</option>
											))}
									</select>
								</div>
							</div>

							<div className="panel">
								<a
									variant="contained"
									className={joinClassNames(
										'saveButton',
										mediaType === mediaTypes.trashedMedias ? 'btn--disabled' : undefined
									)}
									onClick={() => {
										if (mediaType === mediaTypes.trashedMedias) {
											showNotification(t('RESTORE_TO_CHANGE'));
											return;
										}
										this.openNewRulePopup();
									}}
								>
									{t('MANAGE_AND_CREATE_NEW_RULE')}
								</a>
							</div>
							<div className="panel">{this.renderActionButtons()}</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

AccessRestriction.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ session, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	loading: publish.loading,
	newPlaylistTitle: publish.newPlaylistTitle,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	getRestrictionRules: (accId) => dispatch(getRestrictionRules(accId)),
	updateExistingRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(updateExistingRestrictionRule(accountId, restrictionId, body)),
	deleteRestrictionRule: (accountId, restrictionId) => dispatch(deleteRestrictionRule(accountId, restrictionId)),
	createNewRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(createNewRestrictionRule(accountId, restrictionId, body)),
	applyRestrictionRuleOnMedia: (accountId, mediaName, mediaId, body) =>
		dispatch(applyRestrictionRuleOnMedia(accountId, mediaName, mediaId, body)),
	deleteRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(deleteRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles, { withTheme: true })(AccessRestriction));
