import React from 'react';
import { useSelector } from 'react-redux';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
//for latest media menu
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { playerConfigurationsMenuKeys } from '../../constants/inspectScreenMenu';

const playerConfigurationsSubMenuItems = [
	{
		id: playerConfigurationsMenuKeys.presets,
		text: 'Presets',
	},
	{
		id: playerConfigurationsMenuKeys.playbackOptions,
		text: 'Playback options',
	},
	{
		id: playerConfigurationsMenuKeys.topControls,
		text: 'Top controls',
	},
	{
		id: playerConfigurationsMenuKeys.bottomControls,
		text: 'Bottom controls',
	},
	{
		id: playerConfigurationsMenuKeys.engagementFeatures,
		text: 'Engagement features',
	},
	{
		id: playerConfigurationsMenuKeys.accessibilitySettings,
		text: 'Accessibility settings',
	},
	{
		id: playerConfigurationsMenuKeys.playerStyling,
		text: 'Player styling',
	},
];

export const ProfileLeftMenu = ({ onClick, selectedBlock, t, isFeatureEnabled }) => {
	const [isPlayerConfigExpanded, setPlayerConfigExpanded] = React.useState(false);
	const [selectedSubItem, setSelectedSubItem] = React.useState(undefined);
	const { enabledEmbed, hasAdvancedSettings } = useSelector((state) => state.profile);

	const onSubmenuClick = (item) => {
		if (selectedBlock !== '8') {
			onClick('8');
		}
		setSelectedSubItem(item.id);

		const card = document.getElementById(item.id);
		card && card.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	};

	const onExpandIconClick = (event) => {
		event.stopPropagation();
		setPlayerConfigExpanded(!isPlayerConfigExpanded);
	};

	const onMenuItemClick = (itemId) => {
		onClick(itemId);
		setSelectedSubItem(itemId !== '8' ? undefined : playerConfigurationsSubMenuItems.presets);
	};

	return (
		<div className="profileLeftPanelMenu">
			<div
				onClick={() => onMenuItemClick('1')}
				className={`profileIconBlock ${selectedBlock == '1' ? 'selectedProfileIconBlock' : ''}`}
			>
				<div className="profileMenuItem">{t('PROFILE_DETAILS')}</div>
			</div>
			{isFeatureEnabled('Users and features') && isFeatureEnabled('Account') && (
				<div
					onClick={() => onMenuItemClick('3')}
					className={`profileIconBlock ${selectedBlock == '3' ? 'selectedProfileIconBlock' : ''}`}
				>
					<div className="profileMenuItem">{t('USERS')}</div>
				</div>
			)}
			<div
				onClick={() => onMenuItemClick('5')}
				className={`profileIconBlock ${selectedBlock == '5' ? 'selectedProfileIconBlock' : ''}`}
			>
				<div className="profileMenuItem">{t('PROFILE_CUSTOM_PROPERTIES')}</div>
			</div>
			{isFeatureEnabled('Channels') && (
				<div
					onClick={() => onMenuItemClick('6')}
					className={`profileIconBlock ${selectedBlock == '6' ? 'selectedProfileIconBlock' : ''}`}
				>
					<div className="profileMenuItem">{t('CHANNEL_MENU')}</div>
				</div>
			)}

			{hasAdvancedSettings && (
				<div
					onClick={() => onMenuItemClick('9')}
					className={`profileIconBlock ${selectedBlock == '9' ? 'selectedProfileIconBlock' : ''}`}
				>
					<div className="profileMenuItem">{t('ADVANCED_SETTINGS')}</div>
				</div>
			)}

			{enabledEmbed?.version === '2' && hasAdvancedSettings && isFeatureEnabled('QbrickPlayerEditor') && (
				<>
					<div
						onClick={() => onMenuItemClick('8')}
						className={`profileIconBlock profileIconBlock__playerConfiguration ${
							selectedBlock == '8' ? 'selectedProfileIconBlock' : ''
						}`}
					>
						<div className="profileMenuItem">Player presets</div>
						<div className="profileMenuItem__expand-icon" onClick={onExpandIconClick}>
							{isPlayerConfigExpanded ? <ExpandMore /> : <ChevronRight />}
						</div>
					</div>
					{isPlayerConfigExpanded && (
						<List className="playerConfiguration-sub-menu">
							{playerConfigurationsSubMenuItems.map((item) => (
								<ListItem
									button
									key={item.id}
									selected={selectedSubItem === item.id}
									className={[
										'playerConfiguration-sub-menu__item',
										selectedSubItem === item.id
											? 'playerConfiguration-sub-menu__item--selected'
											: '',
									]
										.join(' ')
										.trim()}
									onClick={() => onSubmenuClick(item)}
								>
									<ListItemText primary={item.text} />
								</ListItem>
							))}
						</List>
					)}
				</>
			)}
		</div>
	);
};
