import { publicURL } from '../../../utils/config';

const EXPORT_OPTION = {
	exportOptions: [
		{
			id: 'export-btn',
			label: 'Save & Export to Qbrick',
			target: 'Host',
			optionType: 'button',
			buttonType: 'custom',
		},
	],
};

export const EDITED_MEDIA_ID_PREFIX = 'aeedit';
export namespace AdobeExpressUtils {
	export interface Size {
		width: number;
		height: number;
		unit: 'px' | 'in' | 'mm';
	}
	export interface AEProjectProps {
		ccEverywhere: any;
		actionCallback: any;
		projectId?: string; // required for edit design
		mediaName?: string;
		dataUrl?: string;
		replaceMediaContent?: object;
		mediaType?: string;
		canvasSize?: Size | string;
	}

	export async function readJSON() {
		const resp = fetch(`${publicURL}/QbrickVideoEditor-196182-API-Key.json`);
		return await (await resp).json();
	}

	export async function initializeCcEverywhere() {
		try {
			const credentialJSON = await readJSON();
			const ccEverywhere = await (window as any).CCEverywhere.initialize({
				clientId: credentialJSON?.API_KEY,
				appName: credentialJSON?.name,
				platformCategory: 'web',
			});

			return ccEverywhere;
		} catch (error) {
			console.error('Error initializing ccEverywhere:', error);
			throw error;
		}
	}

	export function terminateCcEverywhere() {
		console.info('terminateCcEverywhere');
		// (window as any).CCEverywhere.terminate();
	}

	export async function createNewProject({
		ccEverywhere,
		dataUrl,
		actionCallback,
		mediaName,
		mediaType,
		canvasSize = 'Video',
	}: AEProjectProps) {
		let localData = {};
		const inputParams = dataUrl
			? {
					editorPanelView: 'media',
					canvasSize,
					...EXPORT_OPTION,
					asset: {
						// for now AE type: video is not working for some reason. Using image type worked for both image and video
						type: 'image',
						dataType: 'url',
						data: dataUrl,
					},
			  }
			: {
					editorPanelView: 'media',
					canvasSize,
					...EXPORT_OPTION,
			  };
		try {
			ccEverywhere.createDesign({
				callbacks: {
					...(actionCallback ?? {}),
					onPublish: (publishParams: any) => {
						actionCallback?.onPublish(publishParams, mediaName);
					},
				},
				modalParams: {
					canvasSize,
					parentElementId: 'aeexpress',
				},
				inputParams,
				outputParams: {
					outputType: 'url',
					allowedFileTypes:
						mediaType === 'image' ? ['image/jpeg', 'image/png'] : ['video/mp4', 'image/png', 'image/jpeg'],
				},
			});
		} catch (error) {
			console.error('Error creating design:', error);
		}

		return localData;
	}

	export async function editDesign({
		ccEverywhere,
		projectId,
		actionCallback,
		mediaName,
		mediaType,
		canvasSize = 'Video',
	}: AEProjectProps) {
		ccEverywhere &&
			ccEverywhere.editDesign({
				inputParams: {
					projectId: projectId,
					...EXPORT_OPTION,
				},
				callbacks: {
					...(actionCallback ?? {}),
					onPublish: (publishParams: any) => {
						actionCallback?.onPublish(publishParams, mediaName);
					},
				},
				modalParams: {
					canvasSize,
					parentElementId: 'aeexpress',
				},
				outputParams: {
					canvasSize,
					allowedFileTypes:
						mediaType === 'image' ? ['image/jpeg', 'image/png'] : ['video/mp4', 'image/png', 'image/jpeg'],
				},
			});
	}

	export const getMediaIdFromProjectId = (projectId: string) => {
		return `${EDITED_MEDIA_ID_PREFIX}-${new Date().getTime()}-${projectId.replaceAll(':', '-')}`;
	};

	export const getAdobeExpressProjectIdFromMediaId = (mediaId: string) => {
		const [, , a, b, c, d] = (mediaId ?? '').split('-');
		const projectUUID = mediaId.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i)?.[0];

		if (!a || !b || !c || !d || !projectUUID) {
			return null;
		}

		return `${a}:${b}:${c}:${d}:${projectUUID}`;
	};
}
