import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { makeMoveable, Draggable, Resizable, Snappable, Rotatable } from 'react-moveable';
import MoveableHelper from 'moveable-helper';
import { Menu, MenuItem } from '@material-ui/core';

import { getDefaultWidgetData } from '../../utils/helper';
import { updateWidgetPositionAction } from '../../../../actions/scenarioActions';
import { getSecondsFromTimelineTime, getTimelineTimeFromTimeInput } from '../../../../services/timeStampService';

import { updateRotation } from './widgetInteractionItem/helper';
import WidgetInteractionItem from './widgetInteractionItem/WidgetInteractionItem';

import './WidgetOverlay.scss';

const Moveable = makeMoveable([Snappable, Draggable, Resizable, Rotatable]);

const WidgetOverlay = forwardRef(
	(
		{
			className = '',
			onDropWidgetOnVideoEditor,
			activeLayout = '',
			editingScene,
			editingBox,
			editingWidget,
			onWidgetClick,
			pausePlayer,
			removeWidgetInBox,
			timelineEditorRef,
			widgetSettingRef,
		},
		ref
	) => {
		const overlayBounds = useRef(null);
		const widgetOverlaySvgRef = useRef(null);
		const dispatch = useDispatch();

		const [helper] = useState(() => {
			return new MoveableHelper();
		});
		const [isMaintainProportions, setIsMaintainProportions] = useState(true);
		const [focusElement, setFocusElement] = useState(null);
		const [isShowElement, setIsShowElement] = useState(false);
		const [playerTimePos, setPlayerTimePos] = useState(0);
		const [contextMenuItemRef, setContextMenuItemRef] = useState(null);

		const [isHoldShift, setHoldShift] = useState(false);

		const { width, height } = overlayBounds.current?.getBoundingClientRect() || {};

		const { layouts: scenesLayouts, widgetTemplates } = editingScene ?? { layouts: [], widgetTemplates: [] };

		const widgetsFromScene = useMemo(() => {
			return scenesLayouts.reduce((widgetsInLayout, curLayout) => {
				const { boxes } = curLayout;

				// For now we show all wigets no matter what layout it is
				const widgetInBoxes = boxes.reduce((allWidgets, curBox) => {
					const { widgets } = curBox;
					allWidgets.push(...(widgets || []));
					return allWidgets;
				}, []);

				widgetsInLayout.push(...widgetInBoxes);

				return widgetsInLayout;
			}, []);
		}, [scenesLayouts]);

		const widgetTemplateMaps = useMemo(() => {
			const result = new Map();
			widgetTemplates.forEach((template) => {
				result.set(template.id, template);
			});

			return result;
		}, [widgetTemplates]);

		const interactiveWidget = useMemo(() => {
			return widgetsFromScene
				.reduce((acc, widget) => {
					const { id, widgetTemplateId } = widget;
					const template = widgetTemplateMaps.get(widgetTemplateId);

					if (template && template.type !== 'video') {
						if (Object.keys(template.style ?? {}).length === 0) {
							template.style = getDefaultWidgetData(id, template.name, template.type).style;
						}

						acc.push({
							...template,
							...widget,
							id,
						});
					}

					return acc;
				}, [])
				.sort((widgetA, widgetB) => {
					const {
						style: { zIndex: zIndexA = 0 },
					} = widgetA;
					const {
						style: { zIndex: zIndexB = 0 },
					} = widgetB;

					return zIndexA > zIndexB ? 1 : -1;
				});
		}, [widgetsFromScene, widgetTemplateMaps]);

		const handleOnDragEnd = () => {
			handleWidgetPositionUpdate();
		};

		const handleOnResizeEnd = () => {
			handleWidgetPositionUpdate();
		};

		const handleOnRotateEnd = () => {
			handleWidgetPositionUpdate();
		};

		const handleWidgetPositionUpdate = () => {
			dispatch(updateWidgetPositionAction({ editingBox }));
		};

		const handleOnMessage = useCallback((event) => {
			if (event.data?.type === 'position') {
				setPlayerTimePos(event.data.position);
			}
			if (event.data?.type === 'set-maintain-proportion') {
				setIsMaintainProportions(
					event.data?.isMaintainProportions === undefined ? true : event.data?.isMaintainProportions
				);
			}
		}, []);

		const handleOnKeyDownInput = useCallback(
			(event) => {
				if (
					event.keyCode === 8 &&
					document.activeElement.tagName === 'BODY' &&
					focusElement?.ref &&
					focusElement?.data
				) {
					removeWidgetInBox({ ...focusElement?.data });
					setFocusElement(null);
				}

				if (event.keyCode === 16) {
					setHoldShift(true);
				}
			},
			[focusElement, removeWidgetInBox]
		);

		const handleOnKeyUpInput = useCallback((event) => {
			if (event.keyCode === 16) {
				setHoldShift(false);
			}
		}, []);

		const handleWidgetsContextMenuClick = (action) => {
			const dataset = contextMenuItemRef.current?.dataset;
			if (dataset) {
				const { widgetId, templateId } = dataset;
				timelineEditorRef.current?.updateZIndexPosition(action === 'front', widgetId, templateId);
			}
			setContextMenuItemRef(null);
		};

		useImperativeHandle(ref, () => ({
			hiddenHighlightBox: (value) => {
				setIsShowElement(value);
			},
		}));

		useEffect(() => {
			window.addEventListener('message', handleOnMessage);
			window.addEventListener('keydown', handleOnKeyDownInput);
			window.addEventListener('keyup', handleOnKeyUpInput);
			return () => {
				window.removeEventListener('message', handleOnMessage);
				window.removeEventListener('keydown', handleOnKeyDownInput);
				window.addEventListener('keyup', handleOnKeyUpInput);
			};
		}, [handleOnKeyDownInput, handleOnKeyUpInput, handleOnMessage]);

		useEffect(() => {
			setFocusElement(null);
			setIsShowElement(false);
		}, [activeLayout]);

		useEffect(() => {
			setIsShowElement(false);
			if (!editingWidget) {
				setFocusElement(null);
				return;
			}
			const { widgetTemplateId } = editingWidget;
			const element = document.getElementById(widgetTemplateId);
			if (element && element.children[0]) {
				pausePlayer?.();
				setFocusElement({ ref: { current: element.children[0] }, data: editingWidget });
				return;
			}
		}, [editingWidget, editingScene]);

		useEffect(() => {
			if (!focusElement || !focusElement?.ref?.current) {
				setIsShowElement(false);
				return;
			}

			const focusingWidgetWithTime = widgetsFromScene.find((w) => w.id === focusElement?.data?.id);
			const shouldShowMovableObject =
				playerTimePos >=
					getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(focusingWidgetWithTime?.start)) &&
				playerTimePos <= getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(focusingWidgetWithTime?.end));
			!shouldShowMovableObject && setFocusElement({ ...focusElement, ref: null });

			setIsShowElement(true);
		}, [focusElement, editingWidget, widgetsFromScene, playerTimePos]);

		return (
			<div
				className={className}
				onDragOver={(e) => {
					e.preventDefault();
				}}
				onDrop={(event) => {
					onDropWidgetOnVideoEditor(event, widgetOverlaySvgRef.current);
				}}
				ref={overlayBounds}
				onContextMenu={(e) => {
					e.preventDefault();
				}}
			>
				<div style={{ position: 'relative', width: '100%', height: '100%' }}>
					<svg
						width="100%"
						height="100%"
						viewBox={activeLayout !== 'mobile' || isMaintainProportions ? '0 0 1600 900' : '547 0 506 900'}
						ref={widgetOverlaySvgRef}
						id="svgWidgetOverlay"
					>
						{interactiveWidget.map((widget, idx) => {
							return (
								<WidgetInteractionItem
									key={`${widget.id}-${idx}`}
									data={widget}
									bounds={overlayBounds.current}
									onClickItem={(itemRef) => {
										pausePlayer?.();
										setFocusElement({
											ref: itemRef,
											data: widget,
										});
										setIsShowElement(true);
										onWidgetClick({
											boxId: '1',
											id: widget.id,
											widgetTemplateId: widget.widgetTemplateId,
										});
									}}
									playerTimePos={playerTimePos}
									onContextMenu={(ref, isShowWidget) => {
										isShowWidget && setContextMenuItemRef(ref);
									}}
									onUpdateLabel={(value) => {
										widgetSettingRef.current?.handleTextLabelChange({
											target: { value },
										});
									}}
								/>
							);
						})}
					</svg>
				</div>
				<Moveable
					target={isShowElement && focusElement?.ref ? focusElement.ref : null}
					svgOrigin="50% 50%"
					//snap
					snappable={true}
					snapDirections={{ top: true, left: true, bottom: true, right: true, center: true, middle: true }}
					verticalGuidelines={[{ pos: '50%', className: 'guideline-custom' }]}
					horizontalGuidelines={[{ pos: '50%', className: 'guideline-custom' }]}
					isDisplaySnapDigit={true}
					elementSnapDirections={{
						top: true,
						left: true,
						bottom: true,
						right: true,
						center: true,
						middle: true,
					}}
					elementGuidelines={interactiveWidget.map(({ widgetTemplateId }) => ({
						element: `.widget_${widgetTemplateId}`,
						className: 'guideline-custom',
					}))}
					snapThreshold={5}
					bounds={{ left: 0, right: width, top: 0, bottom: height }}
					//drag
					draggable={true}
					onDragStart={() => {
						pausePlayer?.();
					}}
					onDrag={({ transform, target }) => {
						target.style.transform = transform;
					}}
					onDragEnd={handleOnDragEnd}
					throttleDragRotate={isHoldShift ? 90 : 0}
					//resize
					resizable={true}
					onResizeStart={(e) => {
						e.setMin([1, 1]);
						pausePlayer?.();
						helper.onResizeStart(e);
					}}
					onResize={(e) => {
						const { delta, target, width, height } = e;
						const object = target.querySelector('foreignObject');

						if (object) {
							delta[0] && object.setAttribute('width', width);
							delta[1] && object.setAttribute('height', height);
							const currentRotation = target.style.transform.match(/rotate\((.*)deg\)/);
							helper.onResize(e);
							updateRotation(currentRotation, e);
						}
					}}
					onResizeEnd={handleOnResizeEnd}
					//rotate
					rotatable={true}
					throttleRotate={0}
					onRotateStart={() => {
						pausePlayer?.();
					}}
					onRotate={(e) => {
						const { target, transform } = e;
						target.style.transform = transform;
					}}
					onRotateEnd={handleOnRotateEnd}
				/>
				<Menu
					id="widgets-menu"
					anchorEl={contextMenuItemRef?.current}
					open={Boolean(contextMenuItemRef)}
					onClose={() => {
						setContextMenuItemRef(null);
					}}
				>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							handleWidgetsContextMenuClick('front');
						}}
					>
						Bring to front
					</MenuItem>
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							handleWidgetsContextMenuClick('back');
						}}
					>
						Send to back
					</MenuItem>
				</Menu>
			</div>
		);
	}
);

export default WidgetOverlay;
