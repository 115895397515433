import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import vis from 'vis-timeline/dist/vis-timeline-graph2d.min.js';
import 'vis-timeline/dist/vis-timeline-graph2d.min.css';
import moment from 'moment';
import { withStyles } from '@material-ui/styles';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';

import {
	addWidgetsToUpdateWaitingList,
	updateEditingSceneAndSendToPlayer,
	updateEditingSceneDurationAction,
} from '../../../actions/scenarioActions';
import {
	convertTimeToWidgetTime,
	getSecondsFromTimelineTime,
	getTimelineStartDate,
	getTimelineTimeFromSeconds,
	getTimelineTimeFromTimeInput,
} from '../../../services/timeStampService';
import { loadResourcesAfterTheFlowStartedForMedias } from '../../../services/mediaService';
import play_black from '../../../assets/images/play_black.svg';
import pause_controls from '../../../assets/images/pause_controls.svg';
import fast_forward_15 from '../../../assets/images/fast_forward_15.svg';
import fast_reward_15 from '../../../assets/images/fast_reward_15.svg';
import { WIDGET_TYPES } from '../../../constants/scenarioConstant';

import './EditorTimeline.scss';
import { showAlert } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';

const DEFAULT_BG_ID = '1';
const timelineCustomBarId = 'timeline-custom-bar-title';
const baseClassName = 'editor-timeline';
const itemClassName = `${baseClassName}__item`;
const colors = {
	[WIDGET_TYPES.WIDGET_TYPE_VIDEO]: 'blue',
	[WIDGET_TYPES.WIDGET_TYPE_BUTTON]: 'pink',
	[WIDGET_TYPES.WIDGET_TYPE_TEXT]: 'purple',
	[WIDGET_TYPES.WIDGET_TYPE_ICON]: 'yellow',
	[WIDGET_TYPES.WIDGET_TYPE_IMAGE]: 'green',
};
const boxes = [
	{
		id: DEFAULT_BG_ID,
		isBackground: true,
	},
];
const defaultGroups = boxes.map((box, i) => ({ id: box.id, content: `${box.isBackground ? 'B' : 'b'}${i + 1}` }));
const START_TIME = '00:00:00:000';

const getItemClassnames = (type) => {
	const value = colors[type] ?? 'white';
	return `${itemClassName} ${itemClassName}--${value}`;
};

const getWidgetsDuration = (widget) => {
	return moment(widget.end).diff(moment(widget.start));
};

const CustomSlider = withStyles({
	root: {
		height: 4,
	},
	thumb: {
		height: 10,
		width: 10,
		backgroundColor: '#fff',
		border: '1px solid currentColor',
		marginTop: -3,
		marginLeft: -6,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	valueLabel: {
		left: 'auto',
		top: -18,
		'& *': {
			background: 'transparent',
			color: '#000',
		},
	},
	track: {
		height: 4,
		borderRadius: 4,
	},
	rail: {
		height: 4,
		borderRadius: 4,
	},
})(Slider);

const EditorTimeline = forwardRef((props, ref) => {
	const dispatch = useDispatch();
	const editingScene = useSelector((state) => state.scenarioReducer.editingScene);
	const timelineChanged = useSelector((state) => state.scenarioReducer.timelineChanged);
	const sceneDuration = useSelector((state) => state.scenarioReducer.editingSceneDuration);
	const defaultAccountId = useSelector((state) => state.session.defaultAccountId);
	const scenario = useSelector((state) => state.scenarioReducer.scenario);

	const timeline = useRef(null);
	const movePlayerPosRef = useRef(props.movePlayerToPosition);
	const pauseRef = useRef(props.onPause);
	const [customTime, setCustomTime] = useState(moment(getTimelineTimeFromTimeInput(START_TIME)));
	const [zoomValue, setZoomValue] = useState(0);
	const [show, setShow] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isMute, setIsMuting] = useState(true);
	const [timeLineItemNeedToBeUpdated, setTimeLineItemNeedToBeUpdated] = useState([]);
	const timelineItemRef = useRef(null);
	const timelineGroupRef = useRef(null);

	const highestZIndexValueRef = useRef(0);

	const timelineMousePositionRef = useRef(0);
	const isMouseDownRef = useRef(false);

	let request = null;

	let timelineStart = null;
	let timelineEnd = null;

	useImperativeHandle(ref, () => {
		return {
			updateZIndexPosition: (isBringToFront, widgetId, templateId) => {
				updateZIndexPosition(isBringToFront, widgetId, templateId);
			},

			getHighestZIndexValue: () => {
				if (!highestZIndexValueRef.current) {
					const boxes = currentLayout.boxes ?? [];
					const numberOfWidgets = boxes.reduce((totalWidget, currBox) => {
						const { widgets } = currBox;
						if (Array.isArray(widgets)) {
							return totalWidget + widgets.length;
						}
						return totalWidget;
					}, 0);
					return numberOfWidgets;
				}
				return highestZIndexValueRef.current;
			},
		};
	});

	const currentLayout = useMemo(() => {
		let layout = editingScene?.layouts?.find((l) => l.type === props.activeLayout);
		return layout ?? editingScene?.layouts?.[0] ?? {};
	}, [props.activeLayout, editingScene]);

	const getTimelineData = () => {
		const itemsToSet = [];
		const hashedGroup = {};

		const boxes = (currentLayout.boxes ?? []).sort((a, b) => a.boxTemplateId - b.boxTemplateId);
		if (!boxes || boxes.length === 0) {
			return {
				itemsToSet: [],
				groups: defaultGroups,
			};
		}

		const sceneWidgets = editingScene?.widgetTemplates ?? [];
		if (sceneWidgets === 0) {
			return {
				itemsToSet: [],
				groups: defaultGroups,
			};
		}

		const listItemByZIndex = {};
		boxes.forEach((box) => {
			const widgets = box.widgets;
			widgets.forEach((widget) => {
				const sceneWidget = sceneWidgets.find((w) => w.id === widget.widgetTemplateId);

				const {
					type,
					style: { zIndex },
				} = sceneWidget;

				if (type === 'video' || zIndex === undefined) {
					return;
				}

				if (listItemByZIndex[String(zIndex ?? 0)] !== undefined) {
					listItemByZIndex[String(zIndex ?? 0)].push(widget);
				} else {
					listItemByZIndex[String(zIndex ?? 0)] = [widget];
				}
			});
		});

		boxes.forEach((box) => {
			const boxWidgets = box.widgets;
			let timelineItems = (boxWidgets ?? [])
				.map((widget) => {
					const sceneWidget = sceneWidgets.find((w) => w.id === widget.widgetTemplateId);
					const {
						style: { zIndex },
					} = sceneWidget;
					if (sceneWidget) {
						const widgetType = sceneWidget?.type ?? 'video';
						let zIndexToSet = Number(zIndex ?? 0);
						if (widgetType !== 'video') {
							const addedHashedGroup = hashedGroup[String(zIndexToSet)];
							if (addedHashedGroup !== undefined) {
								hashedGroup[String(zIndexToSet)] = zIndexToSet;
							} else {
								hashedGroup[String(zIndexToSet)] = zIndexToSet ?? 0;
							}
						}
						return {
							id: widget.id + '-template-' + widget.widgetTemplateId,
							widgetId: widget.id,
							content: sceneWidget?.name ?? 'Widget',
							group: widgetType === 'video' ? 'video-group' : zIndexToSet,
							className: getItemClassnames(widgetType),
							start: getTimelineTimeFromTimeInput((widget.start ?? '').replace('.', ':')),
							end: getTimelineTimeFromTimeInput((widget.end ?? '').replace('.', ':')),
							isVideoBG: widgetType === 'video',
							settings: sceneWidget?.settings,
						};
					}
				})
				.filter(Boolean);
			itemsToSet.push(...timelineItems);
			if (timelineItems.find((i) => i.isVideoBG)) {
				hashedGroup['video-group'] = '';
			}
		});

		const groups = Object.entries(hashedGroup).map(([key, value]) => {
			const defaultValue = {
				id: key,
				value: value,
				content: '',
				visible: true,
			};

			if (key === 'video-group') {
				return {
					...defaultValue,
					className: 'video-group',
				};
			}

			return defaultValue;
		});

		const validZIndex = Object.values(hashedGroup).filter((value) => {
			return value !== '';
		});

		highestZIndexValueRef.current = validZIndex.length > 0 ? Math.max(...validZIndex) : 0;
		return {
			itemsToSet: itemsToSet,
			groups,
		};
	};

	// Create timeline
	useEffect(() => {
		const container = document.getElementById(baseClassName);
		if (!container) {
			return;
		}
		container.innerHTML = '';

		timeline.current = new vis.Timeline(container, undefined, undefined, {});
		timeline.current?.addCustomTime(customTime, timelineCustomBarId);
		timeline.current?.setCustomTimeTitle('00:00:00', timelineCustomBarId);
		timeline.current?.on('drop', onDropOnTimeline);
		timeline.current?.on('timechanged', onTimeChanged);
		timeline.current?.on('timechange', onTimeChange);
		timeline.current?.on('mouseDown', onMouseDown);
		timeline.current?.on('mouseUp', onMouseUp);
		timeline.current?.on('mouseMove', onMouseMove);

		return () => {
			timeline.current?.off('drop', onDropOnTimeline);
			timeline.current?.off('timechanged', onTimeChanged);
			timeline.current?.off('mouseDown', onMouseDown);
			timeline.current?.off('mouseUp', onMouseUp);
			timeline.current?.off('mouseMove', onMouseMove);
		};
	}, []);

	useEffect(() => {
		movePlayerPosRef.current = props.movePlayerToPosition;
	}, [props.movePlayerToPosition]);

	useEffect(() => {
		pauseRef.current = props.onPause;
	}, [props.onPause]);

	// Init timeline
	useEffect(() => {
		const { accountId, sceneEditor } = props;
		const { itemsToSet, groups } = getTimelineData();
		const sortedEndItems = [...(itemsToSet ?? [])].sort((a, b) => {
			const aEnd = typeof a.end === 'string' ? new Date(a.end) : a.end;
			const bEnd = typeof b.end === 'string' ? new Date(b.end) : b.end;
			return aEnd - bEnd;
		});

		timelineStart = moment(getTimelineTimeFromTimeInput(START_TIME));
		timelineEnd = sortedEndItems[sortedEndItems.length - 1]?.end;
		timelineEnd = timelineEnd && moment(timelineEnd).isValid() ? timelineEnd : moment(timelineStart).add(10, 's');

		const BGVideo = itemsToSet.find((i) => i.isVideoBG);
		if (BGVideo) {
			const vidDuration = getWidgetsDuration({ end: BGVideo?.end, start: BGVideo?.start });

			if (!vidDuration) {
				const {
					settings: { mediaId },
				} = BGVideo;
				clearTimeout(request);
				request = setTimeout(() => {
					loadResourcesAfterTheFlowStartedForMedias(accountId, mediaId).then((mediaDetail) => {
						const editingScene = sceneEditor.props.editingScene;
						const editingWidgetTemplate = editingScene?.widgetTemplates.find(
							({ type, settings }) => type === 'video' && settings.mediaId === mediaId
						);
						const widgetFromBox = editingScene?.layouts[0].boxes[0].widgets.find(
							({ widgetTemplateId }) => widgetTemplateId === editingWidgetTemplate?.id
						);
						sceneEditor.updateMediaToVideoWidget(mediaDetail, editingWidgetTemplate, widgetFromBox);
					});
				}, 500);
			} else {
				timelineStart =
					BGVideo.start && moment(BGVideo.start).isValid()
						? BGVideo.start
						: moment(getTimelineTimeFromTimeInput(BGVideo.start));
				timelineEnd = moment(timelineStart).add(vidDuration, 'milliseconds');
				const { start, end } = sceneDuration ?? {};

				if (
					!start ||
					!end ||
					(start && moment(start).valueOf() !== timelineStart.valueOf()) ||
					(end && moment(end).valueOf() !== timelineEnd.valueOf())
				) {
					dispatch(
						updateEditingSceneDurationAction({
							start: timelineStart,
							end: timelineEnd,
						})
					);
				}
			}
		} else {
			dispatch(
				updateEditingSceneDurationAction({
					start: timelineStart,
					end: timelineEnd,
				})
			);
		}

		const options = {
			template: (item) => {
				if (!item) {
					return;
				}
				return `<div>` + item.content + `</div>`;
			},
			snap: null,
			onDropObjectOnItem: onDropOnTimeline,
			onMove: onMove,
			onMoving: onMoving,
			groupOrder: onGroupOrder,
			editable: {
				add: false, // add new items by double tapping
				updateTime: true, // drag items horizontally
				updateGroup: true, // drag items from one group to another
				remove: false, // delete an item by tapping the delete button top right
				overrideItems: false, // allow these options to override item.editable
			},
			selectable: true,
			groupEditable: true,
			stack: false,
			maxHeight: 200,
			start: moment(getTimelineTimeFromTimeInput(START_TIME)),
			end: timelineEnd,
			min: moment(getTimelineTimeFromTimeInput(START_TIME)),
			max: timelineEnd,
			groupHeightMode: 'fixed',
			orientation: 'top',
			showCurrentTime: false,
			zoomable: true,
			zoomMin: 1000 * 2, // one hour in milliseconds
			zoomMax: 1000 * 60 * 60 * 24, // one day in milliseconds
			showMajorLabels: false,
			verticalScroll: true,
			zoomKey: 'ctrlKey',
			format: {
				minorLabels: {
					//showing timeline with only seconds and single digit hours
					second: 'HH:mm:ss',
					minute: 'HH:mm:ss',
					hour: 'HH:mm:ss',
				},
			},
			margin: {
				item: { horizontal: 0 },
			},
		};

		const groupsData = new vis.DataSet();
		const itemsData = new vis.DataSet();

		const itemToUpdate = [];
		if (itemsToSet.length > 0) {
			// Snap all widgets to the right start & end times of TimeLine
			const itemsWithCorrectTimeLine = itemsToSet.map((i) => {
				const itemDuration = getWidgetsDuration({ start: i.start, end: i.end });
				if (i.isVideoBG) {
					return { ...i, start: timelineStart, end: moment(timelineStart).add(itemDuration, 'milliseconds') };
				}
				let newItem = i;
				if (i.start < timelineStart && i.end > timelineEnd) {
					newItem = { ...i, start: timelineStart, end: timelineEnd };
					itemToUpdate.push(newItem);
					return newItem;
				}
				if (i.start < timelineStart) {
					newItem = {
						...i,
						start: timelineStart,
						end: moment(timelineStart).add(itemDuration, 'milliseconds'),
					};
					itemToUpdate.push(newItem);
					return newItem;
				}
				if (i.end > timelineEnd) {
					newItem = {
						...i,
						start: moment(timelineEnd).subtract(itemDuration, 'milliseconds'),
						end: timelineEnd,
					};
					itemToUpdate.push(newItem);
					return newItem;
				}
				return i;
			});
			itemsData.add(itemsWithCorrectTimeLine);
		}
		if (groups.length > 0) {
			groupsData.add(groups);
		}

		setTimeout(() => {
			timeline.current?.setItems(itemsData);
			timeline.current?.setGroups(groupsData);
			timeline.current?.setOptions(options);
			setShow(true);
		});
		timelineItemRef.current = itemsData;
		timelineGroupRef.current = groupsData;

		setTimeLineItemNeedToBeUpdated(itemToUpdate);
	}, [currentLayout, timeline.current, onDropOnTimeline, onTimeLineClick, timelineChanged, editingScene]);

	useEffect(() => {
		if (props.editingWidget) {
			timeline.current?.setSelection(
				`${props.editingWidget.id}-template-${props.editingWidget.widgetTemplateId}`
			);
		}
	}, [props.editingWidget]);

	useEffect(() => {
		const getSceneDuration = () => getWidgetsDuration({ end: sceneDuration?.end, start: sceneDuration?.start });
		props.getSceneDuration?.(getSceneDuration);
		return;
	}, [props.getSceneDuration]);

	useEffect(() => {
		const customTime = getTimelineTimeFromSeconds(0);
		timeline.current?.setCustomTime(customTime, timelineCustomBarId);
		setCustomTime(customTime);
	}, []);

	const onDropOnTimeline = useCallback(
		(dropData) => {
			props.onDropItem?.({ ...dropData, group: DEFAULT_BG_ID });
		},
		[props.onDropItem]
	);

	const onTimeLineClick = useCallback(
		(properties) => {
			const { what, item, group } = properties;

			if (what === 'item' && item && group) {
				const itemInfo = item.split('-template-');
				props.onItemClick?.({
					id: itemInfo[0],
					widgetTemplateId: itemInfo[1],
					boxId: DEFAULT_BG_ID,
					group: group,
				});
			}

			if (what === 'axis') {
				onTimeChanged(properties);
			}
		},
		[onTimeChanged, props.onItemClick]
	);

	const onMoving = (item, callback) => {
		const { isVideoBG } = item;

		if (isVideoBG) {
			callback(null);
			return;
		}
		callback(item);
	};

	const onMove = useCallback(
		(item, callback) => {
			if (item.isVideoBG) {
				// Disable the trimming feature of video - TS-141
				dispatch(showAlert('Video widget should not be moved.', messageTypes.info));
				callback(null);
				return;
			}

			let itemToChange = item;

			const overlapping = timelineItemRef.current?.get({
				filter: (testItem) => {
					if (testItem.id === item.id) {
						return false;
					}

					if (Number(item.group) === Number(testItem.group)) {
						return item.start <= testItem.end && item.end >= testItem.start;
					}
					return false;
				},
			});

			if (timelineMousePositionRef.current >= 0 && overlapping.length !== 0) {
				callback(null);
				dispatch(showAlert('Widget should not be overlapped', messageTypes.info));
				return;
			}

			if (!item.start || item.start <= sceneDuration.start) {
				itemToChange = { ...item, start: sceneDuration.start };
			}

			if (!item.end || item.end >= sceneDuration.end) {
				itemToChange = { ...itemToChange, end: sceneDuration.end };
			}

			if (itemToChange.end < sceneDuration.start) {
				callback(null);
				return;
			}

			if (itemToChange.start > sceneDuration.end) {
				callback(null);
				return;
			}

			if (itemToChange.start >= itemToChange.end) {
				callback(null);
				return;
			}

			updateWidgetTimes([itemToChange]);
			callback(itemToChange);
		},
		[editingScene, updateWidgetTimes, sceneDuration]
	);

	const updateWidgetTimes = useCallback(
		(widgetWithCorrectTimes, updateFromContextMenu) => {
			if (!editingScene) {
				return;
			}

			const remainingLayouts = (editingScene?.layouts ?? []).filter((l) => l.type !== currentLayout.type) ?? {};
			const widgetsToUpdateToApi = [];
			const widgetTemplates = editingScene.widgetTemplates ?? [];
			const hashedIndez = {};

			widgetTemplates.forEach(({ style: { zIndex } }) => {
				if (zIndex !== undefined && zIndex !== null) {
					hashedIndez[String(zIndex)] = zIndex;
				}
			});

			const groupOrderByZIndex = Object.values(hashedIndez).sort((a, b) => a - b);

			widgetWithCorrectTimes.forEach((widgetWithCorrectTime) => {
				const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === DEFAULT_BG_ID);
				const boxes = (currentLayout.boxes ?? []).filter((box) => box.boxTemplateId !== DEFAULT_BG_ID);

				const widgets = (currentBox?.widgets ?? []).filter(
					(widget) => widget?.id !== widgetWithCorrectTime?.widgetId
				);
				let currentWidget = (currentBox?.widgets ?? []).find(
					(widget) => widget.id === widgetWithCorrectTime?.widgetId
				);

				const sceneWidget = (editingScene.widgetTemplates ?? []).find(
					(w) => w.id === currentWidget.widgetTemplateId
				);
				const sceneWidgets = (editingScene.widgetTemplates ?? []).filter(
					(w) => w.id !== currentWidget.widgetTemplateId
				);

				const oldWidgetIndex = Number(sceneWidget.style.zIndex);

				const { group } = widgetWithCorrectTime;

				if (timelineMousePositionRef.current < 0 && !updateFromContextMenu) {
					sceneWidget.style.zIndex = Number(groupOrderByZIndex[groupOrderByZIndex.length - 1]) + 1;
				} else if (group === 'video-group') {
					sceneWidget.style.zIndex = Number(groupOrderByZIndex[0]) - 1;
				} else {
					sceneWidget.style.zIndex = Number(group);
				}

				currentWidget = {
					...currentWidget,
					start: convertTimeToWidgetTime(
						widgetWithCorrectTime.start < getTimelineStartDate()
							? getTimelineStartDate()
							: widgetWithCorrectTime.start
					),
					end: convertTimeToWidgetTime(widgetWithCorrectTime.end),
				};
				currentLayout.boxes = [...boxes, { ...currentBox, widgets: [...widgets, { ...currentWidget }] }];

				//ui update
				dispatch(
					updateEditingSceneAndSendToPlayer(
						{
							...editingScene,
							layouts: [...remainingLayouts, currentLayout],
							widgetTemplates: [...sceneWidgets, sceneWidget],
						},
						true
					)
				);

				// saving to api
				if (!editingScene) {
					return;
				}

				const { id: scenarioId } = scenario;

				const updateBodyToApi =
					oldWidgetIndex === sceneWidget.style.zIndex
						? { start: currentWidget.start, end: currentWidget.end }
						: {
								start: currentWidget.start,
								end: currentWidget.end,
								style: sceneWidget.style,
						  };

				widgetsToUpdateToApi.push({
					defaultAccountId,
					scenarioId,
					sceneId: editingScene.id,
					layoutId: currentLayout?.id,
					boxId: currentBox.boxTemplateId,
					widgetId: currentWidget.id,
					body: updateBodyToApi,
				});
			});

			dispatch(addWidgetsToUpdateWaitingList(widgetsToUpdateToApi));
		},
		[editingScene, currentLayout]
	);

	const onGroupOrder = useCallback(({ id: idA, value: valueA }, { id: idB, value: valueB }) => {
		if (idA === 'video-group') {
			return 1;
		}

		if (idB === 'video-group') {
			return -1;
		}

		return valueB - valueA;
	}, []);

	useEffect(() => {
		if (timeLineItemNeedToBeUpdated.length === 0) {
			return;
		}
		setTimeLineItemNeedToBeUpdated([]);
		updateWidgetTimes(timeLineItemNeedToBeUpdated);
	}, [timeLineItemNeedToBeUpdated]);

	const onZoomIn = useCallback(() => {
		if (zoomValue === 200) {
			return;
		}
		timeline.current?.zoomIn(0.2);
		setZoomValue((prevValue) => (prevValue + 20 > 200 ? 200 : prevValue + 20));
	}, [setZoomValue, zoomValue, timeline.current]);

	const onZoomOut = useCallback(() => {
		if (zoomValue === -200) {
			return;
		}

		timeline.current?.zoomOut(0.2);
		setZoomValue((prevValue) => (prevValue - 20 < -200 ? -200 : prevValue - 20));
	}, [setZoomValue, zoomValue, timeline.current]);

	const onZoomSliderChange = useCallback(
		(event, value) => {
			if (value === zoomValue) {
				return;
			}

			if (value > zoomValue) {
				timeline.current?.zoomIn(Math.abs(value) / 100);
			} else {
				timeline.current?.zoomOut(Math.abs(value) / 100);
			}
			setZoomValue(value);
		},
		[setZoomValue, zoomValue, timeline.current]
	);

	const onFit = useCallback(() => {
		timeline.current?.fit();
	}, [timeline.current]);

	const onPlayBack = () => {
		if (isPlaying) {
			props.onPause?.();
		} else {
			props.onPlay?.();
		}
	};

	const onUpdateVolume = () => {
		if (isMute) {
			props.onUnMute?.();
		} else {
			props.onMute?.();
		}
	};

	const onForward = () => {
		props.onForward?.();
	};

	const onReward = () => {
		props.onReward?.();
	};

	const handleOnMessage = (event) => {
		if (event.data?.type === 'position') {
			onPositionChange(event.data);
		}
		if (
			event.data?.command === 'pause' ||
			event.data?.command?.data === 'pause' ||
			event.data?.command?.data === 'player-pause'
		) {
			setIsPlaying(false);
		}
		if (
			event.data?.command === 'play' ||
			event.data?.command?.data === 'play' ||
			event.data?.command?.data === 'player-play'
		) {
			setIsPlaying(true);
		}

		if (event.data?.command === 'player-mute' || event.data?.command?.data === 'player-mute') {
			setIsMuting(true);
		}

		if (event.data?.command === 'player-unMute' || event.data?.command?.data === 'player-unMute') {
			setIsMuting(false);
		}
	};

	const onPositionChange = (data) => {
		const position = data?.position;
		if (position === undefined || position === null) {
			return;
		}
		const customTime = getTimelineTimeFromSeconds(position);
		timeline.current?.setCustomTime(customTime, timelineCustomBarId);
		timeline.current?.setCustomTimeTitle(
			moment(customTime).isValid() ? convertTimeToWidgetTime(moment(customTime).toLocaleString()) : '',
			timelineCustomBarId
		);

		setCustomTime(customTime);
	};

	const onTimeChanged = (properties) => {
		if (!timelineStart || !timelineEnd) {
			return;
		}

		const time = properties.time;
		const positionToMove = getSecondsFromTimelineTime(time);
		movePlayerPosRef.current?.(positionToMove);
		timeline.current?.setCustomTime(time, timelineCustomBarId);
		timeline.current?.setCustomTimeTitle(
			moment(customTime).isValid() ? convertTimeToWidgetTime(moment(customTime).toLocaleString()) : '',
			timelineCustomBarId
		);
		setCustomTime(time);
	};

	const onTimeChange = () => {
		pauseRef.current?.();
	};

	const onMouseDown = (properties) => {
		onTimeLineClick(properties);
		isMouseDownRef.current = true;
	};

	const onMouseUp = () => {
		isMouseDownRef.current = false;
	};

	const onMouseMove = (event) => {
		if (event.group === 'video-group') {
			const videoElement = document.querySelector('.video-group .vis-item');
			if (isMouseDownRef.current) {
				videoElement && videoElement.classList.add('mouse-over');
			} else {
				videoElement && videoElement.classList.remove('mouse-over');
			}
		} else {
			timelineMousePositionRef.current = event.y;
			const timelineAxis = document.querySelector('.vis-time-axis');
			if (isMouseDownRef.current && timelineMousePositionRef.current < 0) {
				timelineAxis && timelineAxis.classList.add('mouse-over');
			} else {
				timelineAxis && timelineAxis.classList.remove('mouse-over');
			}
		}
	};

	const updateZIndexPosition = (isBringToFront, widgetId, templateId) => {
		const item = timelineItemRef.current?.get(`${widgetId}-template-${templateId}`);

		if (!item) {
			return;
		}

		const widgetTemplates = editingScene.widgetTemplates ?? [];
		const hashedIndez = {};
		widgetTemplates.forEach(({ style: { zIndex } }) => {
			if (zIndex !== undefined && zIndex !== null) {
				hashedIndez[String(zIndex)] = zIndex;
			}
		});

		const groupOrderByZIndex = Object.values(hashedIndez).sort((a, b) => a - b);
		const zIndexToUpdate = isBringToFront
			? groupOrderByZIndex[groupOrderByZIndex.length - 1] + 1
			: groupOrderByZIndex[0] - 1;
		updateWidgetTimes([{ ...item, group: zIndexToUpdate }], true);
	};

	useEffect(() => {
		window.addEventListener('message', handleOnMessage);
		return () => window.removeEventListener('message', handleOnMessage);
	}, []);

	return (
		<>
			<div className={`${baseClassName}__toolbar`} style={{ visibility: show ? 'visible' : 'hidden' }}>
				<div className={`${baseClassName}__toolbar-slot`} />
				<div className={`${baseClassName}__toolbar-slot`}>
					<div className={`${baseClassName}__toolbar--timestamp`}>
						{moment(customTime).isValid()
							? convertTimeToWidgetTime(moment(customTime).toLocaleString())
							: ''}
					</div>
					<IconButton className={`${baseClassName}__toolbar--btn`} title="Go reward 15s" onClick={onReward}>
						<img src={fast_reward_15} />
					</IconButton>
					<IconButton className={`${baseClassName}__toolbar--btn`} title="Play/Pause" onClick={onPlayBack}>
						<img src={isPlaying ? pause_controls : play_black} />
					</IconButton>
					<IconButton className={`${baseClassName}__toolbar--btn`} title="Go forward 15s" onClick={onForward}>
						<img src={fast_forward_15} />
					</IconButton>
					<IconButton className={`${baseClassName}__toolbar--btn`} onClick={onUpdateVolume}>
						{isMute ? <VolumeOffIcon htmlColor="#000" /> : <VolumeUpIcon htmlColor="#000" />}
					</IconButton>
					{/* To make these buttons above centered */}
					<div className={`${baseClassName}__toolbar--timestamp`} style={{ visibility: 'hidden' }}>
						{moment(customTime).isValid()
							? convertTimeToWidgetTime(moment(customTime).toLocaleString())
							: ''}
					</div>
				</div>
				<div className={`${baseClassName}__toolbar-slot`}>
					<IconButton
						className={`${baseClassName}__toolbar--btn ${baseClassName}__toolbar--btnZoom`}
						onClick={onZoomOut}
						title="Zoom out"
					>
						<ZoomOut />
					</IconButton>
					<CustomSlider
						max={200}
						min={-200}
						step={10}
						className={`${baseClassName}__toolbar--slider`}
						value={zoomValue}
						valueLabelDisplay={'off'}
						onChange={onZoomSliderChange}
					/>
					<IconButton
						className={`${baseClassName}__toolbar--btn ${baseClassName}__toolbar--btnZoom`}
						onClick={onZoomIn}
						title="Zoom in"
					>
						<ZoomIn />
					</IconButton>
					<Button
						className={`${baseClassName}__toolbar--btn ${baseClassName}__toolbar--btnFit`}
						variant="outlined"
						onClick={onFit}
					>
						Fit
					</Button>
				</div>
			</div>
			<div className={baseClassName} id={baseClassName} />
		</>
	);
});

export default EditorTimeline;
