import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './../InteractEditorTopPanel/InteractElementProperties/InteractElementProperties.scss';
import InteractButtonActions from './InteractButtonActions';
import { updateInteractTimelineItem } from '../../../actions/interactTimelineActions';
import { interactActionList } from '../../../constants/interactConstant';
import { mediaTypes } from '../../../constants/mediaConstants';
import plus_semiround from '../../../assets/images/plus_semiround.svg';
import _ from 'underscore';

class InteractActionProperties extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showActionsOption: false,
		};
	}

	renderActionsList = () => {
		const { item, fieldObj, t, parentKey } = this.props;
		return Object.keys(fieldObj.default).map((key, index) => (
			<InteractButtonActions
				key={index}
				item={item}
				t={t}
				fieldObj={fieldObj}
				parentKey={parentKey}
				actionKey={key}
				//action={eachButton.action.default[key]}
			/>
		));
	};

	handleActionChange = (e) => {
		const { item, parentKey } = this.props;
		if (e && e.target && e.target.value) {
			let value = e.target.value;
			this.hideActionsDropDown();
			let newItem = {
				...item,
			};
			let action = _.find(interactActionList, (actionObj) => {
				return actionObj.id === value;
			});

			let defaultAction = {
				...action.default,
			};
			//newItem.settings[parentKey].action.default[value] = action.default
			newItem.settings[parentKey].action.default[value] = defaultAction;
			this.props.updateInteractTimelineItem(newItem);
			e.target.value = '0'; // resetting the select drop down value to default
		}
	};

	renderActionOnClickSection = () => {
		const { fieldObj, t, mediaDetails } = this.props;
		let possibleValues = interactActionList;

		if (mediaDetails.type === mediaTypes.livevideo) {
			possibleValues = possibleValues.filter((item) => item.id !== 'jumpToTime');
		}

		return (
			<>
				<div className="actionSelectionMenu">
					<label className="dropdownLabel"> {t('ACTION_ON_CLICK')} </label>
					<div className="sortingDropDownWrapper fullWidthControl">
						<select className="sortingDropDown" onChange={(event) => this.handleActionChange(event)}>
							<option value="0"> {'-Select-'} </option>
							{possibleValues.map((eachValue) => (
								<option
									key={eachValue.value}
									value={eachValue.id}
									disabled={fieldObj.default.hasOwnProperty(eachValue.id)}
								>
									{' '}
									{eachValue.name}{' '}
								</option>
							))}
						</select>
					</div>
				</div>
			</>
		);
	};

	displayActionsDropDown = () => {
		this.setState({
			showActionsOption: true,
		});
	};

	hideActionsDropDown = () => {
		this.setState({
			showActionsOption: false,
		});
	};

	render() {
		const { fieldObj, t } = this.props;
		const { showActionsOption } = this.state;
		let showAddActionLabel = true;
		let defaultKeys = Object.keys(fieldObj.default);
		if (defaultKeys.length > 0 && defaultKeys.length === interactActionList.length) {
			showAddActionLabel = false;
			this.hideActionsDropDown();
		} else if (defaultKeys.length == 0 && defaultKeys.length !== interactActionList.length) {
			this.displayActionsDropDown();
		}
		return (
			<>
				<div className="actionsList">{this.renderActionsList()}</div>

				{!showActionsOption && showAddActionLabel ? (
					<>
						<div
							className="dynamicActionField"
							onClick={() => {
								this.displayActionsDropDown();
							}}
						>
							<div className="wrapper">
								<img src={plus_semiround} />
								<label className="dynamicActionText"> {t('ADD_ACTION')} </label>
							</div>
						</div>
					</>
				) : null}

				{showActionsOption ? this.renderActionOnClickSection() : null}
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractActionProperties);
