import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import * as H from 'history';
import {
	Box,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Switch,
	TextField,
	Button,
} from '@material-ui/core';
import { DateRange, Schedule } from '@material-ui/icons';

import {
	customMetadataTypes,
	mediaNames,
	mediaPropertyNames,
	mediaTypes,
	messageTypes,
} from '../../../constants/mediaConstants';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import { joinClassNames } from '../../../services/elementHelperService';
import TagsControl from '../../../reusable/TagsControl/TagsControl';
import { getMediaQueryDetails, retrieveTags, saveInspectScreenDetails } from '../../../actions/publishActions';
import { showMessage } from '../../../actions/globalActions';
import { getDetails } from '../../../services/mediaDisplayService';
import { updateMediaProperties } from '../../../actions/inspectScreenActions';
import { updateScreenName } from '../../../actions/breadCrumbActions';
import DeleteMedia from './InspectSections/DeleteMedia/DeleteMedia';

interface GeneralDetailsProps {
	mediaDetails: any;
	mediaName: string;
	history: H.History;
	onSaveChange?: () => void;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = ({ mediaDetails, mediaName, history, onSaveChange }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const getTags = useRef<(() => any[]) | null>(null);
	const { customMetadata, defaultAccountId } = useSelector((state) => (state as any).session);
	const getTagsHandler = (handler: () => any[]) => (getTags.current = handler);

	const [customMetadataObject, setCustomMetadataObject] = useState<any | string>('');
	const [mediaCustomData, setMediaCustomData] = useState<any | undefined>(mediaDetails.custom || {});
	const [mediaTitle, setMediaTitle] = useState<string>(mediaDetails?.metadata?.title);
	const [mediaDescription, setMediaDescription] = useState<string>(mediaDetails?.description);
	const [mediaTags, setMediaTags] = useState<any[]>(mediaDetails.tags || []);
	const [accountTags, setAccountTags] = useState<any[]>([]);
	const [isMediaDetailsModified, setMediaDetailsModified] = useState(false);
	const [prevMediaDetails, setPrevMediaDetails] = useState<any | undefined>({
		title: mediaDetails.title,
		description: mediaDetails.description,
		tags: mediaDetails.tags || [],
		custom: mediaDetails.custom || {},
	});

	const setCustomMetaDataObject = useCallback(() => {
		const customMetadataJson = JSON.parse(customMetadata);
		let customMetadataObject = '';
		if (mediaDetails && customMetadataJson) {
			if (mediaDetails.type == mediaTypes.playlists) {
				customMetadataObject = customMetadataJson.properties.playlist;
			} else if (mediaDetails.type == mediaTypes.catalog) {
				customMetadataObject = customMetadataJson.properties.catalog;
			} else {
				customMetadataObject = customMetadataJson.properties.media;
			}

			setCustomMetadataObject(customMetadataObject);
		}
	}, [customMetadata, mediaDetails, setCustomMetadataObject]);

	const handleDetailsChange = useCallback(
		(e: React.ChangeEvent<{}>, property: any, propertyKey: string) => {
			let newCustomObject = { ...(mediaCustomData ?? {}) };
			const eventTarget = e.target as any;

			switch (property.type) {
				case customMetadataTypes.BOOLEAN:
					if (eventTarget) {
						newCustomObject[propertyKey] = eventTarget.checked;
					}
					break;
				case customMetadataTypes.MULTISELECT:
					if (eventTarget.selectedIndex) {
						newCustomObject[propertyKey] = eventTarget[eventTarget.selectedIndex].value;
					} else if (eventTarget.selectedIndex === 0) {
						delete newCustomObject[propertyKey];
					}
					break;
				case customMetadataTypes.NUMBER:
					if (eventTarget && !isNaN(eventTarget.value)) {
						newCustomObject[propertyKey] = eventTarget.value.toString();
					}
					break;
				case customMetadataTypes.STRING:
					newCustomObject[propertyKey] = eventTarget.value.toString();
					break;
				case customMetadataTypes.DATETIME:
					newCustomObject[propertyKey] = e;
					break;
				case customMetadataTypes.DATE:
					newCustomObject[propertyKey] = e !== null ? (e as any).toISOString().split('T')[0] : e;
					break;
				default:
					break;
			}

			setMediaDetailsModified(true);
			setMediaCustomData(newCustomObject);
		},
		[mediaCustomData]
	);

	const getCustomPropertiesComponent = useCallback(
		(property: any, propertyKey: string, requiredSymbol: any) => {
			if (!property.type) {
				return null;
			}
			const custom = mediaCustomData ?? {};
			const selectedValue = custom[propertyKey] ?? 'none';
			switch (property.type) {
				case customMetadataTypes.MULTISELECT:
					return (
						<Box>
							<FormControlLabel
								className={'inspect-input__full'}
								style={{ display: 'flex' }}
								control={
									<Select variant="outlined" fullWidth defaultValue={'none'}>
										<MenuItem key={'key'} value={'none'} selected={selectedValue === 'none'}>
											Select
										</MenuItem>
										{property &&
											property.enum &&
											property.enum.map &&
											property.enum.map((_: any, index: number) => {
												const key = Object.keys(property.enum[index])[0];
												const text = property.enum[index][Object.keys(property.enum[index])[0]];
												return (
													<MenuItem key={key} value={key} selected={selectedValue === key}>
														{text}
													</MenuItem>
												);
											})}
									</Select>
								}
								label={<span className="inspect-input__label">{property?.title + requiredSymbol}</span>}
								onChange={(e) => handleDetailsChange(e, property, propertyKey)}
								labelPlacement="top"
							/>
						</Box>
					);
				case customMetadataTypes.BOOLEAN:
					return (
						<Box>
							<FormControlLabel
								control={
									<Switch
										checked={
											custom && custom.hasOwnProperty(propertyKey) && custom[propertyKey]
												? JSON.parse(custom[propertyKey])
												: false
										}
										color="primary"
									/>
								}
								label={<span className="inspect-input__label">{property?.title + requiredSymbol}</span>}
								onChange={(e) => handleDetailsChange(e, property, propertyKey)}
								labelPlacement="top"
							/>
						</Box>
					);
				case customMetadataTypes.STRING:
					return (
						<Box>
							<FormControlLabel
								className={'inspect-input__full'}
								style={{ display: 'flex' }}
								control={
									<TextField
										variant="outlined"
										fullWidth
										value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : ''}
										multiline
										maxRows={2}
										minRows={2}
										placeholder={t('ENTER_TEXT')}
									/>
								}
								label={<span className="inspect-input__label">{property?.title + requiredSymbol}</span>}
								onChange={(e) => handleDetailsChange(e, property, propertyKey)}
								labelPlacement="top"
							/>
						</Box>
					);
				case customMetadataTypes.NUMBER:
					return (
						<Box>
							<FormControlLabel
								className={'inspect-input__full'}
								style={{ display: 'flex' }}
								control={
									<TextField
										variant="outlined"
										fullWidth
										value={custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : ''}
										placeholder={t('ADD_NUMBERS')}
										type="number"
									/>
								}
								label={<span className="inspect-input__label">{property?.title + requiredSymbol}</span>}
								onChange={(e) => handleDetailsChange(e, property, propertyKey)}
								labelPlacement="top"
							/>
						</Box>
					);
				case customMetadataTypes.DATETIME:
					return (
						<Box>
							<label className="inspect-input__label--standalone">
								{' '}
								{property.title + requiredSymbol}{' '}
							</label>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<div className={joinClassNames('inspect-input__flex-container', 'inspect-input__full')}>
									<DatePicker
										variant="outlined"
										style={{ margin: '0px 16px' }}
										onChange={(e) => handleDetailsChange(e, property, propertyKey)}
										value={
											custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : null
										}
										format="yyyy-MM-dd"
										ampm={false}
										margin="normal"
										clearable
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton>
														<DateRange />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<TimePicker
										variant="outlined"
										style={{ margin: '0px 16px 0px 0px' }}
										onChange={(e) => handleDetailsChange(e, property, propertyKey)}
										value={
											custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : null
										}
										format="HH:mm:ss"
										ampm={false}
										margin="normal"
										clearable
										seconds
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton>
														<Schedule />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
							</MuiPickersUtilsProvider>
						</Box>
					);
				case customMetadataTypes.DATE:
					return (
						<Box>
							<label className="inspect-input__label--standalone">
								{' '}
								{property.title + requiredSymbol}{' '}
							</label>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<div className={joinClassNames('inspect-input__flex-container', 'inspect-input__full')}>
									<DatePicker
										variant="outlined"
										style={{ margin: '0px 16px' }}
										onChange={(e) => handleDetailsChange(e, property, propertyKey)}
										value={
											custom && custom.hasOwnProperty(propertyKey) ? custom[propertyKey] : null
										}
										format="yyyy-MM-dd"
										ampm={false}
										margin="normal"
										clearable
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton>
														<DateRange />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
							</MuiPickersUtilsProvider>
						</Box>
					);
				default:
					return <Box>Test</Box>;
			}
		},
		[handleDetailsChange, mediaCustomData, t]
	);

	const renderCustomMetadataProperties = useCallback(
		(propertyObj: any, propertyKey: string) => {
			let requiredSymbol = '';
			if (
				customMetadataObject.required &&
				customMetadataObject.required.indexOf &&
				customMetadataObject.required.indexOf(propertyKey) != -1
			) {
				requiredSymbol = ' * ';
			}
			return getCustomPropertiesComponent(propertyObj, propertyKey, requiredSymbol);
		},
		[customMetadataObject.required, getCustomPropertiesComponent]
	);

	const renderCustomMetadata = useCallback(() => {
		if (
			customMetadataObject &&
			customMetadataObject.properties &&
			customMetadataObject.properties &&
			Object.keys(customMetadataObject.properties).map
		) {
			return (
				<>
					{Object.keys(customMetadataObject.properties).map((propertyKey) => (
						<>{renderCustomMetadataProperties(customMetadataObject.properties[propertyKey], propertyKey)}</>
					))}
				</>
			);
		}
	}, [customMetadataObject, renderCustomMetadataProperties]);

	const fetchTags = useCallback(() => {
		dispatch(retrieveTags(defaultAccountId)).then((data: any) => {
			setAccountTags(data);
		});
	}, [defaultAccountId, dispatch]);

	const handleCancelMediaDetailsChange = useCallback(() => {
		setMediaCustomData((prevMediaDetails as any).custom ?? {});
		setMediaTitle(prevMediaDetails?.title ?? '');
		setMediaDescription(prevMediaDetails?.description ?? '');
		setMediaTags(prevMediaDetails.tags);
		setMediaDetailsModified(false);
		dispatch(updateScreenName(prevMediaDetails?.title ?? ''));
	}, [dispatch, prevMediaDetails]);

	const checkForError = useCallback(() => {
		if (customMetadataObject && customMetadataObject.required && customMetadataObject.required.length > 0) {
			for (var property in customMetadataObject.properties) {
				if (
					customMetadataObject.required &&
					customMetadataObject.required.indexOf &&
					customMetadataObject.required.indexOf(property) !== -1 &&
					!mediaCustomData[property]
				) {
					dispatch(showMessage(t('FILL_THE_REQUIRED_FIELDS'), messageTypes.error));
					return true;
				}
			}
		}
		return false;
	}, [customMetadataObject, dispatch, mediaCustomData, t]);

	const getBodyOfMediaForDetails = useCallback(
		(callback: (_: any | undefined) => void) => {
			const newTags = getTags.current?.();
			let body = {
				id: mediaDetails.id,
				asset: {
					id: mediaDetails.asset.id,
				},
				metadata: {
					title: mediaTitle || '',
					description: mediaDescription || '',
				},
				tags: newTags,
				custom: mediaCustomData || {},
			};
			let newCustom = {
				...mediaCustomData,
			};
			let newCustomObj: any = {};
			for (var key in newCustom) {
				// to be relooked if this is really needed else assign state.custom directly to body.custom
				if (newCustom[key]) {
					newCustomObj[key] = newCustom[key];
				}
			}
			body.custom = newCustomObj;
			if (mediaName == mediaNames.medias) {
				dispatch(getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias)).then(
					(media: any) => {
						if (!media || media.message) {
							dispatch(showMessage(t('FAILED_FETCH_MEDIA_DETAILS'), messageTypes.error));
							return;
						}
						let mediaDetails = getDetails(media, mediaName === mediaNames.playlists);
						if (mediaDetails && mediaDetails.custom && mediaDetails.custom.livemanager) {
							if (mediaDetails.custom.livemanager.start) {
								body.custom['livemanager'] = {
									start: mediaDetails.custom.livemanager.start,
								};
							}
							if (body.custom.livemanager && mediaDetails.custom.livemanager.stop) {
								body.custom.livemanager.stop = mediaDetails.custom.livemanager.stop;
							}
						}
						callback?.(body);
					}
				);
			} else {
				callback?.(body);
			}
		},
		[defaultAccountId, dispatch, mediaCustomData, mediaDescription, mediaDetails, mediaName, mediaTitle, t]
	);

	const handleSaveForGeneralDetails = useCallback(() => {
		dispatch(showMessage(t('WAIT_TO_SAVE_CHANGE'), messageTypes.info));

		getBodyOfMediaForDetails((body: any) => {
			dispatch(saveInspectScreenDetails(defaultAccountId, mediaDetails.id, mediaName, body)).then((data: any) => {
				if (!data || data.message) {
					dispatch(showMessage(t('FAILED_TO_SAVE_MEDIA_DETAILS'), messageTypes.error));
					return;
				}

				if (body.metadata.hasOwnProperty('title')) {
					dispatch(
						updateMediaProperties({
							id: mediaDetails.id,
							mediaPropertyKey: mediaPropertyNames.TITLE,
							mediaPropertyValue: body.metadata.title,
						})
					);
				}
				dispatch(showMessage(t('SAVED_DETAILS_SUCCESSFULLY'), messageTypes.success));
				setMediaDetailsModified(false);
				setTimeout(() => onSaveChange?.(), 1000);
			});
		});
	}, [defaultAccountId, dispatch, getBodyOfMediaForDetails, mediaDetails.id, mediaName, onSaveChange, t]);

	const handleSaveMediaDetails = useCallback(() => {
		if (mediaDetails && mediaDetails.asset) {
			if (!checkForError()) {
				handleSaveForGeneralDetails();
			}
		} else {
			dispatch(showMessage(t('FAILED_TO_SAVE_NO_ASSETID'), messageTypes.error));
		}
	}, [checkForError, dispatch, handleSaveForGeneralDetails, mediaDetails, t]);

	useEffect(() => {
		setMediaCustomData((mediaDetails as any)?.custom || {});
		setMediaTitle(mediaDetails?.metadata?.title ?? '');
		setMediaDescription(mediaDetails?.description ?? '');
		setMediaTags(mediaDetails.tags || []);

		setPrevMediaDetails({
			title: mediaDetails.metadata?.title ?? '',
			description: mediaDetails.metadata?.description ?? '',
			tags: mediaDetails.tags || [],
			custom: mediaDetails.custom || {},
		});
	}, [mediaDetails]);

	useEffect(() => {
		setCustomMetaDataObject();
		fetchTags();
	}, [setCustomMetaDataObject, fetchTags]);

	return (
		<>
			<Box>
				<FormControlLabel
					className={'inspect-input__full'}
					style={{ display: 'flex' }}
					control={<TextField fullWidth variant="outlined" value={mediaTitle ?? 'No title'} />}
					label={<span className={'inspect-input__label'}>{t('TITLE')}</span>}
					onChange={(e) => {
						setMediaTitle((e.target as any).value);
						setMediaDetailsModified(true);
						dispatch(updateScreenName((e.target as any).value));
					}}
					labelPlacement="top"
					placeholder={t('ENTER_TITLE')}
				/>
			</Box>
			<Box>
				<FormControlLabel
					className={'inspect-input__full'}
					style={{ display: 'flex' }}
					control={
						<TextField
							variant="outlined"
							fullWidth
							value={mediaDescription ?? 'No title'}
							multiline
							maxRows={6}
							minRows={6}
						/>
					}
					label={<span className="inspect-input__label">{t('DESCRIPTION')}</span>}
					placeholder={t('ENTER_DESCRIPTION')}
					onChange={(e) => {
						setMediaDescription((e.target as any).value);
						setMediaDetailsModified(true);
					}}
					labelPlacement="top"
				/>
			</Box>
			<Box>
				<div className="tagsSection" style={{ margin: '0px 16px 12px 16px' }}>
					<label>{t('LABEL_TEXT_TAGS')}</label>
					<TagsControl
						t={t}
						displayTags={mediaTags}
						suggestions={accountTags}
						getTagsHandler={getTagsHandler}
						onDetailsChange={() => setMediaDetailsModified(true)}
					/>
				</div>
			</Box>
			{renderCustomMetadata()}

			<div className="inspect-container__content-action">
				<Button variant="outlined" disabled={!isMediaDetailsModified} onClick={handleCancelMediaDetailsChange}>
					{t('LABEL_CANCEL_BUTTON')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSaveMediaDetails}
					disabled={!isMediaDetailsModified}
				>
					{t('LABEL_SAVE_BUTTON')}
				</Button>
			</div>
			<div className="inspect-container__content-action">
				<DeleteMedia mediaDetails={mediaDetails} mediaName={mediaName} t={t} history={history} />
			</div>
		</>
	);
};

export default GeneralDetails;
