import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'underscore';

import { showNotification } from '../../../actions/notificationActions';
import { messageTypes, mediaNames } from '../../../constants/mediaConstants';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import AssetLibrary from '../../../reusable/AssetLibrary/AssetLibrary';
import { liveEventStatusList } from '../../../constants/liveEventStatus';
import {
	createInteractTimelineItem,
	updateInteractTimelineItem,
	sendUpdateInteractItems,
	removeInteractTimelineItem,
	setLiveQueueItem,
	startFetchInteractItems,
	setQueueOperation,
	resetQueueOperation,
} from './../../../actions/interactTimelineActions';
import { setInteractItemsToDelete, showInteractDeleteDialog } from '../../../actions/interactElementsActions';
import { updateTimelineItem, removeTimelineItem } from '../../../actions/liveManagerActions';
import InteractQueueDropDown from './InteractQueueDropdown';
import { getTimelineStartDate } from '../../../services/timeStampService';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import { getItemStyle, getBodyOfSequence } from '../../../services/interactScreenHelperService';
import { interactMimeType, tracksSequencesTypes } from '../../../constants/interactConstant';
import { updateSequence } from '../../../actions/tracksActions';

import './LiveManagerInteractQueue.scss';

class LiveManagerInteractQueue extends Component {
	justChangedLiveQueue = false;

	constructor(props) {
		super(props);

		const interactItems = this.getInteractItemsWithIndex();

		this.state = {
			openAssetLibrary: false,
			menuAnchor: null,
			editedItem: null,
			interactItemsState: interactItems,
		};
	}

	componentDidUpdate(prevProps, _) {
		if (prevProps.interactItems !== this.props.interactItems) {
			const interactItems = this.getInteractItemsWithIndex();
			this.setState({ interactItemsState: interactItems });
			this.justChangedLiveQueue = false;
		}
	}

	getInteractItemsWithIndex = () => {
		let interactItems = this.props.interactItems ? this.props.interactItems : [];
		const indexInteractItems = interactItems.filter((i) => i.index !== undefined || i.index !== null);
		const lastIndex = indexInteractItems.length > 0 ? indexInteractItems[indexInteractItems.length - 1] : 0;
		return interactItems
			? interactItems
					.sort((a, b) => a.index - b.index)
					.map((item, i) => ({ ...item, index: item.index ?? lastIndex + i }))
			: [];
	};

	addPresets = (items) => {
		const { interactItemsState } = this.state;
		const itemIndex =
			interactItemsState.length > 0
				? interactItemsState.sort((a, b) => a.index - b.index)[interactItemsState.length - 1].index + 1
				: 0;
		this.props.liveInteractSave.addInteractItemToTracks({ ...items, index: itemIndex });
	};

	showAssetLibrary = () => {
		this.setState({
			openAssetLibrary: true,
		});
	};

	closeAssetLibrary = () => {
		this.setState({
			openAssetLibrary: false,
		});
	};

	renderAssetLibrary = () => {
		const { openAssetLibrary } = this.state;
		if (!openAssetLibrary) {
			return null;
		}

		const { t } = this.props;
		return <AssetLibrary t={t} onClose={this.closeAssetLibrary} addPresets={this.addPresets} />;
	};

	openElementForEdit = (item) => {
		let url = window.location.href.split('/liveManager');
		url = url[0] + '/interactiveManager/' + item.id;
		window.open(url, '_blank');
	};

	openInteractiveManager = () => {
		let url = window.location.href.split('/liveManager');
		url = url[0] + '/interactiveManager';
		window.open(url, '_blank');
	};

	addToLiveQueue = (item) => {
		const { t, liveQueueItem, liveEventStatus, queueOperationInProgress } = this.props;
		if (liveEventStatus !== liveEventStatusList.STARTED) {
			this.props.showMessage(t('START_TO_ADD_QUEUE'), messageTypes.error);
			return;
		}
		if (!queueOperationInProgress) {
			this.props.setQueueOperation();
			this.props.showMessage(t('MSG_ADD_TO_QUEUE'), messageTypes.info);
			if (liveQueueItem) {
				this.removeFromLiveQueue(liveQueueItem, () => {
					this.props.liveInteractSave.modifyTimeOfQueueElement(
						item,
						this.props.liveStreamPositionTime,
						null,
						(error) => {
							this.props.resetQueueOperation();
							if (error) {
								this.props.showMessage(t('FAILED_TO_REMOVE_FROM_LIVE_QUEUE'), messageTypes.error);
								return;
							}
							this.props.showMessage(t('REMOVED_FROM_LIVE_QUEUE'), messageTypes.info);
							this.props.setLiveQueueItem(item);
						}
					); //set start and end time
				});
			} else {
				this.props.liveInteractSave.modifyTimeOfQueueElement(
					item,
					this.props.liveStreamPositionTime,
					null,
					(error) => {
						this.props.resetQueueOperation();
						if (error) {
							this.props.showMessage(t('FAILED_TO_ADD_TO_LIVE_QUEUE'), messageTypes.error);
							return;
						}
						this.props.showMessage(t('ADD_TO_LIVE_QUEUE'), messageTypes.info);
						this.props.setLiveQueueItem(item);
					}
				); //set start and end time
			}
		} else {
			this.props.showMessage(t('QUEUE_OPERATION_IN_PROGRESS'), messageTypes.error);
		}
	};

	onRemoveClick = (item) => {
		this.props.setQueueOperation();
		this.removeFromLiveQueue(item, () => {
			this.props.setLiveQueueItem('');
			this.props.resetQueueOperation();
		});
	};

	removeFromLiveQueue = (item, callback) => {
		const { t } = this.props;
		this.props.showMessage(t('MSG_REMOVE_FROM_QUEUE'), messageTypes.info);
		if (item) {
			this.justChangedLiveQueue = true;
			this.props.liveInteractSave.modifyTimeOfQueueElement(
				item,
				item.start,
				this.props.liveStreamPositionTime,
				(error) => {
					if (error) {
						this.props.showMessage(t('FAILED_TO_REMOVE_FROM_LIVE_QUEUE'), messageTypes.error);
						this.props.resetQueueOperation();
						return;
					}
					this.props.liveInteractSave.pushItemBackToQueue(item);
					if (callback) {
						callback();
					}
				}
			);
		}
	};

	renderDropdown = () => {
		const { t } = this.props;
		const { menuAnchor, editedItem } = this.state;
		if (menuAnchor) {
			return (
				<>
					<InteractQueueDropDown
						t={t}
						menuAnchor={menuAnchor}
						handleMenuClose={this.handleMenuClose}
						item={editedItem}
					/>
				</>
			);
		} else {
			return null;
		}
	};

	showMenu = (e, item) => {
		this.setState({
			menuAnchor: e.target,
			editedItem: item,
		});
	};

	handleMenuClose = (action, item) => {
		this.setState({
			menuAnchor: null,
			editedItem: null,
		});
		if (action === 'edit') {
			this.openElementForEdit(item);
		} else if (action === 'delete') {
			this.props.setInteractItemsToDelete([item]);
			this.props.showInteractDeleteDialog();
		}
	};

	refreshQueueItems = () => {
		this.props.startFetchInteractItems();
	};

	getDragItemStyle = (isDragging, draggableStyle) => ({
		userSelect: 'none',
		background: isDragging ? '#444b60' : '',
		...draggableStyle,
	});

	onDragEnd = (result, items) => {
		const changedItem = items.find((i) => i.id === result.draggableId);
		const currentItemIndex = changedItem.index;
		const delta = currentItemIndex < result.destination.index ? -1 : 1;

		const newItems = items.map((item) => {
			if (item.id === result.draggableId) {
				item.index = result.destination.index;
			} else {
				if (delta < 0 && item.index > currentItemIndex && item.index <= result.destination.index) {
					item.index = item.index + delta;
				}
				if (delta > 0 && item.index < currentItemIndex && item.index >= result.destination.index) {
					item.index = item.index + delta;
				}
			}
			return item;
		});
		this.setState({ interactItemsState: newItems }, this.updateSequence);
	};

	updateSequence = () => {
		const { t, defaultAccountId, mediaId } = this.props;
		const { interactItemsState } = this.state;

		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (!media || typeof media == 'string') {
				this.props.showMessage(t('FAILED_TO_SAVE_INVALID_VALUES'), messageTypes.error);
				return;
			}
			let tracks = media.tracks;
			if (!tracks || tracks.length === 0) {
				this.props.showMessage(t('FAILED_TO_SAVE_INVALID_VALUES'), messageTypes.error);
				return;
			}
			let serverInteractTrack = [];
			tracks.map((track) => {
				if (
					track &&
					track.sequences &&
					track.sequences.length > 0 &&
					track.sequences[0].type === 'custom' &&
					track.sequences[0].custom &&
					track.sequences[0].custom.mimeType === interactMimeType &&
					track.sequences[0].custom.interactiveWidget
				) {
					serverInteractTrack = [track];
				}
			});

			const trackId = serverInteractTrack[serverInteractTrack.length - 1].id;
			if (!trackId) {
				this.props.showMessage(t('FAILED_TO_SAVE_INVALID_VALUES'), messageTypes.error);
				return;
			}

			let count = 0;
			interactItemsState.forEach((item) => {
				const customBody = getBodyOfSequence(item);
				const originItem = this.props.interactItems.find((i) => i.id === item.id);

				if ((originItem && originItem.index === item.index) || !customBody) {
					count = count + 1;
					return;
				}

				this.props
					.updateSequence(
						defaultAccountId,
						mediaId,
						trackId,
						tracksSequencesTypes.customs,
						item.id,
						customBody
					)
					.then((res) => {
						if (typeof res === 'string') {
							count = count + 1;
							if (count === interactItemsState.length) {
								this.props.showMessage(t('UPDATED_INTERACT_ITEMS'), messageTypes.info);
							}
						} else {
							this.props.showMessage(
								t('FAILED_TO_SAVE') + ' ' + (item.templateName || item.name),
								messageTypes.error
							);
						}
					});
			});

			if (interactItemsState.length > 0) {
				this.props.sendUpdateInteractItems(interactItemsState);
			}
		});
	};

	render() {
		const { t, liveQueueItem, liveEventStatus, liveStreamPositionTime, queueOperationInProgress } = this.props;
		const { interactItemsState } = this.state;
		const shouldDisableInteractPlay =
			liveEventStatus === liveEventStatusList.STOPPED ||
			(liveStreamPositionTime.getTime && liveStreamPositionTime.getTime() == getTimelineStartDate().getTime());
		interactItemsState.sort((a, b) => a.index - b.index);

		return (
			<>
				<div
					className="LiveManagerInteractQueueWrapper"
					status={liveEventStatus === liveEventStatusList.STOPPED ? 'disabled' : ''}
				>
					<ul className="interactQueueOption">
						<li onClick={() => this.showAssetLibrary()}>
							<IconsStore iconName={IconsList.BOOK} />
							<label>{t('ADD_FROM_LIBRARY')}</label>
						</li>
						<li onClick={() => this.openInteractiveManager()}>
							<IconsStore iconName={IconsList.PLUS_SQUARE} />
							<label>{t('CREATE_NEW_ASSET')}</label>
						</li>
					</ul>

					{liveQueueItem && (
						<div className="liveQueue">
							<label className="queueHeading">{t('LIVE').toUpperCase()}</label>
							<ul className="queueList">
								<li className="queueItem liveQueueItem">
									<div
										className="interactElementQueueImageWrapper"
										style={getItemStyle(liveQueueItem)}
									/>
									<div className="queueItemTitle">
										{' '}
										{liveQueueItem.name || liveQueueItem.content || liveQueueItem.templateName}{' '}
									</div>
									<div className="interactMore disabled" />
									<div className="interactStop" onClick={() => this.onRemoveClick(liveQueueItem)} />
								</li>
							</ul>
						</div>
					)}

					<div className="interactQueue">
						<label className="queueHeading">{t('INTERACT_QUEUE')}</label>
						<div className="refreshBtn" onClick={() => this.refreshQueueItems()}>
							<IconsStore iconName={IconsList.REFRESH} />
						</div>
						<DragDropContext onDragEnd={(r) => this.onDragEnd(r, interactItemsState)}>
							<Droppable droppableId="droppable">
								{(provided) => (
									<ul className="queueList" ref={provided.innerRef} {...provided.droppableProps}>
										{interactItemsState.map((item, index) => {
											return (
												!item.start &&
												!item.end &&
												item.id !== liveQueueItem.id && (
													<Draggable key={item.id} draggableId={item.id} index={item.index}>
														{(provided, snapshot) => (
															<li
																className="queueItem"
																key={index}
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																style={this.getDragItemStyle(
																	snapshot.isDragging,
																	provided.draggableProps.style
																)}
															>
																<div
																	className="interactElementQueueImageWrapper"
																	style={getItemStyle(item)}
																/>
																<div className="queueItemTitle">
																	{' '}
																	{item.name ||
																		item.content ||
																		item.templateName}{' '}
																</div>
																<div
																	className="interactMore"
																	onClick={(e) => this.showMenu(e, item)}
																/>
																<div
																	className={[
																		'interactPlay',
																		shouldDisableInteractPlay
																			? 'interactPlay--disabled'
																			: '',
																	]
																		.join(' ')
																		.trim()}
																	onClick={
																		shouldDisableInteractPlay
																			? undefined
																			: () => this.addToLiveQueue(item)
																	}
																/>
															</li>
														)}
													</Draggable>
												)
											);
										})}
									</ul>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				</div>
				{this.renderAssetLibrary()}
				{this.renderDropdown()}
				{queueOperationInProgress && (
					<LoadingOverlay
						className="componentOverlay"
						active={true}
						spinner={<ScaleLoader color={'#ffffff'} />}
						text={t('QUEUE_OPERATION_IN_PROGRESS')}
					/>
				)}
			</>
		);
	}
}

LiveManagerInteractQueue.defaultProps = {
	interactItems: [],
};

const mapStateToProps = ({
	session,
	notification,
	liveManager,
	inspectScreenReducer,
	interactElementsReducer,
	interactTimelineReducer,
}) => ({
	defaultAccountId: session.defaultAccountId,
	message: notification.message,
	open: notification.showNotification,
	liveEventStatus: liveManager.liveEventStatus,
	SaveAsOnDemand: liveManager.SaveAsOnDemand,
	mediaOriginalDetails: inspectScreenReducer.mediaOriginalDetails,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	interactItems: interactTimelineReducer.items,
	liveQueueItem: interactTimelineReducer.liveQueueItem,
	queueOperationInProgress: interactTimelineReducer.queueOperationInProgress,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	updateTimelineItem: (item) => dispatch(updateTimelineItem(item)),
	removeTimelineItem: (item) => dispatch(removeTimelineItem(item)),
	createInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(createInteractTimelineItem(item, setScreenUnModified)),
	removeInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(removeInteractTimelineItem(item, setScreenUnModified)),
	updateInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(updateInteractTimelineItem(item, setScreenUnModified)),
	setLiveQueueItem: (item) => dispatch(setLiveQueueItem(item)),
	setInteractItemsToDelete: (items) => dispatch(setInteractItemsToDelete(items)),
	showInteractDeleteDialog: (_) => dispatch(showInteractDeleteDialog()),
	startFetchInteractItems: (_) => dispatch(startFetchInteractItems()),
	setQueueOperation: (_) => dispatch(setQueueOperation()),
	resetQueueOperation: (_) => dispatch(resetQueueOperation()),
	updateSequence: (accId, mediaId, trackId, sequenceType, sequenceId, body) =>
		dispatch(updateSequence(accId, mediaId, trackId, sequenceType, sequenceId, body)),
	sendUpdateInteractItems: (items) => dispatch(sendUpdateInteractItems(items)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveManagerInteractQueue);
