import React, { useCallback, useState } from 'react';
import { Button, FormControl, FormControlLabel, TextField } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { saveMediaChatSettings, setChatSessionDetails } from '../../../actions/liveChatActions';
import { setViewEngagementLoading } from '../../../actions/viewEngagementActions';
import { showMessage } from '../../../actions/globalActions';

import { ViewerEngagementTemplate } from './ViewerEngagementTemplate';

const ViewerEngagementSettings: React.FC<ViewerEngagementTemplate.ViewerEngagementSettingsProps> = ({
	mediaId,
	settings,
	onSaveSettings,
}) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId } = useSelector((state: any) => state.session);

	const [expanded, setExpanded] = useState<string[]>(['profile', 'data']);
	const [adminChatName, setAdminChatName] = useState<string>(settings?.adminProfile?.chatName ?? 'Admin');

	const togglePanel = useCallback((key: string) => {
		setExpanded((prev) => {
			if (prev.includes(key)) {
				return prev.filter((p) => p !== key);
			}
			return [...prev, key];
		});
	}, []);

	const onAdminChatNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setAdminChatName(e.target.value),
		[]
	);

	const onSaveMediaChatSettings = useCallback(() => {
		dispatch(setViewEngagementLoading(true));

		const chatSettings = {
			...(settings ?? {}),
			adminProfile: { ...(settings?.adminProfile ?? {}), chatName: adminChatName },
			dataCollection: {
				...(settings?.dataCollection ?? {}),
				requireUsername: false,
			},
		};

		dispatch(saveMediaChatSettings(defaultAccountId, mediaId, chatSettings))
			.then(() => {
				dispatch(setChatSessionDetails(mediaId));
				onSaveSettings?.();
			})
			.catch((error: any) => {
				dispatch(showMessage(`${t('CHAT_SETTINGS_CANT_SAVE_MESSAGE')} ${error}`));
				dispatch(setViewEngagementLoading(false));
			});
	}, [dispatch, settings, adminChatName, defaultAccountId, mediaId, onSaveSettings, t]);

	return (
		<>
			<div
				className={`${ViewerEngagementTemplate.baseClassName}-settings__header`}
				onClick={() => togglePanel('profile')}
			>
				<span>{t('CHAT_SETTINGS_ADMIN_PROFILE_LABEL')}</span>
				{expanded.includes('profile') ? <ExpandLess /> : <ExpandMore />}
			</div>
			{expanded.includes('profile') && (
				<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
					<FormControl fullWidth>
						<FormControlLabel
							label={t('CHAT_SETTINGS_ADMIN_NAME_LABEL')}
							labelPlacement="top"
							control={
								<TextField
									variant={'outlined'}
									value={adminChatName}
									placeholder={t('CHAT_SETTINGS_ADMIN_NAME_LABEL')}
									onChange={onAdminChatNameChange}
								/>
							}
						/>
					</FormControl>
				</div>
			)}

			{/* <div
				className={`${ViewerEngagementTemplate.baseClassName}-settings__header`}
				onClick={() => togglePanel('data')}
			>
				<span>Data Collection</span>
				{expanded.includes('data') ? <ExpandLess /> : <ExpandMore />}
			</div> */}

			{/* {expanded.includes('data') && (
				<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
					<div className={`${ViewerEngagementTemplate.baseClassName}-settings__chat-access`}>
						<label>{t('CHAT_SETTINGS_USER_ACCESS_LABEL')}</label>
						<FormControl fullWidth>
							<FormControlLabel
								control={
									<Checkbox
										value={'username'}
										checked={requireUsername}
										onChange={onUserChatAccessChange}
									/>
								}
								label={t('CHAT_SETTINGS_USER_NAME_LABEL')}
							/>
						</FormControl>
					</div>
				</div>
			)} */}

			<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
				<Button
					variant="contained"
					color="primary"
					className={`defaultActionBtn`}
					disabled={!adminChatName || adminChatName === settings?.adminProfile?.chatName}
					onClick={onSaveMediaChatSettings}
				>
					Save
				</Button>
			</div>
		</>
	);
};

export default ViewerEngagementSettings;
