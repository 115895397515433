import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import IconsStore from '../../../../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../../../../reusable/IconsStore/IconsList';


export const RestrictionRuleDeleteDialog = ({
    t,
    state,
    deleteSelected,
    handleHideDialog
}) => (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={state && state.showDeleteDialogStatus}
            className="confirmationPopup"
        >
            <DialogTitle className="dialogTitle" disableTypography>
                <Typography variant="h6">{t('DELETE_RESTRICTION_RULE') + '?'}</Typography>
                <IconButton onClick={handleHideDialog}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className="dialogContentWrapper">
                        <label>{t('DELETE_CONFIRMATION_SINGLE')}</label>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className="dialogActionsWrapper">
                    <div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
                        <Button variant="contained" className="defaultActionBtn neutralBtn" onClick={handleHideDialog}>{t('BACK')}</Button>
                        <Button variant="contained" className="defaultActionBtn deleteButtonWithIcon" onClick={() => deleteSelected(state.selectedThumbImg, state.selectedResourceId)}>
                            <IconsStore iconName={IconsList.TRASHCAN} />
                            {t('DELETE')}
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );