import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { LoginFormUpdate, passwordChange } from '../../actions/sessionActions';
import {
	listAllUsers,
	userDetails,
	userDelete,
	createNewUser,
	profileNewUserFormUpdate,
	getAccountSettings,
	updateEnabledEmbed,
	setHasAdvancedSettings,
} from '../../actions/profileActions';
import { closeDrawer, updateSelectedKey } from '../../actions/navActions';
import { getValuesInArrayFromObject } from './../../services/componentReusableService';
import ScreenParentContainer from '../../reusable/ScreenParentContainer/ScreenParentContainer';
import FeaturesService from './../../services/FeaturesService';
import { showMessage } from '../../actions/globalActions';
import { messageTypes } from '../../constants/mediaConstants';
import PlayerConfigurations from '../PublishScreen/InspectScreen/InspectSections/PlayerConfigurations/PlayerConfigurations';

import { ProfileLeftMenu } from './ProfileLeftMenu';
import { ProfileInformation } from './ProfileSections/ProfileInformation';
import CustomProperties from './ProfileSections/CustomProperties';
import { UserLicences } from './ProfileSections/UserLicences';
import Users from './ProfileSections/Users/Users';
import Channels from './ProfileSections/Channels/Channels';
import { OtherInformation } from './ProfileSections/OtherInformation';
import AdvancedSettings from './AdvancedSetting/AdvancedSettings';
import './ProfileScreen.scss';
import './Users.scss';
import './Channels.scss';

class ProfileScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			errorFields: {
				password: false,
				username: false,
				newPassword: false,
				confirmPassword: false,
			},
			touched: {
				password: false,
				newPassword: false,
				confirmPassword: false,
			},
			allUsers: [],
			currentPage: 1,
			dataPerPage: 5,
			relatedAccounts: [],
			usernameExpanded: '',
			usernameToDelete: '',
			errorsInTheForm: false,
			successInTheForm: false,
			userDetailsExpanded: '',
			addToUserDeleteBulk: [],
			rowExpandedStatus: false,
			showNewUserDialog: false,
			currentlySelectedItem: '',
			deleteUserDialogStatus: false,
			deleteCustomPropertyDialogStatus: false,
			selectedProfileBlockIndex: this.props.selectedProfileBlockIndex ?? '1',
			addToUserDeleteBulkAsAnArray: [],
		};
	}

	componentDidMount() {
		this.listAllUsersHandler(this.props.defaultAccountId);
		this.loadEmbedSetting();
		this.props._updateSelectedKey('settings');
		this.props._closeDrawer();
		this.clearPasswordFields();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.defaultAccountId !== this.props.defaultAccountId) {
			this.listAllUsersHandler(this.props.defaultAccountId);
			this.loadEmbedSetting();
		}
		if (
			prevProps.selectedProfileBlockIndex !== this.props.selectedProfileBlockIndex &&
			this.state.selectedProfileBlockIndex !== this.props.selectedProfileBlockIndex
		) {
			this.setState({ selectedProfileBlockIndex: this.props.selectedProfileBlockIndex });
		}
	}

	listAllUsersHandler = (defaultAccountId, selectedProfileBlockIndex) =>
		this.props.listAllUsers(defaultAccountId).then((data) => {
			if (data && data.length > 1) {
				this.setState((prevState) => ({
					allUsers: getValuesInArrayFromObject(
						data.concat(data).reduce((acc, cur) => Object.assign(acc, { [cur.login]: cur }), {})
					),
					selectedProfileBlockIndex: selectedProfileBlockIndex
						? selectedProfileBlockIndex
						: prevState.selectedProfileBlockIndex,
				}));
			}
		});

	loadEmbedSetting = () => {
		const { defaultAccountId, getAccountSettings, updateEnabledEmbed, setHasAdvancedSettings } = this.props;
		getAccountSettings(defaultAccountId).then((settings) => {
			setHasAdvancedSettings(settings && Object.keys(settings).length > 0);

			if (!(settings && Object.keys(settings).length > 0)) {
				return;
			}

			let embedVersion = {};
			if (
				settings.hasOwnProperty('qbrickPlayer') &&
				settings['qbrickPlayer'] &&
				settings['qbrickPlayer'].length > 0
			) {
				embedVersion = settings['qbrickPlayer'].find((v) => !!v.enabled);
			}
			updateEnabledEmbed(embedVersion);
		});
	};

	checkForProfileActivityHandler = (i) => this.setState({ selectedProfileBlockIndex: i, userDetailsExpanded: '' });

	handleNextPageHandler = (_) => this.setState((prevState) => ({ currentPage: prevState.currentPage + 1 }));

	handlePreviousPageHandler = (_) => this.setState((prevState) => ({ currentPage: prevState.currentPage - 1 }));

	handleFirstPageHandler = (_) => this.setState({ currentPage: 1 });

	handleLastPageHandler = (_) => {
		const { dataPerPage, allUsers } = this.state;
		this.setState({ currentPage: Math.ceil(allUsers.length / dataPerPage) });
	};

	handleChangeHandler = (_) => (event) =>
		this.setState({ dataPerPage: event.target.value !== '' ? event.target.value : 5 });

	handleDetailsHandler = (username, id) => {
		const { defaultAccountId } = this.props;
		this.setState((prevState) => ({
			usernameExpanded: username,
			userDetailsExpanded: id,
			rowExpandedStatus: !prevState.status,
			relatedAccounts: [],
		}));
		this.props.userDetails(defaultAccountId, id).then((data) => this.setState({ relatedAccounts: data }));
	};

	hideDetailsHandler = (_) => this.setState(() => ({ usernameExpanded: '', userDetailsExpanded: '' }));

	showNewUserDialogBoxHandler = (_) => this.setState({ showNewUserDialog: true });

	hideNewUserDialogHandler = (_) => this.setState({ showNewUserDialog: false });

	handleDeleteUserDialogHandler = (username, userId) =>
		this.setState({ usernameToDelete: username, deleteUserDialogStatus: true, currentlySelectedItem: userId });

	handleHideDeleteUserDialogHandler = (_) =>
		this.setState({
			deleteUserDialogStatus: false,
			currentlySelectedItem: '',
			addToUserDeleteBulkAsAnArray: [],
			usernameToDelete: '',
			addToUserDeleteBulk: [],
		});

	clearPasswordFields = () => {
		this.props.updateForm('password', '');
		this.props.updateForm('newPassword', '');
		this.props.updateForm('confirmPassword', '');
	};

	changePasswordClickHandler = (_) => {
		const { showMessage, submitForm, t } = this.props;
		!this.state.errorsInTheForm &&
			submitForm().then((res) => {
				if (res) {
					showMessage(t('PASSWORD_CHANGED_NOTIFICATION'), messageTypes.success);
					this.clearPasswordFields();
				}
				this.setState({
					errorFields: {
						password: false,
						newPassword: false,
						confirmPassword: false,
					},
					errorsInTheForm: false,
					successInTheForm: res ? true : false,
				});
			});
	};

	handleBlur = (field) => (event) =>
		this.setState({
			touched: { ...this.state.touched, [field]: true },
			errorFields: { [field]: !event.target.value ? true : false },
		});

	updateFormHandler = (field) => (event) => {
		const passwordRule = new RegExp(
			'^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
		);
		switch (field) {
			case 'password':
				this.setState({
					errorFields: {
						[field]: !event.target.value ? true : false,
					},
					errorsInTheForm: !event.target.value ? true : false,
				});
				break;
			case 'newPassword':
			case 'confirmPassword':
				this.setState({
					errorFields: {
						[field]:
							!event.target.value ||
							!passwordRule.test(event.target.value) ||
							event.target.value !== this.props.newPassword
								? true
								: false,
					},
					errorsInTheForm:
						!event.target.value ||
						!passwordRule.test(event.target.value) ||
						event.target.value !== this.props.newPassword
							? true
							: false,
				});
				break;
		}
		this.props.updateForm(field, event.target.value);
	};

	searchUserHandler = (event) => {
		const { allUsers } = this.state;
		const { defaultAccountId } = this.props;
		if (event.target.value) {
			this.setState({
				allUsers: allUsers.filter((username) => username.login.indexOf(event.target.value) !== -1),
			});
		} else {
			this.props.listAllUsers(defaultAccountId).then((data) =>
				this.setState({
					allUsers: getValuesInArrayFromObject(
						data.concat(data).reduce((acc, cur) => Object.assign(acc, { [cur.login]: cur }), {})
					),
				})
			);
		}
	};

	handleUserDelete = (_) => {
		const { defaultAccountId } = this.props;
		const { addToUserDeleteBulk, currentlySelectedItem } = this.state;
		if (addToUserDeleteBulk.length > 0 || currentlySelectedItem) {
			this.setState((prevState) => ({ deleteUserDialogStatus: !prevState.deleteUserDialogStatus }));
			if (addToUserDeleteBulk.length > 0) {
				addToUserDeleteBulk.forEach((item) =>
					this.props
						.deleteUserSelected(item, defaultAccountId)
						.then(() => this.listAllUsersHandler(defaultAccountId))
				);
			} else {
				this.props
					.deleteUserSelected(currentlySelectedItem, defaultAccountId)
					.then(() => this.listAllUsersHandler(defaultAccountId));
			}
		}
	};

	handleMultipleUserDelete = (_) =>
		(this.state.addToUserDeleteBulk.length > 0 || this.state.currentlySelectedItem) &&
		this.setState({ deleteUserDialogStatus: true });

	addToSelectUserDelete = (userId, userName) => {
		const { addToUserDeleteBulk, addToUserDeleteBulkAsAnArray } = this.state;
		this.setState({
			usernameToDelete: userName,
			currentlySelectedItem: userId,
			addToUserDeleteBulk: this.state.addToUserDeleteBulk.concat(userId),
			addToUserDeleteBulkAsAnArray: this.state.addToUserDeleteBulkAsAnArray.concat(userName),
		});
		if (addToUserDeleteBulk.indexOf(userId) !== -1) {
			this.setState({
				addToUserDeleteBulk: addToUserDeleteBulk.filter((item) => item !== userId),
				currentlySelectedItem: '',
			});
		}

		if (addToUserDeleteBulkAsAnArray.indexOf(userName) !== -1) {
			this.setState({
				addToUserDeleteBulkAsAnArray: addToUserDeleteBulkAsAnArray.filter((item) => item !== userName),
				usernameToDelete: '',
			});
		}
	};

	handleTheModelBoxInput = (field) => (event) => this.props.updateNewUserForm(field, event.target.value);

	handleUserCreation = (_) => {
		this.setState({ showNewUserDialog: false });
		this.props
			.createNewUser(this.props.login, this.props.ipRestrictions)
			.then((_) => this.listAllUsersHandler(this.props.defaultAccountId));
	};

	redirectToLocation = (path) => this.props.history.push(`/${path}`);

	stringToColor = (str) => {
		var hash = 0;
		try {
			for (var i = 0; i < str.length; i++) {
				hash = str.charCodeAt(i) + ((hash << 5) - hash);
			}
		} catch (err) {
			console.error(err);
		}
		var h = hash % 260;
		return 'hsl(' + h + ', ' + 39 + '%, ' + 50 + '%)';
	};

	getInitials = (str) => {
		let result = 'notfound';
		try {
			let firstLetter = str.charAt(0);
			let afterDot = str.substr(str.indexOf('.'));
			let secondLetter = afterDot.charAt(1);
			result = firstLetter + ' ' + secondLetter;
		} catch (err) {
			console.error(err);
		}

		return result;
	};

	isFeatureEnabled = (featureName) => {
		const { userPermissions } = this.props;
		return FeaturesService.IsEnabled(userPermissions, featureName);
	};

	render() {
		const { t, login, loading, username, password, newPassword, ipRestrictions, confirmPassword } = this.props;

		const {
			touched,
			allUsers,
			errorFields,
			currentPage,
			dataPerPage,
			relatedAccounts,
			usernameExpanded,
			successInTheForm,
			usernameToDelete,
			showNewUserDialog,
			userDetailsExpanded,
			addToUserDeleteBulk,
			currentlySelectedItem,
			deleteUserDialogStatus,
			selectedProfileBlockIndex,
			addToUserDeleteBulkAsAnArray,
			errorsInTheForm,
		} = this.state;

		if (!allUsers) {
			return null;
		}
		const indexOfLastDataSet = currentPage * dataPerPage;
		const indexOfFirstDataSet = indexOfLastDataSet - dataPerPage;
		const pageNumbers = Math.ceil(allUsers.length / dataPerPage);
		const currentDataSet = allUsers.slice(indexOfFirstDataSet, indexOfLastDataSet);

		return (
			<>
				<ScreenParentContainer classNames="profileScreen__parent">
					<div className="profileScreenWrapper">
						<ProfileLeftMenu
							t={t}
							selectedBlock={selectedProfileBlockIndex}
							onClick={this.checkForProfileActivityHandler}
							isFeatureEnabled={this.isFeatureEnabled}
						/>
						{(() => {
							switch (selectedProfileBlockIndex) {
								case '1':
									return (
										<ProfileInformation
											t={t}
											touched={touched}
											loading={loading}
											username={username}
											password={password}
											errors={errorFields}
											passwordNew={newPassword}
											handleBlur={this.handleBlur}
											passwordConfirm={confirmPassword}
											formSubmissionSuccess={successInTheForm}
											updateFormHandler={this.updateFormHandler}
											changePasswordHandler={this.changePasswordClickHandler}
											stringToColor={this.stringToColor}
											getInitials={this.getInitials}
											errorsInTheForm={errorsInTheForm}
										/>
									);
								case '2':
									return <OtherInformation />;
								case '3':
									if (
										this.isFeatureEnabled('Users and features') &&
										this.isFeatureEnabled('Account')
									) {
										return <Users users={allUsers} />;
									} else {
										this.setState({ selectedProfileBlockIndex: '1' });
									}
									break;
								case '4':
									return (
										<UserLicences
											t={t}
											login={login}
											allUsers={allUsers}
											dataPerPage={dataPerPage}
											currentPage={currentPage}
											pageNumbers={pageNumbers}
											ipRestrictions={ipRestrictions}
											currentDataSet={currentDataSet}
											relatedAccounts={relatedAccounts}
											usernameExpanded={usernameExpanded}
											searchUser={this.searchUserHandler}
											usernameToDelete={usernameToDelete}
											showNewUserDialog={showNewUserDialog}
											hideDetails={this.hideDetailsHandler}
											indexOfLastDataSet={indexOfLastDataSet}
											handleChange={this.handleChangeHandler}
											handleUserDelete={this.handleUserDelete}
											userDetailsExpanded={userDetailsExpanded}
											indexOfFirstDataSet={indexOfFirstDataSet}
											handleDetails={this.handleDetailsHandler}
											addToUserDeleteBulk={addToUserDeleteBulk}
											handleNextPage={this.handleNextPageHandler}
											handleLastPage={this.handleLastPageHandler}
											handleUserCreation={this.handleUserCreation}
											handleFirstPage={this.handleFirstPageHandler}
											currentlySelectedItem={currentlySelectedItem}
											deleteUserDialogStatus={deleteUserDialogStatus}
											addToSelectUserDelete={this.addToSelectUserDelete}
											handlePreviousPage={this.handlePreviousPageHandler}
											handleTheModelBoxInput={this.handleTheModelBoxInput}
											handleHideNewUserDialog={this.hideNewUserDialogHandler}
											handleMultipleUserDelete={this.handleMultipleUserDelete}
											handleNewUserDialogBox={this.showNewUserDialogBoxHandler}
											handleDeleteUserDialog={this.handleDeleteUserDialogHandler}
											addToUserDeleteBulkAsAnArray={addToUserDeleteBulkAsAnArray}
											handleHideDeleteUserDialog={this.handleHideDeleteUserDialogHandler}
											stringToColor={this.stringToColor}
											getInitials={this.getInitials}
										/>
									);
								case '5':
									return <CustomProperties t={t} />;
								case '6':
									return <Channels t={t} />;
								case '8':
									return <PlayerConfigurations />;
								case '9':
									return <AdvancedSettings t={t} />;
							}
						})()}
					</div>
				</ScreenParentContainer>
			</>
		);
	}
}

const mapStateToProps = ({ session, profile, navReducer }) => ({
	login: profile.login,
	open: navReducer.open,
	userId: session.userId,
	loading: session.loading,
	password: session.password,
	username: session.username,
	accounts: session.accounts,
	loadingAcc: profile.loading,
	isLoggedIn: session.isLoggedIn,
	newPassword: session.newPassword,
	ipRestrictions: profile.ipRestrictions,
	confirmPassword: session.confirmPassword,
	defaultAccountId: session.defaultAccountId,
	customMetadata: session.customMetadata ? JSON.parse(session.customMetadata) : '',
	userPermissions: session.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
	_closeDrawer: (_) => dispatch(closeDrawer()),
	submitForm: (_) => dispatch(passwordChange()),
	userDetails: (accountId, userId) => dispatch(userDetails(accountId, userId)),
	_updateSelectedKey: (key) => dispatch(updateSelectedKey(key)),
	listAllUsers: (accountId) => dispatch(listAllUsers(accountId)),
	createNewUser: (email, ip) => dispatch(createNewUser(email, ip)),
	updateForm: (field, value) => dispatch(LoginFormUpdate(field, value)),
	deleteUserSelected: (accountId, userId) => dispatch(userDelete(accountId, userId)),
	updateNewUserForm: (field, value) => dispatch(profileNewUserFormUpdate(field, value)),
	getAccountSettings: (accountId) => dispatch(getAccountSettings(accountId)),
	updateEnabledEmbed: (enabledEmbed) => dispatch(updateEnabledEmbed(enabledEmbed)),
	showMessage: (message, type) => dispatch(showMessage(message, type)),
	setHasAdvancedSettings: (value) => dispatch(setHasAdvancedSettings(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ProfileScreen);
