import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';

import { renditionTypes } from '../../../../../../constants/mediaConstants';
import { dialogSizes } from '../../../../../../constants/dialogSizesConstant';
import { showNotification } from '../../../../../../actions/notificationActions';
import eye from '../../../../../../assets/images/eye.svg';
import copy from '../../../../../../assets/images/copy.svg';

import './InformationDialog.scss';

const styles = (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing(1),
	},
});

class InformationDialog extends Component {
	state = {
		fullWidth: true,
		maxWidth: dialogSizes.SMALL,
	};

	getDialogStyle = (_) => ({ paddingLeft: 53, paddingTop: 0, paddingRight: 53, paddingBottom: 0 });

	renderStorageDetails = () => {
		const { t, selectedRow } = this.props;
		// let path = '...' + selectedRow.link.slice(-20)
		return (
			<>
				<table className="infoTable">
					<tbody>
						<tr>
							<td> {t('AUTHORITY')} </td>
							<td> {selectedRow.authority} </td>
						</tr>
						<tr>
							<td> {t('SCHEME')} </td>
							<td> {selectedRow.scheme ? selectedRow.scheme : ''} </td>
						</tr>
						<tr>
							<td>{t('PATH')}</td>
							<td> {selectedRow.path} </td>
						</tr>
						<tr>
							<td>{t('MIME_TYPE')}</td>
							<td>{selectedRow.mimeType}</td>
						</tr>
						<tr>
							<td>{t('SIZE')}</td>
							<td> {selectedRow.size} </td>
						</tr>
						<tr>
							<td>{t('RELATION')}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</>
		);
	};

	renderVideoAudioDetails = () => {
		const { t, selectedRow } = this.props;
		let audioData = selectedRow.audios ? selectedRow.audios[0] : {};
		if (selectedRow.type == renditionTypes.IMAGE) {
			return (
				<>
					<label> {t('IMAGE')} </label> <br />
					<table className="infoTable">
						<tbody>
							<tr>
								<td>{t('WIDTH') + ':'}</td>
								<td> {selectedRow.width} </td>
							</tr>
							<tr>
								<td> {t('HEIGHT') + ':'} </td>
								<td> {selectedRow.height} </td>
							</tr>
						</tbody>
					</table>
				</>
			);
		} else if (selectedRow.type == renditionTypes.VIDEO) {
			return (
				<>
					<label> {t('VIDEO_AND_AUDIO')} </label> <br />
					<table className="infoTable">
						<tbody>
							<tr>
								<td>{t('BITRATE') + ':'}</td>
								<td> {selectedRow.bitrate} </td>
								<td> {t('SAMPLE_RATE') + ':'}</td>
								<td> {audioData.sampleRate} </td>
							</tr>
							<tr>
								<td>{t('CODEC') + ':'}</td>
								<td> {selectedRow.codec} </td>
								<td>{t('BIT_DEPTH') + ':'}</td>
								<td> {audioData.bitDepth} </td>
							</tr>
							<tr>
								<td>{t('WIDTH') + ':'}</td>
								<td> {selectedRow.width} </td>
								<td>{t('CHANNELS') + ':'}</td>
								<td> {audioData.channels} </td>
							</tr>
							<tr>
								<td>{t('HEIGHT') + ':'}</td>
								<td> {selectedRow.height} </td>
								<td>{t('CODECS') + ':'}</td>
								<td> {audioData.codec} </td>
							</tr>
						</tbody>
					</table>
				</>
			);
		} else if (selectedRow.type == renditionTypes.AUDIO) {
			return (
				<>
					<label> {t('AUDIO')} </label> <br />
					<table className="infoTable">
						<tbody>
							<tr>
								<td> {t('SAMPLE_RATE') + ':'}</td>
								<td> {audioData.sampleRate} </td>
							</tr>
							<tr>
								<td> {t('BIT_DEPTH') + ':'}</td>
								<td> {audioData.bitDepth} </td>
							</tr>
							<tr>
								<td> {t('CHANNELS') + ':'}</td>
								<td> {audioData.channels} </td>
							</tr>
							<tr>
								<td> {t('CODECS') + ':'}</td>
								<td> {audioData.codec} </td>
							</tr>
						</tbody>
					</table>
				</>
			);
		} else {
			return null;
		}
	};

	renderDeliveryUrl = () => {
		const { selectedRow } = this.props;

		return (
			<>
				<TextField
					value={selectedRow.link}
					id="urlLink"
					multiline
					rows="3"
					fullWidth
					margin="normal"
					variant="outlined"
				/>
			</>
		);
	};

	renderCopyAndLinkDetails = () => {
		const { t, selectedRow } = this.props;

		return (
			<>
				<div className="informationDialogBottomActions">
					<a onClick={() => this.showPreview(selectedRow)}>
						<img className="imgIcons" src={eye} />
						<span> {t('LINK')} </span>{' '}
					</a>
					<a onClick={this.copyLink}>
						{' '}
						<img className="imgIcons" src={copy} />
						<span> {t('COPY')} </span>{' '}
					</a>
				</div>
			</>
		);
	};

	showPreview = (rowData) => {
		window.open(rowData.link);
	};

	copyLink = () => {
		const { t } = this.props;
		let copyText = document.getElementById('urlLink');
		copyText.select();
		document.execCommand('copy');
		this.props.showNotification(t('TEXT_COPIED'));
	};

	render() {
		const { t, closeInformationDialog, selectedRow, open } = this.props;

		return (
			<>
				<Dialog
					id="informationDialog"
					style={this.getDialogStyle()}
					fullWidth={true}
					maxWidth={dialogSizes.SMALL}
					open={open}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					onClose={closeInformationDialog}
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">
								{t('FILE_DETAILS') + ':'} {selectedRow.name}</Typography>
						<IconButton onClick={closeInformationDialog}>
							<Close/>
						</IconButton>
					</DialogTitle>

					<DialogContent>
						<DialogContentText>
							<div className="">
								<label> {t('STORAGE')} </label> <br />
								{this.renderStorageDetails()} <br />
								{this.renderVideoAudioDetails()} <br />
								<label> {t('DELIVERY_URL')} </label> <br />
								{this.renderDeliveryUrl()}
								{this.renderCopyAndLinkDetails()}
							</div>
						</DialogContentText>
					</DialogContent>
					{/*
                        <DialogActions>
                        <div className="dialogActionsWrapper">
                            <div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
                                <Button
                                    variant="contained"
                                    className="deleteItemBtn"
                                    onClick={closeInformationDialog}
                                >
                                    {t('CLOSE')}
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                         */}
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles)(InformationDialog));
