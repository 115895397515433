import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import './Layout.scss';
import Sidebar from './Sidebar';
import { KEYS } from '../../../layout/nav/navigationItems';
import { updateSelectedKey } from '../../../actions/navActions';

const Layout = ({ children, title, breadCrumb, mediaView, isShowErrorMessage, setShowErrorMessage }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		!mediaView && dispatch(updateSelectedKey(KEYS.analytics));
	}, []);

	return (
		<Grid container spacing={0} className="analyticsBody">
			<Grid item xs={12} sm={3} md={2}>
				<Sidebar />
			</Grid>
			<Grid item xs={12} sm={9} md={10}>
				{/* this temporary error and will be removed in next release. So don't need translation */}
				{isShowErrorMessage && (
					<Box
						style={{
							padding: 10,
							margin: 10,
							borderRadius: 4,
							backgroundColor: '#126CFC1A',
						}}
					>
						<Box
							display="flex"
							justifyContent={'space-between'}
							alignItems={'center'}
							paddingBottom={'10px'}
						>
							<Typography style={{ fontWeight: 600 }}>Can’t see your data?</Typography>
							<IconButton
								size="small"
								onClick={() => {
									setShowErrorMessage(false);
								}}
							>
								<Close />
							</IconButton>
						</Box>

						<Typography style={{ paddingBottom: '10px' }}>
							We are currently experiencing issues in visualizing the analytics data. We are working to
							restore the service as soon as possible. If you need help in extracting data, please contact
							our support team.
						</Typography>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setShowErrorMessage(false);
							}}
						>
							I understand
						</Button>
					</Box>
				)}
				<div className="analyticsRightBody addScroll">
					<div className="sec mainSec">
						<h1 className="pageTitle">{t(title)}</h1>
						{breadCrumb && (
							<p>
								{' '}
								{breadCrumb.link ? (
									<Link to={breadCrumb.link}>{breadCrumb.text}</Link>
								) : (
									breadCrumb.text
								)}{' '}
								/ <b>{breadCrumb.subTitle}</b>
							</p>
						)}
					</div>
					{children}
				</div>
			</Grid>
		</Grid>
	);
};

export default Layout;
