import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Layout from './components/Layout';
import FilterByDates from './components/FilterByDates';
import SectionLayout from './components/SectionLayout';
import { _optionsMAP } from './../../utils/commonUtil';
import { useSelector } from 'react-redux';
import ReuseTable from './components/ReuseTable';
import { locationColumns } from './../../constants/analyticsConstant';
import { getLocationsStatsByDimension } from './../../services/analyticsServices';
import Grid from '@material-ui/core/Grid';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import mapDataWorld from '@highcharts/map-collection/custom/world.geo.json';
// require("highcharts/modules/map")(Highcharts);

const AnalyticsCountriesScreen = () => {
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [endDate, setEndDate] = useState(moment().valueOf());
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days').valueOf());
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const getFilterData = (sDate, eDate) => {
		//getStatData(startDate, endDate);
		setStartDate(sDate);
		setEndDate(eDate);
	};

	useEffect(async () => {
		setLoading(true);
		const responce = await getLocationsStatsByDimension(accountId, startDate, endDate, 'countrylabel');
		setData(responce);
		setLoading(false);
	}, [startDate, endDate, accountId]);

	locationColumns[0]['label'] = 'Country';
	let newData = [
		{
			title: 'Performance by Country',
			mapData: mapDataWorld,
			joinBy: ['name', 'name'],
			name: 'countries',
			borderColor: 'black',
			showInLegend: false,
			borderWidth: 0.2,
			states: {
				hover: {
					borderWidth: 1,
					color: '#7b9b00',
				},
			},
			data,
		},
	];

	return (
		<Layout title="ANALYTICS_TITLE_CDN_ANALYTICS">
			<FilterByDates getFilterData={getFilterData} />
			<SectionLayout title="Countries" addClass="tech" loading={loading}>
				<Grid item xs={12}>
					{data && data.length > 0 ? (
						<HighchartsReact
							constructorType={'mapChart'}
							highcharts={Highcharts}
							options={_optionsMAP(newData)}
						/>
					) : (
						'No data available'
					)}
				</Grid>
			</SectionLayout>
			<SectionLayout addClass="volumePerformance">
				<ReuseTable columns={locationColumns} data={data} />
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsCountriesScreen;
