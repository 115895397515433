import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { play2ConfigBaseUrl, readApiEndpointV1 } from './../../utils/config';
import { LocalStorageService } from './../../services/localStorageService';
import { setRefreshMediasIds } from '../../actions/publishActions';

class LivePlayer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			widgetId: null,
		};
	}

	componentWillUnmount() {
		this.destroyPlayer();
	}

	componentDidMount = () => {
		this.createPlayer();
	};

	componentDidUpdate(prevProps) {
		const { play, data, refreshMediasIds, mediaDetails, isStreamPublished, setRefreshMediasIds } = this.props;
		if (play) {
			this._play();
		}
		if (!play) {
			this._pause();
		}

		const shouldReinitPlayer =
			refreshMediasIds.find((r) => r === mediaDetails?.id) &&
			((mediaDetails?.restrictions ?? []).length > 0 || (prevProps.mediaDetails?.restrictions ?? []).length > 0);
		if (
			data != prevProps.data ||
			(prevProps.isStreamPublished !== isStreamPublished && isStreamPublished) ||
			shouldReinitPlayer
		) {
			if (shouldReinitPlayer) {
				const newRefreshMediasIds = refreshMediasIds.filter((s) => s !== mediaDetails?.id);
				setRefreshMediasIds(newRefreshMediasIds);
			}
			this.destroyPlayer(); //Player data/url has been changed, so detroy existing one, and create new
			this.createPlayer();
		}
	}

	destroyPlayer = () => {
		const player = this._getPlayerInstance();
		if (player) {
			this._pause();
			this.removeEventHandlers(player);
		}

		if (this.state.widgetId) {
			window.GoBrain.destroy(this.state.widgetId, true);
		}
	};

	_onPlayable = () => {
		const { play } = this.props;

		const { repeat } = this.props;
		if (document.getElementsByTagName('VIDEO').length > 0) {
			document.getElementsByTagName('VIDEO')[0].setAttribute('loop', repeat);
		}
		if (play) {
			this._play();
		}
	};

	_onSeeked = () => {
		const { pauseOnSeek } = this.props;
		const player = this._getPlayerInstance();
		if (pauseOnSeek && player) {
			player.pause();
		}
	};

	addEventHandlers = (player) => {
		player.once('playable', (e) => {
			this._onPlayable(e);
		});
		player.on('seeked', (e) => {
			this._onSeeked(e);
		});
	};

	removeEventHandlers = (player) => {
		player.off('playable', (e) => {
			this._onPlayable(e);
		});
		player.off('seeked', (e) => {
			this._onSeeked(e);
		});
	};

	createPlayer = () => {
		const { widgetPrefix } = this.props;
		const widgetId = this.generateWidgetId();
		let playerSettings = this.getSettings(widgetId);
		let player =
			window.GoBrain && window.GoBrain.create(document.getElementById(this.props.container), playerSettings);
		this.addEventHandlers(player);
		this.setState({ widgetId: widgetId });
		if (this.props.onPlayerCreated) {
			this.props.onPlayerCreated(widgetPrefix);
		}
	};

	generateWidgetId = () => {
		const { widgetPrefix, isLive, calledFromInteract } = this.props;

		let widgetId = `${widgetPrefix}-${isLive ? (calledFromInteract ? 'od' : 'live') : 'od'}`;
		return widgetId;
	};

	_getPlayerInstance = () => {
		if (!this.state.widgetId) {
			return undefined;
		}
		return window.GoBrain.widgets()[this.state.widgetId];
	};

	_play = () => {
		const player = this._getPlayerInstance();
		try {
			player && player.play();
		} catch (e) {}
	};
	_pause = () => {
		const player = this._getPlayerInstance();
		try {
			player && player.pause();
		} catch (e) {}
	};

	_updatePosition = () => {
		const player = this._getPlayerInstance();
		player && player.play();
	};

	getDisabledControls = () => {
		return {
			cog: {
				enabled: false,
			},
			live: {
				enabled: false,
			},
			time: {
				enabled: false,
			},
			playPause: {
				enabled: false,
			},
			sharing: {
				enabled: false,
			},
			volume: {
				enabled: false,
			},
			fade: {
				enabled: false,
			},
			progress: {
				enabled: false,
				interactive: false,
			},
			fullscreen: {
				enabled: false,
				nativeEnabled: false,
			},
		};
	};

	getLayersOfPlayer = () => {
		return [
			{
				type: 'View',
				settings: {
					layout: {
						layers: [
							{
								boxes: [
									{
										top: '0%',
										left: '0%',
										width: '100%',
										height: '100%',
										moduleName: 'MediaPlayer',
									},
								],
							},
							{
								boxes: [
									{
										top: '100%',
										right: '0',
										width: '100%',
										height: '38px',
										position: 'relative',
										moduleName: 'Controls',
									},
								],
							},
						],
					},
				},
			},
		];
	};

	getInteractControls = () => {
		//added fade as disabled so that control is always visible and fullscreen option should not be visible
		return {
			sharing: {
				enabled: false,
			},
			fade: {
				enabled: false,
			},
			fullscreen: {
				enabled: false,
				nativeEnabled: false,
			},
		};
	};

	getLiveManagerControls = () => {
		return {
			cog: {
				enabled: false,
			},
			live: {
				enabled: false,
			},
			time: {
				enabled: false,
			},
			playPause: {
				enabled: true,
			},
			sharing: {
				enabled: false,
			},
			volume: {
				enabled: true,
			},
			fade: {
				enabled: false,
			},
			progress: {
				enabled: false,
				interactive: false,
			},
			fullscreen: {
				enabled: false,
				nativeEnabled: false,
			},
		};
	};

	getSettings = (widgetId) => {
		const {
			keepPreviousStateAfterSeeking,
			defaultAccountId,
			entityId,
			entityType,
			height,
			width,
			configurationId,
			configUrl,
			data,
			autoPlay,
			repeat,
			muted,
			hidePlayButton,
			hideControls,
			interactControls,
			liveManagerControls,
			needAuthorization,
		} = this.props;

		let _configUrl = configUrl
			? configUrl
			: `${play2ConfigBaseUrl}${defaultAccountId}/configurations/${configurationId}`;
		if (!configUrl && !configurationId) {
			_configUrl =
				'https://video.qbrick.com/play2/api/v1/accounts/Accpy7KNHj1IUilFL5BMuNL0Q/configurations/interactive-manager';
		}

		const _data = data
			? data
			: `${readApiEndpointV1}accounts/${defaultAccountId}/${entityType}/${entityId}${
					entityType.toLowerCase() === 'playlists' ? '/medias' : ''
			  }`;
		const _keepPreviousStateAfterSeeking = keepPreviousStateAfterSeeking ? keepPreviousStateAfterSeeking : false;
		const headers = needAuthorization ? { Authorization: 'Bearer ' + LocalStorageService.getAccessToken() } : {};

		let settings = {
			widgetId: widgetId,
			config: _configUrl,
			data: _data,
			autoplay: autoPlay,
			ignoreAnalytics: true,
			keepPreviousStateAfterSeeking: _keepPreviousStateAfterSeeking,
			repeat: repeat,
			volume: muted ? 0 : null,
			height: parseInt(height),
			width: width,
			moduleSettings: {
				Poster: {
					play: {
						enabled: !hidePlayButton,
					},
				},
				Data: {
					headers,
				},
			},
		};
		if (hideControls) {
			settings.moduleSettings['Controls'] = this.getDisabledControls();
		} else if (liveManagerControls) {
			settings.moduleSettings['Controls'] = this.getLiveManagerControls();
		} else if (interactControls) {
			settings.moduleSettings['Controls'] = this.getInteractControls();
			settings['controllers'] = this.getLayersOfPlayer();
		}
		return settings;
	};

	render() {
		let updateMessage = '';
		const { actions } = this.props;

		return (
			<div className="LivePlayerWrapper">
				<div id={this.props.container} className={this.props.isLive ? 'player-live' : 'player-od'}></div>
				<div className="player-tmp-overlay">
					<h5 className="addAnimation">{updateMessage}</h5>
				</div>
				{actions}
			</div>
		);
	}
}

LivePlayer.propTypes = {
	width: PropTypes.string.isRequired,
	height: PropTypes.string.isRequired,
	isLive: PropTypes.bool.isRequired,
	entityId: PropTypes.string.isRequired,
	entityType: PropTypes.string.isRequired,
	container: PropTypes.string.isRequired,
	autoPlay: PropTypes.bool.isRequired,
	repeat: PropTypes.bool.isRequired,
	muted: PropTypes.bool.isRequired,
	hidePlayButton: PropTypes.bool.isRequired,
	configurationId: PropTypes.string.isRequired,
	configUrl: PropTypes.string.isRequired,
	widgetPrefix: PropTypes.string,
	data: PropTypes.string,
	play: PropTypes.bool,
	pause: PropTypes.bool,
	position: PropTypes.number,
	pauseOnSeek: PropTypes.bool,
	calledFromInteract: PropTypes.bool,
	isStreamPublished: PropTypes.bool,
};

LivePlayer.defaultProps = {
	width: '620px',
	height: '320px',
	entityType: 'medias', //medias or playlists
	defaultAccountId: '123462',
	autoPlay: false,
	repeat: false,
	hidePlayButton: false,
	configurationId: 'default',
	configUrl: null,
	data: null,
	muted: false,
	pauseOnSeek: false,
	calledFromInteract: false,
	needAuthorization: true,
	widgetPrefix: 'qbrick-ui',
};

const mapStateToProps = ({ session, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	refreshMediasIds: publish.refreshMediasIds,
});

const mapDispatchToProps = (dispatch) => ({
	setRefreshMediasIds: (ids) => dispatch(setRefreshMediasIds(ids)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LivePlayer);
