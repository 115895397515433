/* eslint-disable */
import React, { ChangeEvent, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import SettingsIcon from '@material-ui/icons/Settings';

import './TabContent.scss';
import { FLOWS, INVALID_PROFILES, UPLOAD_FAILED, mediaTypes, messageTypes } from '../../../constants/mediaConstants';
import Dropzone from 'react-dropzone';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert, showMessage } from '../../../actions/globalActions';
import { useTranslation } from 'react-i18next';

import { formatFileSize } from '../../../services/publishScreenHelperService';
import Tooltip from '../../Tooltip/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import { getPlayIcon } from '../../../services/mediaDisplayService';
import { checkPermissionGrantedFor } from '../../../services/componentReusableService';
import { generateId } from '../../../services/stringHelperService';
import {
	checkMediaUploadProcess,
	didFileUploadActionHappenedStatus,
	preventUserFromCloseWindow,
	replaceIsEncoding,
	setUploadingProcessActive,
	startMediaUploadProcess,
	uploadMediaBegins,
} from '../../../actions/publishActions';
import { TAB_ID } from '../InteractMediaLibraryModal';
import CatalogTree from '../../CatalogTree/CatalogTree';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface UploadFileProps {
	mediaType: string;
	encodingProfiles: Array<any>;
	qbrickEncoderFlows: Array<any>;
	selectedCatalog: string;
	isUploading: boolean;
	setSelectedCatalog: (_id: string) => void;
	setAllowToSubmit: (_e: boolean) => void;
	setCurrentTab: (_id: string) => void;
	setIsUploading: (_isUploading: boolean) => void;
}

const UploadFile = forwardRef((props: UploadFileProps, ref) => {
	const {
		mediaType,
		encodingProfiles,
		qbrickEncoderFlows,
		selectedCatalog,
		isUploading,
		setSelectedCatalog,
		setAllowToSubmit,
		setCurrentTab,
		setIsUploading,
	} = props;
	const dispatch = useDispatch<any>();
	const { t: translator } = useTranslation();
	const [selectedEncodingProfile, setSelectedEncodingProfile] = useState<string>('');
	const [selectedMediaItems, setSelectedMediaItems] = useState<any>([]);
	const [uploadedFiles, setUploadedFiles] = useState<any>([]);

	const userPermissions = useSelector((state: any) => state.session.userPermissions);
	const accountId = useSelector((state: any) => state.session.defaultAccountId);
	const emails = useSelector((state: any) => state.publish.emails);
	const uploadingMedia = useSelector((state: any) => state.publish.uploadingMedia);
	const isEncoding = useSelector((state: any) => state.publish.isEncoding);

	const refineEncodingProfiles = useMemo(() => {
		encodingProfiles.sort((a, b) => a.value.localeCompare(b.value));
		const validProfiles = encodingProfiles.filter(({ value }) => {
			const stringifiedValue = value.toString();

			return (
				!INVALID_PROFILES.includes(stringifiedValue) &&
				!['-smil', 'image', 'subtitle', 'move', 'live', 'snapshot', 'mutliaudio'].some((val) => {
					return stringifiedValue.toLowerCase().includes(val);
				})
			);
		});

		return validProfiles;
	}, [encodingProfiles]);

	const selectEncodingProfile = useCallback((event: ChangeEvent<{ name?: string; value: unknown }>) => {
		const {
			target: { value },
		} = event;
		setSelectedEncodingProfile(value as string);
	}, []);

	const addSelectedMediaItems = (accepctedFiles: object[]) => {
		if (selectedMediaItems.length === 0) {
			setSelectedMediaItems(accepctedFiles);
			return;
		}

		// filter out duplicated
		const newFilesSet = accepctedFiles.filter((newFile: any) => {
			const { newPath, newLastModified, newName, newSize, newType } = newFile;

			return selectedMediaItems.some(
				({ path, lastModified, name, size, type }: any) =>
					path === newPath &&
					lastModified === newLastModified &&
					name === newName &&
					size === newSize &&
					type === newType
			);
		});

		if (typeof newFilesSet[0] === 'undefined') {
			return false;
		}

		setSelectedMediaItems([...selectedMediaItems, ...newFilesSet]);
	};

	const removeTheSelectedMediaItem = (itemName: string) => {
		setSelectedMediaItems(selectedMediaItems.filter((file: any) => file.name !== itemName));
	};

	const getThePlayIcon = useCallback((mimeType: string) => {
		const splittedFileType = mimeType.split('/');
		return getPlayIcon(splittedFileType[0], false);
	}, []);

	const startUploadProcess = () => {
		return new Promise((resolve) => {
			const isAllowToUpload = checkPermissionGrantedFor(userPermissions, 'ingest');

			if (!isAllowToUpload) {
				dispatch(
					showMessage(
						'You do not have the permissions to perform this action, please contact your account admin for further details',
						messageTypes.error
					)
				);
				return;
			}

			setIsUploading(true);
			const uploadedFiles: any = [];
			let failedCounter = 0;
			let counter = 0;

			selectedMediaItems.forEach((file: any) => {
				const mediaId = generateId();
				const blob = file.slice(0, file.size, file.type, file.path);
				let blobFile = new File([blob], `${mediaId}.${file.name.split('.').pop()}`, { type: file.type });
				(blobFile as any).title = file.name;
				(blobFile as any).id = mediaId;
				(blobFile as any).filename = file.name;
				const flowId =
					qbrickEncoderFlows.find((f: any) => f.id === FLOWS.qbrickStandard)?.id || selectedEncodingProfile;

				dispatch(preventUserFromCloseWindow(true));
				dispatch(
					uploadMediaBegins(accountId, flowId, selectedCatalog || '', null, blobFile, emails, file.name)
				).then((data: any) => {
					dispatch(preventUserFromCloseWindow(false));
					counter++;
					if (data && data !== UPLOAD_FAILED) {
						uploadedFiles.push({ ...blobFile, type: 'uploadNew', file: blobFile });
					} else {
						failedCounter++;
					}

					if (counter === selectedMediaItems.length) {
						setUploadedFiles(uploadedFiles);
						resolve(uploadedFiles);
						if (failedCounter > 0) {
							dispatch(showAlert(''));
							dispatch(showAlert(`${UPLOAD_FAILED} (${failedCounter})`, messageTypes.error));
							if (failedCounter === selectedMediaItems.length) {
								dispatch(setUploadingProcessActive('uploadingAllFailed'));
							}
						}
					}
				});
			});
			dispatch(setUploadingProcessActive('uploading'));
			setAllowToSubmit(false);
		});
	};

	const handleAfterUploadFiles = () => {
		if (uploadedFiles.length === 0) {
			dispatch(setUploadingProcessActive(''));
			return;
		}

		const newUploadingMedia = [...(uploadingMedia ?? [])];
		const newEncoding = [...(isEncoding ?? [])];

		if (uploadedFiles && uploadedFiles.length > 0) {
			const newUploadedFiles: any = [];
			uploadedFiles.forEach((newUploadFile: any) => {
				newUploadingMedia.push({ ...newUploadFile, name: newUploadFile.name, filename: newUploadFile.name });
				newEncoding.push(newUploadFile.name);
				newUploadedFiles.push({
					...newUploadFile,
					file: newUploadFile,
					id: newUploadFile.id,
					type: 'uploadNew',
				});
			});
			dispatch(startMediaUploadProcess(newUploadingMedia, newUploadingMedia));
			dispatch(checkMediaUploadProcess(newUploadingMedia));
			dispatch(didFileUploadActionHappenedStatus(true));
			dispatch(replaceIsEncoding(newEncoding));

			if (uploadedFiles[0]?.type.includes('video')) {
				dispatch(showMessage(translator('LABEL_YOUR_MEDIA_BEING_ENCODED'), messageTypes.info));
			}
			setTimeout(() => {
				setIsUploading(false);
				setCurrentTab(TAB_ID.MEDIA_LIBRARY);
			}, 1000);
		}

		setSelectedMediaItems([]);
		setUploadedFiles([]);
	};

	useImperativeHandle(ref, () => {
		return {
			isMediasSelected: () => {
				return selectedMediaItems.length !== 0;
			},
			startUploadProcess,
		};
	});

	useEffect(() => {
		const defaultEcodingProfile =
			refineEncodingProfiles.filter((profile) =>
				profile.value.toString().toLowerCase().includes('fullhighdefinition')
			)?.[0] || refineEncodingProfiles[0];

		setSelectedEncodingProfile(defaultEcodingProfile?.value);
	}, [refineEncodingProfiles]);

	useEffect(() => {
		if (selectedMediaItems.length === 0) {
			setAllowToSubmit(false);
			return;
		}
		setAllowToSubmit(true);
	}, [selectedMediaItems]);

	useEffect(() => handleAfterUploadFiles(), [uploadedFiles]);

	return (
		<Box className="media-library-modal-wrapper">
			<Box className="structure-navigator">
				<Box className={`catalog-tree ${mediaType}`}>
					<CatalogTree
						showNoCatalogOption
						preselectedCatalog={selectedCatalog}
						handleCatalogsChange={setSelectedCatalog}
					/>
				</Box>
				{mediaType === mediaTypes.video &&
					!qbrickEncoderFlows.find((f: any) => f.id === FLOWS.qbrickStandard) && (
						<Box className="encoding-profiles">
							<FormControl className="form-control">
								<InputLabel id="encoding-select">
									<Box display="flex" flexDirection={'row'} className="title">
										<SettingsIcon />
										Encoding Profiles
									</Box>
								</InputLabel>

								<Select
									className="select"
									labelId="encoding-select"
									value={selectedEncodingProfile}
									onChange={selectEncodingProfile}
									IconComponent={ExpandMoreIcon}
									disableUnderline
								>
									{refineEncodingProfiles.map(({ id, value }) => {
										return (
											<MenuItem key={id} value={value}>
												{value}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Box>
					)}
			</Box>
			<Box className="content-wrapper">
				<Dropzone
					accept={
						mediaType === mediaTypes.video
							? '.mp4, .m4a, .m4v, .mp3, .mov, .wmv'
							: '.jpg, .jpeg, .gif, .png, .bmp'
					}
					multiple={false}
					onDrop={(acceptedFiles, fileRejections) => {
						if (fileRejections.length > 0) {
							dispatch(showMessage(translator('LABEL_NOT_SUPPORTED_FILES', messageTypes.error)));
							return;
						}

						addSelectedMediaItems(acceptedFiles);
					}}
				>
					{({ getRootProps, getInputProps }) => (
						<>
							<div className="container">
								<div {...getRootProps({ className: 'dropzone' })}>
									<input {...getInputProps()} />
									<div
										className={`mediaUploadContentArea ${
											selectedMediaItems.length >= 1
												? 'mediaContentBorderBottomOff'
												: 'mediaContentBorderBottomOn'
										}`}
									>
										<Button className="defaultActionBtn">{translator('LABEL_BROWSE')}</Button>
										<label style={{ marginBottom: '5px' }}>
											{translator('LABEL_BROWSE_OR_DRAG')}
										</label>
										<label>
											{translator(
												mediaType === mediaTypes.video
													? 'LABEL_UPLOAD_VIDEO_TYPES_ACCEPTED'
													: 'LABEL_UPLOAD_IMAGE_TYPES_ACCEPTED'
											)}
										</label>
									</div>
								</div>
							</div>
						</>
					)}
				</Dropzone>
				{selectedMediaItems.length >= 1 && (
					<div className="selectedItemsContentWrapper">
						{selectedMediaItems.map(({ name, size, type }: any) => (
							<div className="eachSelectedItemWrapper" key={name}>
								<button>
									<img className="playicon" src={getThePlayIcon(type)} />
								</button>
								<label className="fileNameUploaded">{name}</label>
								<label className="fileSizeUploaded">{formatFileSize(size, 0)}</label>
								{!isUploading && (
									<button className="headercloseBtn" onClick={() => removeTheSelectedMediaItem(name)}>
										<CloseIcon />
									</button>
								)}
							</div>
						))}
					</div>
				)}
			</Box>
		</Box>
	);
});

export default UploadFile;
