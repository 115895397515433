import React, { useMemo, useState } from 'react';

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import WidgetItem from './WidgetItem';
import './WidgetList.scss';
import { FlattenedItem } from './type';
import { flattenTree, getChildCount, getProjection, removeChildrenOf } from './utils';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	addWidgetsToUpdateWaitingList,
	updateEditingSceneAndSendToPlayer,
} from '../../../../../../../../actions/scenarioActions';
import { getTimelineTimeFromTimeInput } from '../../../../../../../../services/timeStampService';
import { showAlert } from '../../../../../../../../actions/globalActions';
import { messageTypes } from '../../../../../../../../constants/mediaConstants';
type WidgetListProps = {
	widgets: any[];
	currentLayout: any;
	indicator?: boolean;
	indentationWidth?: number;
	editingWidget?: {
		id: string;
	};
	collapsible: boolean;
	onWidgetClick: (_event: any, _widget: any) => {};
	onDuplicateWidget: () => {};
	onRemoveWidget: () => {};
};

const WidgetList = ({
	widgets = [],
	currentLayout,
	indicator = false,
	collapsible = true,
	indentationWidth = 30,
	editingWidget,
	onDuplicateWidget,
	onWidgetClick,
	onRemoveWidget,
}: WidgetListProps) => {
	const dispatch = useDispatch();

	const editingScene = useSelector((state: any) => state.scenarioReducer.editingScene);
	const scenario = useSelector((state: any) => state.scenarioReducer.scenario);
	const defaultAccountId = useSelector((state: any) => state.session.defaultAccountId);

	const [offsetLeft, setOffsetLeft] = useState(0);
	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const [activeWidgetData, setActiveWidgetData] = useState<any>(null);
	const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

	const [collapsedHash, setCollapse] = useState<{ [key: string]: boolean | undefined }>({});

	const flattenedItems = useMemo(() => {
		const flattenedTree = flattenTree(widgets);
		const collapsedItems = flattenedTree.reduce<string[]>(
			(acc, { children, id }) => (collapsedHash[String(id)] && children.length ? [...acc, String(id)] : acc),
			[]
		);

		return removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
	}, [widgets, collapsedHash]);

	const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);

	const projected =
		activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth) : null;

	const sensors = useSensors(useSensor(PointerSensor));

	const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

	const handleDragStart = ({ active: { id: activeId } }: any) => {
		setActiveId(activeId);
		setOverId(activeId);

		const activeItem = flattenedItems.find(({ id }) => id === activeId);

		if (activeItem) {
			setActiveWidgetData(activeItem as any);
		}
	};

	const handleDragMove = ({ delta }: any) => {
		setOffsetLeft(delta.x);
	};

	const handleDragOver = ({ over }: any) => {
		setOverId(over?.id ?? null);
	};

	const handleDragEnd = ({ active, over }: any) => {
		resetState();

		if (projected && over) {
			const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(widgets)));
			const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
			const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);

			if (overIndex !== -1 && activeIndex !== -1 && overIndex !== activeIndex) {
				const activeWidget = clonedItems[activeIndex] as any;
				const overWidget = clonedItems[overIndex] as any;

				if (
					activeWidget.type === 'video' ||
					overWidget.type === 'video' ||
					overWidget.groupZIndex === 'video'
				) {
					dispatch(showAlert('Background layer cannot be edited.', messageTypes.info));
					return false;
				}

				let zIndexOver = 0;

				if (!overWidget.style) {
					zIndexOver = Number(overWidget.groupZIndex);
				} else {
					const {
						style: { zIndex: zIndeFromOverWidget },
					} = overWidget;

					zIndexOver = zIndeFromOverWidget;
				}

				if (zIndexOver === activeWidget.style.zIndex && !overWidget.style) {
					const newOverIndex = clonedItems.find(({ index, depth }) => {
						return depth === overWidget.depth && index === overWidget.index - 1;
					});

					if (!newOverIndex) {
						return;
					}

					zIndexOver = Number(newOverIndex.groupZIndex);
				}

				const isOverlap = clonedItems.filter((item: any) => {
					if (!item.style) {
						return false;
					}

					if (item.type === 'video') {
						return false;
					}

					const {
						style: { zIndex: zIndexItem },
					} = item;

					if (item.id === activeWidget.id) {
						return false;
					}

					if (zIndexOver === zIndexItem) {
						return (
							getTimelineTimeFromTimeInput(activeWidget.start.replace('.', ':')) <=
								getTimelineTimeFromTimeInput(item.end.replace('.', ':')) &&
							getTimelineTimeFromTimeInput(activeWidget.end.replace('.', ':')) >=
								getTimelineTimeFromTimeInput(item.start.replace('.', ':'))
						);
					}
					return false;
				});

				if (isOverlap.length > 0) {
					dispatch(showAlert('Widget should not be overlapped', messageTypes.info));
					return;
				}

				const widgetTemplate = (editingScene.widgetTemplates ?? []).find(
					(w: any) => w.id === activeWidget.widgetTemplateId
				);
				const nonEditWidgetTemplates = (editingScene.widgetTemplates ?? []).filter(
					(w: any) => w.id !== activeWidget.widgetTemplateId
				);

				widgetTemplate.style.zIndex = zIndexOver;

				dispatch(
					updateEditingSceneAndSendToPlayer({
						...editingScene,
						widgetTemplates: [...nonEditWidgetTemplates, widgetTemplate],
					})
				);

				const currentBox = (currentLayout.boxes ?? []).find((box: any) => box.boxTemplateId === '1');
				const widget = (currentBox?.widgets ?? []).find((widget: any) => widget.id === activeWidget.id);

				const updateBody = {
					style: widgetTemplate.style,
				};

				const { id: scenarioId } = scenario;

				dispatch(
					addWidgetsToUpdateWaitingList([
						{
							defaultAccountId,
							scenarioId,
							sceneId: editingScene.id,
							layoutId: currentLayout?.id,
							boxId: currentBox.boxTemplateId,
							widgetId: widget.id,
							body: updateBody,
						},
					])
				);
			}
		}
	};

	const handleDragCancel = () => {
		resetState();
	};

	const resetState = () => {
		setOverId(null);
		setActiveId(null);
		setOffsetLeft(0);
	};

	const handleCollapse = (id: UniqueIdentifier) => {
		const collapseKey = String(id);
		if (collapsedHash[collapseKey]) {
			setCollapse({ ...collapsedHash, [collapseKey]: undefined });
			return;
		}
		setCollapse({ ...collapsedHash, [collapseKey]: true });
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragMove={handleDragMove}
			onDragOver={handleDragOver}
			onDragEnd={handleDragEnd}
			onDragCancel={handleDragCancel}
		>
			<SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
				{flattenedItems.map(({ id, children, depth, index, ...widgetData }) => {
					return (
						<WidgetItem
							key={id}
							id={id}
							index={Math.abs(index - (widgets.length - 1)) + 1}
							value={String(id)}
							depth={id === activeId && projected ? projected.depth : depth}
							indentationWidth={indentationWidth}
							indicator={indicator}
							collapsed={collapsedHash[String(id)]}
							onCollapse={collapsible && children.length ? () => handleCollapse(id) : undefined}
							widgetData={{ id: String(id), ...widgetData }}
							editingWidget={editingWidget}
							onDuplicateWidget={onDuplicateWidget}
							onWidgetClick={onWidgetClick}
							onRemoveWidget={onRemoveWidget}
						/>
					);
				})}
				{createPortal(
					<DragOverlay>
						{activeId && activeItem ? (
							<WidgetItem
								id={activeId}
								depth={activeItem.depth}
								clone
								childCount={getChildCount(widgets, activeId) + 1}
								value={activeId.toString()}
								indentationWidth={indentationWidth}
								editingWidget={editingWidget}
								widgetData={activeWidgetData}
								onDuplicateWidget={onDuplicateWidget}
								onWidgetClick={onWidgetClick}
								onRemoveWidget={onRemoveWidget}
							/>
						) : null}
					</DragOverlay>,
					document.body
				)}
			</SortableContext>
		</DndContext>
	);
};

export default WidgetList;
