export const localStorageKeys = {
	ACCESS_TOKEN: 'access_token',
	UPLOAD_ACCESS_TOKEN: 'upload_access_token',
	IMPERSONATED_ACCESS_TOKEN: 'QVP.impersonate_access_token',
	EXPIRES_IN: 'expires_in',
	SCOPE: 'scope',
	STATE: 'state',
	TOKEN_TYPE: 'token_type',
	USER_ID: 'userId',
	USER_NAME: 'username',
	NONCE: 'nonce',
	TOKEN_ID: 'id_token',
	DEFAULT_ACCOUNT_ID: 'defaultAccountId',
	DEFAULT_ACCOUNT_NAME: 'defaultAccountName',
	SESSION_STATE: 'session_state',
	ACCOUNTS: 'accounts',
	USER_PERMISSIONS: 'userPermissions',
	LAST_TOKEN_RECEIVED: 'lastTokenReceived',
	LIVE_MANAGER_CHAT: 'liveManagerChat',
};
