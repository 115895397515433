import {
	isDateBetweenTwoDates,
	getTimelineStartDate,
	getTimelineTimeFromSeconds,
	getSecondsFromTimelineTime,
	getCurrentDateTimeInISOFormat,
	returnAddedTime,
} from './timeStampService';
import {
	interactSettingsType,
	defaultImageUrl,
	interactTimelineIcons,
	interactTimelineItemTypes,
	interactMimeType,
	sequenceTypes,
	tracksSequencesTypes,
} from './../constants/interactConstant';
import { generateId } from './stringHelperService';
import { mediaTypes } from '../constants/mediaConstants';
import { generateUUID } from '../utils/commonUtil';
import _ from 'underscore';

export const getNewCopyOfItem = (item) => {
	let newItem = {
		...item,
	};
	newItem.id = generateId();
	newItem.settings = JSON.parse(JSON.stringify(item.settings));
	newItem.start = null;
	newItem.end = null;
	return newItem;
};

export const getCustomInteractTrack = (media) => {
	let tracks = media.tracks;
	for (let i = 0; i < tracks.length; i++) {
		if (
			tracks[i] &&
			tracks[i].sequences &&
			tracks[i].sequences.length > 0 &&
			tracks[i].sequences[0].type === 'custom' &&
			tracks[i].sequences[0].custom &&
			tracks[i].sequences[0].custom.mimeType === interactMimeType &&
			tracks[i].sequences[0].custom.interactiveWidget
		) {
			return tracks[i].id;
		}
	}
	return '';
};

export const checkIfItemIsOverlapping = (component, item, noMessage) => {
	const { interactItems, t, mediaId, showNotification } = component.props;
	let overlap = false;
	interactItems.map((eachItem) => {
		if (
			item.id !== eachItem.id &&
			item.itemType !== sequenceTypes.video &&
			eachItem.itemType !== sequenceTypes.video &&
			eachItem.id !== mediaId &&
			item.group === eachItem.group
		) {
			if (
				isDateBetweenTwoDates(item.start, eachItem.start, eachItem.end) ||
				isDateBetweenTwoDates(item.end, eachItem.start, eachItem.end) ||
				isDateBetweenTwoDates(eachItem.start, item.start, item.end) ||
				isDateBetweenTwoDates(eachItem.end, item.start, item.end)
			) {
				if (!noMessage) {
					showNotification(t('TIME_IS_OVERLAPPING_GROUP'));
				}
				overlap = true;
			}
		}
	});
	return overlap;
};

export const isTimeBeyondRange = (component, item, noMessage) => {
	const { t, mediaDetails, showNotification } = component.props;
	let positionToMove = getSecondsFromTimelineTime(item.end);

	//check for live interact
	if (mediaDetails.type === mediaTypes.livevideo) {
		return false;
	}
	if (
		!isDateBetweenTwoDates(item.start, getTimelineStartDate(), getTimelineTimeFromSeconds(mediaDetails.duration)) ||
		positionToMove > mediaDetails.duration
	) {
		if (!noMessage) {
			showNotification(t('TIME_IS_OUT_OF_RANGE'));
		}
		return true;
	}
	return false;
};

export const getIteracItemEndDate = (time, component) => {
	const { mediaDetails } = component.props;
	let inter = new Date(time);
	let secondsToIncrease = (10 / 100) * mediaDetails.duration;

	let itemEndDate = new Date(returnAddedTime(inter, secondsToIncrease, 'seconds'));
	let position = getSecondsFromTimelineTime(itemEndDate);
	if (position > mediaDetails.duration) {
		itemEndDate = getTimelineTimeFromSeconds(mediaDetails.duration);
	}
	return itemEndDate;
};

export const getGroupItemsSortedByStartTime = (interactItems, groupId) => {
	let sameGroupItem = [];
	sameGroupItem = _.filter(interactItems, function (eachItem) {
		return eachItem.group === groupId;
	});
	let key = 'start';
	let sortedArray = sameGroupItem.sort((a, b) => (a[key] >= b[key] ? 1 : -1));
	return sortedArray;
};

export const getItemObjectByDefinition = (itemObject) => {
	let objectToReturn = itemObject;
	let definitions = objectToReturn.definitions;

	Object.keys(definitions).map((key) => {
		let minCount = definitions[key].config.min;
		if (definitions[key].config.min > 0) {
			for (let i = 0; i < minCount; i++) {
				let uuid = itemObject.templateGroup;
				objectToReturn = addNewObjectInSettings(objectToReturn, key, uuid);
			}
		}
	});
	return objectToReturn;
};

export const addNewObjectInSettings = (objectToReturn, key, uuid) => {
	let obj = JSON.parse(JSON.stringify(objectToReturn.definitions[key].schema));
	let uuidKeyName = getKeyNameByType(obj, interactSettingsType.uuid);
	let countOfObject = 0;
	obj[uuidKeyName].default = uuid;
	if (objectToReturn.settings) {
		Object.keys(objectToReturn.settings).map((eachKey) => {
			if (objectToReturn.settings[eachKey] && objectToReturn.settings[eachKey].type === key) {
				countOfObject = countOfObject + 1;
			}
		});
	}
	obj.name = objectToReturn.definitions[key].schema.name + (countOfObject ? ' (' + countOfObject + ') ' : '');
	obj.type = key;
	objectToReturn.settings[uuid] = obj;
	return objectToReturn;
};

export const getKeyNameByType = (obj, type) => {
	let keyNameToReturn = '';
	Object.keys(obj).map((key) => {
		if (obj[key].type === type) {
			keyNameToReturn = key;
			return keyNameToReturn;
		}
	});
	return keyNameToReturn;
};

const formActionObjectForSVG = (defaultObj) => {
	let value = JSON.stringify(defaultObj);
	value = value.replace(/\"([^(\")"]+)\":/g, '$1:');
	return value.replace(/"/g, "'");
};

const getReqularExpression = (value) => {
	return new RegExp(value, 'g');
};

export const getModifiedSVGTemplate = (oldItem) => {
	if (!(oldItem && oldItem.settings && oldItem.settings.template)) {
		return '';
	}
	//let item = JSON.parse(JSON.stringify(oldItem))
	let item = {
		...oldItem,
	};
	let template = item.settings.template;
	let settings = item.settings;
	let templateArray = [];
	Object.keys(settings).forEach((eachKey) => {
		if (eachKey !== 'template') {
			let settingsChild = settings[eachKey];

			if (settingsChild && settingsChild.type) {
				let temporaryTemplate = getEachButtonTemplate(settingsChild, item);
				templateArray.push(temporaryTemplate);
			}
			Object.keys(settingsChild).forEach((key) => {
				if (key !== 'name' && key !== 'type') {
					let valueToReplace = getValueBasedOnType(settingsChild[key].type, settingsChild[key].default);
					template = template.replace(getReqularExpression(`{{${eachKey}.${key}}}`), valueToReplace);
				}
			});
		}
	});

	if (templateArray.length > 0) {
		let finaltemplatestring = '';
		for (let i = 0; i < templateArray.length; i++) {
			finaltemplatestring = finaltemplatestring + templateArray[i];
		}
		template = template.replace(getReqularExpression(`{{definitions.button}}`), finaltemplatestring);
	}

	let re = /\[\[[^\]]*\]\]/g;
	template = template.replace(re, function (val) {
		val = val.replace('[[', '');
		val = val.replace(']]', '');

		if (val !== ' / 2') {
			return eval(val);
		} else {
			return eval('0 / 2');
		}
	});
	return template;
};

const getValueBasedOnType = (type, value) => {
	let valueToReplace = value;
	if (type === interactSettingsType.boolean) {
		valueToReplace = "'" + valueToReplace + "'";
	} else if (type === interactSettingsType.actions) {
		valueToReplace = formActionObjectForSVG(valueToReplace);
	} else if (type === interactSettingsType.image) {
		valueToReplace = valueToReplace || defaultImageUrl;
	}
	return valueToReplace;
};

const getEachButtonTemplate = (eachItem, item) => {
	let key = eachItem.type;
	let template = item.definitions[key].schemaTemplate;
	Object.keys(eachItem).forEach((eachKey) => {
		if (eachKey !== 'name' && eachKey !== 'type') {
			let valueToReplace = getValueBasedOnType(eachItem[eachKey].type, eachItem[eachKey].default);
			template = template.replace(getReqularExpression(`{{${eachKey}}}`), valueToReplace);
		}
	});

	let object = item.settings;
	for (let property in object) {
		if (
			object.hasOwnProperty(property) &&
			object[property].hasOwnProperty &&
			object[property].hasOwnProperty('animateIn')
		) {
			template = template.replace(
				getReqularExpression(`{{${property}.animateIn}}`),
				object[property]['animateIn'].default
			);
			break;
		}
	}
	return template;
};

const getWidgetToSave = (item) => {
	let settings = item.settings;
	let objectToReturn = {
		content: item.content,
		template: {
			id: item.templateId,
			groupId: item.templateGroup,
		},
		settings: {},
		index: item.index,
	};

	Object.keys(settings).forEach((eachKey) => {
		if (eachKey !== 'template') {
			let settingsChild = settings[eachKey];
			objectToReturn.settings[eachKey] = {};
			Object.keys(settingsChild).forEach((key) => {
				if (key !== 'name' && key !== 'type') {
					objectToReturn.settings[eachKey][key] = {
						value: settings[eachKey][key].default,
					};
				} else {
					objectToReturn.settings[eachKey][key] = settings[eachKey][key];
				}
			});
		}
	});

	return objectToReturn;
};

export const getBodyOfAWidgetToSave = (item) => {
	return getWidgetToSave(item);
};

const formatDataForWidget = (item, custom) => {
	if (item && custom) {
		let settings = item.settings;
		let customSettings = custom.interactiveWidget.settings;
		item.index = custom.index; // index to (re)order the item when drag & drop
		if (item.definitions) {
			Object.keys(customSettings).forEach((eachKey) => {
				let customChild = customSettings[eachKey];
				if (customSettings[eachKey].type) {
					item = addNewObjectInSettings(item, customSettings[eachKey].type, eachKey);
				}
				Object.keys(customChild).forEach((key) => {
					if (key !== 'name' && key !== 'type') {
						if (key === 'initialPause') {
							//key = 'onEntry'
							settings[eachKey]['onEntry'].default = customSettings[eachKey];
						} else {
							if (settings[eachKey][key] !== undefined) {
								settings[eachKey][key].default = customSettings[eachKey][key].value;
							}
						}
					} else {
						settings[eachKey][key] = customSettings[eachKey][key];
					}
				});
			});
		}
		Object.keys(settings).forEach((eachKey) => {
			if (eachKey !== 'template') {
				let settingsChild = settings[eachKey];
				Object.keys(settingsChild).forEach((key) => {
					if (key !== 'name' && key !== 'type') {
						if (key === 'initialPause') {
							//key = 'onEntry'
							settings[eachKey]['onEntry'].default = customSettings[eachKey];
						} else {
							if (customSettings[eachKey][key] === undefined) {
								settings[eachKey][key].default = customSettings[eachKey][key];
							} else {
								settings[eachKey][key].default = customSettings[eachKey][key].value;
							}
						}
					} else {
						settings[eachKey][key] = customSettings[eachKey][key];
					}
				});
			}
		});
	}
	return item;
};

export const getInteractItemFromValue = (widget, custom) => {
	return formatDataForWidget(widget, custom);
};

export const isItemUpdated = (item, storedItem) => {
	let settings = item.settings;
	let customSettings;

	if (item.id !== storedItem.id) {
		return false;
	}
	if (
		getSecondsFromTimelineTime(item.start) !== storedItem.start ||
		getSecondsFromTimelineTime(item.end) !== storedItem.end
	) {
		return true;
	}

	if (storedItem !== undefined && storedItem.custom && storedItem.custom.interactiveWidget) {
		customSettings = storedItem.custom.interactiveWidget.settings;
	} else {
		if (storedItem.name !== item.content) {
			return true;
		}
		return false;
	}

	let settingsPropertyCount = Object.keys(settings).length - 1; //it includes the template property which is not included in customSettings
	let customSettingsPropertyCount = Object.keys(customSettings).length;

	if (item.content !== storedItem.custom.interactiveWidget.content) {
		return true;
	}

	if (item.id !== storedItem.id) {
		return false;
	}
	if (
		getSecondsFromTimelineTime(item.start) !== storedItem.start ||
		getSecondsFromTimelineTime(item.end) !== storedItem.end
	) {
		return true;
	}
	let isUpdated = false;

	if (settingsPropertyCount !== customSettingsPropertyCount) {
		isUpdated = true;
		return isUpdated;
	}

	for (let property in customSettings) {
		if (!settings[property]) {
			return isUpdated;
		}
	}

	Object.keys(settings).forEach((eachKey) => {
		if (eachKey !== 'template' && !isUpdated) {
			let settingsChild = settings[eachKey];
			Object.keys(settingsChild).forEach((key) => {
				if (customSettings[eachKey][key] === undefined) {
					isUpdated = true;
				} else if (key === 'action') {
					if (!_.isEqual(settings[eachKey][key].default, customSettings[eachKey][key].value)) {
						isUpdated = true;
					}
				} else if (key === 'name' || key === 'type') {
					if (settings[eachKey][key] !== customSettings[eachKey][key]) {
						isUpdated = true;
					}
				} else {
					if (
						customSettings[eachKey][key] &&
						customSettings[eachKey][key].value &&
						settings[eachKey][key].default === customSettings[eachKey][key].value
					) {
					} else {
						if (key === 'action') {
						} else {
							isUpdated = true;
						}
					}
				}
			});
		}
	});

	return isUpdated;
};

export const getBodyOfPreset = (item) => {
	let settings = JSON.parse(JSON.stringify(item.settings));
	let settingsArray = [];
	if (settings.template) {
		delete settings.template;
	}
	Object.keys(settings).forEach((eachKey) => {
		let settingsChild = settings[eachKey];
		let objectToReturn = {};
		objectToReturn = settings[eachKey];
		objectToReturn.id = eachKey;
		Object.keys(settingsChild).forEach((key) => {
			if (settingsChild[key].type === interactSettingsType.actions) {
				objectToReturn[key] = JSON.stringify(settings[eachKey][key].default);
			} else if (typeof settings[eachKey][key] === 'object') {
				objectToReturn[key] = settings[eachKey][key].default;
			} else {
				objectToReturn[key] = settings[eachKey][key];
			}
		});
		settingsArray.push(objectToReturn);
	});
	let tempItem = {
		id: generateUUID(),
		name: item.content,
		templateId: item.templateId,
		settings: JSON.parse(JSON.stringify(settingsArray)),
		created: getCurrentDateTimeInISOFormat(),
		updated: null,
		index: item.index,
	};
	return tempItem;
};

export const getInteractGroupItemFromPreset = (objectData, _item, startTime, endTime) => {
	let group = {
		id: objectData.templateGroup,
		className: objectData.templateGroup,
		content: `<img src=${interactTimelineIcons.buttonsIcon} ></img>`,
		order: 2,
		groupEditable: false,
	};
	let newObj = {};
	_item.settings.map((eachItem) => {
		let settingsChild = {};
		if (objectData.settings[eachItem.id]) {
			settingsChild = objectData.settings[eachItem.id];
			newObj[eachItem.id] = {
				...objectData.settings[eachItem.id],
			};
			if (typeof settingsChild == 'object') {
				Object.keys(settingsChild).map((key) => {
					newObj[eachItem.id][key].default = eachItem[key];
				});
			}
		} else if (objectData.definitions[eachItem.type]) {
			settingsChild = JSON.parse(JSON.stringify(objectData.definitions[eachItem.type].schema));
			if (typeof settingsChild == 'object') {
				//to loop schema object and create new keys
				let id = eachItem.id;
				newObj[id] = {
					...settingsChild,
					type: eachItem.type,
				};
				Object.keys(settingsChild).map((key) => {
					if (typeof newObj[id][key] == 'object') {
						if (key === 'gid') {
							newObj[id][key].default = id;
						} else if (newObj[id][key].type === interactSettingsType.actions) {
							newObj[id][key].default = JSON.parse(eachItem[key]);
						} else {
							newObj[id][key].default = eachItem[key];
						}
					} else {
						newObj[id][key] = eachItem[key];
					}
				});
			}
		}
	});
	let item = {
		definitions: JSON.parse(JSON.stringify(objectData.definitions)),
		settings: {
			...newObj,
			template: objectData.settings.template,
		},
		id: generateId(),
		className: interactTimelineItemTypes.interactItem,
		content: _item.name,
		editable: false,
		start: startTime,
		end: endTime,
		group: group.id,
		mimeType: interactMimeType,
		target: 'item',
		templateGroup: group.id,
		templateId: objectData.templateId,
		templateName: objectData.templateName,
		itemType: sequenceTypes.custom,
		index: _item.index,
	};
	let itemAndGroup = {
		group: group,
		item: item,
	};
	return itemAndGroup;
};

export const getBodyOfSequence = (item) => {
	let body = {
		id: item.id,
		type: item.itemType,
		start: item.start ? getSecondsFromTimelineTime(item.start) : null,
		end: item.end ? getSecondsFromTimelineTime(item.end) : null,
		name: item.content,
	};
	if (item.itemType == sequenceTypes.custom) {
		body['custom'] = {
			mimeType: interactMimeType,
			value: getModifiedSVGTemplate(item),
			interactiveWidget: getBodyOfAWidgetToSave(item),
			index: item.index,
		};
	}
	if (!isItemValueValid(body)) {
		return null;
	}
	return body;
};

export const isItemValueValid = (item) => {
	if (item.start < 0 || item.end < 0 || (item.start && item.end && item.start > item.end)) {
		return false;
	}
	return true;
};

export const getBodyOfTrack = (type) => {
	const trackName = !type ? 'custom' : type;
	return {
		id: generateId(),
		name: trackName,
	};
};

export const getTrackSequenceName = (type) => {
	switch (type) {
		case sequenceTypes.custom:
			return tracksSequencesTypes.customs;
		case sequenceTypes.chapter:
			return tracksSequencesTypes.chapters;
		case sequenceTypes.video:
			return tracksSequencesTypes.videos;
	}
};

export const getTrackOfInteractItem = (media, sequenceId) => {
	let tracks = media.tracks;
	if (!tracks) {
		return null;
	}
	let foundTrack = '';
	tracks.map((track) => {
		if (track && track.sequences && track.sequences.length > 0) {
			track.sequences.map((eachSequence) => {
				// if (eachSequence.type === 'custom' && eachSequence.custom && eachSequence.custom.mimeType === interactMimeType) {
				if (!foundTrack && eachSequence.id === sequenceId) {
					foundTrack = track;
				}
			});
		}
	});
	return foundTrack;
};

export const isTimelineItemInteract = (item) => {
	if (item && item.mimeType === interactMimeType) {
		return true;
	}
	return false;
};

export const getItemStyle = (item) => {
	let element = _.find(item.settings, (eachItem) => {
		return eachItem.hasOwnProperty('color') || (eachItem.color && eachItem.color.hasOwnProperty('default'));
	});
	if (element && element.color && element.color.default) {
		return { backgroundColor: element.color.default };
	} else if (element && element.color) {
		return { backgroundColor: element.color };
	}
	element = _.find(item.settings, (eachItem) => {
		return (
			eachItem.hasOwnProperty('imageUrl') || (eachItem.imageUrl && eachItem.imageUrl.hasOwnProperty('default'))
		);
	});
	if (element && element.imageUrl && element.imageUrl.default) {
		return { backgroundImage: 'url(' + element.imageUrl.default + ')' };
	} else if (element && element.imageUrl && typeof element.imageUrl == 'string') {
		return { backgroundImage: 'url(' + element.imageUrl + ')' };
	} else if (element && element.hasOwnProperty('imageUrl')) {
		return { backgroundImage: 'url(' + defaultImageUrl + ')' };
	}
	return { backgroundColor: 'transparent' };
};
