export const SET_VIEWER_ENGAGEMENT_LOADING = 'SET_VIEWER_ENGAGEMENT_LOADING';
export const SET_VIEWER_ENGAGEMENT_OPEN_INFORMATION_DIALOG = 'SET_VIEWER_ENGAGEMENT_OPEN_INFORMATION_DIALOG';

export const setViewEngagementLoading = (loading) => ({
	type: SET_VIEWER_ENGAGEMENT_LOADING,
	loading,
});

export const setOpenInformationDialog = (openInformationDialog) => ({
	type: SET_VIEWER_ENGAGEMENT_OPEN_INFORMATION_DIALOG,
	openInformationDialog,
});
