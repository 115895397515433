import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import _ from 'underscore';

import handIcon from '../../../assets/images/hand.svg';
import rewind from '../../../assets/images/rewind.svg';
import loop from '../../../assets/images/loop.svg';
import TimeInput from '../../../reusable/TimeInput/TimeInput';
import MediaDetailDialog from '../../../screens/PublishScreen/LiveManagerScreen/MediaDetailDialog';
import {
	getFormattedTimeWithMilliseconds,
	getTimelineStartDate,
	mapTimeDifferenceToDefaultTime,
	getTimeDifferenceInSeconds,
	getTimelineTimeFromTimeInput,
} from '../../../services/timeStampService';
import { createSnapshot } from '../../../actions/workflowActions';
import {
	removeTimelineGroup,
	updateTimelineGroup,
	updateTimelineItem,
	removeTimelineItem,
	setTimelineMarkerPosition,
	setLivePlayerPosition,
} from '../../../actions/liveManagerActions';
import { showNotification } from '../../../actions/notificationActions';
import {
	playSnapShotMedia,
	pauseSnapShotMedia,
	stopSnapShotMedia,
	setSelectedSnapShotStartTime,
	setSelectedSnapShotEndTime,
	resetSnapShotSettings,
} from '../../../actions/snapshotSettingsActions';
import { toggleMediaProperties } from '../../../actions/inspectScreenActions';
import { interactMimeType } from '../../../constants/interactConstant';
import { snapshotTypes } from '../../../constants/snapshotTypes';
import { v3WorkflowTypes, workflowTypes } from '../../../constants/workflowTypes';
import { MIN_SNAPSHOT_TIME } from '../../../constants/liveManagerConstants';
import { FLOWS, mediaPropertyNames } from '../../../constants/mediaConstants';
import { IconsList } from '../../../reusable/IconsStore/IconsList';

import LiveManagerSettingsList from './../LiveManagerSettingsList';
import './LiveManagerSnapshotSettings.scss';

const mediaDetails = {
	title: snapshotTypes.SNAPSHOT.TITLE,
	description: 'description of media',
	playicon: loop,
};

class LiveManagerSnapshotSettings extends PureComponent {
	constructor(props) {
		super(props);

		this.initialState = {
			isMediaSetting: false,
			isNameEdited: false,
			inputNameValue: mediaDetails.title,
			snapshotPlayerStarted: false,
			errorMessage: '',
			startTimeValue: '',
			endTimeValue: '',
			startError: '',
			endError: '',
		};
		this.state = { ...this.initialState };
	}

	componentDidMount = () => {
		this.updateSnapShotSettings(this.props.selectedItem);
	};

	componentDidUpdate = (prevProps) => {
		this.setMediaSettings();
		if (
			this.props.updatedMedia &&
			JSON.stringify(this.props.updatedMedia) !== JSON.stringify(prevProps.updatedMedia)
		) {
			this.updatedMediaDetails(this.props.updatedMedia);
		}
	};

	componentWillUnmount() {
		this.resetState();
		this.stopSnapShotLoop();
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.updateSnapShotSettings(
			nextProps.selectedItem,
			nextProps.selectedItem !== this.props.selectedItem ? undefined : nextProps.timelineItems
		);

		if (
			nextProps.timelineMarkerPosition &&
			nextProps.timelineMarkerPosition !== this.props.timelineMarkerPosition &&
			this.props.selectedSnapShotEndTime != getTimelineStartDate()
		) {
			this.loopPlayerIfSnapShotIsPlayed(nextProps.timelineMarkerPosition);
		}
	}

	updatedMediaDetails = (updatedMedia) => {
		const { selectedItem } = this.props;
		if (selectedItem === updatedMedia.id) {
			if (updatedMedia.mediaPropertyKey === mediaPropertyNames.TITLE) {
				this.updateTitleOfMedia(updatedMedia.mediaPropertyValue);
			}
		}
	};

	togglePlayer = () => {
		const { snapshotPlayStarted, snapshotPlayPaused } = this.props;
		if (snapshotPlayStarted && !snapshotPlayPaused) {
			this.props.pauseSnapShotMedia();
		} else if (snapshotPlayStarted && snapshotPlayPaused) {
			this.props.playSnapShotMedia();
		} else {
			this.props.playSnapShotMedia();
			this.loopPlayerToSnapshotTime();
		}
	};

	pauseLivePlayer = () => {
		this.props.pauseSnapShotMedia();
	};

	loopPlayerIfSnapShotIsPlayed = (timelineMarkerPosition) => {
		const { selectedSnapShotEndTime } = this.props;
		const { snapshotPlayStarted } = this.props;
		if (snapshotPlayStarted && timelineMarkerPosition && selectedSnapShotEndTime) {
			if (timelineMarkerPosition > selectedSnapShotEndTime) {
				// || timelineMarkerPosition < selectedSnapShotStartTime) { //to be added after testing
				this.loopPlayerToSnapshotTime();
			}
		}
	};

	loopPlayerToSnapshotTime = () => {
		const { selectedSnapShotStartTime } = this.props;
		this.props.setLivePlayerPosition(selectedSnapShotStartTime, true); //setting startPlaying as true
	};

	getEndDateWithDuration = (_) => {
		//changing the date to current date as discussed
		let d = getTimelineStartDate();
		d.setSeconds(this.props.duration);
	};

	updateSnapShotSettings = (id, timelineItems) => {
		let item = '';
		item = _.find(timelineItems ? timelineItems : this.props.timelineItems, (item) => {
			return item.id === id;
		});
		if (item) {
			if (item.start) {
				this.updateStartTimeState(item.start);
			}
			if (item.end) {
				this.updateEndTimeState(item.end);
			}
			this.updateTitleOfMedia(item.content);
			this.stopSnapShotLoop();
			this.setState({
				startTimeValue: item.start,
				endTimeValue: item.end,
				errorMessage: '',
				startError: '',
				endError: '',
			});
		}
		if (!item) {
			this.stopSnapShotLoop();
			this.handleClose();
		}
	};

	updateTitleOfMedia = (title) => {
		this.setState({ inputNameValue: title });
	};

	updateStartTimeState = (value) => {
		this.props.setSelectedSnapShotStartTime(value);
	};

	updateEndTimeState = (value) => {
		this.props.setSelectedSnapShotEndTime(value);
	};

	handleConvertToMediaClick = () => {
		const { timelineItems, selectedItem, timelineGroups, selectedGroup, mediaId, defaultAccountId } = this.props;
		// const { startTimeValue, endTimeValue } = this.state
		let startTimeValue = getFormattedTimeWithMilliseconds(this.props.selectedSnapShotStartTime, 'HH:mm:ss.SSS');
		let endTimeValue = getFormattedTimeWithMilliseconds(this.props.selectedSnapShotEndTime, 'HH:mm:ss.SSS');

		let item = _.find(timelineItems, (item) => {
			return item.id === selectedItem && item.className !== snapshotTypes.LIVE.CSS;
		});
		if (item) {
			item.className = snapshotTypes.MEDIA.CSS;
			item.content = item.content || snapshotTypes.MEDIA.TITLE;
			item.editable = false;
			this.props.updateTimelineItem(item);

			let group = _.find(timelineGroups, (group) => {
				return group.id === selectedGroup;
			});
			if (group) {
				group.content = `<img src=${snapshotTypes.MEDIA.ICON} ></img>`;
				this.props.updateTimelineGroup(group);
			}
			this.stopSnapShotLoop();
			this._createSnapShot(defaultAccountId, mediaId, item, startTimeValue, endTimeValue, false);
		}
	};

	_createSnapShot = (accountId, mediaId, item, snapshotStartTime, snapshotEndTime, extractVod) => {
		const { t, qbrickStandardFlows } = this.props;
		const qbrickStandardFlow = qbrickStandardFlows.find((f) => f.id === FLOWS.qbrickStandard);
		const flowId = qbrickStandardFlow?.id ? { flowId: FLOWS.qbrickStandard } : {};
		let body = {
			workflow: {
				id: qbrickStandardFlow?.id ? v3WorkflowTypes.CREATE_SNAPSHOT.ID : workflowTypes.CREATE_SNAPSHOT.ID,
			},
			input: {
				...flowId,
				mediaId: mediaId,
				snapshotMediaId: item.id,
				snapshotStart: snapshotStartTime,
				snapshotEnd: snapshotEndTime,
				snapshotTitle: item.content,
			},
		};

		this.props.createSnapshot(accountId, extractVod, body).then((data) => {
			if ((data && data.Message) || (data && data.message)) {
				this.props.showNotification(t('FAILED_TO_CREATE_SNAPSHOT'));
			} else {
				this.props.showNotification(t('SNAPSHOT_CREATED'));
				this.props.updateTimelineItem(item);
			}
		});
	};

	handleMediaPropertiesClick = () => {
		// this.props.toggleMediaProperties(true);
		let mediaIdToOpen = this.getSelectedMediaId();
		let currentLocation = window.location.href;
		let intermediatePath = currentLocation.split('/medias')[0];
		let mediaPath = intermediatePath + '/medias/' + mediaIdToOpen + '/' + 'detail';
		window.open(mediaPath, '_blank');
	};

	handleDeleteClick = () => {
		let item = _.find(this.props.timelineItems, (item) => {
			return item.id === this.props.selectedItem;
		});
		this.props.removeTimelineItem(item);

		let group = _.find(this.props.timelineGroups, (group) => {
			return group.id === this.props.selectedGroup;
		});
		this.props.removeTimelineGroup(group);
		this.stopSnapShotLoop();
		this.handleClose();
	};

	handleClose = () => {
		this.stopSnapShotLoop();
	};

	stopSnapShotLoop = () => {
		this.props.stopSnapShotMedia();
	};

	resetState = () => {
		this.props.resetSnapShotSettings();
	};

	getTime = (input) => {
		let timeArray = input.split(':');

		return {
			hours: timeArray[0],
			minutes: timeArray[1],
			seconds: timeArray.length > 2 ? timeArray[2] : '00',
			milliseconds: timeArray.length > 3 ? timeArray[3] : '000',
		};
	};

	isInvalidSnapshot = (startTime, endTime) => {
		const { liveStreamPositionTime, t } = this.props;

		let liveEventEndTime = this.getLiveEventEndTimeAsTimeline();
		if ((liveEventEndTime && startTime > liveEventEndTime) || startTime > liveStreamPositionTime) {
			this.setState({
				errorMessage: t('TIME_GREATER_THAN_LIVE'),
				startError: true,
			});
		}
		if ((liveEventEndTime && endTime > liveEventEndTime) || endTime > liveStreamPositionTime) {
			this.setState({
				errorMessage: t('TIME_GREATER_THAN_LIVE'),
				endError: true,
			});
			return true;
		}
		if (startTime > endTime) {
			this.setState({
				errorMessage: t('START_TIME_GREATER_END_TIME'),
				startError: true,
				endError: true,
			});
			return true;
		}
		if (getTimeDifferenceInSeconds(endTime, startTime) < MIN_SNAPSHOT_TIME) {
			this.setState({
				errorMessage: t('MSG_MINIMUM_SNAPSHOT_TIME'),
				startError: true,
				endTime: true,
			});
			return true;
		}

		this.setState({
			errorMessage: '',
			startError: '',
			endError: '',
		});
		return false;
	};

	handleStartTimeOnchange = (e) => {
		if (this.state.isMediaSetting) {
			return;
		}
		let item = this.props.timelineItems.find((item) => {
			return item.id === this.props.selectedItem;
		});
		if (item) {
			let startTime = getTimelineTimeFromTimeInput(e);
			this.setState({
				startTimeValue: startTime,
			});
			if (!this.isInvalidSnapshot(startTime, item.end)) {
				item.start = startTime;
				this.props.updateTimelineItem(item);
				this.updateStartTimeState(item.start);
			}
		}
	};

	getLiveEventEndTimeAsTimeline = () => {
		const { liveEventStartTime, liveEventStopTime } = this.props;
		if (liveEventStartTime && liveEventStopTime) {
			let startTime = liveEventStartTime;
			let endTime = liveEventStopTime;
			endTime = mapTimeDifferenceToDefaultTime(endTime, startTime);
			return endTime;
		}
		return null;
	};

	handleEndTimeOnchange = (e) => {
		if (this.state.isMediaSetting) {
			return;
		}
		let item = this.props.timelineItems.find((item) => {
			return item.id === this.props.selectedItem;
		});
		if (item) {
			let endTime = getTimelineTimeFromTimeInput(e);
			this.setState({
				endTimeValue: endTime,
			});
			if (!this.isInvalidSnapshot(item.start, endTime)) {
				item.end = endTime;
				this.props.updateTimelineItem(item);
				this.updateEndTimeState(item.end);
			}
		}
	};

	setMediaSettings = () => {
		let item = _.find(this.props.timelineItems, (item) => {
			return item.id === this.props.selectedItem;
		});
		if (item && item.className === snapshotTypes.MEDIA.CSS) {
			this.setState({ isMediaSetting: true });
		} else {
			this.setState({ isMediaSetting: false });
		}
	};

	onEditName = (_) => {
		const { inputNameValue } = this.state;
		this.nameInput.value = inputNameValue;
	};

	onNameInputValueChange = (e) => {
		const { selectedItem } = this.props;
		let title = e.target.value;
		this.setState({ inputNameValue: title });
		let item = _.find(this.props.timelineItems, (item) => {
			return item.id === selectedItem;
		});
		if (item) {
			item.content = title;
			this.props.updateTimelineItem(item);
			// this.props.updateSettingsTitle(title)
		}
	};

	getNameSection = (_) => {
		const { inputNameValue, isMediaSetting } = this.state;
		return (
			<>
				<div className="editNameSection subFieldDesc">
					<div className="editName">
						{/* <label className={!isNameEdited ? 'showNameInputBox' : 'hideNameInputBox'} > {(inputNameValue != null) ? inputNameValue : mediaDetails.title}</label>*/}
						<input
							type="text"
							placeholder="Enter title"
							value={inputNameValue}
							ref={(nameInput) => {
								this.nameInput = nameInput;
							}}
							className="showNameInputBox"
							disabled={isMediaSetting}
							onChange={(e) => this.onNameInputValueChange(e)}
						/>
					</div>
				</div>
			</>
		);
	};

	renderDurationSection = () => {
		const { isMediaSetting, errorMessage, startTimeValue, endTimeValue, startError, endError } = this.state;
		return (
			<>
				<div
					className={'generalSubField' + (isMediaSetting ? ' disabled' : '')}
					attr={startError ? 'true' : null}
				>
					<label className="subFieldTitle">Start time</label>
					<TimeInput
						className="subFieldDesc"
						max={24}
						value={getFormattedTimeWithMilliseconds(startTimeValue, 'HH:mm:ss:SSS')}
						onChange={this.handleStartTimeOnchange}
					/>
				</div>
				<div
					className={'generalSubField' + (isMediaSetting ? ' disabled' : '')}
					attr={endError ? 'true' : null}
				>
					<label className="subFieldTitle">End time</label>
					<TimeInput
						className="subFieldDesc"
						max={24}
						value={getFormattedTimeWithMilliseconds(endTimeValue, 'HH:mm:ss:SSS')}
						onChange={this.handleEndTimeOnchange}
					/>
				</div>
				<div className="timeInputErrorMsg">
					<label> {errorMessage} </label>
				</div>
			</>
		);
	};

	getSelectedMediaId = () => {
		let item = _.find(this.props.timelineItems, (item) => {
			return item.id === this.props.selectedItem;
		});
		let mediaId = item && item.id;
		return mediaId;
	};

	getDeleteIcon = () => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.73 8.52">
				<title>Delete</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<g id="H7CZZr.tif">
							<path d="M5.71,8.52H1l-.37-6L0,2.51V1.79a1.81,1.81,0,0,1,.23,0H6.5a1.6,1.6,0,0,1,.23,0v.72l-.65,0ZM3,2.55l.11,5.18H3.6l.11-5.18Zm-1.59,0,.32,5.17h.47l-.1-5.17ZM4.51,7.73H5L5.3,2.56H4.62Z" />
							<path d="M4,0V.57H6.13v.76H.61V.57H2.74l0-.57Z" />
						</g>
					</g>
				</g>
			</svg>
		);
	};

	getInspectIcon = () => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.93 8.87">
				<title>Inspect</title>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path d="M3.65,1.5A2.15,2.15,0,1,1,1.5,3.65,2.15,2.15,0,0,1,3.65,1.5m0-1.5A3.65,3.65,0,1,0,7.3,3.65,3.66,3.66,0,0,0,3.65,0Z" />
						<path d="M8.18,8.87a.77.77,0,0,1-.53-.22L5.42,6.42a.75.75,0,0,1,0-1.06.77.77,0,0,1,1.07,0L8.71,7.59a.75.75,0,0,1,0,1.06A.79.79,0,0,1,8.18,8.87Z" />
					</g>
				</g>
			</svg>
		);
	};

	renderStreamSettings = () => {
		return <></>;
	};

	renderInteractSettings = () => {
		const { t } = this.props;
		return (
			<>
				<div className="interactSettings">
					<LiveManagerSettingsList title={t('GENERAL_SETTINGS')} iconName={IconsList.SETTINGS}>
						<div className="generalSubField">
							<label className="subFieldTitle"> Name</label>
							{this.getNameSection()}
						</div>
						{this.renderDurationSection()}
					</LiveManagerSettingsList>
				</div>
			</>
		);
	};

	render() {
		const { snapshotPlayStarted, selectedItem, t } = this.props;
		const { errorMessage } = this.state;
		let item = this.props.timelineItems.find((item) => {
			return item.id === this.props.selectedItem;
		});
		return (
			<>
				{selectedItem ? (
					<div className="LiveManagerSnapshotSettingsWrapper">
						{item && item.mimeType && item.mimeType == interactMimeType ? (
							<>{this.renderInteractSettings(item)}</>
						) : (
							<>
								<LiveManagerSettingsList title={t('GENERAL_SETTINGS')} iconName={IconsList.SETTINGS}>
									<div className="liveGeneralSettings">
										<div className="generalSubField">
											<label className="subFieldTitle"> {t('REVIEW')} </label>
											<div className="snapShotMediaControls subFieldDesc">
												<Button
													variant="contained"
													className="defaultActionBtn rewind"
													onClick={this.loopPlayerToSnapshotTime}
													disabled={!snapshotPlayStarted}
												>
													<img src={rewind} />
													{t('REWIND')}
												</Button>
												<Button
													variant="contained"
													className="defaultActionBtn loopback"
													onClick={this.togglePlayer}
													disabled={snapshotPlayStarted}
												>
													<img src={loop} /> {t('LOOP')}
												</Button>
											</div>
										</div>
										<div className="generalSubField">
											<label className="subFieldTitle"> {t('NAME')}</label>
											{this.getNameSection()}
										</div>

										{this.renderDurationSection()}
									</div>{' '}
								</LiveManagerSettingsList>

								<div
									className={
										this.state.isMediaSetting ? 'actionButtons mediaButtons' : 'actionButtons'
									}
								>
									<Button
										variant="contained"
										disabled={errorMessage}
										className="defaultActionBtn ConvertToMediaButton"
										onClick={this.handleConvertToMediaClick}
										style={!this.state.isMediaSetting ? {} : { display: 'none' }}
									>
										<img src={handIcon} />
										Convert to media
									</Button>

									<Button
										variant="contained"
										className="defaultActionBtn ConvertToMediaButton inspectMediaButton"
										onClick={this.handleMediaPropertiesClick}
										style={this.state.isMediaSetting ? {} : { display: 'none' }}
									>
										{this.getInspectIcon()}
										Inspect Media
									</Button>
									<br />
									<Button
										variant="contained"
										className="defaultActionBtn deleteButtonWithIcon DeleteButton"
										onClick={this.handleDeleteClick}
										style={!this.state.isMediaSetting ? {} : { display: 'none' }}
									>
										{this.getDeleteIcon()}
										{'Delete'}
									</Button>
								</div>

								{this.props.mediaPropertiesToggled && (
									<>
										<MediaDetailDialog mediaId={this.getSelectedMediaId()}></MediaDetailDialog>
									</>
								)}
							</>
						)}
					</div>
				) : null}{' '}
			</>
		);
	}
}

LiveManagerSnapshotSettings.defaultProps = {};

const mapStateToProps = ({ session, snapshotSettings, liveManager, inspectScreenReducer, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	snapshotPlayStarted: snapshotSettings.snapshotPlayStarted,
	snapshotPlayPaused: snapshotSettings.snapshotPlayPaused,
	selectedSnapShotStartTime: snapshotSettings.selectedSnapShotStartTime,
	selectedSnapShotEndTime: snapshotSettings.selectedSnapShotEndTime,
	timelineItems: liveManager.items,
	timelineGroups: liveManager.groups,
	selectedItem: liveManager.selectedItem,
	selectedGroup: liveManager.selectedGroup,
	mediaPropertiesToggled: inspectScreenReducer.mediaPropertiesToggled,
	defaultDate: liveManager.defaultDate,
	duration: liveManager.duration,
	lastSetPlayerPositionTime: liveManager.lastSetPlayerPositionTime,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	timelineMarkerPosition: liveManager.timelineMarkerPosition,
	liveEventStatus: liveManager.liveEventStatus,
	liveEventStartTime: liveManager.liveEventStartTime,
	liveEventStopTime: liveManager.liveEventStopTime,
	updatedMedia: inspectScreenReducer.updatedMedia,
	liveSettingsId: liveManager.liveSettingsId,
	qbrickStandardFlows: publish.qbrickStandardFlows,
});

const mapDispatchToProps = (dispatch) => ({
	updateTimelineItem: (item) => dispatch(updateTimelineItem(item)),
	removeTimelineItem: (item) => dispatch(removeTimelineItem(item)),
	removeTimelineGroup: (group) => dispatch(removeTimelineGroup(group)),
	updateTimelineGroup: (group) => dispatch(updateTimelineGroup(group)),
	toggleMediaProperties: (toggle) => dispatch(toggleMediaProperties(toggle)),
	showNotification: (message) => dispatch(showNotification(message)),
	createSnapshot: (accountId, extractVod, body) => dispatch(createSnapshot(accountId, extractVod, body)),
	setTimelineMarkerPosition: (datetime) => dispatch(setTimelineMarkerPosition(datetime)),
	setLivePlayerPosition: (datetime, startPlaying) => dispatch(setLivePlayerPosition(datetime, startPlaying)),
	playSnapShotMedia: () => dispatch(playSnapShotMedia()),
	pauseSnapShotMedia: () => dispatch(pauseSnapShotMedia()),
	stopSnapShotMedia: () => dispatch(stopSnapShotMedia()),
	setSelectedSnapShotStartTime: (datetime) => dispatch(setSelectedSnapShotStartTime(datetime)),
	setSelectedSnapShotEndTime: (datetime) => dispatch(setSelectedSnapShotEndTime(datetime)),
	resetSnapShotSettings: () => dispatch(resetSnapShotSettings()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveManagerSnapshotSettings));
