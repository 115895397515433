import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEN from './assets/locale/en/translation.json';
import translationSE from './assets/locale/se/translation.json';
import translationFR from './assets/locale/fr/translation.json';
import translationDE from './assets/locale/de/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	se: {
		translation: translationSE,
	},
	fr: {
		translation: translationFR,
	},
	de: {
		translation: translationDE,
	},
};

i18n.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'en',
		fallbackLng: 'en',
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
