import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Draggable from 'react-draggable';

import LivePlayer from '../../LivePlayer/LivePlayer';
import time from '../../../assets/images/time.svg';
import { liveEventStatusList } from '../../../constants/liveEventStatus';

import './SmallLivePlayer.scss';

class SmallLivePlayer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { position: { x: 0, y: 0 } };
	}

	componentDidMount() {
		var wrapper = document.getElementById('screenParentContainer');
		if (wrapper) {
			let wrapperObj = wrapper.getBoundingClientRect();
			this.setState({
				position: {
					x: wrapperObj.width - 256,
					y: wrapperObj.height - 144 - 50, //28 for top header height
				},
			});
		}
	}

	componentWillUnmount() {
		this.setState(() => {});
	}

	onStop = (event, data) => {
		var wrapper = document.getElementById('screenParentContainer');
		// Viewport (wrapper)
		const wrapperHeight = wrapper.offsetHeight;
		const wrapperWidth = wrapper.offsetWidth;

		let position = {
			x: 256,
			y: 144,
		};
		if (data.x < wrapperWidth / 2 && data.y < wrapperHeight / 2) {
			position = {
				x: 0,
				y: 0 - 20, // not sure of 23
			};
		}

		if (data.x >= wrapperWidth / 2 && data.y >= wrapperHeight / 2) {
			position = {
				x: wrapperWidth - 256,
				y: wrapperHeight - 144 - 50,
			};
		}

		if (data.x >= wrapperWidth / 2 && data.y < wrapperHeight / 2) {
			position = {
				x: wrapperWidth - 256,
				y: 0 - 20,
			};
		}
		if (data.x < wrapperWidth / 2 && data.y >= wrapperHeight / 2) {
			position = {
				x: 0,
				y: wrapperHeight - 144 - 50,
			};
		}
		this.setState({ position: position });
	};

	render() {
		const { isPlayerAtLivePosition, defaultAccountId, isVisible } = this.props;
		return (
			<Draggable position={this.state.position} onStop={(event, data) => this.onStop(event, data)}>
				<div
					id="smallLivePlayer"
					className={
						!isPlayerAtLivePosition && this.props.liveEventStatus === liveEventStatusList.STARTED
							? 'show'
							: 'hide'
					}
				>
					<section className="smallPlayerLiveText">
						<label> LIVE </label>
						<img src={time} />
					</section>

					<LivePlayer
						configUrl={
							'https://video.qbrick.com/play2/api/v1/accounts/Accpy7KNHj1IUilFL5BMuNL0Q/configurations/live-manager'
						}
						isLive={true}
						entityId={this.props.mediaId}
						defaultAccountId={defaultAccountId}
						entityType="medias"
						widgetPrefix="actualLive"
						container="actualLiveContainer"
						width="256"
						height="144"
						autoPlay={true}
						hidePlayButton={false}
						data={null}
						play={isVisible}
						hideControls={true}
						muted={true}
					/>
				</div>
			</Draggable>
		);
	}
}

const mapStateToProps = ({ session, liveManager }) => ({
	defaultAccountId: session.defaultAccountId,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
	liveEventStatus: liveManager.liveEventStatus,
});

const mapDispatchToProps = () => ({
	// resetSnapShotSettings: () => dispatch(resetSnapShotSettings())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SmallLivePlayer);
