import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import { getDetails } from '../../services/mediaDisplayService';
import { getCatalogAndTotalCount } from '../../services/mediaService';
import { ACCESS_LEVEL } from '../CatalogTree/CatalogLimitedAccess';

export const getCatalogs = (
	catalogs: object[] = [],
	accountId: string,
	query: string,
	offset: number,
	limited: number
): Promise<object[]> => {
	return getCatalogAndTotalCount(accountId, query, offset, limited).then((response) => {
		const { data, count } = response as any;
		catalogs = [...catalogs, ...data];

		if (catalogs.length === Number(count)) {
			return refineCatalogs(catalogs);
		}

		return getCatalogs(catalogs, accountId, query, offset + limited, limited);
	});
};

const refineCatalogs = (catalogs: any) => {
	const catalogsDetails = catalogs.map((catalog: any) => getDetails(catalog));
	return convertToTreeStructure(catalogsDetails);
};

const convertToTreeStructure = (catalogs: any) => {
	let tree = [];
	let mappedArr: { [key: string]: any } = {};
	let arrElem;
	let mappedElem;

	// First map the nodes of the array to an object -> create a hash table.
	for (let i = 0; i < catalogs.length; i++) {
		arrElem = catalogs[i];
		mappedArr = {
			...mappedArr,
			[arrElem.id]: {
				...arrElem,
				children: [],
			},
		};
	}

	for (let id in mappedArr) {
		if (mappedArr.hasOwnProperty(id)) {
			mappedElem = mappedArr[id];
			// If the element is not at the root level, add it to its parent array of children.
			if (mappedElem && mappedElem.parentId && mappedArr[mappedElem.parentId]) {
				mappedArr[mappedElem.parentId]['children'].push(mappedElem);
			} else {
				// If the element is at the root level, add it to first level elements array.
				tree.push(mappedElem);
			}
		}
	}
	return tree;
};

export const notAllowToShowEditCatalogAccess = (media: any, userId: string, userPermission: any) => {
	const { access = {} } = media;
	const isCatalogAdmin = access[userId] === 'admin';
	const isUserAccountAdmin = checkPermissionGrantedFor(userPermission, 'accountAdmin');

	const result = isUserAccountAdmin ? false : !isCatalogAdmin && Object.keys(access).length !== 0;

	return result;
};

export const findParentCatalog = (catalogs: any, currentCatalogId: any): any[] => {
	if (!currentCatalogId) {
		return [];
	}

	const currentCatalog = catalogs.find(({ id }: any) => {
		return id === currentCatalogId;
	});

	return [currentCatalogId, ...findParentCatalog(catalogs, currentCatalog.parentId)];
};

// No longer need to remove access of sub-catalog. But still keep this ultity for future using
export const findChildCatalog = (catalogs: any, currentCatalogId: any): any[] => {
	const currentCatalog = catalogs.find(({ id }: any) => {
		return id === currentCatalogId;
	});

	const childList = currentCatalog.children;

	if (childList && childList.length !== 0) {
		const idListToUpdate = childList.map(({ id }: any) => {
			return id;
		});

		return [
			currentCatalogId,
			...idListToUpdate.map((id: any) => {
				return findChildCatalog(catalogs, id);
			}),
		];
	}

	return [currentCatalogId];
};

export const standarlizeRestrictionUser = (access: any) => {
	return Object.keys(access).reduce((alls, curr) => {
		//backend saving FULL = admin, LIMITED = user
		if (access[curr] === 'admin') {
			return { ...alls, [curr]: { id: curr, accessLevel: ACCESS_LEVEL.FULL.type } };
		}
		if (access[curr] === 'user') {
			return { ...alls, [curr]: { id: curr, accessLevel: ACCESS_LEVEL.LIMITED.type } };
		}

		return alls;
	}, {});
};

export const getUsersCatalogAccessLimitHasBeenChanged = (oldUsers: any, newUsers: any) => {
	const modifiedOldUsers = Object.keys(oldUsers).reduce(
		(all: any, userId: any) => {
			const userInUpdatedAccess = newUsers[userId];

			if (!userInUpdatedAccess) {
				return { ...all, removedUsers: { ...all.removedUsers, [userId]: {} } };
			}

			const userInOldfAccess = oldUsers[userId];

			if (userInUpdatedAccess.accessLevel !== userInOldfAccess.accessLevel) {
				return { ...all, updatedUsers: { ...all.updatedUsers, [userId]: userInUpdatedAccess } };
			}

			return all;
		},
		{ removedUsers: {}, updatedUsers: {} }
	);

	const addedNewUsers = Object.keys(newUsers).reduce((all: any, userId: any) => {
		const userInOldAccess = oldUsers[userId];

		if (!userInOldAccess) {
			return { ...all, [userId]: newUsers[userId] };
		}

		return all;
	}, {});

	return { ...modifiedOldUsers, newUsers: addedNewUsers };
};

export const getCatalogAccessLimitHash = (catalog: any) => {
	return catalog.reduce((all: any, cur: any) => {
		const { id, access } = cur;
		const mappedAccess = Object.keys(access).reduce((allAccess: any, currUserId: any) => {
			const accessLevel = access[currUserId];
			return {
				...allAccess,
				[currUserId]: {
					id: currUserId,
					accessLevel: accessLevel === 'admin' ? ACCESS_LEVEL.FULL.type : ACCESS_LEVEL.LIMITED.type,
				},
			};
		}, {});
		return { ...all, [id]: mappedAccess };
	}, {});
};
