import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { login } from '../../actions/sessionActions';
import { TokenService } from '../../services/tokenService';
import { LocalStorageService } from '../../services/localStorageService';
import { screenRoutes } from '../../constants/routesPath';
import { isValidJsonString, isValidTokenResponse } from '../../services/stringHelperService';
import './LoginScreen.scss';

class SSOScreen extends Component {
	state = {
		callBackUrl: '',
	};

	componentDidMount() {
		this.handleSSOCallBack();
	}

	handleSSOCallBack = (_) => {
		let result = {},
			pair,
			jsonString = {},
			i = null;
		const { match } = this.props;

		var params = match.params.callback && match.params.callback.split('&');
		for (i in params) {
			pair = params[i].split('=');
			result[pair[0]] = pair[1];
		}
		jsonString.data = JSON.stringify(result);
		this.handleLogin(jsonString);
	};

	handleLogin = (e) => {
		let expiresIn = 0;
		if (e.data && isValidJsonString(e.data)) {
			const data = JSON.parse(e.data);
			if (!isValidTokenResponse(data)) {
				return;
			}
			LocalStorageService.updateLocalStorage(data);

			expiresIn = data.expires_in;
			if (expiresIn) {
				TokenService.saveTokenTime(expiresIn);
				this.props
					.login()
					.then(() => this.props.history.push(screenRoutes.DASHBOARD))
					.catch((error) => {
						console.error('Failed to login ....', error);
					});
			}
		}
	};

	render() {
		return (
			<div className="loginScreen">
				<div className="loginScreen__bg">
					<div className="loginScreen__slot loginScreen__slot--right">
						<div className="loginScreen__welcomeText">
							Welcome back <span>Q-bies!</span>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
	login: (_) => dispatch(login()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SSOScreen);
