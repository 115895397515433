import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import InteractElementsTemplate from '../../reusable/InteractElementsTemplate/InteractElementsTemplate';
import InteractTopPanelMiddleSection from './InteractEditorTopPanel/InteractTopPanelMiddleSection';
import InteractElementsList from './InteractEditorTopPanel/InteractElementsList/InteractElementsList';
import InteractElementProperties from './InteractEditorTopPanel/InteractElementProperties/InteractElementProperties';
import InteractEditorBottomPanel from './InteractEditorBottomPanel/InteractEditorBottomPanel';
import InteractPreviewDialog from './InteractPreviewDialog/InteractPreviewDialog';
import InteractSaveProject from './InteractSaveProject';
import IconsStore from '../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../reusable/IconsStore/IconsList';
import {
	sendOpenInteractPreview,
	sendCloseInteractPreview,
	clearInteractElementsStore,
	addElementToAssetLibrary,
	hideSelectedSubPropertyKey,
} from '../../actions/interactElementsActions';
import {
	createInteractTimelineItem,
	createInteractTimelineGroup,
	setSelectedInteractTimelineItem,
	showTrimTool,
	removeInteractTimelineItem,
	removeSelectedInteractTimelineItem,
	setInteractScreenModified,
	setInteractScreenUnModified,
	clearInteractStore,
} from '../../actions/interactTimelineActions';
import InteractItemCopyPaste from './InteractItemCopyPaste';
import _ from 'underscore';
import { screenRoutes } from '../../constants/routesPath';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import './InteractEditorScreen.scss';
import { getBodyOfPreset } from '../../services/interactScreenHelperService';
import { messageTypes, mediaNames } from '../../constants/mediaConstants';
import { showNotification } from '../../actions/notificationActions';
import 'react-mosaic-component/react-mosaic-component.css';
import { Mosaic, MosaicWindow } from 'react-mosaic-component';
import { getUrlParameters } from '../../services/componentReusableService';
import { menuKeys } from '../../constants/inspectScreenMenu';
import './InteractEditorBottomPanel/InteractEditorBottomPanel.scss';
import { interactMimeType, sequenceTypes } from '../../constants/interactConstant';
import startPointIcon from '../../assets/images/square_start_bracket.svg';
import endPointIcon from '../../assets/images/square_end_bracket.svg';
import { sendTileWindowChange } from '../../actions/interactElementsActions';

class InteractEditorScreen extends PureComponent {
	constructor(props) {
		super(props);
		this.interactSaveRef = null;
		this.interactBottomPanelRef = null;
		this.state = {
			currentNode: {
				direction: 'row',
				first: {
					direction: 'column',
					first: {
						direction: 'row',
						first: 'a',
						second: 'b',
						splitPercentage: 50,
					},
					second: 'c',
					splitPercentage: 57,
				},
				second: 'd',
				splitPercentage: 67,
			},
			refreshPlayer: Math.random(),
		};
		this.playerContainer = null;
	}

	UNSAFE_componentWillMount = () => {
		this.props.clearInteractElementsStore();
		this.props.clearInteractStore();
	};

	componentWillUnmount() {
		this.props.clearInteractElementsStore();
		this.props.clearInteractStore();
	}

	componentDidMount = () => {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
		this.onSelectedInteractTimeline();
	};

	componentDidUpdate(prevProps) {
		const { selectedInteractItems, isVisible } = this.props;

		if (
			isVisible &&
			selectedInteractItems.length > 0 &&
			selectedInteractItems[0] != (prevProps.selectedInteractItems && prevProps.selectedInteractItems[0]) &&
			window.location.href.match('interactiveManager')
		) {
			let urlParams = getUrlParameters(window.location.hash);
			let selectedMenu = urlParams.selectedMenu ? urlParams.selectedMenu : '';
			if (selectedMenu !== menuKeys.details) {
				this.addIdOfSelectedItemToUrl();
			}
		}

		if (isVisible != prevProps.isVisible && isVisible) {
			this.props.removeSelectedInteractTimelineItem();
		}
		if (prevProps.refreshInteractPlayer !== this.props.refreshInteractPlayer) {
			this.refreshView();
		}
	}

	refreshView = () => {
		this.setState(
			{
				refreshPlayer: null,
			},
			() => {
				this.setState({
					refreshPlayer: Math.random(),
				});
			}
		);
	};

	addIdOfSelectedItemToUrl = () => {
		const { selectedInteractItems, mediaId } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0 && selectedInteractItems[0]) {
			let baseUrl = screenRoutes.PUBLISH_MEDIA_LIBRARY + '/' + mediaNames.medias + '/' + mediaId + '/detail/';
			let newURL = baseUrl + 'interactiveManager/' + selectedInteractItems[0];
			if (this.props.isOnLiveMode) {
				this.props.history.replace(newURL);
			}
		}
	};

	onSelectedInteractTimeline = () => {
		let path = window.location.href.split('interactiveManager/');
		if (path[1]) {
			this.props.setSelectedInteractTimelineItem([path[1]]);
		}
	};

	getInteractItemDetails = (itemId) => {
		const { interactItems } = this.props;
		let item = _.find(interactItems, (item) => {
			return item.id === itemId;
		});
		if (item) {
			return item;
		}
		return null;
	};

	handleRemovingOfItem = () => {
		this.props.removeSelectedInteractTimelineItem();
		this.props.hideSelectedSubPropertyKey();
	};

	showElementsTitle = () => {
		const { selectedInteractItems, selectedSubPropertyKey } = this.props;
		let item = {};
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			item = this.getInteractItemDetails(selectedInteractItems[0]);
		}
		return (
			<>
				<div id="interactElementsListTitleHeader">
					<label
						className="interactELementListTitle interact-semibold-headers "
						onClick={() => this.handleRemovingOfItem()}
					>
						{' '}
						Elements{' '}
					</label>
					{selectedInteractItems && selectedInteractItems.length > 0 && item ? (
						<>
							<IconsStore iconName={IconsList.RIGHT_ARROW} />
							<label
								className="interactELementListTitle interact-semibold-headers "
								onClick={() => this.props.hideSelectedSubPropertyKey()}
							>
								{' '}
								{item.content}{' '}
							</label>{' '}
						</>
					) : null}
					{selectedInteractItems &&
					selectedInteractItems.length > 0 &&
					item &&
					item.settings[selectedSubPropertyKey] &&
					item.definitions ? (
						<>
							<IconsStore iconName={IconsList.RIGHT_ARROW} />
							<label className="interactELementListTitle interact-semibold-headers ">
								{' '}
								{item.settings[selectedSubPropertyKey].name}{' '}
							</label>{' '}
						</>
					) : null}
					<div className="openInNewWindowIcon">
						<OpenInNewIcon />
					</div>
				</div>
			</>
		);
	};

	showSaveAsPresetButton = () => {
		const { selectedInteractItems } = this.props;
		let item = {};
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			item = this.getInteractItemDetails(selectedInteractItems[0]);
		}
		if (item && item.itemType === sequenceTypes.custom && item.mimeType === interactMimeType) {
			return (
				<div id="interactElementSaveAsPreset" className="settingsBottomButtons">
					<Button
						variant="contained"
						className="defaultActionBtn saveAsPresetButton"
						onClick={() => this.saveAsPreset(item)}
					>
						Save as preset
					</Button>
				</div>
			);
		} else {
			return null;
		}
	};

	saveAsPreset = (item) => {
		const { t } = this.props;
		let tempItem = getBodyOfPreset(item);
		this.props.addElementToAssetLibrary(this.props.defaultAccountId, tempItem).then((res) => {
			if (res && res.message) {
				this.props.showMessage(res.message, messageTypes.error);
			} else {
				this.props.showMessage(t('SAVED_ELEMENT_SUCCESSFULLY'), messageTypes.info);
			}
		});
	};

	showPreviewProjectDialog = () => {
		this.props.sendOpenInteractPreview();
	};

	hidePreviewProjectDialog = () => {
		this.props.sendCloseInteractPreview();
	};
	revertChanges = () => {
		const { isInteractScreenModified, interactItems, mediaId } = this.props;
		if (isInteractScreenModified) {
			interactItems.map((item) => {
				if (item.id !== mediaId) {
					this.props.removeInteractTimelineItem(item);
					this.props.removeSelectedInteractTimelineItem(item.id);
				}
			});
			if (this.props.liveInteractHelperRef) {
				//ToDo replace bottomPanelref with liveInteractHelper

				this.props.liveInteractHelperRef.fetchExistingInteractItems();
			}
			this.props.setInteractScreenUnModified();
		}
	};

	renderInteractMainButtons = () => {
		const { mediaId, t, showMessage } = this.props;
		return (
			<>
				<div className="interactMainButtons">
					{/* added to test revert changes
            <Button variant="contained" className="defaultActionBtn neutralBtn" onClick={() => { this.revertChanges() }}>
            Cancel
          </Button>    */}
					<InteractSaveProject
						mediaId={mediaId}
						t={t}
						showMessage={showMessage}
						onRef={(ref) => (this.interactSaveRef = ref)}
					/>
					{this.props.isOnLiveMode ? (
						<></>
					) : (
						<Button
							variant="contained"
							className="defaultActionBtn neutralBtn previewButton"
							onClick={() => {
								this.showPreviewProjectDialog();
							}}
						>
							<IconsStore iconName={IconsList.PLAY} />
							{t('PREVIEW')}
						</Button>
					)}
				</div>
			</>
		);
	};

	renderPreviewDialog = () => {
		const { mediaId, t, interactPreview } = this.props;
		if (interactPreview) {
			return (
				<>
					<InteractPreviewDialog
						t={t}
						mediaId={mediaId}
						hidePreviewProjectDialog={this.hidePreviewProjectDialog}
					/>
				</>
			);
		} else {
			return null;
		}
	};

	renderTiles = (index) => {
		switch (index) {
			case 'a':
				return this.renderTopLeftPanel();
			case 'b':
				return this.renderPlayerContainer();
			case 'c':
				return this.renderBottomPanel();
			case 'd':
				return this.renderSettingsContainer();
		}
	};

	renderTopLeftPanel = () => {
		const { t } = this.props;
		return (
			<>
				<div id="interactTopLeftPlanel" className="interactTopLeftWrapper">
					<InteractElementsTemplate isOnLiveMode={this.props.isOnLiveMode} t={t} />
				</div>
			</>
		);
	};

	renderPlayerContainer = () => {
		const { t, mediaId, channelId, isOnLiveMode } = this.props;
		const showPlayer = this.props.showPlayer || window.location.href.includes('selectedMenu=interactiveManager');

		const { refreshPlayer } = this.state;
		if (refreshPlayer) {
			return (
				<>
					<div id="interactTopMiddlePlanel" className="interactTopMiddleWrapper">
						<InteractTopPanelMiddleSection
							mediaId={mediaId}
							t={t}
							channelId={channelId}
							isOnLiveMode={isOnLiveMode}
							showPlayer={showPlayer}
						/>
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	renderBottomPanel = () => {
		const { t, showMessage, mediaId, isVisible } = this.props;
		const { refreshPlayer } = this.state;
		return (
			<>
				<InteractEditorBottomPanel
					isVisible={isVisible}
					mediaId={mediaId}
					t={t}
					refreshPlayer={refreshPlayer}
					isOnLiveMode={this.props.isOnLiveMode}
					showMessage={showMessage}
					onRef={(ref) => (this.interactBottomPanelRef = ref)}
				/>
			</>
		);
	};

	renderSettingsContainer = () => {
		const { selectedInteractItems, t, mediaId, showMessage, isOnLiveMode } = this.props;
		return (
			<>
				<div id="interactTopRightPanel" className="interactTopRightWrapper">
					{selectedInteractItems.length !== 1 ? (
						<InteractElementsList
							bottomPanelRef={this.interactBottomPanelRef}
							t={t}
							showMessage={showMessage}
							isOnLiveMode={this.props.isOnLiveMode}
							mediaId={mediaId}
						/>
					) : (
						<>
							<InteractElementProperties
								t={t}
								mediaId={mediaId}
								isOnLiveMode={isOnLiveMode}
								showMessage={showMessage}
							/>
							{this.showSaveAsPresetButton()}
						</>
					)}
				</div>
			</>
		);
	};

	getTimelineActionButtons = () => {
		const { interactItems, trimTool } = this.props;
		let videoItem = _.find(interactItems, (item) => {
			return item.itemType === sequenceTypes.video;
		});
		return (
			<>
				<div
					className="InteractBottomPanelContainer"
					onClick={() => this.interactBottomPanelRef.setTimeLineFocus()}
				>
					<label className="semiboldHeaders"> Timeline</label>
					<div className="timelineActionButtons">
						{videoItem && !trimTool && (
							<>
								<Button
									className="in-point"
									onClick={() => this.interactBottomPanelRef.handleEditTrim()}
								>
									<img src={startPointIcon} className="in-point-img"></img>
									<img src={endPointIcon} className="out-point-img"></img>Edit trim
								</Button>
							</>
						)}
						{(!videoItem || trimTool) && (
							<>
								<label className="trimVideoText">Trim video:</label>
								<Button
									className="in-point"
									onClick={() => this.interactBottomPanelRef.handleInpointClick()}
								>
									<img src={startPointIcon} className="in-point-img"></img>In
								</Button>
								<Button
									className="out-point"
									onClick={() => this.interactBottomPanelRef.handleOutpointClick()}
								>
									<img src={endPointIcon} className="out-point-img"></img>
									Out
								</Button>
							</>
						)}
					</div>
				</div>
			</>
		);
	};

	renderHeaders = (index) => {
		const { interacMediaLoopStarted, selectedInteractItems } = this.props;
		const { t } = this.props;
		let item = {};
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			item = this.getInteractItemDetails(selectedInteractItems[0]);
		}
		switch (index) {
			case 'a':
				return (
					<>
						<label className="interact-semibold-headers">{t('INTERACTIVE_ELEMENTS')}</label>
					</>
				);
			case 'b':
				return (
					<>
						{interacMediaLoopStarted && item && (
							<label className="interact-semibold-headers"> {item.content}</label>
						)}
						{!interacMediaLoopStarted && (
							<label className="interact-semibold-headers"> {t('VIDEO_PREVIEW')}</label>
						)}
					</>
				);
			case 'c':
				return (
					<>
						{this.props.isOnLiveMode && (
							<label className="interact-semibold-headers"> {t('INTERACT_QUEUE')} </label>
						)}
						{!this.props.isOnLiveMode && this.getTimelineActionButtons()}
					</>
				);
			case 'd':
				return <>{this.showElementsTitle()}</>;
		}
	};

	onChange = (currentNode) => {
		this.setState({ currentNode });
		this.props.sendTileWindowChange();
		// localStorage.setItem('liveManagerPanel', JSON.stringify(currentNode))
	};

	onRelease = (currentNode) => {
		this.setState({ currentNode });
		this.props.sendTileWindowChange();
		// localStorage.setItem('liveManagerPanel', JSON.stringify(currentNode))
	};

	onDragEnd = () => {};

	render() {
		const { mediaId, t, interactiveManagerLoaded } = this.props;

		return (
			<>
				<div id="interactEditorScreen" className={this.props.isOnLiveMode ? 'liveMedia' : 'onDemandMedia'}>
					{this.renderPreviewDialog()}
					{this.renderInteractMainButtons()}

					{interactiveManagerLoaded && (
						<Mosaic
							renderTile={(count, path) => (
								<MosaicWindow
									renderToolbar={() => (
										<div className="customToolbar">{this.renderHeaders(count)}</div>
									)}
									path={path}
									onDragEnd={(type) => this.onDragEnd(type)}
								>
									{this.renderTiles(count)}
								</MosaicWindow>
							)}
							value={this.state.currentNode}
							onChange={this.onChange}
							onRelease={this.onRelease}
						/>
					)}

					<InteractItemCopyPaste mediaId={mediaId} t={t} bottomPanelRef={this.interactBottomPanelRef} />
				</div>
			</>
		);
	}
}

InteractEditorScreen.propTypes = {
	mediaId: PropTypes.string.isRequired,
	isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ session, interactElementsReducer, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactPreview: interactElementsReducer.interactPreview,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	isInteractScreenModified: interactTimelineReducer.isInteractScreenModified,
	isComponentStatic: interactElementsReducer.isComponentStatic,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
	interactGroups: interactTimelineReducer.groups,
	selectedSubPropertyKey: interactElementsReducer.selectedSubPropertyKey,
	interacMediaLoopStarted: interactTimelineReducer.interacMediaLoopStarted,
	trimTool: interactTimelineReducer.trimTool,
	refreshInteractPlayer: interactTimelineReducer.refreshInteractPlayer,
});

const mapDispatchToProps = (dispatch) => ({
	clearInteractStore: (_) => dispatch(clearInteractStore()),
	setInteractScreenModified: () => dispatch(setInteractScreenModified()),
	setInteractScreenUnModified: () => dispatch(setInteractScreenUnModified()),
	hideSelectedSubPropertyKey: () => dispatch(hideSelectedSubPropertyKey()),
	sendOpenInteractPreview: (_) => dispatch(sendOpenInteractPreview()),
	sendCloseInteractPreview: (_) => dispatch(sendCloseInteractPreview()),
	clearInteractElementsStore: (_) => dispatch(clearInteractElementsStore()),
	removeInteractTimelineItem: (item) => dispatch(removeInteractTimelineItem(item)),
	removeSelectedInteractTimelineItem: (item) => dispatch(removeSelectedInteractTimelineItem(item)),
	addElementToAssetLibrary: (accid, body) => dispatch(addElementToAssetLibrary(accid, body)),
	createInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(createInteractTimelineItem(item, setScreenUnModified)),
	createInteractTimelineGroup: (group) => dispatch(createInteractTimelineGroup(group)),
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
	showNotification: (message) => dispatch(showNotification(message)),
	showTrimTool: () => dispatch(showTrimTool()),
	sendTileWindowChange: () => dispatch(sendTileWindowChange()),
	// fnBlaBla: () => dispatch(action.name()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractEditorScreen);
