import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Close, InfoOutlined, Lock, CheckBox } from '@material-ui/icons';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';

import { ReactComponent as InteractiveMap } from '../../assets/svg/InteractiveMap.svg';
import { ReactComponent as EditorDashboard } from '../../assets/svg/EditorDashboard.svg';
import { ReactComponent as RecordingDashboard } from '../../assets/svg/RecordingDashboard.svg';
import { ReactComponent as LiveEvent } from '../../assets/svg/LiveEvent.svg';
import { ReactComponent as Interact } from '../../assets/svg/InteractiveProject.svg';
import { ReactComponent as UploadVideo } from '../../assets/svg/video-playlist.svg';
import { ReactComponent as EditVideo } from '../../assets/svg/showing-video-frames.svg';
import { ReactComponent as Recording } from '../../assets/svg/radio_button_checked.svg';
import { joinClassNames } from '../../services/elementHelperService';
import { showMessage } from '../../actions/globalActions';
import { messageTypes } from '../../constants/mediaConstants';
import ColorHub from '../../reusable/ColorHub/ColorHub';
import { LocalStorageService } from '../../services/localStorageService';

export namespace DashboardUtils {
	const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,}$/;
	export const LINKS = {
		contact: 'https://www.qbrick.com/contact-us',
		help: 'https://support.qbrick.com/hc/en-us',
		news: 'https://www.qbrick.com/whats-new/',
	};
	export const DASHBOARD_BASE_CLASSNAME = 'dashboard';
	export const RecentMediaTypes = {
		video: 'video',
		live: 'live',
		interact: 'interact',
		edits: 'edits',
		record: 'record',
		speechToText: 'speechToText',
	};

	export const DASHBOARD_LABELS = {
		video: {
			recentListTitle: 'Recent videos',
			shortcutLabel: 'New video',
			emptyMessage: 'No videos to show',
			emptySubMessage: 'Upload your first video to show here',
			noMoreMedia: 'No more videos to show',
			shortcutBtnLabel: (
				<>
					<b>Upload</b> media
				</>
			),
			shortcutBtnTitle: 'Upload media',
			shortcutBtnIcon: <ColorHub component={<UploadVideo />} htmlColor="#000" />,
			backgroundColor: '#126CFC1A',
		},
		edits: {
			featureLabel: 'Edit (Adobe Express)',
			recentListTitle: 'Recent edits',
			shortcutLabel: 'New edit',
			emptyMessage: 'No edits to show',
			emptySubMessage: 'Edit your first video to show here',
			noMoreMedia: 'No more edits to show',
			shortcutBtnLabel: (
				<>
					<b>Edit</b> video
				</>
			),
			shortcutBtnTitle: 'Edit video',
			shortcutBtnIcon: <ColorHub component={<EditVideo />} htmlColor="#000" />,
			backgroundColor: '#3DAA8B4D',
		},
		interact: {
			featureLabel: 'Interact',
			recentListTitle: 'Recent interactive videos',
			shortcutLabel: 'New interactive video',
			emptyMessage: 'No interactive videos to show',
			emptySubMessage: 'Create your first interactive video to show here',
			noMoreMedia: 'No more interactive videos to show',
			shortcutBtnLabel: (
				<>
					Create <br />
					<b>interactive video</b>
				</>
			),
			shortcutBtnTitle: 'Create interactive video',
			shortcutBtnIcon: <ColorHub component={<Interact />} htmlColor="#000" />,
			backgroundColor: '#EBE2D899',
		},
		record: {
			featureLabel: 'Recording',
			shortcutBtnLabel: (
				<>
					Start <b>recording</b>
				</>
			),
			shortcutBtnTitle: 'Start recording',
			shortcutBtnIcon: <ColorHub component={<Recording />} htmlColor="#000" />,
			backgroundColor: '#E6B85C4D',
		},
		live: {
			featureLabel: 'Live streaming',
			recentListTitle: 'Recent live events',
			shortcutLabel: 'New live media',
			emptyMessage: 'No live events to show',
			emptySubMessage: 'Create your first live event to show here',
			noMoreMedia: 'No more live events to show',
			shortcutBtnLabel: (
				<>
					Create <b>live event</b>
				</>
			),
			shortcutBtnTitle: 'Create live event',
			shortcutBtnIcon: <ColorHub component={<LiveEvent />} htmlColor="#000" />,
			backgroundColor: '#126CFC1A',
		},
		speechToText: {
			featureLabel: 'Speech to text',
		},
	};

	export const INTRODUCING_SLIDES = [
		{
			id: RecentMediaTypes.interact,
			header: `Create an interactive video experience in just a few minutes!`,
			content: `Anyone can create an interactive video in Qbrick Interact. Add video scenes and fill them with
            interactive widgets - like buttons, images & text. Create experiences for marketing, demos, courses,
            financal reports & more. Try it out!`,
			buttonLabel: 'Create new',
			graphic: <InteractiveMap />,
			graphicColor: '#3daa8b',
		},
		{
			id: RecentMediaTypes.edits,
			header: `Edit videos in Adobe Express - right here in the Qbrick video platform`,
			content: `With our new Adobe Express implementation, you will have access to a wide range of new features and functionality – such as graphical templates, stock, animations & AI – that will bring your projects to a new level. Try it out!`,
			buttonLabel: 'Edit video',
			graphic: <EditorDashboard />,
			graphicColor: '#126CFC1A',
		},
		{
			id: RecentMediaTypes.record,
			header: `Record yourself or your desktop screen to create guides, demos, courses & much more`,
			content: `Use our recorder to easily record your webcam feed or screen. You can find this feature in your library. Click “New” and “Record”. Try it out!`,
			buttonLabel: 'Start recording',
			graphic: <RecordingDashboard />,
			graphicColor: '#EBE2D8',
		},
	];

	const getButtonCustomStyle = makeStyles((_theme) => ({
		customButton: {
			width: '30px',
			height: '30px',
			minWidth: '30px',
			backgroundColor: 'transparent',
			borderRadius: '4px',
			color: '#909CA5',
			border: 'transparent',
			'&:hover': {
				border: '#F7F8FA',
				backgroundColor: '#F7F8FA',
			},
		},
		customButtonGroup: {
			backgroundColor: '#FFFFFF33',
			padding: '4px',
		},
	}));
	export const useButtonCustomStyle = () => getButtonCustomStyle();

	interface EmptyMediasProps {
		message?: string;
		subMessage?: string;
		baseClassName?: string;
	}
	export const EmptyMedias = ({ message, subMessage, baseClassName }: EmptyMediasProps) => {
		const baseClass = baseClassName ?? DASHBOARD_BASE_CLASSNAME;
		return (
			<div className={`${baseClass}__message-container`}>
				{message && <div className={`${baseClass}__main-message`}>{message}</div>}
				{subMessage && <div className={`${baseClass}__sub-message`}>{subMessage}</div>}
			</div>
		);
	};

	interface FooterProps {
		isMobile?: boolean;
	}
	export const Footer = ({ isMobile }: FooterProps) => {
		const ContactLink = () => (
			<>
				<a href={LINKS.contact} target="_blank">
					Contact sales
				</a>
				<a href={LINKS.help} target="_blank">
					Support portal
				</a>
				<a href="tel:+46 8 459 90 00" className={`${DASHBOARD_BASE_CLASSNAME}__blue-link`}>
					+46 8 459 90 00
				</a>
				<a href="mailto:support@qbrick.com" className={`${DASHBOARD_BASE_CLASSNAME}__blue-link`}>
					support@qbrick.com
				</a>
			</>
		);

		return (
			<div
				className={joinClassNames(
					`${DASHBOARD_BASE_CLASSNAME}__container`,
					`${DASHBOARD_BASE_CLASSNAME}__container--footer`
				)}
			>
				<div className={`${DASHBOARD_BASE_CLASSNAME}__left`}>
					{isMobile ? <ContactLink /> : `©Qbrick ${new Date().getFullYear()}`}
				</div>

				<div className={`${DASHBOARD_BASE_CLASSNAME}__right`}>
					{isMobile ? `©Qbrick ${new Date().getFullYear()}` : <ContactLink />}
				</div>
			</div>
		);
	};

	interface ShortcutButtonProps {
		type?: string;
		noPermission?: boolean;
		onClick?: (_e: React.MouseEvent) => void;
	}
	export const ShortcutButton = ({ type = RecentMediaTypes.video, noPermission, onClick }: ShortcutButtonProps) => {
		const labels = (DASHBOARD_LABELS as any)[type];
		return (
			<Button
				className={joinClassNames(
					`${DASHBOARD_BASE_CLASSNAME}__action-item`,
					`${DASHBOARD_BASE_CLASSNAME}--shadow`,
					noPermission ? `${DASHBOARD_BASE_CLASSNAME}__action-item--locked` : ''
				)}
				onClick={onClick}
				title={labels.shortcutBtnTitle}
			>
				{noPermission && (
					<div className={`${DASHBOARD_BASE_CLASSNAME}__action-locked-layer`}>
						<Lock />
					</div>
				)}
				<div
					className={`${DASHBOARD_BASE_CLASSNAME}__action-icon`}
					style={{ backgroundColor: labels.backgroundColor }}
				>
					{labels.shortcutBtnIcon}
				</div>
				<span>{labels.shortcutBtnLabel}</span>
			</Button>
		);
	};

	interface PermissionInformationDialogProps {
		permission?: string | null;
		currentPermissions?: any;
		onClose?: () => void;
	}
	export const PermissionInformationDialog = ({
		onClose,
		permission,
		currentPermissions,
	}: PermissionInformationDialogProps) => {
		const dispatch = useDispatch();
		const username = LocalStorageService.getUserName();
		const defaultAccountName = LocalStorageService.getDefaultAccountName();

		const [email, setEmail] = useState(username);
		const [company, setCompany] = useState(defaultAccountName);
		const [features, setFeatures] = useState<string[]>(permission ? [permission] : []);

		const onFeatureCheckboxChange = useCallback((feature: string, checked: boolean) => {
			if (checked) {
				setFeatures((prev) => [...prev, feature]);
			} else {
				setFeatures((prev) => prev.filter((f) => f !== feature));
			}
		}, []);

		const renderFeatureCheckbox = useCallback(
			(feature: string) => {
				const labels = (DASHBOARD_LABELS as any)[feature];
				return (
					<FormControlLabel
						name="Extra.1712828381450"
						label={labels.featureLabel}
						labelPlacement="end"
						disabled={currentPermissions[feature]}
						control={
							currentPermissions[feature] ? (
								<div className={`${DASHBOARD_BASE_CLASSNAME}__features--excluded`}>
									<CheckBox />
								</div>
							) : (
								<Checkbox
									style={{ padding: '8px' }}
									color="primary"
									checked={features.includes(feature)}
									onChange={(_e, checked) => onFeatureCheckboxChange(feature, checked)}
									value={labels.featureLabel}
								/>
							)
						}
					/>
				);
			},
			[currentPermissions, features, onFeatureCheckboxChange]
		);

		const onSubmitUpsalesForm = useCallback(
			(e: React.FormEvent) => {
				onClose?.();
				(window as any).validateUpsalesForm?.(e, (status: any) => {
					if (200 === status) {
						dispatch(showMessage('Your request has been sent!', messageTypes.success));
					} else {
						dispatch(showMessage('Unable to send request. Please try again!', messageTypes.warning));
					}
				});
			},
			[dispatch, onClose]
		);

		useEffect(() => {
			setFeatures(permission ? [permission] : []);
			setEmail(LocalStorageService.getUserName());
			setCompany(LocalStorageService.getDefaultAccountName());
		}, [permission]);

		return (
			<Dialog fullWidth maxWidth={'sm'} open={!!permission} className="confirmationPopup">
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">
						<InfoOutlined style={{ marginRight: '8px' }} />
						Access notification
					</Typography>
					<IconButton
						onClick={() => {
							onClose?.();
							setFeatures([]);
						}}
					>
						<Close />
					</IconButton>
				</DialogTitle>

				<DialogContent>
					<DialogContentText className="dialogContentWrapper">
						<span className="dialogContentText">
							It seems you don’t have access to this application. Please contact your account admin or
							fill in the form below to request access!
						</span>
					</DialogContentText>
					<form
						className={`${DASHBOARD_BASE_CLASSNAME}__upsales-form`}
						name={'form_11033uf5ed4e05debb44539c37fd0c98e9b7d4'}
						action="https://power.upsales.com/api/external/formSubmit"
						method="POST"
						onSubmit={onSubmitUpsalesForm}
					>
						<input type="hidden" name="formCid" value="11033" />
						<input type="hidden" name="formId" value="11033uf5ed4e05debb44539c37fd0c98e9b7d4" />
						<input type="hidden" name="isFrame" value="false" />
						<FormControl fullWidth>
							<FormLabel>
								Email <span className="required-mark">*</span>
							</FormLabel>
							<TextField
								name="Contact.email"
								required
								fullWidth
								variant="outlined"
								color={'primary'}
								placeholder={'Qbrick user email'}
								value={email}
								onChange={(event) => setEmail(event.target.value)}
								error={!!email && !EMAIL_REGEXP.test(email)}
							/>
						</FormControl>

						<FormControl fullWidth>
							<FormLabel>
								Company <span className="required-mark">*</span>
							</FormLabel>
							<TextField
								name="Client.name"
								required
								fullWidth
								variant="outlined"
								color="primary"
								placeholder={'Qbrick user company'}
								value={company}
								onChange={(event) => setCompany(event.target.value)}
							/>
						</FormControl>

						<FormControl>
							<FormLabel style={{ marginTop: '24px' }}>Application to add to account</FormLabel>
							<div className={`${DASHBOARD_BASE_CLASSNAME}__features`}>
								{renderFeatureCheckbox(RecentMediaTypes.interact)}
								{renderFeatureCheckbox(RecentMediaTypes.edits)}
								{renderFeatureCheckbox(RecentMediaTypes.live)}
								{renderFeatureCheckbox(RecentMediaTypes.record)}
								{renderFeatureCheckbox(RecentMediaTypes.speechToText)}
							</div>
						</FormControl>
						<Button
							variant="contained"
							color="primary"
							style={{ whiteSpace: 'nowrap', width: '100%', margin: '12px 0' }}
							type="submit"
							disabled={!email || !company || !EMAIL_REGEXP.test(email)}
						>
							Send
						</Button>
					</form>
				</DialogContent>
			</Dialog>
		);
	};
}
