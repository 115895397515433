import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import Pagination from '../../Pagination/Pagination';
import { getDetails } from '../../../services/mediaDisplayService';
import CatalogTree from '../../CatalogTree/CatalogTree';
import { getRestrictionRules } from '../../../actions/publishActions';
import MediaItem from './mediaItem/MediaItem';

import './TabContent.scss';

interface MediaLibraryProps {
	medias: object[];
	totalCount: number;
	page: number;
	rowsPerPage: number;
	selectedCatalog: string;
	mediaType: string;
	onPageChange: (_e: object, _page: number) => void;
	onRowPerPageChange: (_e: any) => void;
	setSelectedCatalog: (_id: string) => void;
	setAllowToSubmit: (_e: boolean) => void;
}

const MediaLibrary = forwardRef(
	(
		{
			medias,
			totalCount,
			page,
			rowsPerPage,
			mediaType,
			selectedCatalog,
			onPageChange,
			onRowPerPageChange,
			setSelectedCatalog,
			setAllowToSubmit,
		}: MediaLibraryProps,
		ref
	) => {
		const { t: translator } = useTranslation();
		const dispatch = useDispatch();
		const [selectedMedia, setSelectedMedia] = useState<any>();
		const refinedMedia = medias.map((media) => getDetails(media));
		const defaultAccountId = useSelector((state) => (state as any).session.defaultAccountId);

		const submitSelectedMedia = () => {
			return [selectedMedia];
		};

		useImperativeHandle(ref, () => {
			return {
				submitSelectedMedia,
			};
		});

		useEffect(() => {
			dispatch(getRestrictionRules(defaultAccountId));
		}, [defaultAccountId, dispatch]);

		return (
			<Box className="media-library-modal-wrapper">
				<Box className="structure-navigator">
					<Box className={`catalog-tree video`}>
						<CatalogTree
							showNoCatalogOption
							preselectedCatalog={selectedCatalog}
							handleCatalogsChange={setSelectedCatalog}
						/>
					</Box>
				</Box>
				<Box className="content-wrapper">
					{refinedMedia.length !== 0 && (
						<Box className="media-list">
							{refinedMedia.map((media: any) => {
								const { id } = media;
								return (
									<MediaItem
										currentMediaType={mediaType}
										translator={translator}
										key={id}
										id={id}
										media={media}
										selected={selectedMedia?.id === id}
										onMediaSelectionChange={(media) => {
											setSelectedMedia(media);
											setAllowToSubmit(true);
										}}
									/>
								);
							})}
						</Box>
					)}
					{refinedMedia.length === 0 && (
						<Box className="empty-medias">
							<Typography variant="body1">{translator('NO_MEDIAS_FOUND')}</Typography>
						</Box>
					)}
					<Pagination
						count={Number(totalCount)}
						page={page}
						rowsPerPage={rowsPerPage}
						labelRowsPerPage={'Medias per page:'}
						onPageChange={onPageChange}
						onRowsPerPageChange={onRowPerPageChange}
					/>
				</Box>
			</Box>
		);
	}
);

export default MediaLibrary;
