import { useCallback, useMemo } from 'react';
import useUploadingImageByUrl from './screens/library/useUploadingImageByUrl';
import useUploadingVideoByUrl from './screens/library/useUploadingVideoByUrl';

interface UseAEEditorProps {
	afterJobFunc?: (_param?: any) => void;
	onPublish?: (_publishParams: any, _mediaName?: string) => void;
}

const useAEEditor = (props: UseAEEditorProps = {}) => {
	const { uploadVideoByUrl } = useUploadingVideoByUrl();
	const { uploadImageByUrl } = useUploadingImageByUrl();

	const { afterJobFunc, onPublish: onPublishProps } = props;

	const onPublish = useCallback(
		(publishParams: any, mediaName?: string) => {
			const asset = publishParams?.asset[0];
			if (!asset) {
				return;
			}
			const mediaTitle = `${mediaName ? `${mediaName} - ` : ''}Edited ${new Date().toDateString()}`;
			const mediaDescription = '';
			const mediaUrl = asset.data;
			const projectId = publishParams.projectId;

			if (asset.type === 'video') {
				uploadVideoByUrl({ mediaTitle, mediaDescription, mediaUrl, projectId }).then(
					(temporaryMediaData: any) => {
						afterJobFunc?.({
							data: publishParams,
							mediaId: temporaryMediaData.id,
							type: asset.type,
							mediaTitle,
						});
					}
				);
			}

			if (asset.type === 'image') {
				uploadImageByUrl({
					mediaTitle,
					mediaUrl,
					projectId,
					mediaType: asset.fileType,
				}).then(() => {
					afterJobFunc?.({ data: publishParams, type: asset.type });
				});
			}
		},
		[afterJobFunc, uploadImageByUrl, uploadVideoByUrl]
	);

	const uploadToPlatformCallback = useMemo(() => {
		return {
			onCancel: () => {
				afterJobFunc?.({});
			},
			onPublish: onPublishProps ?? onPublish,
			onError: (err: any) => {
				console.error('Error received is', err.toString());
				afterJobFunc?.({});
			},
		};
	}, []);

	return {
		uploadToPlatformCallback: uploadToPlatformCallback,
	};
};

export default useAEEditor;
