import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import { liveEventStatusList } from '../../../constants/liveEventStatus';
import './LiveManagerTopHeader.scss';
import {
	getFormattedDate,
	convertSecondsToTimeStamp,
	getDifferenceBetweenTwodates,
} from '../../../services/timeStampService';
import LiveManagerTopPanel from '../../../components/LiveManagerTopPanel/LiveManagerTopPanel';
class LiveManagerTopHeader extends PureComponent {
	renderActionButton = () => {
		const { mediaId, mediaDetails, isVisible, t, isStreamPublished } = this.props;
		return (
			<>
				<LiveManagerTopPanel
					t={t}
					mediaId={mediaId}
					mediaDetails={mediaDetails}
					isVisible={isVisible}
					isStreamPublished={isStreamPublished}
				></LiveManagerTopPanel>
			</>
		);
	};

	render() {
		const { t, liveEventStatus, liveEventStartTime, liveEventStopTime, duration } = this.props;
		return (
			<>
				<div id="liveManagerTopHeader">
					{this.renderActionButton()}
					<ul>
						<li>
							<label>
								{' '}
								{liveEventStatus === liveEventStatusList.STARTED
									? t('YOU_ARE_LIVE')
									: liveEventStatus === liveEventStatusList.STOPPED
									? t('LIVE_ENDED')
									: t('NOT_LIVE')}{' '}
							</label>
							<label> {t('STATUS')} </label>
						</li>
						{liveEventStartTime && (
							<>
								<li>
									<label> {getFormattedDate(liveEventStartTime)} </label>
									<label> {t('START')} </label>
								</li>
							</>
						)}

						{liveEventStatus === liveEventStatusList.STOPPED && (
							<>
								<li>
									<label> {getFormattedDate(liveEventStopTime)} </label>
									<label> {t('STOP')} </label>
								</li>
							</>
						)}
						{liveEventStatus !== liveEventStatusList.INITIAL && (
							<li>
								{liveEventStartTime && liveEventStopTime ? (
									<label>
										{' '}
										{getDifferenceBetweenTwodates(liveEventStopTime, liveEventStartTime)}{' '}
									</label>
								) : (
									<label> {duration ? convertSecondsToTimeStamp(duration) : ''} </label>
								)}

								<label> {t('DURATION')} </label>
							</li>
						)}
					</ul>
					<div className="liveHelp">
						<Button> {t('HELP')} </Button>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ liveManager }) => ({
	liveEventStatus: liveManager.liveEventStatus,
	liveEventStartTime: liveManager.liveEventStartTime,
	liveEventStopTime: liveManager.liveEventStopTime,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	duration: liveManager.duration,
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LiveManagerTopHeader);
