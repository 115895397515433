import moment from 'moment';

export const convertSecondsToTimeStamp = (seconds) => seconds && new Date(seconds * 1000).toISOString().substr(11, 8);
export const convertSecondsToTimeStamps = (seconds) => seconds && new Date(seconds * 1000).toDateString();

export const getCurrentDateTimeInISOFormat = () => {
	return new Date().toISOString();
};

export const getFormattedDate = (dateInput) => moment(dateInput).format('DD-MM-YY   HH:mm:ss');

export const getFormattedTimeWithMilliseconds = (dateInput, format) => moment(dateInput).format(format);

export const getTimeDifferenceInSeconds = (afterTime, beforeTime) => {
	if (!afterTime || !beforeTime) {
		return null;
	}
	return (new Date(afterTime).getTime() - new Date(beforeTime).getTime()) / 1000;
};

/*https://momentjs.com/docs/#/manipulating/add/*/
export const returnAddedTime = (time, duration, format) => {
	let newTime = new Date(moment(time).add(duration, format));
	return newTime;
};

export const returnDecreasedTime = (time, duration, format) => {
	let newTime = new Date(moment(time).subtract(duration, format));
	return newTime;
};

export const isDateBetweenTwoDates = (currentDate, startDate, endDate) => {
	if (!currentDate || !startDate || !endDate) {
		return null;
	}
	if (currentDate.getTime() == startDate.getTime() || currentDate.getTime() == endDate.getTime()) {
		return true;
	}
	let between = moment(currentDate).isBetween(startDate, endDate);
	return between;
};

export const mapTimeDifferenceToDefaultTime = (afterTime, beforeTime) => {
	let timeDiff = new Date(afterTime).getTime() - new Date(beforeTime).getTime();
	let d = returnAddedTime(getTimelineStartDate(), timeDiff, 'ms');
	return d;
};

export const getTimelineStartDate = (_) => {
	//date changed to 1970 as when page is opened in one date and try to save in another date values are saved in negative numbers
	let d = new Date(0);
	d.setHours(0);
	d.setMinutes(0);
	d.setSeconds(0);
	d.setMilliseconds(0);
	return d;
};

export const getTimelineTimeFromSeconds = (seconds) => {
	let newTime = returnAddedTime(getTimelineStartDate(), seconds, 's');
	return newTime;
};

export const getSecondsFromTimelineTime = (time) => {
	return Number(getTimeDifferenceInSeconds(time, getTimelineStartDate()));
};

export const getTime = (input) => {
	if (!input.split) {
		return;
	}
	let timeArray = input.split(':');
	return {
		hours: timeArray[0],
		minutes: timeArray[1],
		seconds: timeArray.length > 2 ? timeArray[2] : '00',
		milliseconds: timeArray.length > 3 ? timeArray[3] : '000',
	};
};

export const getTimelineTimeFromTimeInput = (input) => {
	const timeInput = (input ?? '00:00:00:00').replace('.', ':');
	let time = getTime(timeInput);
	let newTime = getTimelineStartDate();
	newTime.setHours(time.hours);
	newTime.setMinutes(time.minutes);
	newTime.setSeconds(time.seconds);
	newTime.setMilliseconds(time.milliseconds.substr(0, 3));
	return newTime;
};

export const convertTimeToWidgetTime = (input) => {
	const time = moment(input);
	let milliseconds = time.milliseconds();
	let seconds = time.seconds();
	let minutes = time.minutes();
	let hours = time.hours();

	return (
		(hours > 9 ? hours : '0' + hours) +
		':' +
		(minutes > 9 ? minutes : '0' + minutes) +
		':' +
		(seconds > 9 ? seconds : '0' + seconds) +
		'.' +
		(milliseconds > 99 ? milliseconds : milliseconds > 9 ? '0' + milliseconds : '00' + milliseconds)
	);
};

export const convertInputTimeToWidgetTime = (input) => {
	let timeArray = input.split(':');
	const hours = timeArray[0];
	const minutes = timeArray[1];
	const seconds = timeArray[2] ?? '00';
	const milliseconds = timeArray[3] ?? '000';

	return `${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const convertWidgetTimeToInputTime = (widgetTime) => {
	let timeArray = widgetTime.split(':');
	const hours = timeArray[0];
	const minutes = timeArray[1];
	const seconds = timeArray[2].includes('.') ? timeArray[2].split('.')[0] : '00';
	const milliseconds =
		timeArray.length > 3
			? timeArray[3]
			: timeArray.length > 2
			? timeArray[2].includes('.')
				? timeArray[2].split('.')[1]
				: '000'
			: '000';

	return `${hours}:${minutes}:${seconds}:${milliseconds}`;
};

export const getDifferenceBetweenTime = (endTime, startTime) => {
	const duration = moment.duration(moment(endTime).diff(moment(startTime)));
	const seconds = duration.seconds();
	const minutes = duration.minutes();
	const hours = duration.hours();
	const days = duration.days();

	let str = '';
	str = `${days ? `${days} days ` : ''} ${hours ? `${hours} hours ` : ''} ${
		minutes ? `${minutes} minutes ` : ''
	} ${seconds} seconds ago`;
	return str.trim().replace('  ', ' ');
};

export const getDifferenceBetweenTwodates = (endTime, startTime) => {
	let time = new Date(endTime) - new Date(startTime);
	let seconds = moment.duration(time).seconds();
	let minutes = moment.duration(time).minutes();
	let hours = moment.duration(time).hours();
	let days = moment.duration(time).days();
	let str = '';
	if (days > 0) {
		hours = days * 24 + hours;
	}
	str =
		str +
		(hours > 9 ? hours : '0' + hours) +
		':' +
		(minutes > 9 ? minutes : '0' + minutes) +
		':' +
		(seconds > 9 ? seconds : '0' + seconds);
	return str;
};

export const dateToTimeElapsed = (dateInput, t = '') => {
	const now = moment(new Date());
	const duration = moment.duration(now.diff(dateInput));
	const minutes = parseInt(duration.asMinutes());
	const hours = parseInt(duration.asHours());
	const days = parseInt(duration.asDays());
	const months = parseInt(duration.asMonths());
	const years = parseInt(duration.asYears());

	if (minutes < 1) {
		return t('A_FEW_SECONDS_AGO');
	} else if (hours < 1) {
		return minutes + ' ' + (minutes !== 1 ? t('MINUTES') : t('MINUTE')) + ' ' + t('AGO');
	} else if (days < 1) {
		return hours + ' ' + (hours !== 1 ? t('HOURS') : t('HOUR')) + ' ' + t('AGO');
	} else if (months < 1) {
		return days + ' ' + (days !== 1 ? t('DAYS') : t('DAY')) + ' ' + t('AGO');
	} else if (years < 1) {
		return months + ' ' + (months !== 1 ? t('MONTHS') : t('MONTH')) + ' ' + t('AGO');
	} else {
		return years + ' ' + (years !== 1 ? t('YEARS') : t('YEAR')) + ' ' + t('AGO');
	}
};

export const formatNumbers = (number) => {
	number = Number(number);
	if (isNaN(number)) {
		return number;
	}
	let result = '';
	let key = '';
	if (number >= 1000) {
		let powers = [
			{ key: 'Q', value: Math.pow(10, 15) },
			{ key: 'T', value: Math.pow(10, 12) },
			{ key: 'B', value: Math.pow(10, 9) },
			{ key: 'M', value: Math.pow(10, 6) },
			{ key: 'k', value: 1000 },
		];

		for (let i = 0; i < powers.length; i++) {
			let reduced = number / powers[i].value;

			reduced = reduced * 10;
			reduced = Math.trunc(reduced);
			reduced = reduced / 10;

			if (reduced >= 1) {
				result = reduced;
				key = powers[i].key;
				break;
			}
		}
	} else {
		result = number;
	}
	return result + key;
};

export const getMonth = (dateInput) => {
	let formattedDate = new Date(dateInput);
	if (formattedDate && formattedDate.getMonth) {
		return formattedDate.getMonth();
	}
	return '';
};

export const getYear = (dateInput) => {
	let formattedDate = new Date(dateInput);
	if (formattedDate && formattedDate.getFullYear) {
		return formattedDate.getFullYear();
	}
	return '';
};

export const getMonthName = (num, t) => {
	const monthNames = [
		t('JAN'),
		t('FEB'),
		t('MAR'),
		t('APR'),
		t('MAY'),
		t('JUN'),
		t('JUL'),
		t('AUG'),
		t('SEP'),
		t('OCT'),
		t('NOV'),
		t('DEC'),
	];
	return monthNames[num];
};
