export const menuKeys = {
  details: "details",
  playerConfigurations: "playerConfigurations",
  liveManager: "liveManager",
  interactiveManager: "interactiveManager",
  speechToText: "speechToText",
  close: "close",
  mediaanalytics: "mediaanalytics",
  apps: "apps",
};

export const detailsMenuKeys = {
  mediaDetails: "mediaDetails",
  embedVideo: "embedVideo",
  socialSharing: "socialShare",
  thumbnailManager: "thumbnailManager",
  accessRestrictions: "accessRestrictions",
  files: "files",
  playlistMediaDetails: "playlistMediaDetails",
  playerConfigurations: "playerConfigurations",
  scenes: "scenesGraph"
};

export const playerConfigurationsMenuKeys = {
  presets: "presets",
  playbackOptions: "playbackOptions",
  topControls: "topControls",
  bottomControls: "bottomControls",
  engagementFeatures: "engagementFeatures",
  accessibilitySettings: "accessibilitySettings",
  playerStyling: "playerStyling",
};
