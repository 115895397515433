import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class LiveDetailScreen extends Component {
	render() {
		return (
			<>
				<div>This is a LiveDetailScreen</div>
			</>
		);
	}
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveDetailScreen);
