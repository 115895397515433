import React, { useEffect, useState } from 'react';
import Layout from './components/Layout';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
	reportsSummary,
	histogramsReportsSummary,
	reportsMilestones,
	getThumb,
} from './../../services/analyticsServices';
import SectionLayout from './components/SectionLayout';
import TopMetrics from './components/TopMetrics';
import VideoPerformance from './components/VideoPerformance';
import RankingVideoPerformance from './components/RankingVideoPerformance';
import FilterByDates from './components/FilterByDates';
import image_nothumbnail from '../../assets/images/image_nothumbnail.svg';
import { _getBucket } from '../../utils/commonUtil';

const AnalyticsVideoPerformanceScreen = () => {
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [reportsSummaryData, setReportsSummaryData] = useState([]);
	const [histoReportsSummaryData, setHistoReportsSummaryData] = useState([]);
	const [reportsMilestonesData, setReportsMilestonesData] = useState([]);
	const [rankingTableData, setRankingTableData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [vLoading, setVLoading] = useState(false);

	const [isShowErrorMessage, setShowErrorMessage] = useState(true);

	const getRankVideoPreformanceData = async (queries, limit = 200, offset = 0) => {
		const qAndBucket = `${queries}&bucket=media.id 0.01:00:00&limit=${limit}&offset=${offset}`;
		setVLoading(true);
		const VPranking = await histogramsReportsSummary(accountId, qAndBucket);
		let dataThumbs;
		if (VPranking && Object.keys(VPranking).length !== 0) {
			let ids = [];
			for (let data of VPranking?.items) {
				ids.push(`"${data?.item.id}"`);
			}
			ids.length > 0 && (dataThumbs = await getThumb(accountId, ids));
		}

		let newData = [];
		VPranking &&
			Object.keys(VPranking).length !== 0 &&
			VPranking.items.map((data, i) => {
				newData.push({
					key: data.key,
					thumb: image_nothumbnail,
					title: data.item.title,
					totalView: data.item.views.sum.value,
					uniqueView: data.item.uniqueViews.sum.value,
					completes: data.item.completes.sum.value,
					timeWatched: data.item.timeWatched?.sum?.value,
					engagement: data.item.percentageWatched.average.value,
					popularity: data.item.popularity?.rating?.value,
					bitrate: data.item.bitrate?.average?.value,
				});

				dataThumbs &&
					dataThumbs.forEach((dataThumb) => {
						if (data.item.id === dataThumb.id) {
							const renditions = dataThumb?.asset?.resources[0]?.renditions;
							const mimeType = renditions && renditions[0]?.links[0]?.mimeType;
							const href = renditions && renditions[0].links[0].href;
							newData[i].thumb =
								href && mimeType && mimeType.includes('image') ? href : image_nothumbnail;
						}
					});
			});
		if (newData.length > 0) {
			setRankingTableData(newData);
			setVLoading(false);
		}
	};

	const getReportsSummaryData = async (queries, queryBucket) => {
		setLoading(true);
		let [Rsummary, HRsummaryms, Rmilestones] = await Promise.all([
			reportsSummary(accountId, queries),
			histogramsReportsSummary(accountId, queryBucket),
			reportsMilestones(accountId, queryBucket),
		]);

		setReportsSummaryData(Rsummary);
		setHistoReportsSummaryData(HRsummaryms);
		setReportsMilestonesData(Rmilestones);
		if (Rmilestones) {
			setLoading(false);
		}
	};

	const getFilterData = (startDate = moment().subtract(7, 'days').valueOf(), endDate = moment().valueOf()) => {
		const queries =
			'q=started:[' +
			moment(startDate).startOf('day').format() +
			' TO ' +
			moment(new Date(endDate)).endOf('day').format() +
			']';
		const queryRep = queries.replace(/\+/g, '%2B');
		getRankVideoPreformanceData(queryRep);
		let queryBucket = `${queryRep}${_getBucket(startDate, endDate)}`;
		getReportsSummaryData(queryRep, queryBucket);
	};

	useEffect(() => {
		getFilterData();
	}, [accountId]);

	const breadC = {
		subTitle: 'All Medias',
		text: 'Video Performance',
		link: null,
	};

	return (
		<>
			<Layout
				title="ANALYTICS_TITLE_PLAY_ANALYTICS"
				breadCrumb={breadC}
				isShowErrorMessage={isShowErrorMessage}
				setShowErrorMessage={setShowErrorMessage}
			>
				<FilterByDates getFilterData={getFilterData} playSec={true} />
				<SectionLayout title="ANALYTICS_TITLE_TOP_METRICS" addClass="topMetrics" loading={loading}>
					<TopMetrics reportsSummaryData={reportsSummaryData} />
				</SectionLayout>
				<SectionLayout title="ANALYTICS_TITLE_VIDEO_PERFORMANCE" addClass="videoPerformance" loading={loading}>
					<VideoPerformance
						histoReportsSummaryData={histoReportsSummaryData}
						reportsMilestonesData={reportsMilestonesData}
					/>
				</SectionLayout>
				<SectionLayout
					title="ANALYTICS_TITLE_RANKING_VIDEO_PERFORMANCE"
					addClass="rankingVideoPerformance"
					loading={vLoading}
				>
					<RankingVideoPerformance data={rankingTableData} />
				</SectionLayout>
			</Layout>
		</>
	);
};

export default AnalyticsVideoPerformanceScreen;
