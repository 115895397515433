import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, IconButton } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import { joinClassNames } from '../../services/elementHelperService';
import { customScreenType } from '../../constants/mediaConstants';

import { DashboardUtils } from './DashboardUtils';
import './IntroducingSlider.scss';

interface IntroducingSliderProps {
	onActionClick?: (_actionId: string) => void;
	classNames?: string;
	permissions?: any;
}
const sliderBaseClassName = 'slider';

const IntroducingSlider = ({ onActionClick, classNames, permissions }: IntroducingSliderProps) => {
	const { t } = useTranslation();
	const classes = DashboardUtils.useButtonCustomStyle();

	const { screenType } = useSelector((state) => (state as any).publish);
	const [showSlideIndex, setShowSlideIndex] = useState<number>(0);

	const isLargeScreen = useMemo(
		() => screenType === customScreenType.medium || screenType === customScreenType.large,
		[screenType]
	);

	return (
		<div className={joinClassNames(`${sliderBaseClassName}`, classNames)}>
			<ButtonGroup className={joinClassNames(`${sliderBaseClassName}__action`, classes.customButtonGroup)}>
				<IconButton
					title={t('PREVIOUS')}
					className={classes.customButton}
					onClick={() =>
						setShowSlideIndex((prevIndex) =>
							prevIndex === 0 ? DashboardUtils.INTRODUCING_SLIDES.length - 1 : prevIndex - 1
						)
					}
				>
					<NavigateBefore />
				</IconButton>
				<IconButton
					title={t('NEXT')}
					className={classes.customButton}
					onClick={() =>
						setShowSlideIndex((prevIndex) =>
							prevIndex === DashboardUtils.INTRODUCING_SLIDES.length - 1 ? 0 : prevIndex + 1
						)
					}
				>
					<NavigateNext />
				</IconButton>
			</ButtonGroup>
			{DashboardUtils.INTRODUCING_SLIDES.map((slide, index) => {
				if (index !== showSlideIndex) {
					return null;
				}
				return (
					<div key={slide.id} className={`${sliderBaseClassName}__item`}>
						<div className={`${sliderBaseClassName}__item--left`}>
							<div className={`${sliderBaseClassName}__item-title`}>{slide.header}</div>
							<div className={`${sliderBaseClassName}__item-text`}>{slide.content}</div>
							<Button variant="contained" color="primary" onClick={() => onActionClick?.(slide.id)}>
								{permissions[slide.id] ? slide.buttonLabel : 'Get access'}
							</Button>
						</div>
						{isLargeScreen && (
							<div
								className={`${sliderBaseClassName}__item--right`}
								style={{ backgroundColor: slide.graphicColor }}
							>
								{slide.graphic}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default IntroducingSlider;
