import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { getLastIndexCatalogId } from '../../../../services/locationPathServices';
import CatalogTree from '../../../../reusable/CatalogTree/CatalogTree';
import { showMessage } from '../../../../actions/globalActions';
import { NOCATALOG, mediaNames, mediaTypes, messageTypes } from '../../../../constants/mediaConstants';
import {
	assignMediaItemToTheSelectedCatalog,
	deleteMediaItemFromCatalog,
	getMediaQueryDetails,
	refreshCatalogs,
} from '../../../../actions/publishActions';
import { getDetails } from '../../../../services/mediaDisplayService';

import './AddToCatalogDialog.scss';

interface AddToCatalogDialogProps {
	open: boolean;
	preSelectedCatalog?: string;
	mediasToAssign?: any[];
	showCreateNewCatalog?: () => void;
	onClose?: () => void;
	onAssignMediasToCatalog?: () => void;
	handleCatalogsChange?: (_catalogId: string) => void;
}

const AddToCatalogDialog: React.FC<AddToCatalogDialogProps> = ({
	preSelectedCatalog,
	open,
	mediasToAssign,
	showCreateNewCatalog,
	onClose,
	onAssignMediasToCatalog,
	handleCatalogsChange,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const { defaultAccountId } = useSelector((state) => (state as any).session);

	const [selectedCatalog, setSelectedCatalog] = useState(preSelectedCatalog ?? getLastIndexCatalogId());

	const onCatalogsChange = (id: string) => {
		setSelectedCatalog(id);
		handleCatalogsChange?.(id);
	};

	const handleAssignMediasToCatalog = () => {
		if (!mediasToAssign || mediasToAssign.length === 0) {
			return;
		}
		mediasToAssign.map((media: any) => {
			if (media.type === mediaTypes.catalog && media.id === selectedCatalog) {
				dispatch(showMessage(t('CANNOT_ASSIGN_TO_SAME_CATALOG'), messageTypes.error));
			} else {
				assignItemToCatalog(selectedCatalog, media);
			}
		});
	};
	const assignItemToCatalog = (catalogId: string, mediaDetails: any) => {
		const isCatalog = mediaDetails?.type == mediaTypes.catalog;
		const mediaId = mediaDetails.id;
		if (catalogId && catalogId !== NOCATALOG) {
			dispatch(assignMediaItemToTheSelectedCatalog(defaultAccountId, catalogId, mediaId, isCatalog)).then(
				(data: any) => {
					if (!data) {
						dispatch(showMessage(t('FAILED_TO_ASSIGN_TO_CATALOG'), messageTypes.error));
						return;
					} else {
						dispatch(showMessage(t('LABEL_ASSIGN_TO_CATALOG_SUCCESS'), messageTypes.success));
						dispatch(refreshCatalogs());
						onAssignMediasToCatalog?.();
					}
				}
			);
		} else if (catalogId === NOCATALOG) {
			removeMediaFromCatalog(mediaDetails, isCatalog);
		}
	};

	const removeMediaFromCatalog = (mediaDetails: any, isCatalog = false) => {
		const mediaId = mediaDetails.id;
		dispatch(
			getMediaQueryDetails(defaultAccountId, mediaId, isCatalog ? mediaNames.catalogs : mediaNames.medias)
		).then((data: any) => {
			if (!data) {
				dispatch(showMessage(t('FAILED_FETCH_MEDIA_DETAILS'), messageTypes.error));
				return;
			}

			const media = (getDetails(data) ?? {}) as any;
			dispatch(deleteMediaItemFromCatalog(defaultAccountId, media.parentId, media.id, isCatalog)).then(
				(data: any) => {
					if (!data) {
						dispatch(showMessage(t('FAILED_TO_REMOVE_FROM_CATALOG'), messageTypes.error));
						return;
					}
					dispatch(showMessage(t('REMOVED_FROM_CATALOG'), messageTypes.error));
					dispatch(refreshCatalogs());
					onAssignMediasToCatalog?.();
				}
			);
		});
	};

	useEffect(() => {
		setSelectedCatalog(preSelectedCatalog ?? getLastIndexCatalogId());
	}, [preSelectedCatalog]);

	return (
		<>
			<Dialog fullWidth={true} maxWidth={'sm'} id="addToCatalogDialog" open={open}>
				<DialogTitle className="dialogTitle" disableTypography>
					<Typography variant="h6">{t('LABEL_ADD_TO_CATALOG')}</Typography>
					<IconButton onClick={onClose}>
						<Close />
					</IconButton>
				</DialogTitle>
				<div>
					<div className="dialogContentWrapper fullWidth emptyMarginTop">
						<div className="dialogBoxInternalBlock">
							<div className="labelWrapperCatalog">
								<h4>{t('LABEL_CHOOSE_CATALOG')}</h4>
							</div>
							<div className="allCatalogsContainer">
								<CatalogTree
									showNoCatalogOption
									handleCatalogsChange={onCatalogsChange}
									preselectedCatalog={selectedCatalog}
								/>
							</div>
						</div>
					</div>
					<a className="hide" onClick={showCreateNewCatalog}>
						{t('LABEL_NEW_CATALOG_HEADER')}
					</a>
				</div>
				<DialogActions>
					<div className="dialogActionsWrapper">
						<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
							<Button
								variant="contained"
								className="defaultActionBtn"
								disabled={selectedCatalog ? false : true}
								onClick={handleAssignMediasToCatalog}
							>
								{t('LABEL_ADD_TO_CATALOG')}
							</Button>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AddToCatalogDialog;
