import React from 'react';
import Image from '@material-ui/icons/Image';
import CropLandscape from '@material-ui/icons/CropLandscape';
import StarIcon from '@material-ui/icons/Star';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export const PLAYER_IFRAME_ID = 'scene-editor-preview-player';

export const POST_MESSAGE_TYPE = {
	scenario: 'updateScenario',
	scene: 'updateScene',
};

export const ACTION_GO_TO_SCENE = {
	label: 'Go to scene',
	value: 'goToScene',
};

export const ACTION_LOOP_CURRENT_SCENE = {
	label: 'Loop current scene',
	value: 'loopCurrentScene',
};

export const ACTION_GO_TO_HOME = {
	label: 'Go to home',
	value: 'goToHome',
};

export const ACTION_GO_TO_URL = {
	label: 'Go to URL',
	value: 'goToUrl',
};

export const ACTION_JUMP_TO_TIME = {
	label: 'Jump to time',
	value: 'jumpToTime',
};

export const ACTION_PLAY_PAUSE_SCENE = {
	label: 'Play/Pause scene',
	value: 'playPauseScene',
};

export const ACTION_PAUSE = {
	label: 'Pause',
	value: 'pause',
};

export const sceneActions = [ACTION_GO_TO_SCENE, ACTION_LOOP_CURRENT_SCENE, ACTION_GO_TO_HOME];

export const widgetClickActions = [ACTION_GO_TO_SCENE, ACTION_GO_TO_URL, ACTION_JUMP_TO_TIME];
export const widgetEntryActions = [ACTION_PAUSE, ACTION_JUMP_TO_TIME];
export const widgetExitActions = [ACTION_PAUSE, ACTION_JUMP_TO_TIME];

export const WIDGET_EVENT_ENTRY = 'onEntry';
export const WIDGET_EVENT_CLICK = 'onClick';
export const WIDGET_EVENT_EXIT = 'onExit';

export const WIDGET_EVENTS = {
	[WIDGET_EVENT_CLICK]: {
		actions: widgetClickActions,
		icon: <OpenInNewIcon htmlColor="#CDD4D9" />,
		label: 'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_ACTION_ON_CLICK',
		rank: 0,
	},
	[WIDGET_EVENT_ENTRY]: {
		actions: widgetEntryActions,
		icon: <ArrowForwardIcon htmlColor="#CDD4D9" />,
		label: 'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_ACTION_ON_ENTRY',
		rank: 1,
	},
};

export const TRANSITION_NONE = {
	label: 'None',
	value: 'none',
};

export const TRANSITION_FADE_IN = {
	label: 'Fade in',
	value: 'fade-in',
};

export const TRANSITION_FADE_OUT = {
	label: 'Fade out',
	value: 'fade-out',
};

export const sceneTransitionInEffects = [TRANSITION_NONE, TRANSITION_FADE_IN];

export const sceneTransitionOutEffects = [TRANSITION_NONE, TRANSITION_FADE_OUT];

export const positions = [
	{
		value: 'center',
		label: 'Center',
	},
	{
		value: 'top-left',
		label: 'Top left',
	},
	{
		value: 'left',
		label: 'Left',
	},
	{
		value: 'bottom-left',
		label: 'Bottom left',
	},
	{
		value: 'middle-bottom',
		label: 'Middle bottom',
	},
	{
		value: 'bottom-right',
		label: 'Bottom right',
	},
	{
		value: 'right',
		label: 'Right',
	},
	{
		value: 'top-right',
		label: 'Top right',
	},
	{
		value: 'middle-top',
		label: 'Middle top',
	},
];

export const TABS = ['General', 'Edit (Main menu)'];

export const transformKeys = { paddingTop: 'top', paddingRight: 'right', paddingBottom: 'bottom', paddingLeft: 'left' };

export const BorderRadiusKeys = {
	borderRadiusTop: 'top right',
	borderRadiusRight: 'bottom right',
	borderRadiusBottom: 'bottom left',
	borderRadiusLeft: 'top left',
};

export const TextRadiusKeys = {
	radiusTop: 'top right',
	radiusRight: 'bottom right',
	radiusBottom: 'bottom left',
	radiusLeft: 'top left',
};

export const DEFAULT_LAYOUT_TEMPLATE = {
	id: '0001',
	boxes: [
		{
			id: '1',
			name: 'Box 1',
			isBackground: true,
		},
	],
};

export const DEFAULT_LAYOUT = {
	layoutTemplateId: '0001',
	boxes: [
		{
			boxTemplateId: '1',
			settings: { transition: 'fade-in' },
		},
	],
};

export const DEFAULT_SCENE_DATA = {
	transitionIn: 'fade-in',
	duration: {
		enabled: false,
		value: undefined,
	},
};

export const DEFAULT_WIDGETS_DATA = {
	events: [],
	style: {
		fill: 'fit-box',
		position: 'center',
		textLabel: {
			font: 'Poppins',
			fontSize: '12',
			fontWeight: 'normal',
			color: '#126cfc',
			backgroundColor: 'transparent',
			hoverFontWeight: 'normal',
			hoverColor: '#126cfc',
			hoverBackgroundColor: 'transparent',
		},
		backgroundSettings: {
			backgroundColor: 'transparent',
			hoverBackgroundColor: 'transparent',
		},
		transformSettings: {
			borderRadiusTop: '0',
			borderRadiusBottom: '0',
			borderRadiusRight: '0',
			borderRadiusLeft: '0',
			radiusTop: '0',
			radiusBottom: '0',
			radiusRight: '0',
			radiusLeft: '0',
			paddingTop: '0',
			paddingBottom: '0',
			paddingRight: '0',
			paddingLeft: '0',
			hoverRadiusTop: '0',
			hoverRadiusBottom: '0',
			hoverRadiusRight: '0',
			hoverRadiusLeft: '0',
			hoverPaddingTop: '0',
			hoverPaddingBottom: '0',
			hoverPaddingRight: '0',
			hoverPaddingLeft: '0',
		},
	},
};

export const WIDGET_TYPE_VIDEO = 'video';
export const WIDGET_TYPE_IMAGE = 'image';
export const WIDGET_TYPE_BUTTON = 'button';
export const WIDGET_TYPE_ICON = 'icon';
export const WIDGET_TYPE_TEXT = 'text';

export const WIDGET_TYPES = {
	WIDGET_TYPE_VIDEO,
	WIDGET_TYPE_IMAGE,
	WIDGET_TYPE_BUTTON,
	WIDGET_TYPE_ICON,
	WIDGET_TYPE_TEXT,
};

export const WIDGETS_TYPE_LIST = [
	{ type: WIDGET_TYPE_ICON, icon: <StarIcon /> },
	{
		type: WIDGET_TYPE_IMAGE,
		icon: <Image />,
	},
	{
		type: WIDGET_TYPE_BUTTON,
		icon: <CropLandscape />,
	},
	{
		type: WIDGET_TYPE_TEXT,
		icon: <FormatShapesIcon />,
	},
];

export const WIDGET_SHORT_NAME = {
	[WIDGET_TYPE_IMAGE]: 'img',
	[WIDGET_TYPE_BUTTON]: 'btn',
	[WIDGET_TYPE_ICON]: 'icon',
	[WIDGET_TYPE_TEXT]: 'text',
};
