import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Close } from '@material-ui/icons';
import { IconButton, Typography } from '@material-ui/core';

import { mediaActionKeys, mediaNames, mediaTypes, messageTypes } from '../../constants/mediaConstants';
import { showMessage } from '../../actions/globalActions';
import { deleteSelectedMediaItem } from '../../actions/publishActions';

interface DeleteMediaDialogProps {
	open: boolean;
	deletingMedias: any[];
	deleteType?: string;
	onClose?: () => void;
	handleDeleteItems?: () => void;
}

export const getTheMediaItemTitle = (mediaItem: any) => {
	if (mediaItem && mediaItem.metadata && mediaItem.metadata.title) {
		return mediaItem.metadata.title;
	} else if (mediaItem) {
		return mediaItem.title;
	}
};

const DeleteMediaDialog: React.FC<DeleteMediaDialogProps> = ({
	open,
	deletingMedias,
	deleteType,
	onClose,
	handleDeleteItems,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;
	const { defaultAccountId } = useSelector((state) => (state as any).session);

	const onDeleteItems = () => {
		if (deleteType === mediaActionKeys.emptyBin) {
			// TODO: Should call DELETE \bin
			dispatch(showMessage('Teehee~ feature is coming', messageTypes.info));
			handleDeleteItems?.();
			return;
		}

		let count = 0;
		let failedDeletion = 0;

		deletingMedias.map((item: any) => {
			deleteItem(item, (data: any) => {
				count++;
				if (!data || data.message) {
					failedDeletion++;
				}

				if (count === deletingMedias.length) {
					handleDeleteItems?.();
					if (failedDeletion > 0) {
						dispatch(showMessage(t('DELETION_FAILED') + ` ${failedDeletion} media(s)`, messageTypes.error));
					}
					if (count > failedDeletion) {
						dispatch(
							showMessage(
								t('DELETED_SUCCESSFULLY') + ` ${count - failedDeletion} media(s)`,
								messageTypes.info
							)
						);
					}
				}
			});
		});
		handleDeleteItems?.();
	};

	const deleteItem = (eachMedia: any, callback: (_data: any) => void) => {
		let mediaName = mediaNames.medias;
		if (eachMedia && eachMedia.type === mediaTypes.catalog) {
			mediaName = mediaNames.catalogs;
			if (eachMedia.includedChildren > 0 && !eachMedia.hasChildren) {
				dispatch(showMessage(t('CATALOG_WITH_MEDIAS_CANNOT_DELETED'), messageTypes.error));
				return;
			} else if (eachMedia.includedChildren == 0 && eachMedia.hasChildren) {
				dispatch(showMessage(t('CATALOG_WITH_SUBCATALOG_CANNOT_DELETED'), messageTypes.error));
				return;
			} else if (eachMedia.includedChildren > 0 && eachMedia.hasChildren) {
				dispatch(showMessage(t('CATALOG_WITH_MEDIAS_SUBCATALOG_CANNOT_DELETED'), messageTypes.error));
				return;
			}
		}
		if (eachMedia.type === mediaTypes.playlists) {
			mediaName = mediaNames.playlists;
		}
		if (eachMedia.type === mediaTypes.scenario) {
			mediaName = mediaNames.scenarios;
		}

		if (eachMedia) {
			let assetId = '';
			if (eachMedia && eachMedia.type === mediaTypes.catalog) {
				//not trying to delete catalog for now
				assetId = '';
			} else {
				assetId = eachMedia.asset ? eachMedia.asset.id : '';
			}

			dispatch(deleteSelectedMediaItem(eachMedia.id, defaultAccountId, mediaName, assetId)).then((data: any) => {
				callback?.(data);
			});
		}
	};

	const deleteTitle = useMemo(() => {
		let translateKey = 'DELETE_MEDIAS';
		if (deletingMedias.length === 0) {
			return t(translateKey);
		}

		if (deletingMedias.every((m) => m.type === mediaTypes.playlists)) {
			translateKey = 'DELETE_PLAYLISTS';
		} else if (deletingMedias.every((m) => m.type === mediaTypes.catalog)) {
			translateKey = 'DELETE_CATALOG';
		} else if (deletingMedias.every((m) => m.type === mediaTypes.scenario)) {
			translateKey = 'DELETE_INTERACT';
		}

		return t(translateKey);
	}, [deletingMedias, t]);

	return (
		<Dialog
			fullWidth
			maxWidth={'sm'}
			open={open}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className="confirmationPopup"
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">
					{deleteType === mediaActionKeys.emptyBin ? t('LABEL_EMPTY_BIN') : deleteTitle + '?'}
				</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<DialogContentText className="dialogContentWrapper">
					<span className="dialogContentText">
						{t(
							deleteType === mediaActionKeys.emptyBin
								? 'EMPTY_BIN_CONFIRMATION'
								: 'DELETE_MEDIAS_CONFIRMATION'
						)}
					</span>
					{(deletingMedias ?? []).length > 0 && (
						<div className="itemsList">
							{deletingMedias.map(
								(eachItem, i) =>
									eachItem && (
										<span className="dialogContentText dialogContentText--bold" key={i}>
											{getTheMediaItemTitle(eachItem)}
										</span>
									)
							)}
						</div>
					)}
				</DialogContentText>
			</DialogContent>

			<DialogActions className="dialogActions">
				<Button variant="outlined" color="primary" style={{ whiteSpace: 'nowrap' }} onClick={onClose}>
					{t('BACK')}
				</Button>
				<Button variant="contained" color="primary" style={{ whiteSpace: 'nowrap' }} onClick={onDeleteItems}>
					{t(deleteType === mediaActionKeys.emptyBin ? 'LABEL_EMPTY_BIN' : 'DELETE')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteMediaDialog;
