import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class InteractQueueDropdown extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { item, menuAnchor, handleMenuClose } = this.props;
		return (
			<>
				<Menu
					id="interactQueueDropDownList"
					anchorEl={menuAnchor}
					onClose={handleMenuClose}
					open={Boolean(menuAnchor)}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				>
					<MenuItem className="listingMenuDropDown" key="1" onClick={() => handleMenuClose('edit', item)}>
						Edit
					</MenuItem>
					<MenuItem className="listingMenuDropDown" key="2" onClick={() => handleMenuClose('delete', item)}>
						Remove
					</MenuItem>
				</Menu>
			</>
		);
	}
}

export default InteractQueueDropdown;
