import React, { useCallback, useRef, useState } from 'react';
import { Close, MoreHoriz } from '@material-ui/icons';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { getFormattedTimeWithMilliseconds } from '../../../../services/timeStampService';
import { joinClassNames } from '../../../../services/elementHelperService';

import './ChatContainer.scss';
import Icons from '../../../../reusable/IconsStore/Icons';

export namespace ChatContainerTemplate {
	export const chatBaseClassName = 'chat-container';
	export interface ChatContainerProps {
		mediaId: string;
		settings: any;
		onShowCreatePoll?: () => void;
	}
	export interface Message {
		id: string;
		message: { text: string; pinned?: boolean };
		sender: { name: string; admin?: boolean; userId: string };
		recipient: { replyMessageId?: string };
		created: string;
		blocked?: boolean;
	}

	export interface ChatMessageProps {
		message: Message;
		repliedMessage?: Message;
		// eslint-disable-next-line no-unused-vars
		onReply?: (messageId: string) => void;
		// eslint-disable-next-line no-unused-vars
		onBlockMessage?: (message: Message) => void;
		// eslint-disable-next-line no-unused-vars
		onBlockUser?: (message: Message, block?: boolean) => void;
		// eslint-disable-next-line no-unused-vars
		onTogglePinnedMessage?: (messageId: string, pinned?: boolean) => void;
		pinned?: boolean;
		replyingMessage?: boolean;
		blockedUserIds?: string[];
		pinedMessageId?: string;
	}

	export const ChatMessage: React.FC<ChatMessageProps> = ({
		message,
		repliedMessage,
		onReply,
		onBlockMessage,
		onTogglePinnedMessage,
		pinned,
		replyingMessage,
		onBlockUser,
		blockedUserIds = [],
		pinedMessageId,
	}) => {
		const { t } = useTranslation();
		const actionButtonRef = useRef<HTMLButtonElement | null>(null);
		const messageContainerRef = useRef<HTMLDivElement | null>(null);
		const [openActionMenu, setOpenActionMenu] = useState<boolean>(false);

		let foundBlockedUser = _.find(blockedUserIds, (user) => user === message.sender.userId);

		const onReplyClick = useCallback(() => {
			onReply?.(message.id);
			setOpenActionMenu(false);
		}, [message.id, onReply]);

		const onBlockMessageClick = useCallback(() => {
			onBlockMessage?.(message);
			setOpenActionMenu(false);
		}, [message, onBlockMessage]);

		const onBlockUserClick = useCallback(() => {
			onBlockUser?.(message, !foundBlockedUser);
			setOpenActionMenu(false);
		}, [foundBlockedUser, message, onBlockUser]);

		const onPinMessageClick = useCallback(() => {
			onTogglePinnedMessage?.(message.id, !message.message?.pinned);
			setOpenActionMenu(false);
		}, [message.id, message.message?.pinned, onTogglePinnedMessage]);

		return (
			<>
				<div
					ref={(ref) => (messageContainerRef.current = ref)}
					className={joinClassNames(
						`${chatBaseClassName}__message-container`,
						pinned || replyingMessage ? `${chatBaseClassName}__message--pinned` : '',
						message.sender?.admin ? `${chatBaseClassName}__message--admin` : ''
					)}
					key={message.id}
				>
					<div className={joinClassNames(`${chatBaseClassName}__message`)}>
						<div className={`${chatBaseClassName}__message-title`}>
							<div>
								{pinned && <Icons.PinIcon />}
								<span className={`${chatBaseClassName}__message-sender`}>{message.sender?.name}</span>
								<span className={`${chatBaseClassName}__message-time`}>
									{getFormattedTimeWithMilliseconds(message.created, 'HH:mm:ss')}
								</span>
							</div>

							{pinned && (
								<IconButton
									className={`${chatBaseClassName}__message-more-btn`}
									size="small"
									onClick={onPinMessageClick}
									title="Unpin message"
								>
									<Close />
								</IconButton>
							)}

							{!pinned && (
								<IconButton
									className={`${chatBaseClassName}__message-more-btn`}
									size="small"
									ref={(ref) => (actionButtonRef.current = ref)}
									onClick={() => setOpenActionMenu((prev) => !prev)}
									title="Open action menu"
								>
									<MoreHoriz />
								</IconButton>
							)}
						</div>

						{repliedMessage && (
							<div className={`${chatBaseClassName}__message-quote`}>{repliedMessage.message.text}</div>
						)}
						<div
							className={`${chatBaseClassName}__message-content`}
							title={pinned ? message.message.text : undefined}
						>
							{message.message.text}
						</div>
					</div>
				</div>

				<Menu
					open={openActionMenu}
					anchorEl={actionButtonRef.current}
					onClose={() => setOpenActionMenu(false)}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				>
					<MenuItem onClick={onReplyClick}>{t('REPLY')}</MenuItem>
					<MenuItem onClick={onBlockUserClick}>
						{foundBlockedUser ? t('UNBLOCK_USER') : t('BLOCK_USER')}
					</MenuItem>
					<MenuItem onClick={onBlockMessageClick}>{t('BLOCK_MESSAGE')}</MenuItem>
					<MenuItem onClick={onPinMessageClick}>
						{pinedMessageId && message.id === pinedMessageId ? t('UNPIN_MESSAGE') : t('PIN_MESSAGE')}
					</MenuItem>
				</Menu>
			</>
		);
	};
}
