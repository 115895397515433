import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import ChipInput from 'material-ui-chip-input';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'underscore';

import TimeInput from '../../../../reusable/TimeInput/TimeInput';
import ButtonFileChoose from '../../InteractPropertiesComponent/ButtonFileChoose';
import InteractActionProperties from '../../InteractPropertiesComponent/InteractActionProperties';
import DynamicObjectCreation from '../../InteractPropertiesComponent/DynamicObjectCreation';
import FontTypography from '../../InteractPropertiesComponent/FontTypography';
import InteractMediaControls from '../../InteractPropertiesComponent/InteractMediaControls';
import ColorPicker from '../../../../reusable/ColorPicker/ColorPicker';
import {
	getFormattedTimeWithMilliseconds,
	getTimelineTimeFromTimeInput,
	getTimelineTimeFromSeconds,
	getSecondsFromTimelineTime,
	getTime,
} from '../../../../services/timeStampService';
import { showNotification } from '../../../../actions/notificationActions';
import { mediaTypes, UPLOADING_FILE, UPLOAD_FAILED } from '../../../../constants/mediaConstants';
import { checkIfItemIsOverlapping, isTimeBeyondRange } from '../../../../services/interactScreenHelperService';
import {
	updateInteractTimelineItem,
	setSelectedInteractTimelineItem,
	hideTrimTool,
} from '../../../../actions/interactTimelineActions';
import {
	showSelectedSubPropertyKey,
	hideSelectedSubPropertyKey,
	setInteractItemsToDelete,
	showInteractDeleteDialog,
	setElementPropertiesError,
	resetElementPropertiesError,
} from '../../../../actions/interactElementsActions';
import { interactAnimationsList, interactSettingsType, sequenceTypes } from '../../../../constants/interactConstant';
import IconsStore from '../../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../../reusable/IconsStore/IconsList';
import InteractSaveProject from './../../InteractSaveProject';
import {
	getMediaQueryDetails,
	startThumbImageUploadProcess,
	getAllCurrentJobs,
	setUploadingProcessActive,
} from '../../../../actions/publishActions';
import { generateId } from '../../../../services/stringHelperService';
import { setIsElementUpdating } from '../../../../actions/interactElementsActions';
import { setInteractButtonImageUploaded } from '../../../../actions/inspectScreenActions';
import { mediaNames, messageTypes } from '../../../../constants/mediaConstants';
import { showAlert } from '../../../../actions/globalActions';

import './InteractElementProperties.scss';

class InteractElementProperties extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			priceKey: '',
			priceParentKey: '',
			priceFieldObject: {},
			priceValue: '0',
			priceCurrency: 'EUR',
			variantParentKey: '',
			variantName1: '',
			variantName2: '',
			variantValues1: [],
			variantValues1Obj: {},
			variantValues2: [],
			variantValues2Obj: {},
			variantCombinationList: [],
			isVariantComboCheckboxHeaderChecked: false,
			hideVariantNameInputs: false,
			checkedVariants: [],
			errorMessage: '',
			startTimeValue: '',
			endTimeValue: '',
			startError: '',
			endError: '',
			showloading: false,
		};
		//used only to handle fonttypography click outside its component
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.wrapperRef = null;
		this.interactSaveRef = null;
		//fonttypography click outside end
	}

	UNSAFE_componentWillMount = () => {
		this.props.hideSelectedSubPropertyKey();
		document.addEventListener('mousedown', this.handleClickOutside);
		let item = this.getCurrentlyEditedItem();

		if (item.templateName === 'Product') {
			this.setState({
				variantValues1:
					item.settings.button.variantValues1.default === undefined
						? []
						: item.settings.button.variantValues1.default,
				variantValues2:
					item.settings.button.variantValues2.default === undefined
						? []
						: item.settings.button.variantValues2.default,
				variantName1: item.settings.button.variantName1.default,
				variantName2: item.settings.button.variantName2.default,
				variantCombinationList: item.settings.button.variantCombinations.default,
				hideVariantNameInputs:
					item.settings.button.variantCombinations.default !== undefined &&
					item.settings.button.variantCombinations.default.length > 0
						? true
						: false,
			});
		}
	};

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	componentDidMount = () => {
		this.setTimeValue();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.startError !== this.state.startError || prevState.endError !== this.state.endError) {
			if (this.state.startError || this.state.endError) {
				this.props.setElementPropertiesError();
			} else {
				this.props.resetElementPropertiesError();
			}
		}

		if (prevState.priceValue !== this.state.priceValue || prevState.priceCurrency !== this.state.priceCurrency) {
			let tempCurrency;

			if (this.state.priceCurrency === undefined) {
				tempCurrency = 'EUR';
			} else {
				tempCurrency = this.state.priceCurrency;
			}
			let valueToPass = this.state.priceValue + ' ' + tempCurrency;

			this.handleChange(valueToPass, this.state.priceParentKey, this.state.priceKey, this.state.priceFieldObject);
		}

		if (prevState.variantValues1 !== this.state.variantValues1) {
			this.handleChange(
				this.state.variantValues1,
				this.state.variantParentKey,
				'variantValues1',
				this.state.variantValues1Obj
			);
		}

		if (prevState.variantValues2 !== this.state.variantValues2) {
			this.handleChange(
				this.state.variantValues2,
				this.state.variantParentKey,
				'variantValues2',
				this.state.variantValues2Obj
			);
		}

		if (prevState.variantCombinationList !== this.state.variantCombinationList) {
			let tempObj = {
				name: 'Variant Combinations',
				type: 'variant.combinations',
				default: this.state.variantCombinationList,
			};

			this.handleChange(
				this.state.variantCombinationList,
				this.state.variantParentKey,
				'variantCombinations',
				tempObj
			);
		}

		if (prevState.isVariantComboCheckboxHeaderChecked !== this.state.isVariantComboCheckboxHeaderChecked) {
			if (this.state.isVariantComboCheckboxHeaderChecked) {
				let tempArray = [];
				this.state.variantCombinationList.map((item) => {
					tempArray.push(item.comboName);
				});
				this.setState({
					checkedVariants: tempArray,
				});
			} else {
				this.setState({
					checkedVariants: [],
				});
			}
		}
		if (JSON.stringify(prevProps.selectedInteractItems) !== JSON.stringify(this.props.selectedInteractItems)) {
			this.props.resetElementPropertiesError();
			this.setTimeValue();
			let item = this.getCurrentlyEditedItem();

			if (item.templateName === 'Product') {
				this.setState({
					variantValues1:
						item.settings.button.variantValues1.default === undefined
							? []
							: item.settings.button.variantValues1.default,
					variantValues2:
						item.settings.button.variantValues2.default === undefined
							? []
							: item.settings.button.variantValues2.default,
					variantName1: item.settings.button.variantName1.default,
					variantName2: item.settings.button.variantName2.default,
					variantCombinationList: item.settings.button.variantCombinations.default,
					hideVariantNameInputs:
						item.settings.button.variantCombinations.default !== undefined &&
						item.settings.button.variantCombinations.default.length > 0
							? true
							: false,
				});
			}
		}

		if (
			JSON.stringify(prevProps.interactItems) !== JSON.stringify(this.props.interactItems) ||
			prevProps.updateSelectedElement !== this.props.updateSelectedElement
		) {
			let item = this.getCurrentlyEditedItem();
			if (item.start !== this.state.startTimeValue || item.end !== this.state.endTimeValue) {
				this.setTimeValue();
			}
		}
	}

	setTimeValue = () => {
		if (this.props.selectedInteractItems && this.props.selectedInteractItems.length > 0) {
			let item = _.find(this.props.interactItems, (item) => {
				return item.id === this.props.selectedInteractItems[0];
			});
			if (item) {
				this.setState({
					startTimeValue: item.start,
					endTimeValue: item.end,
					startError: '',
					endError: '',
					errorMessage: '',
				});
			}
		}
	};

	/**
	 * Set the wrapper ref
	 */
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	onfontTypographyLoaded = (fontTypo) => {
		this.fontTypography = fontTypo;
	};

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.fontTypography) {
			this.fontTypography.handleCancelTypography();
		}
	}

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return '';
	};

	isInvalidTime = (startTime, endTime, refItem) => {
		const { t } = this.props;
		let item = JSON.parse(JSON.stringify(refItem));
		item.start = startTime;
		item.end = endTime;

		if (startTime > endTime) {
			this.setState({
				errorMessage: t('START_TIME_GREATER_END_TIME'),
				startError: true,
				endError: true,
			});
			return true;
		}

		if (checkIfItemIsOverlapping(this, item, 'noMessage')) {
			this.setState({
				errorMessage: t('TIME_IS_OVERLAPPING_GROUP'),
				startError: true,
				endError: true,
			});
			return true;
		}

		if (isTimeBeyondRange(this, item, 'noMessgae')) {
			this.setState({
				errorMessage: t('TIME_IS_OUT_OF_RANGE'),
				startError: true,
				endError: true,
			});
			return true;
		}

		this.setState({
			errorMessage: '',
			startError: '',
			endError: '',
		});
		return false;
	};

	handleStartTimeOnChange = (e) => {
		const { endTimeValue } = this.state;
		let item = this.getCurrentlyEditedItem();
		let time = getTimelineTimeFromTimeInput(e);
		let newItem = {
			...item,
		};

		this.setState(
			{
				startTimeValue: time,
			},
			() => {
				if (!this.isInvalidTime(time, endTimeValue, newItem)) {
					newItem.start = time;
					this.props.updateInteractTimelineItem(newItem, item.itemType === sequenceTypes.video);
				}
			}
		);
	};

	handleEndTimeOnChange = (e) => {
		const { startTimeValue } = this.state;
		let item = this.getCurrentlyEditedItem();
		let time = getTimelineTimeFromTimeInput(e);
		let newItem = {
			...item,
		};
		this.setState(
			{
				endTimeValue: time,
			},
			() => {
				if (!this.isInvalidTime(startTimeValue, time, newItem)) {
					newItem.end = time;
					this.props.updateInteractTimelineItem(newItem, item.itemType === sequenceTypes.video);
				}
			}
		);
	};

	renderTimeStampOfItem = (_) => {
		const { t } = this.props;
		const { startError, endError, errorMessage, startTimeValue, endTimeValue } = this.state;

		return (
			<>
				<div className="interactPropertiesSection">
					<div className="interactTime" attr={startError ? 'true' : null}>
						<label className="inlineLabel"> {t('START_TIME')}</label>
						<TimeInput
							value={getFormattedTimeWithMilliseconds(startTimeValue, 'HH:mm:ss:SSS')}
							onChange={this.handleStartTimeOnChange}
						/>
					</div>
					<div className="interactTime" attr={endError ? 'true' : null}>
						<label className="inlineLabel"> {t('END_TIME')}</label>
						<TimeInput
							value={getFormattedTimeWithMilliseconds(endTimeValue, 'HH:mm:ss:SSS')}
							onChange={this.handleEndTimeOnChange}
						/>
					</div>
					<div className="timeInputErrorMsg">
						<label> {errorMessage} </label>
					</div>
				</div>
			</>
		);
	};

	handleChangeChip = (chip, fieldObj, parentKey) => {
		if (fieldObj.type === interactSettingsType.variantValues1) {
			this.setState({
				variantValues1: chip,
				variantValues1Obj: fieldObj,
				variantParentKey: parentKey,
			});
		} else if (fieldObj.type === interactSettingsType.variantValues2) {
			this.setState({
				variantValues2: chip,
				variantValues2Obj: fieldObj,
				variantParentKey: parentKey,
			});
		}
	};

	handleChange = (e, parentKey, key, fieldObj) => {
		const { mediaDetails, t } = this.props;

		let item = this.getCurrentlyEditedItem();
		let value = '';

		let newItem = {
			...item,
		};

		let triggerUpdate = true;

		if (typeof e === 'object' && e !== null && e.target !== undefined) {
			switch (fieldObj.type) {
				case interactSettingsType.timestamp:
					value = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(e));
					if (value > mediaDetails.duration) {
						this.props.showNotification(t('TIME_IS_OUT_OF_RANGE'));
						return;
					}
					break;
				case interactSettingsType.boolean:
					value = e.target.checked;
					if (key === 'onEntry') {
						value = value ? 'pause' : 'play';
					}
					if (key === 'position') {
						if (Object.keys(item.settings).length > 5) {
							value = false;
							triggerUpdate = false;
							this.props.showMessage(t('FIXED_POSITION_MAX_ELEMENTS'), messageTypes.error);
						} else {
							if (e.target.checked) {
								let startingY = 40;
								Object.entries(newItem.settings).forEach(([_, value]) => {
									if (value.hasOwnProperty('type')) {
										value.height.default = 150;
										value.width.default = 150;
										value.x.default = 40;
										value.y.default = startingY;
										startingY = startingY + 190;
									}
								});
							}
						}
					}
					break;
				case interactSettingsType.hideVolumeCounter:
					value = e.target.checked;

					break;
				case interactSettingsType.widthCoordinate:
				case interactSettingsType.metaData:
					value = e.target.value;
					if (
						value.includes('$') ||
						value.includes('@') ||
						value.includes('#') ||
						value.includes('{') ||
						value.includes('}') ||
						value.includes('¤')
					) {
						this.props.showNotification(t('INVALID_CHARACTER'));
						return;
					}
					break;
				case interactSettingsType.description:
					value = e.target.value;
					if (
						value.includes('$') ||
						value.includes('@') ||
						value.includes('#') ||
						value.includes('{') ||
						value.includes('}') ||
						value.includes('¤')
					) {
						this.props.showNotification(t('INVALID_CHARACTER'));
						return;
					}
					break;
				case interactSettingsType.variantValues1:
					value = e;
					break;
				case interactSettingsType.variantValues2:
					value = e;
					break;
				case interactSettingsType.variantCombinations:
					value = e;
					break;
				case interactSettingsType.heightCoordinate:
				case interactSettingsType.number:
					if (e.target.value > 0) {
						value = Number(e.target.value);
					} else {
						value = 0;
					}

					break;
				case interactSettingsType.color:
					value = e.hex;
					break;
				default:
					value = e.target.value;
			}
		} else {
			if (e && e.hex && fieldObj.type === interactSettingsType.color) {
				value = e.hex;
			} else {
				value = e;
			}
		}
		if (fieldObj.name === 'Jump to time') {
			let tempValueObj = getTime(value);

			let secs =
				(Number(tempValueObj.milliseconds) +
					Number(tempValueObj.seconds) * 1000 +
					Number(tempValueObj.minutes) * 60000 +
					Number(tempValueObj.hours) * 3600000) /
				1000;

			value = secs;
		}

		if (key === 'content') {
			newItem.content = value;
		}

		if (
			newItem.settings &&
			newItem.settings.hasOwnProperty(parentKey) &&
			newItem.settings[parentKey].hasOwnProperty(key)
		) {
			if (key === 'prodName') {
				newItem.settings[parentKey]['name'] = value;
				newItem.settings[parentKey][key].default = value;
			}
			if (key === 'prodId') {
				/*  let tempValue = newItem.settings[parentKey]['action'].default
                 if (tempValue.hasOwnProperty('customEvent')){
                     let tempObj = { customEvent: { value: { "productId": value} }
                 } */
				/*  let tempObj = { customEvent: { value: value } }
                 newItem.settings[parentKey]['action'].default = tempObj */
				newItem.settings[parentKey][key].default = value;
			}
			if (key === 'variantName1' || key === 'variantName2') {
				/*  let tempValue = newItem.settings[parentKey]['action'].default
                 if (tempValue.hasOwnProperty('customEvent')){
                     let tempObj = { customEvent: { value: { "productId": value} } }

                 } */
				/*  let tempObj = { customEvent: { value: value } }
                 newItem.settings[parentKey]['action'].default = tempObj */
				newItem.settings[parentKey][key].default = value;
			}
			if (key === 'variantValues1' || key === 'variantValues2') {
				/*  let tempValue = newItem.settings[parentKey]['action'].default
                 if (tempValue.hasOwnProperty('customEvent')){
                     let tempObj = { customEvent: { value: { "productId": value} } }

                 } */
				/*  let tempObj = { customEvent: { value: value } }
                 newItem.settings[parentKey]['action'].default = tempObj */
				newItem.settings[parentKey][key].default = value;
			}
			if (key === 'name') {
				newItem.settings[parentKey][key] = value;
			} else {
				newItem.settings[parentKey][key].default = value;
			}
		}

		newItem.settings = JSON.parse(JSON.stringify(newItem.settings)); //add this code if new value is not reflecting

		if (triggerUpdate) {
			this.props.updateInteractTimelineItem(newItem);
		}
	};

	renderTextInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<div className="textFieldInputSection">
					<label> {fieldObj.name} </label>
					<input
						type="text"
						className="sixtyWidth"
						value={fieldObj.default}
						onChange={(e) => {
							this.handleChange(e, parentKey, key, fieldObj);
						}}
					/>
				</div>
			</>
		);
	};

	renderVariantNameInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<div className="variantNameFieldInputSection">
					<label> {fieldObj.name} </label>
					<input
						type="text"
						className="fullWidth"
						value={fieldObj.default}
						onChange={(e) => {
							this.handleChange(e, parentKey, key, fieldObj);
						}}
					/>
				</div>
			</>
		);
	};

	renderDescriptionInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<div className="descriptionFieldInputSection">
					<label> {fieldObj.name} </label>
					<textarea
						value={fieldObj.default}
						onChange={(e) => {
							this.handleChange(e, parentKey, key, fieldObj);
						}}
					/>
				</div>
			</>
		);
	};

	renderHideVolumeCounterInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<div className="descriptionFieldInputSection">
					<label style={{ top: '50%', transform: 'translateY(0)' }}> {fieldObj.name} </label>
					<Checkbox
						id="hideVolumeCounterInput"
						checked={fieldObj.default}
						onChange={(e) => {
							this.handleChange(e, parentKey, key, fieldObj);
						}}
						style={{ color: '#d6d6d6', padding: '0px' }}
					/>
				</div>
			</>
		);
	};

	chipRenderer = ({ chip, className, handleClick, handleDelete }, key) => (
		<Chip
			className={className}
			key={key}
			label={chip}
			style={{
				backgroundColor: '#7365e2',
				color: 'white',
				borderRadius: '2px',
				font: ' normal normal bold 12px/15px Open Sans',
				height: '18px',
				verticalAlign: 'middle',
			}}
			deleteIcon={
				<svg xmlns="http://www.w3.org/2000/svg" width={9.849} height={9.848} viewBox="0 0 9.849 9.848">
					<defs>
						<style>{'.prefix__b{fill:#fff;fill-rule:evenodd}'}</style>
					</defs>
					<g opacity={0.539}>
						<path
							className="prefix__b"
							d="M9.668.181a.615.615 0 010 .871L1.052 9.668a.616.616 0 11-.871-.867l8.62-8.62a.615.615 0 01.871 0z"
						/>
						<path
							className="prefix__b"
							d="M.181.181a.615.615 0 000 .871l8.62 8.616a.616.616 0 10.871-.871L1.052.181a.615.615 0 00-.871 0z"
						/>
					</g>
				</svg>
			}
			onClick={handleClick}
			onDelete={handleDelete}
			size="small"
		/>
	);
	renderVariantValuesInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<div className="variantValuesFieldInputSection ">
					<div className="variantValuesFieldInputSectionFirstRow">
						<label> {fieldObj.name} </label>
						{/* <textarea value={'test'} onChange={(e) => { this.handleChange(e, parentKey, key, fieldObj) }} /> */}
						<svg
							onClick={() => {
								this.handleChangeChip([], fieldObj, parentKey);
							}}
							xmlns="http://www.w3.org/2000/svg"
							width={9.849}
							height={9.848}
							viewBox="0 0 9.849 9.848"
						>
							<defs>
								<style>{'.prefix__b{fill:#fff;fill-rule:evenodd}'}</style>
							</defs>
							<g opacity={0.539}>
								<path
									className="prefix__b"
									d="M9.668.181a.615.615 0 010 .871L1.052 9.668a.616.616 0 11-.871-.867l8.62-8.62a.615.615 0 01.871 0z"
								/>
								<path
									className="prefix__b"
									d="M.181.181a.615.615 0 000 .871l8.62 8.616a.616.616 0 10.871-.871L1.052.181a.615.615 0 00-.871 0z"
								/>
							</g>
						</svg>
					</div>
					<ChipInput
						defaultValue={fieldObj.default}
						chipRenderer={this.chipRenderer}
						style={{
							padding: '3px',
							borderRadius: '0%',
							backgroundColor: '#373c4a',
							border: '1px solid #7487a7',
							width: '97%',
							height: '77px',
						}}
						textFieldStyle={{ height: '20px' }}
						newChipKeys={['Enter', ',']}
						onChange={(chip) => {
							this.handleChangeChip(chip, fieldObj, parentKey);
						}}
					/>
				</div>
			</>
		);
	};

	handleClickOnVariantHeaderCheckbox = () => {
		if (this.state.isVariantComboCheckboxHeaderChecked) {
			this.setState({
				isVariantComboCheckboxHeaderChecked: false,
			});
		} else {
			this.setState({
				isVariantComboCheckboxHeaderChecked: true,
			});
		}
	};
	removeItemFromArray = (arr, value) => {
		var index = arr.indexOf(value);
		if (index > -1) {
			arr.splice(index, 1);
		}
		return arr;
	};

	handleClickOnVariantCheckbox = (e, variantName) => {
		if (this.state.checkedVariants.includes(variantName)) {
			this.setState({
				checkedVariants: this.state.checkedVariants.filter(function (item) {
					return item !== variantName;
				}),
			});
		} else {
			this.setState({
				checkedVariants: [...this.state.checkedVariants, variantName],
			});
		}
	};

	handleDeleteVariants = (e, parentKey) => {
		let tempChecked = this.state.checkedVariants;
		let newComboArray = this.state.variantCombinationList.filter(function (item) {
			return !tempChecked.includes(item.comboName);
		});

		this.setState(
			{
				variantCombinationList: newComboArray,
				variantParentKey: parentKey,
			},
			() => {
				this.setState({
					checkedVariants: [],
				});
				if (this.state.variantCombinationList.length <= 0) {
					let item = this.getCurrentlyEditedItem();
					let value = '';

					let newItem = {
						...item,
					};

					newItem.settings[parentKey].variantName1.default = value;
					newItem.settings[parentKey].variantName2.default = value;
					this.setState({
						hideVariantNameInputs: false,
						variantValues1: [],
						variantValues1Obj: {},
						variantValues2: [],
						variantValues2Obj: {},
						variantName1: '',
						variantName2: '',
					});

					this.props.updateInteractTimelineItem(newItem);
				}
			}
		);
	};

	handleVariantChangeProductId = (e, parentKey, key, fieldObj, item, variant) => {
		let newItem = {
			...item,
		};

		let value = e.target.value;

		let index = newItem.settings[parentKey][key].default.findIndex((item) => item.comboName === variant.comboName);

		newItem.settings[parentKey][key].default[index].comboId = value;
		this.props.updateInteractTimelineItem(newItem);
	};

	handleVariantChangePrice = (e, parentKey, key, fieldObj, item, variant) => {
		let newItem = {
			...item,
		};

		let value = e.target.value;

		let index = newItem.settings[parentKey][key].default.findIndex((item) => item.comboName === variant.comboName);

		newItem.settings[parentKey][key].default[index].comboPrice = value;
		this.props.updateInteractTimelineItem(newItem);
	};

	handleVariantImageUpload = () => {};

	getRandomFileName = (filename) => {
		let randomFileName = generateId() + '.' + this.getFileNameExtension(filename);
		return randomFileName;
	};

	setLoading = () => {
		this.setState({
			showloading: true,
		});
	};

	resetLoading = () => {
		this.setState({
			showloading: false,
		});
	};

	trackUploadStatus = (eachFile, uid, parentKey, key, variant) => {
		const { defaultAccountId } = this.props;
		let file = '';
		this.props
			.getAllCurrentJobs(defaultAccountId)
			.then((data) => {
				if (data && data.length > 0) {
					file = _.find(data, (eachData) => {
						return eachData.uid.trim() === uid.trim();
					});
				}
				if (file) {
					setTimeout(() => {
						this.trackUploadStatus(eachFile, uid, parentKey, key, variant);
					}, 500);
				} else {
					setTimeout(() => {
						this.getSelectedMedia(eachFile, parentKey, key, variant, () => {
							this.tryFetchingMediaAgain(eachFile, parentKey, key, variant);
						});
					}, 2000);
					this.resetLoading();
				}
			})
			.catch(() => {
				setTimeout(() => {
					this.getSelectedMedia(eachFile, parentKey, key, variant, () => {
						this.tryFetchingMediaAgain(eachFile, parentKey, key, variant);
					});
				}, 2000);
				this.resetLoading();
			});
	};

	tryFetchingMediaAgain = (eachFile, parentKey, key, variant) => {
		setTimeout(() => {
			this.getSelectedMedia(eachFile, parentKey, key, variant, () => {
				this.tryFetchingMediaAgain(eachFile, parentKey, key, variant);
			});
		}, 2000);
	};

	getSelectedMedia = (eachFile, parentKey, key, variant, callback) => {
		const { defaultAccountId, mediaDetails, t } = this.props;
		this.setLoading();
		let fileFound = false;
		this.props.getMediaQueryDetails(defaultAccountId, mediaDetails.id, mediaNames.medias).then((media) => {
			if (!media) {
				this.props.showNotification(t('LABEL_MEDIA_NOT_FOUND'));
				this.resetLoading();
				return;
			}
			let resourceArray = [];
			if (media && media.asset && media.asset.resources && media.asset.resources.map) {
				resourceArray = media.asset.resources.filter(
					(eachResource) => eachResource && eachResource.type === 'image' && eachResource.renditions
				);
				if (resourceArray.length > 0) {
					resourceArray.map((resource) => {
						resource.renditions.map((rendition) => {
							if (rendition.path.match(eachFile.name) && rendition.size === eachFile.size) {
								fileFound = true;

								this.setState(
									{
										currentResouceId: resource.id,
									},
									() => {
										this.setImageUrl(
											rendition.links[0].href || rendition.path,
											parentKey,
											key,
											variant
										);
										this.props.setIsElementUpdating(false);
									}
								);
								this.resetLoading();
							}
						});
					});
				}
			}
			if (!fileFound && callback) {
				callback();
			} else if (!fileFound) {
				this.props.showNotification(t('FILE_NOT_FOUND'));
			}
			this.resetLoading();
		});
	};

	setImageUrl = (filePath, parentKey, key, variant) => {
		let item = this.getCurrentlyEditedItem();
		let newItem = {
			//added to update item properly
			...item,
		};
		if (variant === 'multi') {
			for (var i = 0; i < this.state.checkedVariants.length; i++) {
				let index = newItem.settings[parentKey][key].default.findIndex(
					(item) => item.comboName === this.state.checkedVariants[i]
				);

				newItem.settings[parentKey][key].default[index].comboImg = filePath;
			}
		} else {
			let index = newItem.settings[parentKey][key].default.findIndex(
				(item) => item.comboName === variant.comboName
			);

			newItem.settings[parentKey][key].default[index].comboImg = filePath;
		}
		this.props.updateInteractTimelineItem(newItem);
	};

	loadTheSelectedMediaItems = (files, parentKey, key, variant) => {
		const { defaultAccountId, mediaDetails, t, setUploadingProcessActive } = this.props;
		let eachFileTemp = files[0];
		let assetId = '';
		this.props.setIsElementUpdating(true);
		var blob = eachFileTemp.slice(0, eachFileTemp.size, eachFileTemp.type);
		var eachFile = new File([blob], this.getRandomFileName(eachFileTemp.name), { type: eachFileTemp.type });
		if (mediaDetails && mediaDetails.asset && mediaDetails.asset.id) {
			assetId = mediaDetails.asset.id;
			this.setLoading();
			setUploadingProcessActive(UPLOADING_FILE);

			this.props.startThumbImageUploadProcess(defaultAccountId, eachFile, assetId).then((res) => {
				setTimeout(() => setUploadingProcessActive(''), 1000);

				if (!res || res === UPLOAD_FAILED) {
					this.props.showAlert('');
					this.props.showAlert(UPLOAD_FAILED, messageTypes.error);
					this.resetLoading();
					return;
				}

				if (res) {
					if (res && res.data && typeof res.data === 'string') {
						let uid = res.data.split('uid:');
						uid = uid.length > 0 ? uid[1] : '';
						setTimeout(() => {
							this.trackUploadStatus(eachFile, uid, parentKey, key, variant);
						}, 500);
					}
					this.props.setInteractButtonImageUploaded(true);
					this.resetLoading();
				}
			});
		} else {
			this.props.showMessage(t('FAILED_PROCESS_NO_ASSET_ID'), messageTypes.error);
		}
	};

	getFileNameExtension = (filename) => {
		return filename.split('.').pop();
	};

	handleClickVariantSelector = (item) => {
		let tempArray = [];

		this.state.variantCombinationList.map((el) => {
			if (el.comboName.includes(item)) {
				tempArray.push(el.comboName);
			}
		});

		this.setState({
			checkedVariants: tempArray,
		});
	};

	renderVariantCombinations = (parentKey, key, fieldObj, item) => {
		const { t } = this.props;
		//let item = this.getCurrentlyEditedItem();

		if (
			item.settings[parentKey].variantCombinations.default !== undefined &&
			item.settings[parentKey].variantCombinations.default.length > 0
		) {
			let arrayOfVariables;
			if (item.settings[parentKey].variantValues1.default && item.settings[parentKey].variantValues2.default) {
				arrayOfVariables = item.settings[parentKey].variantValues1.default.concat(
					item.settings[parentKey].variantValues2.default
				);
			} else if (
				item.settings[parentKey].variantValues1.default &&
				item.settings[parentKey].variantValues2.default === undefined
			) {
				arrayOfVariables = item.settings[parentKey].variantValues1.default;
			} else if (
				item.settings[parentKey].variantValues2.default &&
				item.settings[parentKey].variantValues1.default === undefined
			) {
				arrayOfVariables = item.settings[parentKey].variantValues1.default;
			} else {
				arrayOfVariables = [];
			}

			if (
				item.settings[parentKey].variantValues1.default &&
				item.settings[parentKey].variantValues2.default &&
				item.settings[parentKey].variantValues1.default.length > 0 &&
				item.settings[parentKey].variantValues2.default.length > 0
			) {
				return (
					<>
						<div className="variantCombinationsVariablesMenu">
							<span>{t('VARIABLES')}</span>
							<div className="variantCombinationsVariablesMenuButtons">
								{arrayOfVariables.map((item, index) => {
									return (
										<div
											key={index}
											className="variantCombinationsVariablesMenuButtonsItem"
											onClick={() => {
												this.handleClickVariantSelector(item);
											}}
										>
											{item}
										</div>
									);
								})}
							</div>
						</div>
						<div className="variantCombinationsSummaryMenu">
							<span>{t('SUMMARY')}</span>
							<label className="variantCombinationsDropdown">
								<div className="variantCombinationsDropdown-button">Actions</div>

								<input type="checkbox" className="variantCombinationsDropdown-input" id="test" />

								<ul className="variantCombinationsDropdown-menu">
									<li>
										<Dropzone
											ref="dropzoneVariant"
											multiple={false}
											accept=".jpg, .jpeg, .gif, .png, .bmp"
											parallelChunkUploads={true}
											onDrop={(acceptedFiles, fileRejections) => {
												if (fileRejections.length > 0) {
													this.props.showMessage(
														this.props.t('LABEL_NOT_SUPPORTED_FILES'),
														messageTypes.error
													);
													return;
												}
												this.loadTheSelectedMediaItems(acceptedFiles, parentKey, key, 'multi');
											}}
											// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
											inputContent={(files, extra) =>
												extra.reject ? 'Image files only' : 'Drag Files'
											}
										>
											{({ getRootProps, getInputProps }) => (
												<div {...getRootProps({ className: 'dropzoneVariant' })}>
													<input {...getInputProps()} />
													Upload Image
												</div>
											)}
										</Dropzone>
									</li>
									<li
										onClick={(e) => {
											this.handleDeleteVariants(e, parentKey);
										}}
									>
										Delete
									</li>
								</ul>
							</label>
						</div>
						<table className="variantCombinationsSection tableSize6">
							<thead>
								<tr>
									<th>
										<Checkbox
											className="variantComboCheckboxHeader"
											checked={this.state.isVariantComboCheckboxHeaderChecked}
											onChange={(e) => {
												this.handleClickOnVariantHeaderCheckbox(e);
											}}
											style={{ color: '#d6d6d6' }}
										/>
									</th>
									<th>{item.settings[parentKey].variantName1.default}</th>
									<th>{item.settings[parentKey].variantName2.default}</th>
									<th>Product Id*</th>
									<th>Price</th>
									<th>Image</th>
								</tr>
							</thead>
							<tbody>
								{item.settings[parentKey].variantCombinations.default.map((variant, index) => {
									let itemTypes = variant.comboName.split('-');

									return (
										<tr className="variantCombinationsItemRow" key={index}>
											<td>
												<Checkbox
													id={'variant-combo-checkbox-' + variant.comboName}
													className="variantComboCheckboxItem"
													checked={this.state.checkedVariants.includes(variant.comboName)}
													onChange={(e) => {
														this.handleClickOnVariantCheckbox(e, variant.comboName);
													}}
													style={{ color: '#d6d6d6' }}
												/>
											</td>
											<td>
												<div className="variantCombinationsName ">{itemTypes[0]}</div>
											</td>
											<td>
												<div className="variantCombinationsName ">{itemTypes[1]}</div>
											</td>
											<td>
												<input
													type="text"
													className="variantCombinationsInput"
													value={variant.comboId}
													onChange={(e) => {
														this.handleVariantChangeProductId(
															e,
															parentKey,
															key,
															item.settings[parentKey].variantCombinations,
															item,
															variant
														);
													}}
												/>
											</td>
											<td>
												<input
													type="number"
													className="variantCombinationsInput "
													value={variant.comboPrice}
													onChange={(e) => {
														this.handleVariantChangePrice(
															e,
															parentKey,
															key,
															item.settings[parentKey].variantCombinations,
															item,
															variant
														);
													}}
												/>
											</td>
											<td>
												<div className="variantCombinationsImage">
													{/*   <VariantImageUpload t={t} parentKey={parentKey} objKey={key} fieldObj={fieldObj} isVariant={true} /> */}
													<Dropzone
														ref="dropzoneVariant"
														multiple={false}
														accept=".jpg, .jpeg, .gif, .png, .bmp"
														parallelChunkUploads={true}
														onDrop={(acceptedFiles, fileRejections) => {
															if (fileRejections.length > 0) {
																this.props.showMessage(
																	this.props.t('LABEL_NOT_SUPPORTED_FILES'),
																	messageTypes.error
																);
																return;
															}
															this.loadTheSelectedMediaItems(
																acceptedFiles,
																parentKey,
																key,
																variant
															);
														}}
														// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
														inputContent={(files, extra) =>
															extra.reject ? 'Image files only' : 'Drag Files'
														}
													>
														{({ getRootProps, getInputProps }) => (
															<>
																<div
																	{...getRootProps({ className: 'dropzoneVariant' })}
																>
																	<input {...getInputProps()} />
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width={28}
																		height={24}
																		viewBox="0 0 28 24"
																	>
																		<defs>
																			<style>
																				{
																					'.prefix__a{fill:#3d4457}.prefix__b{fill:#fff}'
																				}
																			</style>
																		</defs>
																		<g transform="translate(-349 -314)">
																			<rect
																				className="prefix__a"
																				width={28}
																				height={24}
																				rx={4}
																				transform="translate(349 314)"
																			/>
																			<path
																				className="prefix__b"
																				d="M368.17 325.932v4.309h-10.341v-4.309a.862.862 0 10-1.724 0v5.171a.862.862 0 00.862.862h12.065a.861.861 0 00.862-.862v-5.171a.862.862 0 10-1.724 0z"
																			/>
																			<path
																				className="prefix__b"
																				d="M362.39 319.289l-2.585 2.585a.862.862 0 101.219 1.219l1.114-1.114v5.676a.862.862 0 101.724 0v-5.676l1.114 1.114a.862.862 0 101.219-1.219l-2.585-2.585a.861.861 0 00-1.22 0z"
																			/>
																		</g>
																	</svg>
																</div>
															</>
														)}
													</Dropzone>

													<img
														className="variantCombinationsImageResult"
														src={variant.comboImg}
													/>
												</div>
												{/* <input type="text" className="variantCombinationsInput " value={variant.comboImg} /> */}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				);
			} else if (
				item.settings[parentKey].variantValues1.default &&
				item.settings[parentKey].variantValues1.default.length > 0 &&
				((item.settings[parentKey].variantValues2.default &&
					item.settings[parentKey].variantValues2.default.length <= 0) ||
					item.settings[parentKey].variantValues2.default === undefined)
			) {
				return (
					<>
						<div className="variantCombinationsVariablesMenu">
							<span>{t('VARIABLES')}</span>
							<div className="variantCombinationsVariablesMenuButtons">
								{arrayOfVariables.map((item, index) => {
									return (
										<div
											key={index}
											className="variantCombinationsVariablesMenuButtonsItem"
											onClick={() => {
												this.handleClickVariantSelector(item);
											}}
										>
											{item}
										</div>
									);
								})}
							</div>
						</div>
						<div className="variantCombinationsSummaryMenu">
							<span>{t('SUMMARY')}</span>
							<label className="variantCombinationsDropdown">
								<div className="variantCombinationsDropdown-button">Actions</div>

								<input type="checkbox" className="variantCombinationsDropdown-input" id="test" />

								<ul className="variantCombinationsDropdown-menu">
									<li>
										<Dropzone
											ref="dropzoneVariant"
											multiple={false}
											accept=".jpg, .jpeg, .gif, .png, .bmp"
											parallelChunkUploads={true}
											onDrop={(acceptedFiles, fileRejections) => {
												if (fileRejections.length > 0) {
													this.props.showMessage(
														this.props.t('LABEL_NOT_SUPPORTED_FILES'),
														messageTypes.error
													);
													return;
												}
												this.loadTheSelectedMediaItems(acceptedFiles, parentKey, key, 'multi');
											}}
											// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
											inputContent={(files, extra) =>
												extra.reject ? 'Image files only' : 'Drag Files'
											}
										>
											{({ getRootProps, getInputProps }) => (
												<>
													<div {...getRootProps({ className: 'dropzoneVariant' })}>
														<input {...getInputProps()} />
														Upload Image
													</div>
												</>
											)}
										</Dropzone>
									</li>
									<li
										onClick={(e) => {
											this.handleDeleteVariants(e, parentKey);
										}}
									>
										Delete
									</li>
								</ul>
							</label>
						</div>
						<table className="variantCombinationsSection tableSize5">
							<thead>
								<tr>
									<th>
										<Checkbox
											className="variantComboCheckboxHeader"
											checked={this.state.isVariantComboCheckboxHeaderChecked}
											onChange={(e) => {
												this.handleClickOnVariantHeaderCheckbox(e);
											}}
											style={{ color: '#d6d6d6' }}
										/>
									</th>
									<th>{item.settings[parentKey].variantName1.default}</th>
									<th>Product Id*</th>
									<th>Price</th>
									<th>Image</th>
								</tr>
							</thead>
							<tbody>
								{item.settings[parentKey].variantCombinations.default.map((variant, index) => {
									return (
										<tr className="variantCombinationsItemRow" key={index}>
											<td>
												<Checkbox
													id={'variant-combo-checkbox-' + variant.comboName}
													className="variantComboCheckboxItem"
													checked={this.state.checkedVariants.includes(variant.comboName)}
													onChange={(e) => {
														this.handleClickOnVariantCheckbox(e, variant.comboName);
													}}
													style={{ color: '#d6d6d6' }}
												/>
											</td>
											<td>
												<div className="variantCombinationsName ">{variant.comboName}</div>
											</td>
											<td>
												<input
													type="text"
													className="variantCombinationsInput"
													value={variant.comboId}
													onChange={(e) => {
														this.handleVariantChangeProductId(
															e,
															parentKey,
															key,
															item.settings[parentKey].variantCombinations,
															item,
															variant
														);
													}}
												/>
											</td>
											<td>
												<input
													type="text"
													className="variantCombinationsInput "
													value={variant.comboPrice}
													onChange={(e) => {
														this.handleVariantChangePrice(
															e,
															parentKey,
															key,
															item.settings[parentKey].variantCombinations,
															item,
															variant
														);
													}}
												/>
											</td>
											<td>
												<div className="variantCombinationsImage">
													{/*   <VariantImageUpload t={t} parentKey={parentKey} objKey={key} fieldObj={fieldObj} isVariant={true} /> */}
													<Dropzone
														ref="dropzoneVariant"
														multiple={false}
														accept=".jpg, .jpeg, .gif, .png, .bmp"
														parallelChunkUploads={true}
														onDrop={(acceptedFiles, fileRejections) => {
															if (fileRejections.length > 0) {
																this.props.showMessage(
																	this.props.t('LABEL_NOT_SUPPORTED_FILES'),
																	messageTypes.error
																);
																return;
															}
															this.loadTheSelectedMediaItems(
																acceptedFiles,
																parentKey,
																key,
																variant
															);
														}}
														// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
														inputContent={(files, extra) =>
															extra.reject ? 'Image files only' : 'Drag Files'
														}
													>
														{({ getRootProps, getInputProps }) => (
															<>
																<div
																	{...getRootProps({ className: 'dropzoneVariant' })}
																>
																	<input {...getInputProps()} />
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width={28}
																		height={24}
																		viewBox="0 0 28 24"
																	>
																		<defs>
																			<style>
																				{
																					'.prefix__a{fill:#3d4457}.prefix__b{fill:#fff}'
																				}
																			</style>
																		</defs>
																		<g transform="translate(-349 -314)">
																			<rect
																				className="prefix__a"
																				width={28}
																				height={24}
																				rx={4}
																				transform="translate(349 314)"
																			/>
																			<path
																				className="prefix__b"
																				d="M368.17 325.932v4.309h-10.341v-4.309a.862.862 0 10-1.724 0v5.171a.862.862 0 00.862.862h12.065a.861.861 0 00.862-.862v-5.171a.862.862 0 10-1.724 0z"
																			/>
																			<path
																				className="prefix__b"
																				d="M362.39 319.289l-2.585 2.585a.862.862 0 101.219 1.219l1.114-1.114v5.676a.862.862 0 101.724 0v-5.676l1.114 1.114a.862.862 0 101.219-1.219l-2.585-2.585a.861.861 0 00-1.22 0z"
																			/>
																		</g>
																	</svg>
																</div>
															</>
														)}
													</Dropzone>

													<img
														className="variantCombinationsImageResult"
														src={variant.comboImg}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				);
			} else if (
				(item.settings[parentKey].variantValues1.default === undefined ||
					(item.settings[parentKey].variantValues1.default &&
						item.settings[parentKey].variantValues1.default.length <= 0)) &&
				item.settings[parentKey].variantValues2.default &&
				item.settings[parentKey].variantValues2.default.length > 0
			) {
				return (
					<>
						<div className="variantCombinationsVariablesMenu">
							<span>{t('VARIABLES')}</span>
							<div className="variantCombinationsVariablesMenuButtons">
								{arrayOfVariables.map((item, index) => {
									return (
										<div
											key={index}
											className="variantCombinationsVariablesMenuButtonsItem"
											onClick={() => {
												this.handleClickVariantSelector(item);
											}}
										>
											{item}
										</div>
									);
								})}
							</div>
						</div>
						<div className="variantCombinationsSummaryMenu">
							<span>{t('SUMMARY')}</span>
							<label className="variantCombinationsDropdown">
								<div className="variantCombinationsDropdown-button">Actions</div>

								<input type="checkbox" className="variantCombinationsDropdown-input" id="test" />

								<ul className="variantCombinationsDropdown-menu">
									<li>
										<Dropzone
											ref="dropzoneVariant"
											multiple={false}
											accept=".jpg, .jpeg, .gif, .png, .bmp"
											parallelChunkUploads={true}
											onDrop={(acceptedFiles, fileRejections) => {
												if (fileRejections.length > 0) {
													this.props.showMessage(
														this.props.t('LABEL_NOT_SUPPORTED_FILES'),
														messageTypes.error
													);
													return;
												}
												this.loadTheSelectedMediaItems(acceptedFiles, parentKey, key, 'multi');
											}}
											// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
											inputContent={(files, extra) =>
												extra.reject ? 'Image files only' : 'Drag Files'
											}
										>
											{({ getRootProps, getInputProps }) => (
												<>
													<div {...getRootProps({ className: 'dropzoneVariant' })}>
														<input {...getInputProps()} />
														Upload Image
													</div>
												</>
											)}
										</Dropzone>
									</li>
									<li
										onClick={(e) => {
											this.handleDeleteVariants(e, parentKey);
										}}
									>
										Delete
									</li>
								</ul>
							</label>
						</div>
						<table className="variantCombinationsSection tableSize5">
							<thead>
								<tr>
									<th>
										<Checkbox
											className="variantComboCheckboxHeader"
											checked={this.state.isVariantComboCheckboxHeaderChecked}
											onChange={(e) => {
												this.handleClickOnVariantHeaderCheckbox(e);
											}}
											style={{ color: '#d6d6d6' }}
										/>
									</th>
									<th>{item.settings[parentKey].variantName2.default}</th>
									<th>Product Id*</th>
									<th>Price</th>
									<th>Image</th>
								</tr>
							</thead>
							<tbody>
								{item.settings[parentKey].variantCombinations.default.map((variant, index) => {
									return (
										<tr className="variantCombinationsItemRow" key={index}>
											<td>
												<Checkbox
													id={'variant-combo-checkbox-' + variant.comboName}
													className="variantComboCheckboxItem"
													checked={this.state.checkedVariants.includes(variant.comboName)}
													onChange={(e) => {
														this.handleClickOnVariantCheckbox(
															e,
															variant.comboName,
															parentKey
														);
													}}
													style={{ color: '#d6d6d6' }}
												/>
											</td>
											<td>
												<div className="variantCombinationsName ">{variant.comboName}</div>
											</td>
											<td>
												<input
													type="text"
													className="variantCombinationsInput"
													value={variant.comboId}
													onChange={(e) => {
														this.handleVariantChangeProductId(
															e,
															parentKey,
															key,
															item.settings[parentKey].variantCombinations,
															item,
															variant
														);
													}}
												/>
											</td>
											<td>
												<input
													type="text"
													className="variantCombinationsInput "
													value={variant.comboPrice}
													onChange={(e) => {
														this.handleVariantChangePrice(
															e,
															parentKey,
															key,
															item.settings[parentKey].variantCombinations,
															item,
															variant
														);
													}}
												/>
											</td>
											<td>
												<div className="variantCombinationsImage">
													{/*   <VariantImageUpload t={t} parentKey={parentKey} objKey={key} fieldObj={fieldObj} isVariant={true} /> */}
													<Dropzone
														ref="dropzoneVariant"
														multiple={false}
														accept=".jpg, .jpeg, .gif, .png, .bmp"
														parallelChunkUploads={true}
														onDrop={(acceptedFiles, fileRejections) => {
															if (fileRejections.length > 0) {
																this.props.showMessage(
																	this.props.t('LABEL_NOT_SUPPORTED_FILES'),
																	messageTypes.error
																);
																return;
															}
															this.loadTheSelectedMediaItems(
																acceptedFiles,
																parentKey,
																key,
																variant
															);
														}}
														// onFileDialogCancel={(e) => this.clearTheFilesSelection(e)}
														inputContent={(files, extra) =>
															extra.reject ? 'Image files only' : 'Drag Files'
														}
													>
														{({ getRootProps, getInputProps }) => (
															<>
																<div
																	{...getRootProps({ className: 'dropzoneVariant' })}
																>
																	<input {...getInputProps()} />
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width={28}
																		height={24}
																		viewBox="0 0 28 24"
																	>
																		<defs>
																			<style>
																				{
																					'.prefix__a{fill:#3d4457}.prefix__b{fill:#fff}'
																				}
																			</style>
																		</defs>
																		<g transform="translate(-349 -314)">
																			<rect
																				className="prefix__a"
																				width={28}
																				height={24}
																				rx={4}
																				transform="translate(349 314)"
																			/>
																			<path
																				className="prefix__b"
																				d="M368.17 325.932v4.309h-10.341v-4.309a.862.862 0 10-1.724 0v5.171a.862.862 0 00.862.862h12.065a.861.861 0 00.862-.862v-5.171a.862.862 0 10-1.724 0z"
																			/>
																			<path
																				className="prefix__b"
																				d="M362.39 319.289l-2.585 2.585a.862.862 0 101.219 1.219l1.114-1.114v5.676a.862.862 0 101.724 0v-5.676l1.114 1.114a.862.862 0 101.219-1.219l-2.585-2.585a.861.861 0 00-1.22 0z"
																			/>
																		</g>
																	</svg>
																</div>
															</>
														)}
													</Dropzone>

													<img
														className="variantCombinationsImageResult"
														src={variant.comboImg}
													/>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				);
			}
		} else {
			return <></>;
		}
	};

	renderPriceInput = (parentKey, key, fieldObj) => {
		const currrencyArray = ['EUR', 'SEK', 'DKK', 'NOK', 'USD', 'GBP'];
		return (
			<>
				<div className="priceFieldInputSection">
					<label className="priceLabel"> {fieldObj.name} </label>
					<div className="priceInputField">
						<input
							type="number"
							className="sixtyWidth "
							value={this.getValueFromPrice(fieldObj.default)}
							onChange={(e) =>
								this.setState({
									priceParentKey: parentKey,
									priceKey: key,
									priceFieldObject: fieldObj,
									priceValue: e.target.value,
									priceCurrency: this.getCurrencyFromPrice(fieldObj.default),
								})
							}
						/>
						<select
							className="currencySelectInput"
							onChange={(evt) =>
								this.setState({
									priceParentKey: parentKey,
									priceKey: key,
									priceFieldObject: fieldObj,
									priceCurrency: evt.target.value,
									priceValue: this.getValueFromPrice(fieldObj.default),
								})
							}
						>
							{currrencyArray.map((item, index) => {
								if (item === this.getCurrencyFromPrice(fieldObj.default)) {
									return (
										<option value={item} selected key={index}>
											{item}
										</option>
									);
								} else {
									return (
										<option value={item} key={index}>
											{item}
										</option>
									);
								}
							})}
						</select>
					</div>
				</div>
			</>
		);
	};

	getValueFromPrice = (data) => {
		return data.split(' ')[0];
	};

	getCurrencyFromPrice = (data) => {
		return data.split(' ')[1];
	};

	renderNumberInput = (parentKey, key, fieldObj) => {
		const { t } = this.props;
		return (
			<>
				<div className="positionAndSize">
					<label className="propertySectionTitle sectionNames"> {t('POSITION_AND_SIZE')} </label>
					<label> {fieldObj.name} </label>
					<input
						type="number"
						value={this.removeZeroAtStart(fieldObj.default)}
						onChange={(e) => {
							this.handleChange(e, parentKey, key, fieldObj);
						}}
					/>
				</div>
			</>
		);
	};

	removeZeroAtStart = (value) => {
		let tempArray = [...value.toString()];

		if (tempArray.length > 1 && tempArray[0] === '0') {
			tempArray.shift();
		}

		return tempArray.join('');
	};

	renderBooleanInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<>
					<div className="booleanSelection">
						{
							{
								onEntry: (
									<>
										<Checkbox
											id="pauseVideoOnEntry"
											checked={fieldObj.default === 'pause' ? true : false}
											onChange={(e) => {
												this.handleChange(e, parentKey, key, fieldObj);
											}}
											style={{ color: '#d6d6d6' }}
										/>
										<label className="inlineLabel" for="pauseVideoOnEntry">
											{fieldObj.name}
										</label>
									</>
								),
								position: (
									<>
										<Checkbox
											id="fixedPostionToggle"
											checked={fieldObj.default}
											onChange={(e) => {
												this.handleChange(e, parentKey, key, fieldObj);
											}}
											style={{ color: '#d6d6d6' }}
										/>
										<label className="inlineLabel" for="fixedPostionToggle">
											{fieldObj.name}
										</label>
									</>
								),
							}[key]
						}
					</div>
				</>
			</>
		);
	};

	renderSelectInput = (parentKey, key, fieldObj) => {
		let list = [];
		if (key === 'animateIn') {
			list = interactAnimationsList;
		}
		return (
			<>
				<>
					<div className="interactSelectionSection">
						<label className="propertySectionTitle"> {fieldObj.name} </label>
						<div className="sortingDropDownWrapper fullWidthControl">
							<select
								className="sortingDropDown"
								onChange={(e) => {
									this.handleChange(e, parentKey, key, fieldObj);
								}}
							>
								{list.map((eachValue) => (
									<option
										key={eachValue.value}
										selected={eachValue.value === fieldObj.default}
										value={eachValue.value}
									>
										{' '}
										{eachValue.name}{' '}
									</option>
								))}
							</select>
						</div>
					</div>
				</>
			</>
		);
	};

	renderTimeStampInput = (parentKey, key, fieldObj) => {
		let newTime = getTimelineTimeFromSeconds(fieldObj.default);
		let formattedTime = getFormattedTimeWithMilliseconds(newTime, 'HH:mm:ss:SSS');

		return (
			<>
				<div className="interactPropertiesSection">
					<div className="interactTime">
						<label className="inlineLabel"> {fieldObj.name} </label>
						<TimeInput
							max={12}
							value={formattedTime}
							onChange={(e) => {
								this.handleChange(e, parentKey, key, fieldObj);
							}}
						/>
					</div>
				</div>
			</>
		);
	};

	renderImageInput = (parentKey, key, fieldObj) => {
		const { t } = this.props;
		let objKey = key;
		let item = this.getCurrentlyEditedItem();
		if (item.settings[parentKey] && item.settings[parentKey][objKey]) {
			return (
				<>
					<div className="fileChooseSelection">
						<label> {fieldObj.name} </label>
						<ButtonFileChoose t={t} parentKey={parentKey} objKey={objKey} fieldObj={fieldObj} />
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	renderColorInput = (parentKey, key, fieldObj) => {
		return (
			<>
				<div className="colorPicker">
					{/* <input type="color" name="favcolor" value={fieldObj.default} onChange={(e) => { this.handleChange(e, parentKey, key, fieldObj) }} /> */}
					<label> {fieldObj.name} </label>
					<ColorPicker
						color={fieldObj.default}
						onChangeComplete={(e) => {
							this.handleChange(e, parentKey, key, fieldObj);
						}}
					/>
				</div>
			</>
		);
	};

	renderActionInput = (parentKey, objKey, fieldObj) => {
		const { t } = this.props;
		let item = this.getCurrentlyEditedItem();
		return (
			<>
				<InteractActionProperties
					t={t}
					fieldObj={fieldObj}
					parentKey={parentKey}
					objKey={objKey}
					item={item}
					mediaDetails={this.props.mediaDetails}
				/>
			</>
		);
	};

	renderFontInput = (parentKey, key, fieldObj) => {
		const { t } = this.props;
		return (
			<>
				<FontTypography
					fontRef={this.setWrapperRef}
					onfontTypographyLoaded={this.onfontTypographyLoaded}
					fieldObj={fieldObj}
					t={t}
					parentKey={parentKey}
					objKey={key}
				/>
			</>
		);
	};

	deleteKey = (parentKey) => {
		let item = this.getCurrentlyEditedItem();
		delete item.settings[parentKey];
		item.settings = JSON.parse(JSON.stringify(item.settings));
		this.props.updateInteractTimelineItem(item);
	};

	renderDynamicButtonOption = (key) => {
		let eacKey = key;
		const { t } = this.props;
		return (
			<>
				<DynamicObjectCreation eacKey={eacKey} t={t} />
			</>
		);
	};

	renderProperties = (parentKey, key, fieldObj, item) => {
		let type = fieldObj && fieldObj.type ? fieldObj.type : '';
		if (type === interactSettingsType.uuid) {
			return null;
		}

		switch (type) {
			case interactSettingsType.number:
			case interactSettingsType.xCoordinate:
			case interactSettingsType.yCoordinate:
			case interactSettingsType.widthCoordinate:
			case interactSettingsType.heightCoordinate:
			case interactSettingsType.radiusCoordinate:
			case interactSettingsType.coordinate:
				return this.renderNumberInput(parentKey, key, fieldObj);
			case interactSettingsType.boolean:
				return this.renderBooleanInput(parentKey, key, fieldObj);
			case interactSettingsType.select:
				return this.renderSelectInput(parentKey, key, fieldObj);
			case interactSettingsType.timestamp:
				return this.renderTimeStampInput(parentKey, key, fieldObj);
			case interactSettingsType.image:
				return this.renderImageInput(parentKey, key, fieldObj);
			case interactSettingsType.color:
				return this.renderColorInput(parentKey, key, fieldObj);
			case interactSettingsType.actions:
				return this.renderActionInput(parentKey, key, fieldObj);
			case interactSettingsType.font:
				return this.renderFontInput(parentKey, key, fieldObj);
			case interactSettingsType.price:
				return this.renderPriceInput(parentKey, key, fieldObj);
			case interactSettingsType.variantName1:
				return this.renderVariantNameInput(parentKey, key, fieldObj);
			case interactSettingsType.variantName2:
				return this.renderVariantNameInput(parentKey, key, fieldObj);
			case interactSettingsType.variantValues1:
				return this.renderVariantValuesInput(parentKey, key, fieldObj);
			case interactSettingsType.variantValues2:
				return this.renderVariantValuesInput(parentKey, key, fieldObj);
			case interactSettingsType.variantCombinations:
				return this.renderVariantCombinations(parentKey, key, fieldObj, item);
			case interactSettingsType.hideVolumeCounter:
				return this.renderHideVolumeCounterInput(parentKey, key, fieldObj);
			case interactSettingsType.description:
				return this.renderDescriptionInput(parentKey, key, fieldObj);
			default:
				if (fieldObj && fieldObj.type) {
					return this.renderTextInput(parentKey, key, fieldObj);
				}
				break;
		}
	};

	showSubProperties = (eachKey) => {
		this.props.showSelectedSubPropertyKey(eachKey);
	};

	isTypeAppearance = (type) => {
		if (
			type === interactSettingsType.image ||
			type === interactSettingsType.color ||
			type === interactSettingsType.string ||
			type === interactSettingsType.font
		) {
			return true;
		}
		return false;
	};

	isTypeProductImages = (type) => {
		if (
			type === interactSettingsType.image ||
			type === interactSettingsType.color ||
			type === interactSettingsType.string ||
			type === interactSettingsType.font
		) {
			return true;
		}
		return false;
	};

	isTypeMetadata = (type) => {
		if (
			type === interactSettingsType.metaData ||
			type === interactSettingsType.price ||
			type === interactSettingsType.description ||
			type === interactSettingsType.hideVolumeCounter
		) {
			return true;
		}
		return false;
	};

	isTypeVariant = (type) => {
		if (
			type === interactSettingsType.variantName1 ||
			type === interactSettingsType.variantName2 ||
			(type === interactSettingsType.variantValues1) | (type === interactSettingsType.variantValues2)
		) {
			return true;
		}
		return false;
	};

	isTypeVariantCombo = (type) => {
		if (type === interactSettingsType.variantCombinations) {
			return true;
		}
		return false;
	};

	isTypePositionAndSize = (type) => {
		if (
			type === interactSettingsType.coordinate ||
			type === interactSettingsType.xCoordinate ||
			type === interactSettingsType.yCoordinate ||
			type === interactSettingsType.widthCoordinate ||
			type === interactSettingsType.heightCoordinate ||
			type === interactSettingsType.radiusCoordinate ||
			type === interactSettingsType.number
		) {
			return true;
		}
		return false;
	};

	renderSubObjectProperties = (item, eachKey) => {
		const { t } = this.props;

		return (
			<>
				{item.templateName !== 'Product' && this.renderNameField(item, eachKey)}
				{item.templateName !== 'Product' && (
					<div className="fieldSettings appearanceSection">
						{this.renderToggleButton(t('APPEARANCE'))}
						<div className="toggleDescription">
							{Object.keys(item.settings[eachKey]).map((key) => (
								<>
									{item.settings[eachKey] &&
										item.settings[eachKey][key] &&
										item.settings[eachKey][key].hasOwnProperty('type') &&
										this.isTypeAppearance(item.settings[eachKey][key].type) &&
										this.renderProperties(eachKey, key, item.settings[eachKey][key])}
								</>
							))}
						</div>
					</div>
				)}
				{item.templateName === 'Product' && (
					<div className="fieldSettings appearanceSection">
						{this.renderToggleButton(t('METADATA'))}
						<div className="toggleDescription">
							{Object.keys(item.settings[eachKey]).map((key) => (
								<>
									{item.settings[eachKey] &&
										item.settings[eachKey][key] &&
										item.settings[eachKey][key].hasOwnProperty('type') &&
										this.isTypeMetadata(item.settings[eachKey][key].type) &&
										this.renderProperties(eachKey, key, item.settings[eachKey][key])}
								</>
							))}
						</div>
					</div>
				)}

				{item.templateName === 'Product' && (
					<div className="fieldSettings appearanceSection">
						{this.renderToggleButton(t('PRODUCT_IMAGES'))}
						<div className="toggleDescription descriptionProductImages">
							{Object.keys(item.settings[eachKey]).map((key) => (
								<>
									{item.settings[eachKey] &&
										item.settings[eachKey][key] &&
										item.settings[eachKey][key].hasOwnProperty('type') &&
										this.isTypeProductImages(item.settings[eachKey][key].type) &&
										this.renderProperties(eachKey, key, item.settings[eachKey][key])}
								</>
							))}
						</div>
					</div>
				)}
				{item.templateName === 'Product' && (
					<div className="fieldSettings appearanceSection">
						{this.renderVariantCombinationsButton(t('VARIANTS'))}
						<div className="toggleDescription">
							{this.state.hideVariantNameInputs ? (
								<></>
							) : (
								<>
									<div className="variantInputsGrid">
										{Object.keys(item.settings[eachKey]).map((key) => (
											<>
												{item.settings[eachKey] &&
													item.settings[eachKey][key] &&
													item.settings[eachKey][key].hasOwnProperty('type') &&
													this.isTypeVariant(item.settings[eachKey][key].type) &&
													this.renderProperties(eachKey, key, item.settings[eachKey][key])}
											</>
										))}
									</div>
									<div className="generateCombinationsButtonWrapper">
										<Button
											disabled={this.areBothVariablesEmpty(eachKey)}
											className={
												this.areBothVariablesEmpty(eachKey)
													? 'generateCombinationsButton generateCombinationsButtonDisabled'
													: 'generateCombinationsButton'
											}
											onClick={(e) => {
												this.handleVariantCombinationsUpdate(e, eachKey);
											}}
										>
											Create combinations
										</Button>
									</div>
								</>
							)}
							{Object.keys(item.settings[eachKey]).map((key) => (
								<>
									{item.settings[eachKey] &&
										item.settings[eachKey][key] &&
										item.settings[eachKey][key].hasOwnProperty('type') &&
										this.isTypeVariantCombo(item.settings[eachKey][key].type) &&
										this.renderProperties(eachKey, key, item.settings[eachKey][key], item)}
								</>
							))}
						</div>
					</div>
				)}

				{item.templateName !== 'Product' && (
					<div className="fieldSettings">
						{this.renderToggleButton(t('ACTION_ON_CLICK'))}
						<div className="toggleDescription">
							{Object.keys(item.settings[eachKey]).map((key) => (
								<>
									{item.settings[eachKey] &&
										item.settings[eachKey][key] &&
										item.settings[eachKey][key].hasOwnProperty('type') &&
										item.settings[eachKey][key].type === interactSettingsType.actions &&
										this.renderProperties(eachKey, key, item.settings[eachKey][key])}
								</>
							))}
						</div>
					</div>
				)}
				{!item.settings.general.position.default && (
					<div className="fieldSettings">
						{this.renderToggleButton(t('POSITION_AND_SIZE'))}
						<div className="toggleDescription">
							{Object.keys(item.settings[eachKey]).map((key) => (
								<>
									{item.settings[eachKey] &&
										item.settings[eachKey][key] &&
										item.settings[eachKey][key].hasOwnProperty('type') &&
										this.isTypePositionAndSize(item.settings[eachKey][key].type) &&
										this.renderProperties(eachKey, key, item.settings[eachKey][key], item)}
								</>
							))}
						</div>
					</div>
				)}
			</>
		);
	};

	renderNameField = (item, eachKey) => {
		const { t } = this.props;
		return (
			<>
				<div className="fieldSettings">
					<label className="objectTitle propertySectionTitle"> {t('BUTTON_ID')} </label>
					<div className="objectNameField">
						<label className="buttonNameLabel"> {t('NAME')} </label>
						<input
							className="buttonNameValue"
							value={item.settings[eachKey].name}
							type="text"
							onChange={(e) => {
								this.handleChange(e, eachKey, 'name', {});
							}}
						/>
					</div>
				</div>
			</>
		);
	};

	renderNameElementField = (item, eachKey) => {
		const { t } = this.props;

		return (
			<>
				<div className="fieldSettings">
					<label className="objectTitle propertySectionTitle"> {t('ELEMENT_NAME')} </label>
					<div className="objectNameField">
						<label className="buttonNameLabel"> {t('NAME')} </label>
						<input
							className="buttonNameValue"
							value={item.content}
							type="text"
							onChange={(e) => {
								this.handleChange(e, eachKey, 'content', {});
							}}
						/>
					</div>
				</div>
			</>
		);
	};

	renderSubPropertyList = (item, eachKey) => {
		return (
			<>
				<div id="interactItemList">
					<div className="interactItem">
						<div className="interactItemDetail" onClick={() => this.showSubProperties(eachKey)}>
							{item.settings[eachKey].name}
						</div>
						<div className="deleteInteractItem" onClick={() => this.deleteKey(eachKey)}>
							<IconsStore iconName={IconsList.CLOSE} />
						</div>
					</div>
				</div>
			</>
		);
	};

	handleToggle = (e) => {
		e.preventDefault();
		let target = '';
		if (e.target.classList.contains('toggle')) {
			target = e.target;
		} else if (e.target.parentElement.classList.contains('toggle')) {
			target = e.target.parentElement;
		}

		if (target.classList.contains('active')) {
			target.classList.remove('active');
		} else {
			target.classList.add('active');
		}
	};

	renderToggleButton = (title) => {
		return (
			<>
				<div
					className="toggle active"
					onClick={(e) => {
						this.handleToggle(e);
					}}
				>
					<div className="toggleButton"></div>
					<label className="propertySectionTitle"> {title} </label>
				</div>
			</>
		);
	};

	renderVariantCombinationsButton = (title) => {
		return (
			<>
				<div
					className="toggle active"
					onClick={(e) => {
						this.handleToggle(e);
					}}
				>
					<div className="toggleButton"></div>
					<label className="propertySectionTitle"> {title} </label>
					{this.state.variantCombinationList !== undefined && this.state.variantCombinationList.length > 0 ? (
						<div
							className="editVariablesButton"
							onClick={(e) => {
								e.stopPropagation();

								if (this.state.hideVariantNameInputs === false) {
									this.setState({
										hideVariantNameInputs: true,
									});
								} else {
									this.setState({
										hideVariantNameInputs: false,
									});
								}
							}}
						>
							Edit variables
						</div>
					) : (
						<></>
					)}
				</div>
			</>
		);
	};

	areBothVariablesEmpty = (eachKey) => {
		let item = this.getCurrentlyEditedItem();
		if (
			item.settings[eachKey].variantValues1.default &&
			item.settings[eachKey].variantValues2.default &&
			item.settings[eachKey].variantValues1.default.length > 0 &&
			item.settings[eachKey].variantValues2.default.length > 0
		) {
			return false;
		} else if (
			item.settings[eachKey].variantValues1.default &&
			item.settings[eachKey].variantValues1.default.length > 0 &&
			((item.settings[eachKey].variantValues2.default &&
				item.settings[eachKey].variantValues2.default.length <= 0) ||
				item.settings[eachKey].variantValues2.default === undefined)
		) {
			return false;
		} else if (
			item.settings[eachKey].variantValues2.default &&
			item.settings[eachKey].variantValues2.default.length > 0 &&
			((item.settings[eachKey].variantValues1.default &&
				item.settings[eachKey].variantValues1.default.length <= 0) ||
				item.settings[eachKey].variantValues1.default === undefined)
		) {
			return false;
		} else {
			return true;
		}
	};

	handleVariantCombinationsUpdate = (e, eachKey) => {
		e.preventDefault();

		let item = this.getCurrentlyEditedItem();
		let tempArray = [];

		if (
			item.settings[eachKey].variantValues1.default &&
			item.settings[eachKey].variantValues2.default &&
			item.settings[eachKey].variantValues1.default.length > 0 &&
			item.settings[eachKey].variantValues2.default.length > 0
		) {
			for (var i = 0; i < item.settings[eachKey].variantValues1.default.length; i++) {
				for (var j = 0; j < item.settings[eachKey].variantValues2.default.length; j++) {
					let tempObj = {
						comboName:
							item.settings[eachKey].variantValues1.default[i] +
							'-' +
							item.settings[eachKey].variantValues2.default[j],
						comboId: '',
						comboImg: '',
						comboPrice: '',
					};

					tempArray.push(tempObj);
				}
			}
		} else if (
			item.settings[eachKey].variantValues1.default &&
			item.settings[eachKey].variantValues1.default.length > 0 &&
			((item.settings[eachKey].variantValues2.default &&
				item.settings[eachKey].variantValues2.default.length <= 0) ||
				item.settings[eachKey].variantValues2.default === undefined)
		) {
			for (let i = 0; i < item.settings[eachKey].variantValues1.default.length; i++) {
				let tempObj = {
					comboName: item.settings[eachKey].variantValues1.default[i],
					comboId: '',
					comboImg: '',
					comboPrice: '',
				};

				tempArray.push(tempObj);
			}
		} else if (
			item.settings[eachKey].variantValues2.default &&
			item.settings[eachKey].variantValues2.default.length > 0 &&
			((item.settings[eachKey].variantValues1.default &&
				item.settings[eachKey].variantValues1.default.length <= 0) ||
				item.settings[eachKey].variantValues1.default === undefined)
		) {
			for (let i = 0; i < item.settings[eachKey].variantValues2.default.length; i++) {
				let tempObj = {
					comboName: item.settings[eachKey].variantValues2.default[i],
					comboId: '',
					comboImg: '',
					comboPrice: '',
				};

				tempArray.push(tempObj);
			}
		}

		this.setState({
			variantCombinationList: tempArray,
			variantParentKey: eachKey,
		});

		if (this.state.hideVariantNameInputs === false) {
			this.setState({
				hideVariantNameInputs: true,
			});
		} else {
			this.setState({
				hideVariantNameInputs: false,
			});
		}
	};

	renderInteractControls = () => {
		const { t } = this.props;
		return (
			<>
				<InteractMediaControls t={t} />
			</>
		);
	};

	renderAllProperties = (item) => {
		const { t, disableVideoApplyButton } = this.props;
		return (
			<>
				<div className="fieldSettings">
					{this.renderToggleButton(t('GENERAL_SETTINGS'))}
					<div className="toggleDescription">
						{this.renderInteractControls(item)}
						{this.renderTimeStampOfItem(item)}
						{Object.keys(item.settings).map((eachKey) => (
							<>
								{eachKey === 'general' &&
									Object.keys(item.settings[eachKey]).map((key) => (
										<>{this.renderProperties(eachKey, key, item.settings[eachKey][key])}</>
									))}
							</>
						))}
					</div>
				</div>
				{Object.keys(item.settings).map((eachKey) => (
					<>
						{eachKey !== 'general' &&
							eachKey !== 'template' &&
							this.renderSubObjectProperties(item, eachKey)}
					</>
				))}
				{item.itemType === sequenceTypes.video && (
					<>
						<Button
							variant="contained"
							disabled={disableVideoApplyButton}
							className="defaultActionBtn trimButtonsSet"
							onClick={() => {
								this.applyTrim(item);
							}}
						>
							{' '}
							{t('APPLY_TRIM')}{' '}
						</Button>
						<Button
							variant="contained"
							className="defaultActionBtn deleteButtonWithIcon trimButtonsSet"
							onClick={() => this.removeTrim(item)}
						>
							<IconsStore iconName={IconsList.TRASHCAN} />
							{t('REMOVE_TRIM')}
						</Button>
						<Button
							variant="contained"
							className="defaultActionBtn trimButtonsSet neutralBtn trimCancelBtn"
							onClick={() => this.cancelTrim(item)}
						>
							<IconsStore iconName={IconsList.CLOSE} />
							{t('CANCEL').toLowerCase()}
						</Button>
					</>
				)}
			</>
		);
	};

	getCountOfType = (item, eachKey) => {
		let count = 0;
		Object.keys(item.settings).map((key) => {
			if (item.settings[key].type === eachKey) {
				count = count + 1;
			}
		});
		return '(' + count + '/' + item.definitions[eachKey].config.max + ')';
	};

	applyTrim = (item) => {
		this.props.setSelectedInteractTimelineItem([]);
		this.props.updateInteractTimelineItem(item, true);
		this.interactSaveRef.applyTrim(item);
		this.props.hideTrimTool();
	};

	removeTrim = (item) => {
		const { t } = this.props;
		this.props.setInteractItemsToDelete([item]);
		this.props.showInteractDeleteDialog(t('REMOVE_TRIM_MSG'));
	};

	cancelTrim = (item) => {
		this.props.setSelectedInteractTimelineItem([]);
		this.interactSaveRef.cancelTrim(item);
	};

	render() {
		let item = this.getCurrentlyEditedItem();
		const { selectedSubPropertyKey, t, mediaDetails, isOnLiveMode, mediaId, showMessage } = this.props;

		return (
			<>
				<InteractSaveProject
					mediaId={mediaId}
					t={t}
					showMessage={showMessage}
					noUI={true}
					onRef={(ref) => (this.interactSaveRef = ref)}
				/>
				{item && (
					<div id="interactElementProperties">
						{!item.definitions && (
							<>
								{item.itemType !== sequenceTypes.video && this.renderNameElementField(item)}
								{!isOnLiveMode && this.renderAllProperties(item)}
							</>
						)}

						{!selectedSubPropertyKey && item.definitions && (
							<>
								{this.renderNameElementField(item)}

								{Object.keys(item.definitions).map((eachKey) => (
									<>
										<div className="fieldSettings">
											{this.renderToggleButton(
												eachKey
													? eachKey.charAt(0).toUpperCase() +
															eachKey.slice(1) +
															's' +
															' ' +
															this.getCountOfType(item, eachKey)
													: ''
											)}
											<div className="toggleDescription">
												{Object.keys(item.settings).map((key) => (
													<>
														{item.settings[key] &&
															item.settings[key].hasOwnProperty('type') &&
															item.settings[key].type === eachKey &&
															this.renderSubPropertyList(item, key)}
													</>
												))}
												{this.renderDynamicButtonOption(eachKey)}
											</div>
										</div>
									</>
								))}
							</>
						)}

						{
							// onclick of dynamic button show only button properties
							selectedSubPropertyKey &&
							item.definitions &&
							item.settings[selectedSubPropertyKey] &&
							item.settings[selectedSubPropertyKey].type &&
							item.definitions[item.settings[selectedSubPropertyKey].type] ? (
								<>{this.renderSubObjectProperties(item, selectedSubPropertyKey)}</>
							) : null
						}

						{
							//To show General Settings
							!selectedSubPropertyKey && item.definitions && (
								<>
									<div className="fieldSettings">
										{this.renderToggleButton(t('GENERAL_SETTINGS'))}
										<div className="toggleDescription">
											{mediaDetails.type === mediaTypes.livevideo ? (
												<></>
											) : (
												<>
													{' '}
													{this.renderInteractControls(item)}
													{this.renderTimeStampOfItem(item)}
												</>
											)}
											{item && item.settings && (
												<>
													{Object.keys(item.settings).map(
														(eachKey) =>
															eachKey &&
															eachKey !== 'template' &&
															!item.settings[eachKey].hasOwnProperty('type') && (
																<>
																	{Object.keys(item.settings[eachKey]).map((key) =>
																		this.renderProperties(
																			eachKey,
																			key,
																			item.settings[eachKey][key]
																		)
																	)}
																</>
															)
													)}
												</>
											)}
										</div>
									</div>
								</>
							)
						}
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, inspectScreenReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
	selectedSubPropertyKey: interactElementsReducer.selectedSubPropertyKey,
	disableVideoApplyButton: interactTimelineReducer.disableVideoApplyButton,
	updateSelectedElement: interactElementsReducer.updateSelectedElement,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(updateInteractTimelineItem(item, setScreenUnModified)),
	showNotification: (message) => dispatch(showNotification(message)),
	startThumbImageUploadProcess: (accId, file, assetId) =>
		dispatch(startThumbImageUploadProcess(accId, file, assetId)),
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
	showSelectedSubPropertyKey: (key) => dispatch(showSelectedSubPropertyKey(key)),
	hideSelectedSubPropertyKey: (_) => dispatch(hideSelectedSubPropertyKey()),
	setInteractItemsToDelete: (items) => dispatch(setInteractItemsToDelete(items)),
	showInteractDeleteDialog: (msg) => dispatch(showInteractDeleteDialog(msg)),
	hideTrimTool: () => dispatch(hideTrimTool()),
	setInteractButtonImageUploaded: (value) => dispatch(setInteractButtonImageUploaded(value)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	setIsElementUpdating: (isUpdating) => dispatch(setIsElementUpdating(isUpdating)),
	setElementPropertiesError: () => dispatch(setElementPropertiesError()),
	getAllCurrentJobs: (defaultAccountId) => dispatch(getAllCurrentJobs(defaultAccountId)),
	resetElementPropertiesError: () => dispatch(resetElementPropertiesError()),
	showAlert: (value, type) => dispatch(showAlert(value, type)),
	setUploadingProcessActive: (value) => dispatch(setUploadingProcessActive(value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractElementProperties);
