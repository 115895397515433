import React, { useLayoutEffect, useRef } from 'react';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import ColorHub from '../../../reusable/ColorHub/ColorHub';
import { useListCustomStyle } from '../../../reusable/CustomStyle/useCustomStyle';
import { joinClassNames } from '../../../services/elementHelperService';
import { screenRoutes } from '../../../constants/routesPath';

interface DeleteMediasProps {
	handleLibraryViewChange: () => void;
}

const RecycleBinSideBar = ({ handleLibraryViewChange }: DeleteMediasProps) => {
	const { t: translator } = useTranslation();

	const history = useHistory();
	const classes = useListCustomStyle();
	const recycleBinItemRef = useRef<HTMLElement | null>(null);

	const isSelected = history.location.pathname.match(screenRoutes.PUBLISH_RECYCLE_BIN);

	useLayoutEffect(() => {
		if (isSelected && recycleBinItemRef?.current) {
			recycleBinItemRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'end',
			});
		}
	}, [isSelected]);

	return (
		<List ref={(r) => (recycleBinItemRef.current = r)}>
			<ListItem
				button
				selected={isSelected}
				classes={{ selected: classes.selected }}
				className={joinClassNames(classes.customItem, 'searchFilter--item')}
				onClick={() => {
					handleLibraryViewChange();
				}}
			>
				<ListItemIcon style={{ minWidth: '30px' }}>
					<ColorHub component={<DeleteIcon />} />
				</ListItemIcon>
				<span className={classes.ellipsis}>{translator('LABEL_RECYCLE_BIN')}</span>
			</ListItem>
		</List>
	);
};

export default RecycleBinSideBar;
