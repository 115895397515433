import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { dialogSizes } from './../../../constants/dialogSizesConstant';
import { mediaNames } from '../../../constants/mediaConstants';
import LivePlayer from '../../../components/LivePlayer/LivePlayer';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import { isRequestFail } from '../../../services/inspectScreenHelperService';
import { showNotification } from '../../../actions/notificationActions';
import { generateId } from '../../../services/stringHelperService';
import { getSecondsFromTimelineTime } from '../../../services/timeStampService';
import { getModifiedSVGTemplate } from '../../../services/interactScreenHelperService';
import { interactMimeType } from '../../../constants/interactConstant';

import './InteractPreviewDialog.scss';

class InteractPreviewDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
		};
		this.player = null;
	}

	componentDidMount = () => {};

	componentWillUnmount() {
		if (this.player) {
			this.player.off('playable', (e) => {
				this._onPlayable(e);
			});
		}
	}

	handleClose = () => {
		this.setState({ open: false });
		if (this.props.hidePreviewProjectDialog) {
			this.props.hidePreviewProjectDialog();
		}
	};

	onPlayerCreated = () => {
		const { interactItems, t } = this.props;
		if (interactItems.length > 1) {
			this.player = window.GoBrain.widgets()['interactPreviewPlayer-od'];
			this.player.once('playable', (e) => {
				this._onPlayable(e);
			});
		} else {
			this.props.showNotification(t('INTERACT_ELEMENTS_EMPTY'));
		}
	};

	_onPlayable = () => {
		this.previewProject();
	};

	getSequences = () => {
		const { interactItems, mediaId } = this.props;
		let sequences = [];
		interactItems.map((item) => {
			if (item.id !== mediaId) {
				let obj = {
					type: 'custom',
					custom: {
						mimeType: interactMimeType,
						value: getModifiedSVGTemplate(item),
						/*interactiveWidget: {
                            template: {
                                id: "123456789"
                            },
                            settings: {
                                general: {
                                    initialPause: {
                                        value: "play"
                                    },
                                    animateIn: {
                                        value: "fadein-0s"
                                    }
                                },
                                button1: {
                                    x: {
                                        value: 200
                                    },
                                    y: {
                                        value: 200
                                    },
                                    width: {
                                        value: 400
                                    },
                                    height: {
                                        value: 200
                                    },
                                    imageUrl: {
                                        value: "https://0ab291be162e0c3c5189d37f7e5b7935-httpcache0-90006-cachedown0.dna.ip-only.net/90006-cachedown0/assets/fe/fe474af33429492f90d6e3e003cc91d6/DNB%20post.png"
                                    },
                                    hoverUrl: {
                                        value: "http://55706112c3aec42cf0c75b179186fb58-httpcache0-90006-cachedown0.dna.qbrick.com/90006-cachedown0/assets/d3/d3dcd73d-00090006/btn1.png"
                                    },
                                    action: {
                                        value: {}
                                    }
                                },
                                button1: {
                                    x: {
                                        value: 200
                                    },
                                    y: {
                                        value: 200
                                    },
                                    width: {
                                        value: 400
                                    },
                                    height: {
                                        value: 200
                                    },
                                    imageUrl: {
                                        value: "https://0ab291be162e0c3c5189d37f7e5b7935-httpcache0-90006-cachedown0.dna.ip-only.net/90006-cachedown0/assets/fe/fe474af33429492f90d6e3e003cc91d6/DNB%20post.png"
                                    },
                                    hoverUrl: {
                                        value: "http://55706112c3aec42cf0c75b179186fb58-httpcache0-90006-cachedown0.dna.qbrick.com/90006-cachedown0/assets/d3/d3dcd73d-00090006/btn1.png"
                                    },
                                    action: {
                                        value: {}
                                    }
                                }
                            }
                        }*/
					},
					id: item.id,
					start: getSecondsFromTimelineTime(item.start),
					end: getSecondsFromTimelineTime(item.end),
				};
				sequences.push(obj);
			}
		});
		return sequences;
	};

	previewProject = () => {
		const { mediaId, t, defaultAccountId } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.showNotification(t('FAILED_FETCH_MEDIA_DETAILS'));
				return;
			}
			media.tracks = [
				{
					id: generateId(),
					name: 'custom',
					sequences: this.getSequences(),
				},
			];
			if (this.player && this.player.loadData) {
				this.player.loadData(media);
			} else {
				this.props.showNotification(t('FAILED_TO_LOAD_DATA_PLAYER'));
			}
		});
	};

	render() {
		const { mediaId } = this.props;
		const { message } = this.state;
		return (
			<>
				<div className="InteractPreviewDialog">
					<Dialog
						onClose={this.handleClose}
						id="InteractPreviewDialog"
						fullWidth={true}
						maxWidth={dialogSizes.MEDIUM}
						open={this.state.open}
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6"></Typography>
							<IconButton onClick={this.handleClose}>
								<Close/>
							</IconButton>
						</DialogTitle>

						<DialogContent className="interactPreviewContent">
							<p> {message}</p>
							<LivePlayer
								configUrl={
									'https://video.qbrick.com/play2/api/v1/accounts/Accpy7KNHj1IUilFL5BMuNL0Q/configurations/interactive-manager'
								}
								onPlayerCreated={this.onPlayerCreated}
								isLive={false}
								entityId={mediaId}
								defaultAccountId={this.props.defaultAccountId}
								entityType={mediaNames.medias}
								widgetPrefix="interactPreviewPlayer"
								container="interactPreviewContainer"
								height="504"
								width="896"
								hidePlayButton={false}
								configurationId="interactiveEditor"
							/>
						</DialogContent>
					</Dialog>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
});

const mapDispatchToProps = (dispatch) => ({
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	showNotification: (message) => dispatch(showNotification(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractPreviewDialog);
