import BackendClient from './backendClient';
import { readApiEndpointV1, writeApiEndpointV1 } from '../utils/config';

export const postMessage = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/messages`, body);

export const postReaction = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/reactions`, body);

export const blockUser = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/users/block`, body);

export const unblockUser = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/users/unblock`, body);

export const updateMessage = (accountId, mediaId, messageId, body) =>
	BackendClient.put(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/messages/${messageId}`, body, false, false);

export const pinMessage = (accountId, mediaId, messageId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/messages/${messageId}/pin`);
export const unpinMessage = (accountId, mediaId, messageId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/messages/${messageId}/unpin`);

export const getUsers = (accountId, mediaId) => BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/users`);

export const getBlockedUsers = (accountId, mediaId) =>
	BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/users/block`);

export const blockMessages = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/messages/block`, body);

export const getMessages = (accountId, mediaId) => BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/messages`);

export const getReactions = (accountId, mediaId) => BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/reactions`);

export const getAccountChatSettings = (accountId) => BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/settings`);

export const getMediaChatSettings = (accountId, mediaId) =>
	BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/settings`);

export const saveMediaChatSettings = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/settings`, body);

// const tempServer = 'https://as1266.qbrick.com:85/api/v1/';
// QnA
export const fetchAllQnAs = (accountId, mediaId) => BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/qnas`);

export const fetchQuestions = (accountId, mediaId, qnaId) =>
	BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/qnas/${qnaId}/questions`);

export const _createQnA = (accountId, mediaId, body) =>
	BackendClient.post(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/qnas`, body);

export const _publishQnAPrompt = (accountId, mediaId, qnaId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/qnas/${qnaId}/publish`);

export const _unpublishQnAPrompt = (accountId, mediaId, qnaId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/qnas/${qnaId}/unpublish`);
