import React, { Component, createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Redirect, Switch } from 'react-router';
import { Route, HashRouter } from 'react-router-dom';

import Header from './header/Header.tsx';
import SnackBar from '../reusable/Snackbar/Snackbar';
import Global from '../components/Global/Global';
import LoginScreen from '../screens/LoginScreen/LoginScreen';
import SSOScreen from '../screens/LoginScreen/SSOScreen';
import Dashboard from '../screens/DashboardScreen/Dashboard';
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen';
import AppScreen from '../screens/AppScreen/AppScreen';
import CreateScreen from '../screens/CreateScreen/CreateScreen';
import LiveScreen from '../screens/LiveScreen/LiveScreen';
import InspectMediaScreen from '../screens/PublishScreen/InspectScreen/InspectMediaScreen';
import InspectCatalogScreen from '../screens/PublishScreen/InspectScreen/InspectCatalogScreen';
import InspectPlaylist from '../screens/PublishScreen/InspectScreen/InspectPlaylist';
import { screenRoutes } from './../constants/routesPath';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ScenarioMainChart from '../screens/Scenario/ScenarioMainChart';
import InspectScenarioScreen from '../screens/Scenario/InspectScenarioScreen';
import ScenarioEditor from '../screens/Scenario/SceneEditor/SceneEditor';

import AnalyticsBrowsersScreen from '../screens/AnalyticsScreen/AnalyticsBrowsersScreen';
import AnalyticsBrowsersTypeScreen from '../screens/AnalyticsScreen/AnalyticsBrowsersTypeScreen';
import AnalyticsCitiesScreen from '../screens/AnalyticsScreen/AnalyticsCitiesScreen';
import AnalyticsCountriesScreen from '../screens/AnalyticsScreen/AnalyticsCountriesScreen';
import AnalyticsDeviceTypesScreen from '../screens/AnalyticsScreen/AnalyticsDeviceTypesScreen';
import AnalyticsGeneralPerformanceScreen from '../screens/AnalyticsScreen/AnalyticsGeneralPerformanceScreen';
import AnalyticsOperatingSystemsScreen from '../screens/AnalyticsScreen/AnalyticsOperatingSystemsScreen';
import AnalyticsProtocolScreen from '../screens/AnalyticsScreen/AnalyticsProtocolScreen';
import AnalyticsRealTimeScreen from '../screens/AnalyticsScreen/AnalyticsRealTimeScreen';
import AnalyticsRegionsScreen from '../screens/AnalyticsScreen/AnalyticsRegionsScreen';
import AnalyticsSocialScreen from '../screens/AnalyticsScreen/AnalyticsSocialScreen';
import AnalyticsStorageScreen from '../screens/AnalyticsScreen/AnalyticsStorageScreen';
import AnalyticsUrlPerformanceScreen from '../screens/AnalyticsScreen/AnalyticsUrlPerformanceScreen';
import AnalyticsVideoPerformanceScreen from '../screens/AnalyticsScreen/AnalyticsVideoPerformanceScreen';
import AnalyticsVideoPerformanceByMediaIdScreen from '../screens/AnalyticsScreen/AnalyticsVideoPerformanceByMediaIdScreen';
import { checkPermissionGrantedFor } from '../services/componentReusableService';
import { mediaLibraryKeys, mediaNames } from '../constants/mediaConstants';
import MediaLibrary from '../screens/PublishScreen/MediaLibrary/MediaLibrary';
import ScreenRecorderConfigModal from '../components/ScreenRecoder/ScreenRecorderConfigModal';

import '../screens/PublishScreen/PublishScreen.scss';
import './../responsive.scss';
import './App.scss';

const theme = createTheme({
	typography: { fontFamily: 'Poppins', button: { textTransform: 'none', fontSize: '13px' } },
	palette: { primary: { main: '#126CFC', dark: '#0053d8' } },
});

const CustomRoute = ({
	exact: exact,
	path: path,
	component: Component,
	extraClassName,
	permissionType,
	userPermissions,
	...otherProps
}) => {
	const neededPermission = permissionType && userPermissions;
	const permissonGranted = neededPermission || checkPermissionGrantedFor(userPermissions, permissionType);

	if (neededPermission && !permissonGranted) {
		return <Redirect to={screenRoutes.PUBLISH_ALL_MEDIA} />;
	}

	if (!neededPermission || (neededPermission && permissonGranted)) {
		return (
			<Route
				exact={exact}
				path={path}
				render={(props) => (
					<div className={extraClassName} style={{ width: '100%', height: '100%' }}>
						<Component {...props} {...otherProps} />
					</div>
				)}
			/>
		);
	}
};

class App extends Component {
	constructor(props) {
		super(props);
		// init screenRecord Modal here because we want to this modal persisten when we move around qvp
		this.screenRecordModalRef = createRef(null);
	}

	render() {
		const NoMatch = ({ location }) => (
			<div>
				<h3>
					Sorry, we could not find <code>{location.pathname}</code>
				</h3>
			</div>
		);

		if (window.location.hostname === 'localhost' || window.location.hostname === 'stage.video.local') {
		} else {
			if (window.location.protocol != 'https:') {
				window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
			}
		}

		return (
			<ThemeProvider theme={theme}>
				<HashRouter>
					<>
						<Route component={Global} />
						<Route component={SnackBar} />
						<Route component={Header} />
						<Switch>
							<Route exact path="/">
								{this.props.isLoggedIn ? <Redirect to={screenRoutes.DASHBOARD} /> : <LoginScreen />}
							</Route>
							<Route exact path={screenRoutes.LOGIN}>
								{this.props.isLoggedIn ? <Redirect to={screenRoutes.DASHBOARD} /> : <LoginScreen />}
							</Route>

							<Route exact path={'/sso/:callback'} component={SSOScreen} />

							<CustomRoute exact path={screenRoutes.DASHBOARD} component={Dashboard} />

							{/* Create */}
							<CustomRoute exact path="/create" component={CreateScreen} />

							{/* Live */}
							<CustomRoute exact path={screenRoutes.LIVE} component={LiveScreen} />

							{/* Media routes */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY}
								component={() => <Redirect to={screenRoutes.PUBLISH_ALL_MEDIA} />}
								mediaType={mediaNames.medias}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_ALL_MEDIA}
								component={MediaLibrary}
								mediaType={mediaNames.medias}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/:mediaId/detail'}
								component={InspectMediaScreen}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/medias/:mediaId/detail/:menuKey?'}
								component={InspectMediaScreen}
							/>

							<CustomRoute
								exact
								path={
									screenRoutes.PUBLISH_MEDIA_LIBRARY +
									'/medias/:mediaId/detail/:menuKey?/:subMenuKey?'
								}
								component={InspectMediaScreen}
							/>
							{/* End Media routes */}

							{/* Calalog route */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS}
								component={MediaLibrary}
								mediaType={mediaNames.catalogs}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + '/:catalogId'}
								component={MediaLibrary}
								mediaType={mediaNames.catalogs}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + ':catalogId/detail'}
								component={InspectCatalogScreen}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + '/:catalogId/detail/:menuKey?'}
								component={InspectCatalogScreen}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_CATALOGS + '/:catalogId/detail/:menuKey?/:subMenuKey?'}
								component={InspectCatalogScreen}
							/>

							{/* End catalog routes */}

							{/* Playlist routes */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_PLAYLISTS}
								component={MediaLibrary}
								mediaType={mediaNames.playlists}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_PLAYLISTS + '/:playlistsId/detail'}
								component={InspectPlaylist}
							/>

							{/* End Playlist routes */}

							{/* Smart filter routes */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_SMART_FILTER}
								component={MediaLibrary}
								mediaType={mediaLibraryKeys.smartFilter}
							/>
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_SMART_FILTER + '/:filterId'}
								component={MediaLibrary}
								mediaType={mediaLibraryKeys.smartFilter}
							/>
							{/* End Smart filter routes */}

							{/* Scenarios routes */}
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_SCENARIOS}
								component={MediaLibrary}
								mediaType={mediaNames.scenarios}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/interact/:scenarioId/chart'}
								recorderModalRef={this.screenRecordModalRef}
								component={ScenarioMainChart}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/interact/:scenarioId/detail'}
								component={InspectScenarioScreen}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={screenRoutes.PUBLISH_MEDIA_LIBRARY + '/interact/:scenarioId/detail/:menuKey?'}
								component={InspectScenarioScreen}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={
									screenRoutes.PUBLISH_MEDIA_LIBRARY +
									'/interact/:scenarioId/detail/:menuKey?/:subMenuKey?'
								}
								component={InspectScenarioScreen}
							/>
							<CustomRoute
								permissionType="scenarios"
								userPermissions={this.props.userPermissions}
								exact
								path={
									screenRoutes.PUBLISH_MEDIA_LIBRARY +
									'/interact/:scenarioId/detail/scenes/:sceneId/edit'
								}
								component={ScenarioEditor}
							/>
							{/* End Scenarios routes */}

							{/* Edited media with adobe express routes */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_EDITED_MEDIA}
								component={MediaLibrary}
								mediaType={mediaNames.editedMedias}
							/>

							{/* End edited media with adobe express routes */}

							{/* Recycle bin routes */}
							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN}
								component={MediaLibrary}
								mediaType={mediaNames.trashedMedias}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN + '/:mediaId/detail'}
								component={InspectMediaScreen}
								mediaType={mediaNames.trashedMedias}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN + '/:mediaId/detail/:menuKey?'}
								component={InspectMediaScreen}
								mediaType={mediaNames.trashedMedias}
							/>

							<CustomRoute
								exact
								path={screenRoutes.PUBLISH_RECYCLE_BIN + '/:mediaId/detail/:menuKey?/:subMenuKey?'}
								component={InspectMediaScreen}
								mediaType={mediaNames.trashedMedias}
							/>

							{/* End edited media with adobe express routes */}

							{/* Analytics */}
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS}
								component={AnalyticsVideoPerformanceScreen}
							/>
							<CustomRoute
								path={screenRoutes.ANALYTICS + '/:mediaId'}
								component={AnalyticsVideoPerformanceByMediaIdScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_BROWSERS}
								component={AnalyticsBrowsersScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_BROWSERS_TYPE}
								component={AnalyticsBrowsersTypeScreen}
							/>
							<CustomRoute exact path={screenRoutes.ANALYTICS_CITIES} component={AnalyticsCitiesScreen} />
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_COUNTRIES}
								component={AnalyticsCountriesScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_DEVICE_TYPE}
								component={AnalyticsDeviceTypesScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_GENERAL_PERFORMANCE}
								component={AnalyticsGeneralPerformanceScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_OPERATING_SYSTEMS}
								component={AnalyticsOperatingSystemsScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_PROTOCOL}
								component={AnalyticsProtocolScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_REAL_TIME}
								component={AnalyticsRealTimeScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_REGIONS}
								component={AnalyticsRegionsScreen}
							/>
							<CustomRoute exact path={screenRoutes.ANALYTICS_SOCIAL} component={AnalyticsSocialScreen} />
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_STORAGE}
								component={AnalyticsStorageScreen}
							/>
							<CustomRoute
								exact
								path={screenRoutes.ANALYTICS_URL_PERFORMANCE}
								component={AnalyticsUrlPerformanceScreen}
							/>

							<CustomRoute exact path={'/test'} component={AnalyticsUrlPerformanceScreen} />

							{/* Apps */}
							<CustomRoute exact path={screenRoutes.APPS + '/:appId/:configId'} component={AppScreen} />

							{/* Settings */}
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS}
								extraClassName="profileSettings__wrapper"
								component={ProfileScreen}
							/>
							<CustomRoute exact path={screenRoutes.SETTINGS + '/users'} component={ProfileScreen} />
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS + '/notification'}
								component={ProfileScreen}
							/>
							<CustomRoute exact path={screenRoutes.SETTINGS + '/support'} />
							<CustomRoute
								exact
								path={screenRoutes.SETTINGS + '/players'}
								extraClassName="profileSettings__wrapper"
								component={() => <ProfileScreen selectedProfileBlockIndex="8" />}
							/>

							<Route component={NoMatch} />
						</Switch>
					</>
				</HashRouter>
				<ScreenRecorderConfigModal ref={this.screenRecordModalRef} />
			</ThemeProvider>
		);
	}
}
const mapStateToProps = ({ session }) => ({
	isLoggedIn: session.isLoggedIn,
	userPermissions: session.userPermissions,
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(App);
