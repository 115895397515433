import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
const TopMetrics = ({ reportsSummaryData }) => {
	return (
		<>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">Views</Typography>
					<Typography className="paperTxt paperNum">{reportsSummaryData?.views?.sum?.value}</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">Unique views</Typography>
					<Typography className="paperTxt paperNum">{reportsSummaryData?.uniqueViews?.sum?.value}</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">Completes</Typography>
					<Typography className="paperTxt paperNum">{reportsSummaryData?.completes?.sum?.value}</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">Watched</Typography>
					<Typography className="paperTxt paperNum">
						{reportsSummaryData?.percentageWatched?.average?.value}%
					</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} sm={4} lg={2}>
				<Paper elevation={3} className="paper">
					<Typography className="paperTxt">Popularity</Typography>
					<Typography className="paperTxt paperNum">
						{reportsSummaryData?.popularity?.score?.value}
					</Typography>
				</Paper>
			</Grid>
		</>
	);
};

export default memo(TopMetrics);
