import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import * as H from 'history';
import { Button } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';

import BreadCrumb from '../../../layout/header/breadCrumb';
import { customScreenType, mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { detailsMenuKeys } from '../../../constants/inspectScreenMenu';
import {
	clearBreadCrumbLocationArray,
	updateBreadCrumbLocationArray,
	updateScreenName,
} from '../../../actions/breadCrumbActions';
import { screenRoutes } from '../../../constants/routesPath';
import { KEYS } from '../../../layout/nav/navigationItems';
import LivePlayer from '../../../components/LivePlayer';
import {
	defaultMobileVideoHeight,
	defaultMobileVideoWidth,
	defaultVideoHeight,
	defaultVideoWidth,
} from '../../../services/inspectScreenHelperService';
import { joinClassNames } from '../../../services/elementHelperService';
import { showMessage } from '../../../actions/globalActions';
import { assignMediaItemToTheSelectedPlaylist } from '../../../actions/publishActions';
import MiniMediaLibrary from '../../../reusable/MiniMediaLibrary/MiniMediaLibrary';

import InspectPlayerPreviewArea from './InspectSections/InspectPlayerPreviewArea';
import ThumbnailManager from './InspectSections/ThumbnailManager/ThumbnailManager';
import InformationSection from './InspectSections/InformationSection/InformationSection';
import EmbedVideo from './InspectSections/EmbedVideo/EmbedVideo';

import InspectMediaMenu from './InspectMediaMenu';
import { PlaylistMedias } from './PlaylistMedias';
import useFetchMediaDetails from './useFetchMedia';
import GeneralDetails from './GeneralDetails';

import './InspectScreenNew.scss';

interface InspectPlaylistProps {
	match: any;
	history: H.History;
}

const InspectPlaylist: React.FC<InspectPlaylistProps> = ({ match, history }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();
	const containerRef = useRef<HTMLElement | null>(null);

	const { screenType } = useSelector((state) => (state as any).publish);
	const { defaultAccountId } = useSelector((state) => (state as any).session);

	const [selectedMenu, setSelectedMenu] = useState(detailsMenuKeys.playlistMediaDetails);
	const [containerHeight, setContainerHeight] = useState('100%');
	const [breadCrumbHeight, setBreadCrumbHeight] = useState(45);
	const [videoWidth, setVideoWidth] = useState(defaultVideoWidth);
	const [videoHeight, setVideoHeight] = useState(defaultVideoHeight);
	const [refreshData, setRefreshData] = useState(true);

	const [showInfomationZone, setShowInfomationZone] = useState(false);

	const { mediaDetails, embedVersion } = useFetchMediaDetails({
		mediaId: match.params.playlistsId,
		mediaName: mediaNames.playlists,
		refreshData,
		resetRefreshData: () => setRefreshData(false),
	});

	const isSmallScreen = useMemo(
		() => screenType !== customScreenType.large && screenType !== customScreenType.medium,
		[screenType]
	);

	const onSelectedMenuChange = useCallback((_menuId: string) => {
		setSelectedMenu(_menuId);
		setShowInfomationZone(true);
	}, []);

	const handleAddMediaSelection = useCallback(
		(medias: any[]) => {
			if (!medias.length) {
				dispatch(showMessage(t('NO_MEDIAS_SELECTED'), messageTypes.error));
				return;
			}

			let counter = 0;
			let failedCounter = 0;
			medias.forEach((media) => {
				dispatch(
					assignMediaItemToTheSelectedPlaylist(defaultAccountId, match.params.playlistsId, media.id)
				).then((res: any) => {
					counter++;
					if (!res) {
						failedCounter++;
					}
					if (counter === medias.length) {
						setTimeout(() => {
							dispatch(showMessage(t('LABEL_ASSIGN_TO_PLAYLIST_SUCCESS'), messageTypes.info));
							setRefreshData(true);
						}, 3000);
					}
					if (failedCounter) {
						dispatch(showMessage(t('FAILED_ASSIGN_TO_PLAYLIST'), messageTypes.error));
					}
				});
			});
		},
		[defaultAccountId, dispatch, match.params.playlistsId, t]
	);

	const getContainerHeight = useCallback(() => {
		const previewWrapper = document.querySelector('.inspect-container-right');
		let widthToSet = (previewWrapper ? previewWrapper.getBoundingClientRect().width : window.innerWidth) - 24;
		let heightToSet = (Number(widthToSet) * 9) / 16;

		if (isSmallScreen) {
			widthToSet = Number(defaultMobileVideoWidth);
			heightToSet = Number(defaultMobileVideoHeight);
			let screenSize = 90; // for mobile screen to occupy 100% width
			widthToSet = (screenSize / 100) * window.innerWidth - 24;
			heightToSet = (widthToSet * 9) / 16;
		}

		setVideoWidth(widthToSet.toString());
		setVideoHeight(heightToSet.toString());

		if (!containerRef.current) {
			return;
		}
		const header = document.querySelector('.header-container');
		const breadCrumb = document.querySelector('#breadCrumbSection');
		const headerNo = header?.getBoundingClientRect().height ?? 0;
		const breadCrumbNo = breadCrumb?.getBoundingClientRect().height ?? 0;
		setBreadCrumbHeight(breadCrumbNo);
		setContainerHeight(window.innerHeight - headerNo - breadCrumbNo + 'px');
	}, [isSmallScreen]);

	const updateBreadCrumb = useCallback(() => {
		if (!mediaDetails) {
			return;
		}

		dispatch(clearBreadCrumbLocationArray());
		let breadCrumbLocationArray = [];
		breadCrumbLocationArray.push({
			href: screenRoutes.PUBLISH_PLAYLISTS + '/' + mediaDetails.id + '/' + 'detail',
			text: mediaDetails.title,
		});
		dispatch(updateBreadCrumbLocationArray(KEYS.publish, breadCrumbLocationArray));
		dispatch(updateScreenName(mediaDetails?.title ?? ''));
	}, [dispatch, mediaDetails]);

	useEffect(() => {
		getContainerHeight();
		updateBreadCrumb();
		window.addEventListener('resize', getContainerHeight);

		return () => window.removeEventListener('resize', getContainerHeight);
	}, [getContainerHeight, updateBreadCrumb]);

	const renderRightArea = () => (
		<div
			className="inspect-container inspect-container-right"
			style={!isSmallScreen ? { marginTop: `-${breadCrumbHeight}px` } : undefined}
		>
			{embedVersion === '1' && mediaDetails && (
				<>
					<LivePlayer
						width={videoWidth}
						height={videoHeight}
						isLive={false}
						entityType={mediaNames.playlists}
						entityId={mediaDetails?.id}
						widgetPrefix={'mediaDetail-' + mediaDetails?.id}
						container={'mediaDetailContainer-' + mediaDetails?.id}
					/>
					<InformationSection t={t} mediaDetails={mediaDetails} width={defaultVideoWidth} />
				</>
			)}
			{embedVersion === '2' && mediaDetails && (
				<InspectPlayerPreviewArea
					mediaId={mediaDetails?.id}
					videoHeight={videoHeight}
					videoWidth={videoWidth}
					mediaDetails={mediaDetails}
					placementType={'playlists'}
					breadCrumbHeight={breadCrumbHeight}
				/>
			)}
		</div>
	);

	const renderLeftArea = () => (
		<div className="inspect-container inspect-container-left">
			<InspectMediaMenu onSelectedMenuChange={onSelectedMenuChange} embedVersion={embedVersion} />
		</div>
	);

	const renderContent = () => (
		<div
			className="inspect-container__content"
			style={{ overflow: 'auto', height: isSmallScreen ? 'calc(100% - 150px)' : '' }}
		>
			{mediaDetails && selectedMenu === detailsMenuKeys.playlistMediaDetails && !refreshData && (
				<PlaylistMedias mediaDetails={mediaDetails} />
			)}
			{mediaDetails && selectedMenu === detailsMenuKeys.mediaDetails && (
				<GeneralDetails
					mediaDetails={mediaDetails}
					mediaName={mediaNames.playlists}
					history={history}
					onSaveChange={() => setRefreshData(true)}
				/>
			)}
			{mediaDetails && selectedMenu === detailsMenuKeys.thumbnailManager && (
				<div className="inspect-container__content-padding">
					<ThumbnailManager
						t={t}
						mediaName={mediaNames.playlists}
						mediaDetails={mediaDetails}
						mediaId={mediaDetails?.id}
					/>
				</div>
			)}
			{mediaDetails && selectedMenu === detailsMenuKeys.embedVideo && embedVersion === '1' && (
				<div className="inspect-container__content-padding">
					<EmbedVideo id={mediaDetails?.id} placementType={'playlist'} mediaDetails={mediaDetails} />
				</div>
			)}
		</div>
	);

	if (!isSmallScreen) {
		return (
			<>
				<Helmet>
					<title>Playlist</title>
				</Helmet>
				<BreadCrumb history={history} hasBorder />
				<div
					className="inspect-container-wrapper"
					ref={(r) => (containerRef.current = r)}
					style={{ height: containerHeight }}
				>
					{renderLeftArea()}
					<div className="inspect-container inspect-container-middle">
						<div className="inspect-container__content-header">
							{selectedMenu === detailsMenuKeys.playlistMediaDetails && (
								<>
									<span>Videos</span>
									<MiniMediaLibrary
										handleSelectedMedia={handleAddMediaSelection}
										useNewDesign
										actionButtonText={t('ADD_VIDEOS')}
									/>
								</>
							)}
							{selectedMenu === detailsMenuKeys.mediaDetails && 'General'}
							{selectedMenu === detailsMenuKeys.embedVideo && embedVersion === '1' && 'Embed'}
							{selectedMenu === detailsMenuKeys.thumbnailManager && 'Thumbnail'}
						</div>
						{renderContent()}
					</div>
					{renderRightArea()}
				</div>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>Playlist</title>
			</Helmet>
			<BreadCrumb history={history} hasBorder />
			<div
				className={joinClassNames(
					'inspect-container-wrapper inspect-container-wrapper--mobile',
					showInfomationZone ? '' : 'inspect-container-wrapper--scroll'
				)}
				ref={(r) => (containerRef.current = r)}
				style={{ height: containerHeight }}
			>
				{!showInfomationZone && (
					<>
						{renderRightArea()}
						{renderLeftArea()}
					</>
				)}
				{showInfomationZone && (
					<>
						<div className="inspect-container__action">
							<Button
								variant="contained"
								color="primary"
								className="inspect-container__back-btn"
								onClick={() => setShowInfomationZone(false)}
							>
								<KeyboardBackspace />
								{selectedMenu === detailsMenuKeys.playlistMediaDetails && 'Videos'}
								{selectedMenu === detailsMenuKeys.mediaDetails && 'General'}
								{selectedMenu === detailsMenuKeys.embedVideo && embedVersion === '1' && 'Embed'}
								{selectedMenu === detailsMenuKeys.thumbnailManager && 'Thumbnail'}
							</Button>
						</div>
						<div className="inspect-container inspect-container-middle">
							<div className="inspect-container__content-header">
								{selectedMenu === detailsMenuKeys.playlistMediaDetails && (
									<>
										<span>Videos</span>
										<MiniMediaLibrary
											handleSelectedMedia={handleAddMediaSelection}
											useNewDesign
											actionButtonText={t('ADD_VIDEOS')}
										/>
									</>
								)}
								{selectedMenu === detailsMenuKeys.mediaDetails && 'General'}
								{selectedMenu === detailsMenuKeys.embedVideo && embedVersion === '1' && 'Embed'}
								{selectedMenu === detailsMenuKeys.thumbnailManager && 'Thumbnail'}
							</div>

							{renderContent()}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default InspectPlaylist;
