import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'underscore';

import {
	getInteractElements,
	setComponentStaticTrue,
	setComponentStaticFalse,
} from './../../actions/interactElementsActions';
import IconsStore from '../../reusable/IconsStore/IconsStore';
import { interactWidgetsList, interactWidgetsListKeys } from '../../constants/interactConstant';
import './InteractElementsTemplate.scss';

class InteractElementsTemplate extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			interactElements: [],
		};
	}

	componentDidMount() {
		this.fetchInteractElements();
	}

	fetchInteractElements = () => {
		const { defaultAccountId } = this.props;
		this.props.getInteractElements(defaultAccountId).then((res) => {
			if (res && res.map) {
				this.setState({
					interactElements: res,
				});
			}
		});
	};

	onDragStart = (e, element) => {
		let objectItem = {
			target: 'item',
			content: element.templateName,
			...element,
		};
		e.dataTransfer.setData('text', JSON.stringify(objectItem));
		e.stopPropagation();
	};

	renderEachElements = (element) => {
		if (element) {
			let widgetElement = _.find(interactWidgetsList, (widget) => {
				return widget.id === element.templateId;
			});

			return (
				<>
					<div
						className="interactSingleElements"
						onMouseOver={() => this.props.setComponentStaticTrue()}
						onMouseOut={() => this.props.setComponentStaticFalse()}
						draggable="true"
						onDragStart={(e) => {
							this.onDragStart(e, element);
						}}
						onDragStop={(e) => {
							e.stopPropagation();
						}}
					>
						<div className="interactElementIcon">
							{widgetElement && <IconsStore iconName={widgetElement.img} />}
						</div>
						<label>{element.templateName}</label>
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	render() {
		const { t } = this.props;
		const { interactElements } = this.state;

		let interactItems = interactElements;

		if (this.props.isOnLiveMode) {
			interactItems = interactItems.filter(
				(item) =>
					item.templateId != interactWidgetsListKeys.jumpToTime &&
					item.templateId != interactWidgetsListKeys.interactChapter
			);
		}

		return (
			<>
				<div id="interactElements">
					<div className="dragInfo">
						<span> {t('DRAG_AND_DROP_TO_ADD')} </span>
					</div>
					<div className={interactItems.length > 0 ? 'interactElementsList' : ''}>
						{interactItems.map((elements) => this.renderEachElements(elements))}
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	getInteractElements: () => dispatch(getInteractElements()),
	setComponentStaticTrue: () => dispatch(setComponentStaticTrue()),
	setComponentStaticFalse: () => dispatch(setComponentStaticFalse()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractElementsTemplate);
