import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import IconsStore from './../../../../reusable/IconsStore/IconsStore';
import { IconsList } from './../../../../reusable/IconsStore/IconsList';
import copy_white from './../../../../assets/images/copy_white.svg';
import eye_black from './../../../../assets/images/eye_black.svg';
import eye_black_slash from './../../../../assets/images/eye_black_slash.svg';
import down_arrow_black from './../../../../assets/images/down_arrow_black.svg';
import { showNotification } from './../../../../actions/notificationActions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ListingMenuDropDown } from './../../../../reusable/ListingMenuDropDown/ListingMenuDropDown';

import { getIPonlyChannels } from './../../../../actions/iponlyChannelActions';

import { createIPOnlyChannel, deleteIPOnlyChannel } from './../../../../actions/workflowActions';

import { workflowTypes } from './../../../../constants/workflowTypes';
import { channelProfiles } from './../../../../constants/channelProfiles';
import FeaturesService from './../../../../services/FeaturesService';
import { loadChannels, updateChannel } from './../../../../actions/channelActions';
import { generateChannelId } from './../../../../services/stringHelperService';

class Channels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			channels: [],
			featurePermissions: [],
			customerId: null,
			accounts: [],
			channelToEdit: {},
			channelToCreate: {},
			currentlySelectedChannel: {},
			showEditDialog: false,
			visibleChannelList: [],
			channelLimit: 0,
		};
	}

	componentDidMount = () => {
		this.initialize();
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.customerId !== this.state.customerId) {
		}
	}

	initialize = () => {
		const { defaultAccountId, loadChannels, getIPonlyChannels } = this.props;
		loadChannels(defaultAccountId).then(() => {});

		getIPonlyChannels(defaultAccountId, 'liveorigin1').then((data) => {
			this.setState({ channelLimit: data && data.channelLimit ? data.channelLimit : 0 });
		});
	};

	showEditDialogBoxHandler = (data) => {
		this.setState({ showEditDialog: true });
		let channel = this.state.channelToEdit || {};
		channel = data;
		channel.id = data.id;
		channel.metadata = data.metadata;

		this.setState({ channelToEdit: channel });
	};

	handleNewUserDialogBox = (_) => {
		const { channels } = this.props;
		const { channelLimit } = this.state;

		const notLegacyChannels = (channels ?? []).filter((c) => !c?.id?.includes('legacy'));

		if (channelLimit <= notLegacyChannels.length) {
			showNotification('We cannot create new channel, as maximum limit is being reached.');
			return;
		}
		this.setState({ showCreateDialog: true });
	};

	hideNewDialogHandler = (_) =>
		this.setState({ showCreateDialog: false }, () => {
			this.resetChannelToCreate();
		});
	hideEditDialogHandler = () =>
		this.setState({ showEditDialog: false }, () => {
			this.resetChannelToEdit();
		});

	handleUpdate = () => {
		const { defaultAccountId, updateChannel } = this.props;
		const { channelToEdit } = this.state;

		if (!channelToEdit.metadata.title) {
			showNotification('Please enter channel name');
			return;
		}

		updateChannel(defaultAccountId, channelToEdit.id, channelToEdit).then(() => {
			//Refresh channel list
			this.initialize();
		});

		this.setState({ showEditDialog: false });
	};

	handleCreation = () => {
		const { defaultAccountId, createIPOnlyChannel, showNotification, channels } = this.props;
		const { channelToCreate, channelLimit } = this.state;

		const notLegacyChannels = (channels ?? []).filter((c) => !c?.id?.includes('legacy'));

		if (channelLimit <= notLegacyChannels.length) {
			showNotification('We cannot create new channel, as maximum limit is being reached.');
			return;
		}

		if (!channelToCreate.title) {
			showNotification('Please enter channel name');
			return;
		}

		if (!channelToCreate.termAndConditionsAccepted) {
			showNotification('Please accept the term and conditions');
			return;
		}

		let body = {
			workflow: {
				id: workflowTypes.CREATE_IPONLY_CHANNEL.ID,
			},
			input: {
				channelId: generateChannelId(),
				title: channelToCreate.title,
				description: channelToCreate.description,
				channelTemplate: channelToCreate.profile,
				//channelTemplate: channelToCreate.profile === "1080p" ? "standard-1080p-169-6-profiles" : "standard-720p-169-5-profiles",
			},
		};

		//this.setState({ totalChannels: channels && channels.length >= 0 ? channels.length : 0 })

		createIPOnlyChannel(defaultAccountId, body).then(() => {
			//Refresh channel list
			this.initialize();

			setTimeout(() => {
				this.initialize();
			}, 1500); // Refresh channals again, if still not created
		});

		this.setState({ showCreateDialog: false });
	};

	handleDeletion = () => {
		const { currentlySelectedChannel } = this.state;
		const { defaultAccountId, deleteIPOnlyChannel, showNotification, t } = this.props;
		let body = {
			workflow: {
				id: workflowTypes.DELETE_IPONLY_CHANNEL.ID,
			},
			input: {
				channelId: currentlySelectedChannel.id,
			},
		};

		deleteIPOnlyChannel(defaultAccountId, body).then(() => {
			//Refresh channel list
			this.initialize();
			showNotification(t('CHANNEL_DELETED_SUCCESSFULLY'));
		});

		this.handleHideDeleteDialog();
	};

	handleDeleteUserDialogHandler = (channel) =>
		this.setState({ deleteDialogStatus: true, currentlySelectedChannel: channel });
	handleHideDeleteDialog = (_) => this.setState({ deleteDialogStatus: false });
	showActionMenuItems = (channel, e) => this.setState({ currentlySelectedChannel: channel, actionAnchor: e.target });
	handleMenuClose = (menuAction, channel) => {
		if (menuAction === 'remove') {
			this.handleDeleteUserDialogHandler(channel);
		} else if (menuAction === 'details') {
			this.showEditDialogBoxHandler(channel);
		}
		this.setState({ actionAnchor: null });
	};

	handleVisibleChannels = (e, channelId, type) => {
		let list = this.state.visibleChannelList;

		if (this.existInVisibleChannels(channelId, type)) {
			list = list.filter((e) => {
				return e.channelId != channelId && e.type != type;
			});
		} else {
			list.push({ channelId: channelId, type: type });
		}
		this.setState({ visibleChannelList: list });
		e.stopPropagation();
	};

	existInVisibleChannels = (channelId, type) => {
		let list = this.state.visibleChannelList;
		return list &&
			list.findIndex((e) => {
				return e.channelId === channelId && e.type === type;
			}) === -1
			? false
			: true;
	};

	handleChannelNameChange = (event) => {
		let channel = this.state.channelToCreate;
		channel.title = event.target.value;
		this.setState({ channelToCreate: channel });
	};

	handleChannelDescriptionChange = (event) => {
		let channel = this.state.channelToCreate;
		channel.description = event.target.value;
		this.setState({ channelToCreate: channel });
	};

	handleProfileChange = (event) => {
		let channel = this.state.channelToCreate;
		channel.profile = event.target.value;
		this.setState({ channelToCreate: channel });
	};

	handleTermAndConditions = (event) => {
		let channel = this.state.channelToCreate;
		channel.termAndConditionsAccepted = event.target.checked;
		this.setState({ channelToCreate: channel });
	};

	handleChannelTitleChangeInEdit = (event) => {
		let channel = this.state.channelToEdit;
		channel.metadata.title = event.target.value;
		this.setState({ channelToEdit: channel });
	};

	handleChannelDescriptionChangeInEdit = (event) => {
		let channel = this.state.channelToEdit;
		channel.metadata.description = event.target.value;
		this.setState({ channelToEdit: channel });
	};

	handleTermAndConditionsInEdit = (event) => {
		let channel = this.state.channelToEdit;
		channel.termAndConditionsAccepted = event.target.checked;
		this.setState({ channelToEdit: channel });
	};

	isFeatureEnabled = (featureName) => {
		const { userPermissions } = this.props;
		return FeaturesService.IsEnabled(userPermissions, featureName);
	};

	resetChannelToEdit = (_) => {
		this.setState({ channelToEdit: {} });
	};

	resetChannelToCreate = (_) => {
		this.setState({ channelToCreate: {} });
	};

	handleCopy = (e, param) => {
		const { showNotification } = this.props;
		e.stopPropagation();
		showNotification(param + ' copied to the clipboard');
	};

	render() {
		const {
			showCreateDialog,
			showEditDialog,
			channelToEdit,
			channelToCreate,
			deleteDialogStatus,
			currentlySelectedChannel,
			channelLimit,
			actionAnchor,
		} = this.state;

		const { t, channels } = this.props;

		const notLegacyChannels = (channels ?? []).filter((c) => !c?.id?.includes('legacy'));

		return (
			this.isFeatureEnabled('Channels') && (
				<>
					<div className="channelsContentWrapper">
						<div className="channelsTableHeading">
							<h5>{t('CHANNEL_LIST_HEADER_LABEL')} </h5>
						</div>
						<div className="channelsListingTopHeader">
							{
								<label>
									{`You are using ${notLegacyChannels.length}/${channelLimit} available channels`}{' '}
								</label>
							}

							<Button
								variant="contained"
								className="newUserBtn defaultActionBtn"
								onClick={this.handleNewUserDialogBox}
								style={{ display: channelLimit <= notLegacyChannels.length ? 'none' : '' }}
								startIcon={<IconsStore iconName={IconsList.PLUS_NEW} color="#fff" />}
							>
								{t('LABEL_NEW')}
							</Button>
						</div>
						<div className="channelsTableWrapper">
							<div className="channelsTableRowCollection channelsTableHeader">
								<div className="channelsTableRow">
									<div className="channelsTableItem">
										<label>{t('CHANNEL_LIST_NAME_LABEL')}</label>
									</div>
									<div className="channelsTableItem">
										<label>{t('CHANNEL_LIST_DESCRIPTION_LABEL')}</label>
									</div>
									<div className="channelsTableItem">
										<label>{t('CHANNEL_LIST_QUALITY_LABEL')}</label>
									</div>
									<div className="channelsTableItem">
										<label>{t('CHANNEL_LIST_STREAM_URL_LABEL')}</label>
									</div>
									<div className="channelsTableItem">
										<label>{t('CHANNEL_LIST_STREAM_KEY_LABEL')}</label>
									</div>
									<div className="channelsTableItem"></div>
								</div>
							</div>
							<div className="channelsTableRowCollection channelsTableItems">
								{notLegacyChannels.map((channel, i) => {
									return (
										<div onClick={() => {}} key={i}>
											<div key={i} className="channelsTableRow">
												<div
													className="channelsTableItem"
													data-header={t('CHANNEL_LIST_NAME_LABEL')}
												>
													{channel.metadata && channel.metadata.title}
												</div>
												<div
													className="channelsTableItem"
													data-header={t('CHANNEL_LIST_DESCRIPTION_LABEL')}
												>
													{channel.metadata && channel.metadata.description}
												</div>
												<div
													className="channelsTableItem"
													data-header={t('CHANNEL_LIST_QUALITY_LABEL')}
												>
													{channel.liveOrigin &&
													channel.liveOrigin.manifest &&
													channel.liveOrigin.manifest.videos &&
													channel.liveOrigin.manifest.videos.filter((e) => {
														return e.height === 1080;
													}).length > 0
														? '1080p'
														: '720p'}
												</div>
												<div
													className="channelsTableItem"
													data-header={t('CHANNEL_LIST_STREAM_URL_LABEL')}
												>
													{this.existInVisibleChannels(channel.id, 'url') ? (
														<>
															{channel.ingestPoint && channel.ingestPoint.ingestURL}
															{channel.ingestPoint &&
																channel.ingestPoint.authentication &&
																channel.ingestPoint.authentication.password}
															<img
																src={eye_black}
																onClick={(e) =>
																	this.handleVisibleChannels(e, channel.id, 'url')
																}
																style={{ margin: '0 10px' }}
															></img>
														</>
													) : (
														<>
															{'--------------------------------'}
															<img
																src={eye_black_slash}
																onClick={(e) =>
																	this.handleVisibleChannels(e, channel.id, 'url')
																}
																style={{ margin: '0 10px' }}
															></img>
														</>
													)}
													<CopyToClipboard
														text={`${channel.ingestPoint && channel.ingestPoint.ingestURL}${
															channel.ingestPoint &&
															channel.ingestPoint.authentication &&
															channel.ingestPoint.authentication.password
														}`}
													>
														<img
															src={copy_white}
															onClick={(e) => this.handleCopy(e, 'Stream url')}
														></img>
													</CopyToClipboard>
												</div>

												<div
													className="channelsTableItem"
													data-header={t('CHANNEL_LIST_STREAM_KEY_LABEL')}
												>
													{this.existInVisibleChannels(channel.id, 'key') ? (
														<>
															{channel.id}
															<img
																src={eye_black}
																onClick={(e) =>
																	this.handleVisibleChannels(e, channel.id, 'key')
																}
																style={{ margin: '0 10px' }}
															></img>
														</>
													) : (
														<>
															{'-----------------'}
															<img
																src={eye_black_slash}
																onClick={(e) =>
																	this.handleVisibleChannels(e, channel.id, 'key')
																}
																style={{ margin: '0 10px' }}
															></img>
														</>
													)}
													<CopyToClipboard text={channel.id}>
														<img
															src={copy_white}
															onClick={(e) => this.handleCopy(e, 'Stream key')}
														></img>
													</CopyToClipboard>
												</div>

												<div className="channelsTableItem">
													<button
														className="actionButton"
														onClick={(e) => this.showActionMenuItems(channel, e)}
													>
														{t('USER_ACTIONS_BUTTON_LABEL')}
														<img src={down_arrow_black} />
													</button>
													{actionAnchor && currentlySelectedChannel.id === channel.id && (
														<ListingMenuDropDown
															attr={'editUserDetailDropdown'}
															menuList={[
																{
																	menuAction: 'details',
																	menuTitle: t('EDIT_USER_DETAILS'),
																},
																{
																	menuAction: 'remove',
																	menuTitle: t('REMOVE_USER_FROM_ACCOUNT'),
																},
															]}
															objectParams={currentlySelectedChannel}
															menuAnchor={actionAnchor}
															handleMenuClose={this.handleMenuClose}
														/>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<Dialog
						open={showCreateDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{t('CHANNEL_CREATE_HEADER_LABEL')}</Typography>
							<IconButton onClick={this.hideNewDialogHandler}>
								<Close/>
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<div className="dialogContentWrapper">
									<div className="dialogBoxInternalBlock">
										<label>{t('CHANNEL_NAME_LABEL')}</label>
										<input
											type="text"
											className="genericTextInput"
											value={channelToCreate.title}
											onChange={(event) => this.handleChannelNameChange(event)}
											required
										/>
									</div>
									<div className="dialogBoxInternalBlock">
										<label>{'Description'}</label>
										<textarea
											rows="4"
											className="genericTextInput"
											value={channelToCreate.description}
											onChange={(event) => this.handleChannelDescriptionChange(event)}
										/>
									</div>
									<div className="dialogBoxInternalBlock">
										<label>{'Quality output'}</label>
										<div className="typeDropDownWrapper">
											<select
												className="typeDropDown"
												onChange={(event) => this.handleProfileChange(event)}
												value={channelToCreate.profile}
											>
												{channelProfiles.map((profile, i) => (
													<option
														key={i}
														value={profile.ID}
														selected={profile.TITLE === '720p' ? 'selected' : ''}
													>
														{profile.TITLE}{' '}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="dialogBoxInternalBlock">
										<label style={{ fontWeight: 'normal', textAlign: 'center' }}>
											{t('CHANNEL_CREATE_INFORMATION_MESSAGE')}
										</label>
									</div>
									<div className="dialogBoxInternalBlock">
										<div style={{ textAlign: 'center' }}>
											<Checkbox
												id={'termAndConditions'}
												style={{ color: '#126CFC', paddingLeft: '0px' }}
												onChange={this.handleTermAndConditions}
												value={channelToCreate.termAndConditionsAccepted}
											/>
											<label
												for={'termAndConditions'}
												style={{ fontWeight: 'normal', display: 'inline' }}
											>
												{t('CHANNEL_CREATE_AGREE_TERMS_LABEL')}
											</label>
										</div>
									</div>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
									<Button
										variant="contained"
										className="defaultActionBtn"
										disabled={
											channelToCreate.title &&
											channelToCreate.title.length > 0 &&
											channelToCreate.termAndConditionsAccepted
												? false
												: true
										}
										onClick={this.handleCreation}
									>
										{t('CHANNEL_CREATE_BUTTON_LABEL')}
									</Button>
								</div>
							</div>
						</DialogActions>
					</Dialog>

					<Dialog
						open={showEditDialog}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{t('Channel details')}</Typography>
							<IconButton onClick={this.hideEditDialogHandler}>
								<Close/>
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<div className="dialogContentWrapper">
									<div className="dialogBoxInternalBlock">
										<label>{t('CHANNEL_NAME_LABEL')}</label>
										<input
											type="text"
											className="genericTextInput"
											value={channelToEdit.metadata && channelToEdit.metadata.title}
											onChange={(event) => this.handleChannelTitleChangeInEdit(event)}
											required
										/>
									</div>
									<div className="dialogBoxInternalBlock">
										<label>{'Description'}</label>
										<textarea
											rows="4"
											className="genericTextInput"
											value={channelToEdit.metadata && channelToEdit.metadata.description}
											onChange={(event) => this.handleChannelDescriptionChangeInEdit(event)}
										/>
									</div>
									<div className="dialogBoxInternalBlock">
										<label>{'Quality output'}</label>
										<div className="typeDropDownWrapper">
											<select
												className="typeDropDown"
												value={channelToCreate.profile}
												disabled={true}
											>
												{channelProfiles.map((p, i) => (
													<option key={i} value={p.ID}>
														{p.TITLE}
													</option>
												))}
											</select>
										</div>
									</div>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
									<Button
										variant="contained"
										className="defaultActionBtn"
										disabled={
											channelToEdit.metadata && channelToEdit.metadata.title.length > 0
												? false
												: true
										}
										onClick={this.handleUpdate}
									>
										{t('CHANNEL_UPDATE_BUTTON_LABEL')}
									</Button>
								</div>
							</div>
						</DialogActions>
					</Dialog>

					<Dialog
						open={deleteDialogStatus}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle className="dialogTitle" disableTypography>
							<Typography variant="h6">{t('LABEL_ARE_YOU_SURE_TO_DELETE_CHANNEL')}</Typography>
							<IconButton onClick={this.handleHideDeleteDialog}>
								<Close/>
							</IconButton>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								<div className="dialogContentWrapper">
									<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
										{
											<label>
												{currentlySelectedChannel.metadata &&
													currentlySelectedChannel.metadata.title}
											</label>
										}
									</div>
								</div>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<div className="dialogActionsWrapper">
								<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
									<Button
										variant="contained"
										className="defaultActionBtn neutralBtn"
										onClick={this.handleHideDeleteDialog}
									>
										{t('BACK')}
									</Button>
									<Button
										variant="contained"
										className="defaultActionBtn deleteButtonWithIcon"
										onClick={this.handleDeletion}
									>
										<IconsStore iconName={IconsList.TRASHCAN} />
										{t('DELETE')}
									</Button>
								</div>
							</div>
						</DialogActions>
					</Dialog>
				</>
			)
		);
	}
}

const mapStateToProps = ({ session, channel }) => ({
	loading: session.loading,
	defaultAccountId: session.defaultAccountId,
	userPermissions: session.userPermissions,
	channels: channel.channels,
});

const mapDispatchToProps = (dispatch) => ({
	//getChannel: (accountId, channelId) => dispatch(getChannel(accountId, channelId)),
	getIPonlyChannels: (accountId, resourceId) => dispatch(getIPonlyChannels(accountId, resourceId)),
	//addChannel: (accountId, resouceId, channelName, data) => dispatch(addChannel(accountId, resouceId, channelName, data)),
	//deleteChannel: (accountId, resouceId, channelName) => dispatch(deleteChannel(accountId, resouceId, channelName)),
	//updateChannel: (accountId, resouceId, channelName, data) => dispatch(updateChannel(accountId, resouceId, channelName, data)),
	loadChannels: (accountId) => dispatch(loadChannels(accountId)),
	updateChannel: (accountId, channelId, data) => dispatch(updateChannel(accountId, channelId, data)),
	createIPOnlyChannel: (accountId, body) => dispatch(createIPOnlyChannel(accountId, body)),
	deleteIPOnlyChannel: (accountId, body) => dispatch(deleteIPOnlyChannel(accountId, body)),
	showNotification: (message) => dispatch(showNotification(message)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(Channels);
