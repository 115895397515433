import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getAllQnAs, getQuestions } from '../../../../actions/liveChatActions';

import { QuestionAndAnswerTemplate } from './QuestionAnsAnswerTemplate';

interface DataProps {
	defaultAccountId: string;
	mediaId: string;
}
interface ReturnDataProps {
	qnas?: any[];
	questions?: QuestionAndAnswerTemplate.Question[];
}

export const useFetchQnAData = ({ defaultAccountId, mediaId }: DataProps): ReturnDataProps => {
	const dispatch = useDispatch() as any;
	const [qnas, setQnas] = useState([]);
	const [questions, setQuestions] = useState([]);

	useEffect(() => {
		dispatch(getAllQnAs(defaultAccountId, mediaId)).then((response: any) => {
			if (response) {
				setQnas(response);
			}

			if (response?.[0]?.id) {
				dispatch(getQuestions(defaultAccountId, mediaId, response?.[0]?.id)).then((questions: any) => {
					if (questions) {
						setQuestions(questions);
					}
				});
			}
		});
	}, [defaultAccountId, dispatch, mediaId]);

	return {
		qnas,
		questions,
	};
};
