import { appPlacementTypes } from '../../constants/appPlacementTypes';
import { appsHelperService } from '../../services/appsHelperService';
import { checkPermissionGrantedFor } from '../../services/componentReusableService';
import { NavigationItems } from '../nav/navigationItems';
import { DEFAULT_MENU_ITEMS, KEYS, MENU_ICONS, MENU_ORDER } from './constants';

export const stringToColor = (str: string | null) => {
	if (!str) {
		return '#126cfc';
	}

	let hash = 0;
	try {
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
	} catch (err) {
		console.error(err);
	}
	const h = hash % 260;
	return 'hsl(' + h + ', ' + 39 + '%, ' + 50 + '%)';
};

export const getInitials = (str: string | null) => {
	let result = 'notfound';
	try {
		let firstLetter = str?.charAt(0);
		let afterDot = str?.substr(str?.indexOf('.'));
		let secondLetter = afterDot?.charAt(1);
		result = firstLetter + ' ' + secondLetter;
	} catch (err) {
		console.error(err);
	}

	return result;
};

export const getMainMenuItem = (apps: any[], t: any, userPermissions: any) => {
	let items: any[] = [];
	let defaultMenuItems = NavigationItems.getDashboardItems(t);
	let appMenuItems = apps && apps.length > 0 ? appsHelperService.getApps(apps, appPlacementTypes.LEFTMENU, 6) : [];

	if (appMenuItems && appMenuItems.length > 0) {
		items = appsHelperService.parseLeftMenu(appMenuItems);
	}

	let menus = items ? defaultMenuItems.concat(items) : defaultMenuItems;
	menus = menus.sort((a, b) => {
		return a.order - b.order;
	});

	let lastItems: any[] = [];
	menus.forEach((menu: any) => {
		if (menu.key === KEYS.dashboard) {
			return; // Dashboard no longer included in main menu
		}

		let icon = menu.icon;
		let order = menu.order;
		Object.keys(MENU_ICONS).forEach((key) => {
			if (!menu.key) {
				return;
			}
			if (menu.key.toString().includes(key) || menu.key === key) {
				icon = (MENU_ICONS as any)[key];
				order = menu.key.toString().includes('app') ? MENU_ORDER.apps : (MENU_ORDER as any)[key];
			}
		});
		const menuText = (
			menu.key.toString().includes('portal')
				? 'CHANNELS'
				: menu.scope && menu.scope.split('.').length > 2
				? menu.text
				: menu.text
		).toLowerCase(); // Portal called Channel now
		lastItems.push({
			...menu,
			text: menuText.charAt(0).toUpperCase() + menuText.slice(1), // Upper case first letter
			icon: icon,
			icon_black: icon,
			icon_grey: menu.icon_grey,
			order,
			hasPermission: true,
		});
	});

	lastItems.push({
		...DEFAULT_MENU_ITEMS.find((i) => i.key === KEYS.interact),
		hasPermission: checkPermissionGrantedFor(userPermissions, 'scenarios'),
	});
	lastItems.push({
		...DEFAULT_MENU_ITEMS.find((i) => i.key === KEYS.edit),
		hasPermission: checkPermissionGrantedFor(userPermissions, 'adobeExpress'),
	});

	return lastItems.filter(Boolean).sort((a: any, b: any) => a.order - b.order);
};
