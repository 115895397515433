import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import _ from 'underscore';

import LiveManagerSnapshotSettings from './LiveManagerSnapshotSettings/LiveManagerSnapshotSettings';
import LiveManagerStreamSettings from './LiveManagerStreamSettings/LiveManagerStreamSettings';
import LiveBroadcasterSettings from './LiveBroadcasterSettings/LiveBroadcasterSettings';
import LiveManagerInteractQueue from './LiveManagerInteractQueue/LiveManagerInteractQueue';

import { setLiveSettingsId } from '../../actions/liveManagerActions';
import IconsStore from '../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../reusable/IconsStore/IconsList';
import { liveSettingsList } from '../../constants/liveManagerConstants';

import ViewerEngagement from './ViewEngagement/ViewerEngagement';

import './LiveManagerSettings.scss';

class LiveManagerSettings extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderStreamSettings = () => {
		const { t, mediaId, isVisible, SaveAsOnDemand } = this.props;
		return (
			<>
				<LiveManagerStreamSettings
					isVisible={isVisible}
					t={t}
					mediaId={mediaId}
					onStreamSettingsLoaded={this.onStreamSettingsLoaded}
					SaveAsOnDemand={SaveAsOnDemand}
				/>
			</>
		);
	};

	renderInteractSettings = () => {
		const { t, mediaId, showMessage } = this.props;
		return (
			<>
				<LiveManagerInteractQueue
					t={t}
					mediaId={mediaId}
					showMessage={showMessage}
					liveInteractSave={this.props.liveInteractSave}
				/>
			</>
		);
	};

	renderSnapshotSettings = () => {
		const { t, mediaId } = this.props;
		return (
			<>
				<LiveManagerSnapshotSettings t={t} mediaId={mediaId}></LiveManagerSnapshotSettings>
			</>
		);
	};

	renderViewerEngagement = () => {
		const { mediaId, mediaDetails, reloadData } = this.props;
		return (
			<ViewerEngagement
				backToList={this.backToList}
				mediaId={mediaId}
				mediaDetails={mediaDetails}
				reloadData={reloadData}
			/>
		);
	};

	renderBroadcasterSettings = () => {
		const { t, mediaId } = this.props;
		return <>{<LiveBroadcasterSettings t={t} mediaId={mediaId}></LiveBroadcasterSettings>}</>;
	};

	handleSettingsSelection = (id) => {
		this.props.setLiveSettingsId(id);
	};

	backToList = () => {
		this.props.setLiveSettingsId('');
	};

	render() {
		const { selectedItem, liveSettingsId, t, timelineItems } = this.props;
		let item = _.find(timelineItems ? timelineItems : this.props.timelineItems, (item) => {
			return item.id === selectedItem;
		});
		let settingsText = '';
		if (liveSettingsId === liveSettingsList.STREAM) {
			settingsText = settingsText + t('STREAM_SETTINGS');
		} else if (liveSettingsId === liveSettingsList.INTERACT) {
			settingsText = settingsText + t('INTERACT_QUEUE');
		} else if (liveSettingsId === liveSettingsList.SNAPSHOT && item) {
			settingsText = settingsText + (item && item.content ? item.content : '');
		} else if (liveSettingsId === liveSettingsList.CHAT) {
			settingsText = settingsText + t('INCOMING_CHAT');
		} /*  else if (liveSettingsId === liveSettingsList.BROADCASTER) {
            settingsText = settingsText + t('BROADCASTER')
        } */

		return (
			<>
				<div id="liveStreamSettings">
					{!liveSettingsId && (
						<ul className="streamSettingsOption">
							<li onClick={() => this.handleSettingsSelection(liveSettingsList.STREAM)}>
								{' '}
								{<IconsStore iconName={IconsList.SETTINGS} />}
								<label> {t('STREAM_SETTINGS')} </label>{' '}
							</li>
							<li onClick={() => this.handleSettingsSelection(liveSettingsList.INTERACT)}>
								{' '}
								<IconsStore iconName={IconsList.INTERACT} />
								<label> {t('INTERACT_QUEUE')} </label>{' '}
							</li>
							{
								<li onClick={() => this.handleSettingsSelection(liveSettingsList.CHAT)}>
									{' '}
									<IconsStore iconName={IconsList.CHAT_LEFT_DOTS} />
									<label> {t('INCOMING_CHAT')} </label>{' '}
								</li>
							}
							{/*
                            <li onClick={(e) => this.handleSettingsSelection(liveSettingsList.BROADCASTER)}>  <IconsStore iconName={IconsList.BROADCASTER_ICON} />
                                <label> {t('BROADCASTER')} </label> </li> */}
						</ul>
					)}
					{liveSettingsId && liveSettingsId !== liveSettingsList.CHAT && (
						<div className="liveSettingsHeader">
							<div className="backButton" onClick={() => this.backToList()}>
								{<IconsStore iconName={IconsList.LEFT_ARROW} />}
							</div>
							<label>{settingsText}</label>
						</div>
					)}

					{liveSettingsId === liveSettingsList.STREAM ? (
						<> {this.renderStreamSettings()} </>
					) : liveSettingsId === liveSettingsList.INTERACT ? (
						<> {this.renderInteractSettings()} </>
					) : (
						<>
							{' '}
							{liveSettingsId === liveSettingsList.SNAPSHOT && item ? (
								<>{this.renderSnapshotSettings()} </>
							) : null}{' '}
						</>
					)}
					{liveSettingsId === liveSettingsList.CHAT && this.renderViewerEngagement()}
				</div>
			</>
		);
	}
}

LiveManagerSnapshotSettings.propTypes = {
	mediaId: PropTypes.string.isRequired,
};

LiveManagerSnapshotSettings.defaultProps = {
	// showSettings: false
};

const mapStateToProps = ({ liveManager }) => ({
	liveSettingsId: liveManager.liveSettingsId,
	selectedItem: liveManager.selectedItem,
	timelineItems: liveManager.items,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
});

const mapDispatchToProps = (dispatch) => ({
	setLiveSettingsId: (id) => dispatch(setLiveSettingsId(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveManagerSettings));
