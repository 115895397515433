import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Divider, IconButton, Menu, MenuItem } from '@material-ui/core';

import { AddToPlaylistDialog } from '../../InspectSections/AddToPlaylistDialog';
import AddToCatalogDialog from '../../InspectSections/AddToCatalogDialog';
import { showNotification } from '../../../../../actions/notificationActions';
import { mediaTypes, messageTypes } from '../../../../../constants/mediaConstants';
import {
	loadAllThePlaylists,
	createNewPlaylist,
	loadMediaPlayLists,
	assignMediaItemToTheSelectedPlaylist,
	assignMediaItemToTheSelectedCatalog,
	deleteMediaItemFromCatalog,
	createNewCatalog,
	getMediaQueryDetails,
	moveMediaToBin,
} from '../../../../../actions/publishActions';
import CreateNewPlaylistDialog from '../CreateNewPlaylistDialog';
import CreateNewCatalogDialog from '../../../../../reusable/CatalogTree/CreateNewCatalogDialog';
import AdobeExpressForClassComponent from '../AdobeExpressForClassComponent';
import { EDITED_MEDIA_ID_PREFIX } from '../../../AdobeExpress/utils';
import { showMessage } from '../../../../../actions/globalActions';

import './MediaAddSection.scss';

class MediaAddSection extends Component {
	state = {
		createCatalogDialog: false,
		showAddToCatalogDialogBoxStatus: false,
		currentlySelectedPlaylistToAddMedia: '',
		showAddToPlaylistDialogBoxStatus: false,
		showCreatePlaylistsDialogBoxStatus: false,
		selectedCatalog: '',
		playlists: [],
		mobileView: this.props.mobileView,
		menuAnchor: null,
	};

	closeAddToPlaylistDialog = (_) =>
		this.setState({
			showAddToPlaylistDialogBoxStatus: false,
			currentlySelectedPlaylistToAddMedia: '',
			playlists: [],
		});

	closeAddToCatalogDialog = (_) => this.setState({ showAddToCatalogDialogBoxStatus: false });

	renderCreateCatalogDialog = (_) => {
		const { createCatalogDialog } = this.state;
		if (createCatalogDialog) {
			return (
				<CreateNewCatalogDialog
					open={createCatalogDialog}
					onClose={() => this.setState({ createCatalogDialog: false })}
				/>
			);
		}
		return null;
	};

	handleCatalogsChange = (id) => this.setState({ selectedCatalog: id });

	showCreateNewPlaylist = (_) =>
		this.setState({ showAddToPlaylistDialogBoxStatus: false, showCreatePlaylistsDialogBoxStatus: true });

	showCreateNewCatalog = (_) => this.setState({ showAddToCatalogDialogBoxStatus: false, createCatalogDialog: true });

	handleHideCreateNewPlaylistDialog = (_) => this.setState({ showCreatePlaylistsDialogBoxStatus: false });

	clearScreen = (_) => this.setState({ playlists: [] });

	createNewPlaylistHandler = (_) => {
		this.handleHideCreateNewPlaylistDialog();
		this.clearScreen();
		this.closeAddToPlaylistDialog();
	};

	highlightTheSelectedPlaylistContainer = (id) => this.setState({ currentlySelectedPlaylistToAddMedia: id });

	onAssignMediasToCatalog = () => {
		this.closeAddToCatalogDialog();
		this.reloadBreadCrumb();
	};

	reloadBreadCrumb = () => {
		if (this.props.reloadBreadCrumb) {
			setTimeout(() => {
				this.props.reloadBreadCrumb();
			}, 2000);
		}
	};

	backToThePreviousDialogBoxIfSpecified = (_) => {
		this.setState({ showCreatePlaylistsDialogBoxStatus: false });
		this.props
			.loadTheAllMediaPlayLists(this.props.defaultAccountId)
			.then((data) => this.setState({ playlists: data, showAddToPlaylistDialogBoxStatus: true }));
	};

	getUploadDialogStyle = (_) => ({ paddingLeft: 53, paddingTop: 0, paddingRight: 53, paddingBottom: 0 });

	showMediaRelativeDialogBox = (targetType) => {
		switch (targetType) {
			case 'catalog':
				this.setState({ showAddToCatalogDialogBoxStatus: true });
				break;
			case 'playlist':
				this.setState({ showAddToPlaylistDialogBoxStatus: true });
				this.props.loadMediaPlayLists(this.props.defaultAccountId, 100, 0, 'created desc').then((data) => {
					let playlistResources = [];
					for (var i = 0, max = data.length; i < max; i += 1) {
						// if ((data[i].asset) || ((data[i].asset) && (data[i].asset.resources))) {
						playlistResources.push(data[i]);
						// }
					}
					this.setState({ playlists: playlistResources });
				});
				break;
		}
	};

	showAddToCatalogDialogBox = (_) => {
		const { mediaDetails } = this.props;
		const { showAddToCatalogDialogBoxStatus } = this.state;
		if (showAddToCatalogDialogBoxStatus) {
			return (
				<>
					<AddToCatalogDialog
						open={showAddToCatalogDialogBoxStatus}
						onClose={this.closeAddToCatalogDialog}
						onAssignMediasToCatalog={this.onAssignMediasToCatalog}
						mediasToAssign={[mediaDetails]}
						preSelectedCatalog={mediaDetails.parentId}
						showCreateNewCatalog={this.showCreateNewCatalog}
						handleCatalogsChange={this.handleCatalogsChange}
					/>
				</>
			);
		} else {
			return null;
		}
	};

	onMoveMediaToBinClick = () => {
		const { t, mediaDetails, moveMediaToBin, showMessage, defaultAccountId } = this.props;
		this.setState({ menuAnchor: null });

		if (!mediaDetails?.id) {
			return;
		}

		moveMediaToBin({ accountId: defaultAccountId, mediaId: mediaDetails.id })
			.then((res) => {
				if (!res || res.message) {
					showMessage(`${t('MOVE_MEDIA_TO_BIN_FAILED')} ${failedToMove} media(s)`, messageTypes.error);
					return;
				}

				showMessage(t('MOVE_SINGLE_MEDIA_TO_BIN_SUCCESSFULLY'), messageTypes.success);
			})
			.catch((error) => showMessage(`${t('MOVE_MEDIA_TO_BIN_FAILED')}. ${error}`, messageTypes.error));
	};

	render() {
		const {
			t,
			mediaDetails,
			handleReplaceContentButtonClick,
			isAllowReplaceMediaContent,
			deleteMediaDialogRef,
			mediaPreviewDialogRef,
			mediaPreviewLink,
			mediaDownloadLink,
		} = this.props;
		const { showCreatePlaylistsDialogBoxStatus, showAddToPlaylistDialogBoxStatus, menuAnchor } = this.state;
		const isRecyclable =
			mediaDetails.type === mediaTypes.image ||
			mediaDetails.type === mediaTypes.video ||
			mediaDetails.type === mediaTypes.livevideo;

		return (
			<>
				<div id="mediaAddSection">
					{mediaDetails.hasOwnProperty('type') && mediaDetails.type != mediaTypes.catalog}
					{
						<>
							<AddToPlaylistDialog
								open={showAddToPlaylistDialogBoxStatus}
								showCreateNewPlaylist={this.showCreateNewPlaylist}
								mediasToAssign={[mediaDetails]}
								onClose={this.closeAddToPlaylistDialog}
								onAssignMediaToPlaylist={this.closeAddToPlaylistDialog}
							/>

							<CreateNewPlaylistDialog
								open={showCreatePlaylistsDialogBoxStatus}
								previousSource={'media-inspection'}
								onClose={this.handleHideCreateNewPlaylistDialog}
								onCreateNewPlaylist={this.createNewPlaylistHandler}
								mediasToAssign={[mediaDetails]}
								backToThePreviousDialogBoxIfSpecified={this.backToThePreviousDialogBoxIfSpecified}
							/>
							<IconButton
								size="small"
								onClick={(e) => {
									this.setState({ menuAnchor: e.target });
								}}
							>
								<MoreHorizIcon htmlColor="#000" />
							</IconButton>
						</>
					}
					{mediaDetails.hasOwnProperty('type') && mediaDetails.type != mediaTypes.playlists}
					{
						<>
							{this.showAddToCatalogDialogBox()}
							{this.renderCreateCatalogDialog()}
						</>
					}
					<Menu
						className="action-menu"
						anchorEl={menuAnchor}
						open={Boolean(menuAnchor)}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						getContentAnchorEl={null}
						onClose={() => {
							this.setState({ menuAnchor: null });
						}}
					>
						<MenuItem
							className="action-menu__small-item"
							onClick={() => {
								this.showMediaRelativeDialogBox('catalog');
								this.setState({ menuAnchor: null });
							}}
						>
							{t('ADD_TO_CATALOG')}
						</MenuItem>
						<MenuItem
							className="action-menu__small-item"
							onClick={() => {
								this.showMediaRelativeDialogBox('playlist');
								this.setState({ menuAnchor: null });
							}}
						>
							{t('LABEL_ADD_TO_PLAYLIST')}
						</MenuItem>

						{mediaDetails?.id && mediaDetails.id.startsWith(EDITED_MEDIA_ID_PREFIX) && (
							<AdobeExpressForClassComponent
								className="action-menu__small-item"
								mediaId={mediaDetails.id}
								onClick={() => {
									this.setState({ menuAnchor: null });
								}}
								mediaName={mediaDetails.metadata.title || 'Untitle'}
							/>
						)}

						<MenuItem
							className="action-menu__small-item"
							onClick={() => {
								const downloadLink = mediaDownloadLink
									? mediaDownloadLink
									: mediaPreviewDialogRef && mediaPreviewDialogRef.getDownloadLink();
								const a = document.createElement('a');
								a.href = downloadLink;
								a.download = downloadLink.split('/').pop();
								document.body.appendChild(a);
								a.click();
								document.body.removeChild(a);
								this.setState({ menuAnchor: null });
							}}
						>
							{t('DOWNLOAD')}
						</MenuItem>
						<MenuItem
							className="action-menu__small-item"
							onClick={() => {
								const previewLink = mediaPreviewLink
									? mediaPreviewLink
									: mediaPreviewDialogRef && mediaPreviewDialogRef.getPreviewLink();
								window.open(previewLink, '_blank');
								this.setState({ menuAnchor: null });
							}}
						>
							{t('LABEL_PREVIEW')}
						</MenuItem>

						{isAllowReplaceMediaContent && (
							<>
								<Divider />
								<MenuItem
									className="action-menu__small-item"
									onClick={() => {
										handleReplaceContentButtonClick();
										this.setState({ menuAnchor: null });
									}}
								>
									{t('REPLACE_VIDEO')}
								</MenuItem>
							</>
						)}

						<Divider />

						{isRecyclable ? (
							<MenuItem className="action-menu__small-item" onClick={this.onMoveMediaToBinClick}>
								{t('MOVE_TO_BIN')}
							</MenuItem>
						) : (
							<MenuItem
								className="action-menu__small-item"
								onClick={() => {
									deleteMediaDialogRef && deleteMediaDialogRef.handleDeleteMedia();
									this.setState({ menuAnchor: null });
								}}
							>
								{t('DELETE_MEDIA')}
							</MenuItem>
						)}
					</Menu>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish, inspectScreenReducer }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
	mediaPreviewLink: inspectScreenReducer.previewLink,
	mediaDownloadLink: inspectScreenReducer.downloadLink,
});

const mapDispatchToProps = (dispatch) => ({
	showMessage: (mess, type) => dispatch(showMessage(mess, type)),
	showNotification: (message) => dispatch(showNotification(message)),
	loadTheAllMediaPlayLists: (accountId) => dispatch(loadAllThePlaylists(accountId)),
	loadMediaPlayLists: (accountId, dataPerPage, currentOffset, sortType) =>
		dispatch(loadMediaPlayLists(accountId, dataPerPage, currentOffset, sortType)),
	createNewPlaylist: (accountId, assetsId, uuidForPlaylist) =>
		dispatch(createNewPlaylist(accountId, assetsId, uuidForPlaylist)),
	assignMediaItemToSelectedPlaylist: (accountId, playListId, mediaId) =>
		dispatch(assignMediaItemToTheSelectedPlaylist(accountId, playListId, mediaId)),
	createNewCatalog: (accountId, assetsId, uuidForCatalog, catalogName, parentId) =>
		dispatch(createNewCatalog(accountId, assetsId, uuidForCatalog, catalogName, parentId)),
	assignMediaItemToSelectedCatalog: (accountId, playListId, mediaId, isCatalog) =>
		dispatch(assignMediaItemToTheSelectedCatalog(accountId, playListId, mediaId, isCatalog)),
	deleteMediaItemFromCatalog: (accountId, playListId, mediaId, isCatalog) =>
		dispatch(deleteMediaItemFromCatalog(accountId, playListId, mediaId, isCatalog)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	moveMediaToBin: (mediaInfo) => dispatch(moveMediaToBin(mediaInfo)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(MediaAddSection);
