export const publicConfigIds = {
	Enterprise: 'Enterprise',
	Interactive: 'Interactive',
};

export const HAL_9000_ACCOUNT_ID = 'Accpy7KNHj1IUilFL5BMuNL0Q';

export const defaultSettingTypes = {
	enterprise: {
		name: 'Enterprise player',
		note: 'For regular videos',
	},
	interactive: {
		name: 'Interactive player',
		note: 'For interactive videos',
	},
	shopping: {
		name: 'Shopping player',
		note: 'For shopping videos',
	},
};

export const advancedSettingTypes = {
	playbackOptions: 'playbackOptions',
	playCover: 'playCover',
	topControls: 'topControls',
	bottomControls: 'bottomControls',
	engagementFeatures: 'engagementFeatures',
	accessibilitySettings: 'accessibilitySettings',
	analyticsSettings: 'analyticsSettings',
	playerStyling: 'playerStyling',
	additionalSettings: 'additionalSettings',
};

export const advancedSettingTypesOrder = {
	playbackOptions: 1,
	playCover: 2,
	topControls: 3,
	bottomControls: 4,
	engagementFeatures: 5,
	accessibilitySettings: 6,
	analyticsSettings: 7,
	playerStyling: 8,
	additionalSettings: 9,
};

export const playbackOptionsKeys = [
	'softFullscreen',
	'maintainProportions',
	'autostart',
	'muteAtStart',
	'replay',
	'subtitlesAutoOn',
];

export const playCoverOptionsKeys = ['playButtonSize', 'playButtonPosition', 'customPlayButtonIcon'];

export const topControlsKeys = [
	'showTitle',
	'showAccountName',
	'showSocialSharing',
	'showDownloadButton',
	'showOpenInNewWindow',
	'showHoverShadow',
	'alwaysVisible',
];

export const bottomControlsKeys = [
	'showPlaybackControls',
	'showPlayPauseButton',
	'showTimeline',
	'showTime',
	'showRewindForwardButtons',
	'showAudioControls',
	'showCastDevices',
	'showLanguageSettingsButton',
	'showSubtitlesSettings',
	'showAudioTracksSettings',
	'showSettingsButton',
	'showVideoQualitySettings',
	'showSocialSharingButton',
	'showPictureInPicture',
	'showFullscreen',
	'showHoverShadow',
	'alwaysVisible',
];

export const engagementFeaturesKeys = ['showShoppingCart', 'showChat', 'openChatOnPlay', 'showLiveLikes'];

export const accessibilitySettingsKeys = ['language'];

export const analyticsSettingsKeys = {
	gaMeasurementId: 'gaMeasurementId',
};

export const playerStylingKeys = ['primaryColor', 'playerFont', 'useCustomFont'];

export const additionalSettingsKeys = ['noCookies'];

export const advancedSettingLabels = {
	uniqueFrame: 'UNIQUE_FRAME',

	playbackOptions: 'PLAYBACK_OPTIONS',
	replay: 'REPLAY',
	muteAtStart: 'MUTE_ON_PLAY',
	softFullscreen: 'SOFT_FULL_SCREEN',
	autostart: 'AUTOPLAY',
	maintainProportions: 'MAINTAIN_VIDEO_PROPORTIONS',
	subtitlesAutoOn: 'SUBTITLE_AUTO_ON',

	playCover: 'PLAY_COVER',
	playButtonSize: 'PLAY_BUTTON_SIZE',
	playButtonPosition: 'PLAY_BUTTON_POSITION',
	customPlayButtonIcon: 'CUSTOM_PLAY_BUTTON_ICON',
	customPlayButtonUrlPlaceholder: 'CUSTOM_PLAY_BUTTON_ICON_URL_PLACEHOLDER',

	topControls: 'TOP_CONTROLS',
	showTitle: 'SHOW_TITLE',
	showAccountName: 'SHOW_ACC_NAME',
	showAccountNamePlaceholder: 'SHOW_ACC_NAME_PLACEHOLDER',
	showSocialSharing: 'SOCIAL_SHARING',
	showDownloadButton: 'DOWNLOAD_BUTTON',
	showOpenInNewWindow: 'PLAYER_OPEN_IN_NEW_WINDOW',
	showHoverShadow: 'HOVER_SHADOW',
	alwaysVisible: 'ALWAYS_VISIBLE',

	bottomControls: 'BOTTOM_CONTROLS',
	showPlaybackControls: 'BOTTOM_CONTROLS_PLAYBACK',
	showPlayPauseButton: 'PLAY_PAUSE_BUTTON',
	showTime: 'SHOW_TIME',
	showRewindForwardButtons: 'BOTTOM_CONTROLS_REWIND',
	showSettingsButton: 'BOTTOM_CONTROLS_SETTINGS',
	showSocialSharingButton: 'SOCIAL_SHARING',
	showVideoQualitySettings: 'VIDEO_QUALITY',
	showLanguageSettingsButton: 'LANGUAGE_SETTINGS',
	showSubtitlesSettings: 'LANGUAGE_SETTINGS_SUBTITLES',
	showAudioTracksSettings: 'LANGUAGE_SETTINGS_AUDIO_TRACKS',
	showTimeline: 'BOTTOM_CONTROLS_TIMELINE',
	showPictureInPicture: 'PICTURE_IN_PICTURE',
	showAudioControls: 'AUDIO_CONTROLS',
	showFullscreen: 'FULL_SCREEN',
	showCastDevices: 'SHOW_CAST_DEVICES',

	engagementFeatures: 'ENGAGEMENT',
	showShoppingCart: 'SHOPPING_CART',
	showChat: 'CHAT',
	showLiveLikes: 'LIVE_LIKES',
	openChatOnPlay: 'OPEN_CHAT_ON_PLAY',

	accessibilitySettings: 'A11Y_SETTINGS',

	analyticsSettings: 'ANALYTICS_SETTINGS',
	gaMeasurementId: 'GA_MEASUREMENT_ID',

	language: 'A11Y_LANGUAGE',

	playerStyling: 'PLAYER_STYLING',
	menuBackgroundColor: 'MENU_BG',
	hoverColor: 'HOVER_COLOR',
	primaryColor: 'PRIMARY_COLOR',
	playerFont: 'PLAYER_FONT',
	playerCustomFontUrl: 'PLAYER_CUSTOM_FONT_URL',
	playerCustomFontName: 'PLAYER_CUSTOM_FONT_NAME',
	useCustomFont: 'USE_CUSTOM_FONT',
	controlBackgroundColor: 'CONTROL_BG',
	textColor: 'ICON_AND_TEXT',

	additionalSettings: 'ADDITIONAL_SETTINGS',
	noCookies: 'NO_COOKIES',
};

export const subDataKeys = {
	showPlayPauseButton: 'showPlaybackControls',
	showTimeline: 'showPlaybackControls',
	showTime: 'showTime',
	showRewindForwardButtons: 'showPlaybackControls',
	showAudioControls: 'showPlaybackControls',
	showSubtitlesSettings: 'showLanguageSettingsButton',
	showAudioTracksSettings: 'showLanguageSettingsButton',
	showVideoQualitySettings: 'showSettingsButton',
	showSocialSharingButton: 'showSocialSharingButton',
	openChatOnPlay: 'openChatOnPlay',
};

export const headerWithCheckbox = {
	topControls: 'topControls',
	bottomControls: 'bottomControls',
	engagementFeatures: 'engagementFeatures',
};

export const opacityKeys = {
	menuBackgroundColor: 'menuBackgroundOpacity',
	controlBackgroundColor: 'controlBackgroundOpacity',
	textColor: 'textOpacity',
	hoverColor: 'hoverOpacity',
	primaryColor: 'primaryOpacity',
};

export const advancedSettingsHelperTexts = {
	noCookies: 'NO_COOKIES_HELPER_TEXT',
};

export const fonts = ['Arial', 'Poppins', 'Roboto'];

export const fontWeights = [
	'100',
	'200',
	'300',
	'400',
	'500',
	'600',
	'700',
	'800',
	'900',
	'normal',
	'bold',
	'lighter',
	'bolder',
];

export const NEW_EMBED_VERSION_2 = {
	qbrickPlayer: [
		{
			version: '2',
			enabled: true,
			presets: {
				defaults: '[Enterprise,Shopping,Interactive]',
			},
		},
	],
};

export const PLAY_COVER_CONSTANTS = {
	playButtonSizeWidth: 120,
	playButtonPositionX: 50,
	playButtonPositionY: 50,
	customPlayButtonIcon: false,
	customPlayButtonUrl: '',
};
