import React from 'react';
import Button from '@material-ui/core/Button';
import Avatar from 'react-avatar';

export const ProfileInformation = ({
	t,
	errors,
	touched,
	password,
	username,
	handleBlur,
	passwordNew,
	passwordConfirm,
	updateFormHandler,
	changePasswordHandler,
	stringToColor,
	getInitials,
	errorsInTheForm,
}) => (
	<>
		<div className="profileLeftColumn">
			<Avatar
				className="profileAvatarIcon"
				color={stringToColor(username)}
				name={getInitials(username)}
				size="200"
				round
			/>
		</div>
		<div className="profileRightColumn">
			<div className="profileSectionInputBlock">
				<h4 className="profileBlockHeaderTitle">{t('PERSONAL_INFORMATION')}</h4>
			</div>
			<div className="profileSectionInputBlock">
				<label>{t('EMAIL')}</label>
				<input type="text" className="genericTextInput" readOnly value={username} />
			</div>
			<div className="profileSectionInputBlock">
				<h4 className="profileBlockHeaderTitle">{t('CHANGE_PASSWORD')}</h4>
			</div>
			<div className="profileSectionInputBlock">
				<label>{t('OLD_PASSWORD')} *</label>
				<input
					type="password"
					value={password}
					onBlur={handleBlur('password')}
					onChange={updateFormHandler('password')}
					className={`genericTextInput ${touched.password && errors.password ? 'errorIndication' : ''}`}
				/>
			</div>
			<div className="profileSectionInputBlock">
				<label>{t('NEW_PASSWORD')} *</label>
				<input
					type="password"
					value={passwordNew}
					onBlur={handleBlur('newPassword')}
					onChange={updateFormHandler('newPassword')}
					className={`genericTextInput ${touched.newPassword && errors.newPassword ? 'errorIndication' : ''}`}
				/>
			</div>
			<div className="profileSectionInputBlock">
				<label>{t('REPEAT_PASSWORD')} *</label>
				<input
					type="password"
					value={passwordConfirm}
					onBlur={handleBlur('confirmPassword')}
					onChange={updateFormHandler('confirmPassword')}
					className={`genericTextInput ${
						touched.confirmPassword && errors.confirmPassword ? 'errorIndication' : ''
					}`}
				/>
			</div>
			<div className="profileSectionInputBlock profileSectionPasswordReq">
				<label className="boldedText">{t('PASSWORD_REQUIREMENTS')}</label>
				<label style={{ whiteSpace: 'pre-wrap' }}>{t('PASSWORD_RULE_LABEL')}</label>
			</div>
			<div className="profileSectionInputBlock">
				<Button
					variant="contained"
					className="defaultActionBtn"
					onClick={changePasswordHandler}
					disabled={
						errorsInTheForm ||
						!password ||
						!passwordNew ||
						!passwordConfirm ||
						passwordNew != passwordConfirm
					}
				>
					{t('CHANGE_PASSWORD')}
				</Button>
			</div>
		</div>
	</>
);
